import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withStyles
} from '@material-ui/core';

import { SchemaForm, handleChangeAdapter, validateData } from 'components/JsonSchema';

import WorkflowCategorySelect from '../WorkflowCategorySelect';

const styles = {

};

class WorkflowSettingsDialog extends React.Component {
    state = { workflow: this.props.workflow, errors: [] };

    componentDidUpdate({ workflow: { id: oldWorkflowId } }) {
        const { workflow, workflow: { id: newWorkflowId } } = this.props;

        if (oldWorkflowId !== newWorkflowId) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ workflow });
        }
    }

    handleChange = workflow => this.setState({ workflow });

    handleChangeCategory = (workflowTemplateCategoryId) => {
        const { workflow } = this.state;
        this.setState({
            workflow: {
                ...workflow,
                workflowTemplateCategoryId: workflowTemplateCategoryId || ''
            }
        });
    };

    handleSave = () => {
        const { workflow } = this.state;
        const { workflowSchema, onClose, onChange } = this.props;

        const errors = validateData(workflow, workflowSchema);

        this.setState({ errors });
        if (!errors.length) {
            onChange(workflow);
            onClose();
        } else {
            console.log('workflow errors', errors);
        }
    };

    render() {
        const { workflow, errors } = this.state;
        const { open, t, classes, workflowSchema, onClose } = this.props;

        return (
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="lg"
                onClose={onClose}
            >
                <DialogTitle>{t('Settings')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <WorkflowCategorySelect
                        value={workflow.workflowTemplateCategoryId}
                        onChange={this.handleChangeCategory}
                    />
                    <SchemaForm
                        value={workflow}
                        onChange={handleChangeAdapter(workflow, this.handleChange, true, workflowSchema)}
                        schema={workflowSchema}
                        errors={errors}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t('Close')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleSave}
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

WorkflowSettingsDialog.propTypes = {
    workflow: PropTypes.object,
    open: PropTypes.bool,
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    workflowSchema: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onChange: PropTypes.func
};

WorkflowSettingsDialog.defaultProps = {
    workflow: {},
    open: false,
    onClose: () => null,
    onChange: () => null
};

const styled = withStyles(styles)(WorkflowSettingsDialog);
export default translate('WorkflowAdminPage')(styled);

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
    TableCell,
    TableRow,
    Checkbox,
    withStyles
} from '@material-ui/core';

const styles = theme => ({
    selected: {
        backgroundColor: `${theme.dataTableHoverBg}!important`
    },
    hover: {
        '&:hover': {
            '& td': {
                color: `${theme.dataTableHoverColor}!important`
            }
        }
    },
    clickable: {
        cursor: 'pointer'
    },
    tableCell: {
        TableCell: {
            [theme.breakpoints.down('xs')]: {
                padding: 0,
                fontSize: 13,
                lineHeight: '18px'
            }
        }
    },
    hightlight: {
        backgroundColor: '#FFFCE5'
    }
});

const DataTableRow = ({ classes, rowIndex, item, selected, hightlight, selectable, checkable, columns, hiddenColumns, onClick, onSelect, cellStyle }) => (
    <TableRow
        hover={true}
        selected={selected}
        classes={{
            hover: classes.hover,
            selected: classes.selected
        }}
        className={classNames({
            [classes.clickable]: !!onClick,
            [classes.hightlight]: hightlight
        })}
    >
        {checkable ? (
            <TableCell style={cellStyle} padding="checkbox" width={64}>
                <Checkbox
                    checked={selected}
                    disabled={!selectable}
                    onChange={onSelect}
                />
            </TableCell>
        ) : null}
        {columns
            .filter(column => !(hiddenColumns || []).includes(column.id))
            .map(({ id, render, handleClick, disableClick, ...rest }, columnKey) => (
                <TableCell
                    style={cellStyle}
                    className={classes.tableCell}
                    {...rest}
                    key={columnKey}
                    onClick={disableClick ? null : (handleClick ? handleClick(item).bind(this) : onClick)}
                >
                    {render ? render(item[id], item, columnKey, rowIndex) : item[id]}
                </TableCell>
            ))}
    </TableRow>
);

DataTableRow.propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    hightlight: PropTypes.bool,
    selectable: PropTypes.bool,
    checkable: PropTypes.bool,
    columns: PropTypes.array
};

DataTableRow.defaultProps = {
    selected: false,
    hightlight: false,
    selectable: false,
    checkable: false,
    columns: []
};

export default withStyles(styles)(DataTableRow);

import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import UserActiveMenuItem from './UserActiveMenuItem';
import UserUnitsMenuItem from './UserUnitsMenuItem';
import UserIsAdminMenuItem from './UserIsAdminMenuItem';
import UserCertMenuItem from './UserCertMenuItem';

const UserActions = ({ user, actions }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                <MenuIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted={true}
            >
                <UserActiveMenuItem actions={actions} user={user} onClose={handleClose} onChange={actions.load} />
                <UserUnitsMenuItem actions={actions} user={user} onClose={handleClose} onChange={actions.load} />
                <UserIsAdminMenuItem actions={actions} user={user} onClose={handleClose} onChange={actions.load} />
                <UserCertMenuItem user={user} onClose={handleClose} />
            </Menu>
        </>
    );
};

UserActions.propTypes = {
    user: PropTypes.object,
    actions: PropTypes.object
};

UserActions.defaultProps = {
    user: {},
    actions: {}
};

export default UserActions;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, FormControl, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import renderHTML from 'helpers/renderHTML';
import evalate from 'helpers/evalate';
import classNames from 'classnames';

import Masked from 'components/JsonSchema/elements/StringElement/components/mask';
import EJVError from 'components/JsonSchema/components/EJVError';
import FieldLabel from 'components/JsonSchema/components/FieldLabel';

const style = theme => ({
    formControl: {
        marginBottom: 40,
        maxWidth: 640,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
            minWidth: '40px!important',
            '&>div>label': {
                top: 3,
                paddingRight: 20
            },
            '&>div>div>input': {
                fontSize: 13,
                lineHeight: '22px'
            }
        }
    },
    noMargin: {
        margin: 0
    },
    menuItem: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    },
    textField: {
        '& p': {
            whiteSpace: 'normal'
        }
    },
    adornment: {
        '& p': {
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('xs')]: {
                fontSize: 13
            }
        }
    },
    placeHolderRoot: {
        fontSize: 16,
        lineHeight: '18px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 13
        }
    },
    select: {
        '&:focus': {
            background: 'transparent'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px',
            minHeight: 22,
            display: 'flex',
            alignItems: 'center'
        }
    }
});

const Layout = ({
    classes,
    sample,
    schema,
    error,
    description,
    formControlProps,
    readOnly,
    InputProps,
    mask,
    required,
    path,
    select,
    options,
    helperText,
    multiline,
    startAdornment,
    endAdornment,
    value,
    parentValue,
    rootDocument,
    maxLength,
    width,
    maxWidth,
    hidden,
    formatChars,
    checkRequired,
    handleChange,
    renderChildren,
    useTrim,
    onFocus,
    onBlur,
    noMargin,
    onInput,
    className,
    changeOnBlur,
    wrapperClass,
    ...rest
}) => {
    const [focused, setFocused] = React.useState(false);
    const [inputValue, setInputValue] = React.useState(value || '');

    React.useEffect(() => {
        setInputValue(value);
    }, [value]);

    const InputPropsOptions = {
        ...InputProps
    };

    if (!multiline && mask.length) {
        InputPropsOptions.inputComponent = Masked;
    }

    if (startAdornment) {
        InputPropsOptions.startAdornment = <InputAdornment className={classes.adornment} position="start">{startAdornment}</InputAdornment>;
    }

    if (endAdornment) {
        InputPropsOptions.endAdornment = <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }

    const sampleElement = (helperText || sample || (schema && schema.sample)) && renderHTML(helperText || sample || (schema && schema.sample));
    const errorText = error && <EJVError error={error} />;

    if (hidden) return null;

    return (
        <FormControl
            fullWidth={true}
            {...formControlProps}
            className={classNames(classes.formControl, className && className, {
                [classes.noMargin]: !!noMargin
            })}
            style={{ width, maxWidth }}
        >
            <TextField
                {...rest}
                className={classNames({
                    [classes.textField]: true
                }, wrapperClass)}
                select={select || !!options}
                label={description ? <FieldLabel description={description} required={required} /> : null}
                onChange={(e) => {
                    setInputValue(e.target.value);
                    if (!changeOnBlur) {
                        handleChange(e);
                    }
                }}
                disabled={readOnly}
                helperText={sampleElement && errorText ? errorText : (sampleElement || errorText)}
                error={!!error}
                multiline={multiline}
                InputProps={InputPropsOptions}
                inputProps={{ maxLength, mask, formatChars, ...(!multiline ? { style: { height: 'auto' } } : {}) }}// eslint-disable-line react/jsx-no-duplicate-props
                InputLabelProps={
                    {
                        shrink: !!value || focused || startAdornment,
                        classes: {
                            root: classes.placeHolderRoot
                        }
                    }
                }
                SelectProps={
                    {
                        classes: {
                            select: classes.select
                        }
                    }
                }
                id={path.join('-')}
                value={inputValue}
                onFocus={
                    () => {
                        setFocused(true);
                        onFocus && onFocus();
                    }
                }
                onBlur={
                    ({ target }) => {
                        if (changeOnBlur || useTrim) {
                            handleChange({ target: { ...target, value: useTrim ? inputValue.trim() : inputValue } });
                        }
                        setFocused(false);
                        onBlur && onBlur();
                    }
                }
                onInput={(e) => {
                    if (onInput) {
                        try {
                            const { selectionStart } = e.target;
                            const oldLength = e.target.value.length;
                            e.target.value = evalate(onInput, e.target.value);
                            const newLength = e.target.value.length;

                            const cursorPosition = selectionStart + (newLength - oldLength);

                            e.target.selectionStart = cursorPosition;
                            e.target.selectionEnd = cursorPosition;
                        } catch (err) {
                            // Nothing to do
                        }
                    }
                }}
            >
                {renderChildren()}
            </TextField>
        </FormControl>
    );
};

Layout.propTypes = {
    onChange: PropTypes.func,
    children: PropTypes.node,
    enum: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    type: PropTypes.string,
    placeholder: PropTypes.string,
    select: PropTypes.bool,
    sample: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    error: PropTypes.object,
    formControlProps: PropTypes.object,
    description: PropTypes.string,
    classes: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    InputProps: PropTypes.object,
    SelectProps: PropTypes.object,
    mask: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    helperText: PropTypes.string,
    path: PropTypes.array,
    useTrim: PropTypes.bool,
    changeCase: PropTypes.string,
    checkRequired: PropTypes.string,
    wrapperClass: PropTypes.string
};

Layout.defaultProps = {
    children: '',
    enum: null,
    type: 'string',
    placeholder: '',
    select: false,
    onChange: undefined,
    sample: '',
    formControlProps: {},
    error: null,
    description: '',
    readOnly: false,
    InputProps: {},
    SelectProps: {},
    mask: '',
    required: false,
    value: '',
    helperText: '',
    path: [],
    width: null,
    useTrim: false,
    changeCase: null,
    checkRequired: null,
    wrapperClass: null
};

export default withStyles(style)(Layout);

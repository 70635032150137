import React from 'react';
import PropTypes from 'prop-types';

import {
    LinearProgress,
    withStyles
} from '@material-ui/core';

const styles = {
    root: {
        height: 2,
        zIndex: 1300,
        marginBottom: -2
    },
    progress: {
        height: 2
    }
};

const ProgressLine = ({ classes, loading, style }) => (
    <div className={classes.root} style={style}>
        {loading ? <LinearProgress className={classes.progress} /> : null}
    </div>
);

ProgressLine.propTypes = {
    loading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    style: PropTypes.object
};

ProgressLine.defaultProps = {
    loading: false,
    style: null
};

export default withStyles(styles)(ProgressLine);

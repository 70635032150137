/* eslint-disable no-template-curly-in-string */
import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'helpers/renderHTML';

import Handlebars from 'handlebars';
import objectPath from 'object-path';

import { withStyles } from '@material-ui/core/styles';
import ElementContainer from '../components/ElementContainer';

const styles = theme => ({
    visibility: {
        maxWidth: 1000,
        fontFamily: theme.typography.fontFamily,
        lineHeight: '1.5em',
        letterSpacing: '-0.02em',
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px',
            marginBottom: 10,
            marginTop: 10
        }
    },
    container: {
        marginBottom: 0,
        marginTop: 0
    }
});

// eslint-disable-next-line consistent-return
const TextBlock = ({ noMargin, htmlBlock, value, classes, pure, params, useParentData, parentValue, hidden, rootDocument, row, pathIndex }) => {
    if (hidden) {
        return null;
    }

    if (params) {
        const template = Handlebars.compile(htmlBlock);

        const templateData = Object.keys(params).reduce((acc, param) => {
            const paramPath = pathIndex ? (params[param] || '').replace('${index}', pathIndex.index) : params[param];
            return {
                ...acc,
                [param]: objectPath.get(useParentData ? parentValue : rootDocument.data, paramPath)
            };
        }, {});

        htmlBlock = template(templateData);
    }

    return (pure
        ? renderHTML(htmlBlock || value)
        : (
            <ElementContainer
                className={classes.container}
                row={row}
                noMargin={noMargin}
            >
                <div className={classes.visibility}>
                    {renderHTML(htmlBlock || value)}
                </div>
            </ElementContainer>
        ));
};

TextBlock.propTypes = {
    classes: PropTypes.object.isRequired,
    htmlBlock: PropTypes.string,
    value: PropTypes.string,
    pure: PropTypes.bool,
    row: PropTypes.bool,
    pathIndex: PropTypes.object
};

TextBlock.defaultProps = {
    htmlBlock: '',
    value: '',
    pure: false,
    row: false,
    pathIndex: null
};

export default withStyles(styles)(TextBlock);

import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import ProgressLine from 'components/Preloader/ProgressLine';
import { translate } from 'react-translate';

const ConfirmDialog = ({
    open,
    loading,
    title,
    description,
    handleClose,
    handleConfirm,
    t
}) => (
    <Dialog
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {description}
                <ProgressLine loading={loading} />
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
                variant="text"
                color="primary"
                id="cancel-btn"
            >
                {t('Cancel')}
            </Button>
            {
                handleConfirm ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                        autoFocus={true}
                        id="accept-btn"
                    >
                        {t('Accept')}
                    </Button>
                ) : null
            }
        </DialogActions>
    </Dialog>
);

ConfirmDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func,
    open: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    loading: PropTypes.bool
};

ConfirmDialog.defaultProps = {
    title: PropTypes.string,
    description: PropTypes.string,
    handleConfirm: null,
    loading: false
};

const translated = translate('Elements')(ConfirmDialog);
export default withMobileDialog()(translated);

import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';

export default {
    type: 'Element',
    group: 'Basic',
    Icon: StorageOutlinedIcon,
    defaultData: {
        type: 'object',
        description: 'Адреса',
        control: 'registry.search',
        keyId: 456
    }
};

import React from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@material-ui/core';

import { history } from 'store';

import formElements from './elements';

const ElementDetails = ({
    numberTemplates,
    selection,
    workflow,
    modeler,
    onChange,
    blockHotkeysEvent,
    openSelection,
    t
}) => {
    const [open, setOpen] = React.useState(false);
    const CustomElementDetails = selection && formElements[selection.type];

    if (!CustomElementDetails) {
        return null;
    }

    const content = (
        <CustomElementDetails
            numberTemplates={numberTemplates}
            selectionId={selection.businessObject.id}
            element={selection}
            workflow={workflow}
            modeler={modeler}
            onChange={onChange}
        />
    );

    const onClose = () => {
        if (openSelection) {
            history.replace(`/workflow/${workflow.id}`);
        } else {
            setOpen(false);
        }
        blockHotkeysEvent(false);
    };

    return (
        <>
            <Button
                variant="outlined"
                onClick={
                    () => {
                        setOpen(true);
                        blockHotkeysEvent(true);
                    }
                }
            >
                {selection.businessObject.id}
            </Button>
            <div style={{ display: 'none' }}>{content}</div>
            <Dialog
                open={open || openSelection}
                scroll="body"
                fullWidth={true}
                maxWidth="sm"
                onClose={onClose}
            >
                <DialogContent>
                    {content}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t('Close')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ElementDetails;

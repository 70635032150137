import React from 'react';
import TimeLabel from 'components/Label/Time';
import StringFilterHandler from 'components/DataTable/components/StringFilterHandler';
import RegisterActions from '../components/RegisterActions';

export default ({ t, actions }) => ({
    controls: {
        pagination: true,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: true
    },
    checkable: false,
    columns: [{
        id: 'id',
        align: 'left',
        sortable: false,
        name: t('RegisterId')
    }, {
        id: 'name',
        align: 'left',
        sortable: false,
        name: t('RegisterName')
    }, {
        id: 'description',
        align: 'left',
        sortable: false,
        name: t('RegisterDescription')
    }, {
        id: 'createdBy',
        align: 'left',
        sortable: false,
        name: t('CreatedBy')
    }, {
        id: 'updatedBy',
        align: 'left',
        sortable: false,
        name: t('UpdatedBy')
    }, {
        id: 'createdAt',
        width: 160,
        align: 'center',
        sortable: false,
        padding: 'checkbox',
        name: t('CreatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'updatedAt',
        width: 160,
        align: 'center',
        sortable: false,
        padding: 'checkbox',
        name: t('UpdatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'actions',
        width: 32,
        align: 'right',
        sortable: false,
        padding: 'checkbox',
        hiddable: false,
        disableClick: true,
        render: (value, register) => <RegisterActions register={register} actions={actions} />
    }],
    filterHandlers: {
        id: props => <StringFilterHandler name={t('RegistryId')} {...props} />,
        key_id: props => <StringFilterHandler name={t('RegistryKeyId')} {...props} />
    }
});

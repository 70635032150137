import evalate from 'helpers/evalate';

const removeHiddenFields = (schema, data) => {
    const { properties: schemaProperties, required: schemaRequired } = schema;

    if (!schemaProperties || !schemaRequired) {
        return schema;
    }

    const required = (schemaRequired || []).filter((field) => {
        if (schemaProperties[field] && !schemaProperties[field].hidden) {
            return true;
        }

        const result = evalate(schemaProperties[field].hidden, data);

        if (result instanceof Error) {
            result.commit({ type: 'template required error', schema });
        }

        return !result;
    });

    const properties = Object.keys(schemaProperties).reduce((acc, propertyName) => {
        const property = schemaProperties[propertyName];

        if (property.type === 'array' && property.items) {
            property.items = removeHiddenFields(property.items, data);
        }

        return {
            ...acc,
            [propertyName]: property.type === 'object' ? removeHiddenFields(property, data) : property
        };
    }, {});

    return { ...schema, required, properties };
};

export default removeHiddenFields;

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { Tooltip, Checkbox } from '@material-ui/core';

const getSelection = (rowsSelected, selectableData) => {
    if (rowsSelected.length) {
        return [];
    }

    return selectableData.filter(item => !item.entryTaskFinishedAt).map(({ id }) => id);
};

const SelectAllButton = ({ t, rowsSelected, selectableData, onRowsSelect }) => (
    <Tooltip title={t('Select')}>
        <Checkbox
            id="select-checkbox"
            indeterminate={rowsSelected.length > 0 && rowsSelected.length < selectableData.length}
            checked={rowsSelected.length === selectableData.length && selectableData.length}
            onChange={() => onRowsSelect && onRowsSelect(getSelection(rowsSelected, selectableData))}
        />
    </Tooltip>
);

SelectAllButton.propTypes = {
    t: PropTypes.func.isRequired,
    rowsSelected: PropTypes.array,
    selectableData: PropTypes.array,
    onRowsSelect: PropTypes.func.isRequired
};

SelectAllButton.defaultProps = {
    rowsSelected: [],
    selectableData: []
};

export default translate('DataTable')(SelectAllButton);

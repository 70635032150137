import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import DataTable from 'components/DataTable';

import dataTableSettings from './dataTableSettings';
import fileTableSettings from './fileTableSettings';

import FileDataTableToolbar from './components/Toolbar';

class FileDataTable extends React.Component {
    state = { rowsSelected: [] };

    onRowsSelect = rowsSelected => this.setState({ rowsSelected });

    renderToolbar = (props) => {
        const { CustomToolbar } = this.props;

        return (
            <Fragment>
                <FileDataTableToolbar {...props} />
                {CustomToolbar ? <CustomToolbar {...props} /> : null}
            </Fragment>
        );
    }

    getSettings = () => {
        const {
            fileControl,
            t,
            actions,
            fileStorage,
            directDownload,
            handleDownload,
            handleDeleteFile
        } = this.props;

        if (fileControl) {
            return fileTableSettings({
                t,
                fileStorage,
                directDownload,
                handleDownload,
                handleDeleteFile
            });
        }

        return dataTableSettings({
            t,
            fileStorage,
            actions: {
                ...actions,
                onRowsSelect: this.onRowsSelect,
                isRowSelectable: file => !(file instanceof File)
            }
        });
    };

    render() {
        const { data } = this.props;
        const { rowsSelected } = this.state;

        const settings = this.getSettings();

        const tableData = Array.isArray(data) ? data.map((file) => {
            if (file instanceof File) {
                return file;
            }
            return { ...file, id: file.id || file.fileLink };
        }) : data;

        return (
            <DataTable
                {...settings}
                {...this.props}
                CustomToolbar={this.renderToolbar}
                rowsSelected={rowsSelected}
                data={tableData}
                actions={settings.actions}
            />
        );
    }
}

FileDataTable.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object,
    fileStorage: PropTypes.object,
    fileControl: PropTypes.bool,
    handleDownload: PropTypes.func
};

FileDataTable.defaultProps = {
    actions: {},
    fileStorage: {},
    fileControl: false,
    handleDownload: null
};

export default translate('FileDataTable')(FileDataTable);

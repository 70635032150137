import { combineReducers } from 'redux';

import adminpanel from 'application/adminpanel/reducers';
import manager from 'application/manager/reducers';

const RESET_STATE = 'APP/RESET_STATE';

const clearState = ({ app, auth, eds }) => ({ app, auth, eds });

const rootReducer = appReducer => (state, action) => {
    if (action.type === RESET_STATE) {
        state = clearState(state);
    }
    return combineReducers(appReducer)(state, action);
};

export default {
    adminpanel: rootReducer(adminpanel),
    manager: rootReducer(manager)
};

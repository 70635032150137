import React, { Fragment } from 'react';
import { translate } from 'react-translate';

import {
    Tooltip,
    IconButton
} from '@material-ui/core';

import DeletedIcon from '@material-ui/icons/Delete';

import ConfirmDialog from 'components/ConfirmDialog';

class DeleteWorkflow extends React.Component {
    state = { openConfirmDialog: false };

    handleOpenConfirmDialog = () => this.setState({ openConfirmDialog: true });

    handleCloseConfirmDialog = () => this.setState({ openConfirmDialog: false });

    handleDelete = async () => {
        const { data, rowsSelected, actions: { onRowsDelete } } = this.props;
        onRowsDelete && await onRowsDelete(rowsSelected.map(row => data.find(({ id }) => id === row).entryTaskId));
        this.handleCloseConfirmDialog();
    };

    render() {
        const { t } = this.props;
        const { openConfirmDialog } = this.state;
        return (
            <Fragment>
                <Tooltip title={t('DeleteWorkflow')}>
                    <IconButton onClick={this.handleOpenConfirmDialog} id="delete-workflow">
                        <DeletedIcon />
                    </IconButton>
                </Tooltip>
                <ConfirmDialog
                    fullScreen={false}
                    open={openConfirmDialog}
                    title={t('DeleteWorkflowConfirmation')}
                    description={t('DeleteWorkflowConfirmationText')}
                    handleClose={this.handleCloseConfirmDialog}
                    handleConfirm={this.handleDelete}
                />
            </Fragment>
        );
    }
}

export default translate('WorkflowListPage')(DeleteWorkflow);

import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SplitPane from 'react-split-pane';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    Toolbar,
    IconButton,
    withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

import propertiesEach from 'components/JsonSchema/helpers/propertiesEach';

import evalate from 'helpers/evalate';
import { setCheckValidFunc } from 'actions/debugTools';

import AceEditor from 'react-ace';
import ReactResizeDetector from 'react-resize-detector';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-twilight';

const styles = {
    root: {
        display: 'flex',
        height: '100%',
        '& > div': {
            flex: '.5'
        }
    },
    leftContainer: {
        height: '100%'
    },
    rightContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        paddingLeft: 2
    },
    funcContainer: {
        flex: 1
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        minHeight: 40
    },
    iconButton: {
        width: 26,
        height: 26,
        padding: 1
    }
};

class CheckValidFunction extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
        this.aceComponentInput = React.createRef();
        this.aceComponentOutput = React.createRef();
        this.aceComponentOriginData = React.createRef();
    }

    handleChange = (property, value) => {
        const { actions, checkValidFuncs, task } = this.props;

        actions.setCheckValidFunc(task.id, {
            ...(checkValidFuncs[task.id] || {}),
            [property]: value
        });
    };

    handleCheckFunc = () => {
        const { task, stepId, checkValidFuncs } = this.props;
        const { element, func } = checkValidFuncs[task.id] || {};

        const elements = this.getElements();
        const control = elements.find(({ key }) => key === element);

        if (!control) {
            return '';
        }

        const result = evalate(func, control && control.data, task.document.data[stepId], task.document.data, control.parentData);
        if (result instanceof Error) {
            result.commit({ type: 'debug tools: check valid function', task });
            return result.message;
        }

        return JSON.stringify(result, null, 4);
    };

    getElements = () => {
        const { task, template, stepId } = this.props;
        const pages = template && template.jsonSchema.properties;

        const elements = [];

        if (stepId && pages[stepId]) {
            propertiesEach(pages[stepId], task.document.data[stepId], (schema, data, path, parentSchema, parentData, key) => {
                key && elements.push({ schema, data, path, parentSchema, parentData, key });
            });
        }

        return elements;
    };

    renderControls = () => {
        const { t, task, checkValidFuncs } = this.props;
        const { element } = checkValidFuncs[task.id] || {};

        return (
            <FormControl fullWidth={true}>
                <InputLabel htmlFor="age-simple">{t('SelectElement')}</InputLabel>
                <Select
                    value={element || ''}
                    onChange={({ target: { value } }) => this.handleChange('element', value)}
                >
                    {this.getElements().map(item => <MenuItem key={item.key} value={item.key}>{[item.key, item.schema.description].filter(Boolean).join(' - ')}</MenuItem>)}
                </Select>
            </FormControl>
        );
    };

    onResize = () => {
        this.aceComponentInput.current.editor.resize();
        this.aceComponentOutput.current.editor.resize();
        this.aceComponentOriginData.current.editor.resize();
    };

    openModal = () => this.setState({ open: true });

    closeModal = () => this.setState({ open: false });

    render() {
        const { t, classes, task, checkValidFuncs } = this.props;
        const { open } = this.state;
        const { func } = checkValidFuncs[task.id] || {};

        return (
            <div className={classes.root}>
                <SplitPane split="vertical" minSize="50%">
                    <AceEditor
                        ref={this.aceComponentOriginData}
                        mode="json"
                        theme="twilight"
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={JSON.stringify(task && task.document.data, null, 4)}
                        width="100%"
                        height="100%"
                        readOnly={true}
                        setOptions={
                            {
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 4
                            }
                        }
                    />
                    <div className={classes.rightContainer}>
                        <ReactResizeDetector handleHeight={true} onResize={this.onResize} />
                        {this.renderControls()}
                        <div className={classes.funcContainer}>
                            {t('Function')}
                            <IconButton onClick={this.openModal} className={classes.iconButton}>
                                <FullscreenIcon />
                            </IconButton>
                            <AceEditor
                                ref={this.aceComponentInput}
                                mode="javascript"
                                theme="twilight"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={func || ''}
                                width="100%"
                                height="calc(100% - 24px)"
                                readOnly={false}
                                onChange={value => this.handleChange('func', value)}
                                wrapEnabled={true}
                                setOptions={
                                    {
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true,
                                        showLineNumbers: true,
                                        tabSize: 4,
                                        highlightActiveLine: true
                                    }
                                }
                            />
                        </div>
                        <div className={classes.funcContainer}>
                            {t('Result')}
                            <AceEditor
                                ref={this.aceComponentOutput}
                                mode="json"
                                theme="twilight"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={this.handleCheckFunc()}
                                width="100%"
                                height="calc(100% - 18px)"
                                readOnly={false}
                                onChange={this.handleChangeFunc}
                                wrapEnabled={true}
                                setOptions={
                                    {
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true,
                                        showLineNumbers: true,
                                        tabSize: 4
                                    }
                                }
                            />
                        </div>
                    </div>
                </SplitPane>

                <Dialog
                    open={open}
                    fullScreen={true}
                    fullWidth={true}
                >
                    <Toolbar className={classes.toolbar}>
                        <IconButton onClick={this.closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <AceEditor
                        mode="javascript"
                        theme="twilight"
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={func || ''}
                        width="100%"
                        height="100%"
                        readOnly={false}
                        onChange={value => this.handleChange('func', value)}
                        wrapEnabled={true}
                        setOptions={
                            {
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 4,
                                highlightActiveLine: true
                            }
                        }
                    />
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({ debugTools: { checkValidFuncs } }) => ({ checkValidFuncs });

const mapDispatchToProps = dispatch => ({
    actions: {
        setCheckValidFunc: bindActionCreators(setCheckValidFunc, dispatch)
    }
});

const styled = withStyles(styles)(CheckValidFunction);
const translated = translate('DebugTools')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    MenuItem,
    ListItemIcon,
    ListItemText,
    withStyles
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import ConfirmDialog from 'components/ConfirmDialog';

import promiseChain from 'helpers/promiseChain';

const styles = {
    root: {
        color: '#ff0000'
    }
};

const DeleteKeyMenuItem = ({ t, classes, registerId, registerKey, actions, onClose }) => {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(null);

    return (
        <>
            <MenuItem
                className={classes.root}
                onClick={() => { setOpen(true); onClose(); }}
            >
                <ListItemIcon>
                    <ClearIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.root }} primary={t('DeleteKey')} />
            </MenuItem>
            <ConfirmDialog
                open={open}
                title={t('DeletePrompt')}
                description={t('DeletePropmtDescription')}
                handleClose={() => setOpen(false)}
                handleConfirm={
                    async () => {
                        try {
                            await promiseChain([
                                actions.deleteKey,
                                actions.load,
                                () => setOpen(false)
                            ], { registerId, keyId: registerKey.id });
                        } catch (e) {
                            setError(e);
                        }
                    }
                }
            />
            <ConfirmDialog
                open={!!error}
                title={t('DeleteError')}
                description={t(error && error.message)}
                handleClose={() => setError(null)}
            />
        </>
    );
};

DeleteKeyMenuItem.propTypes = {
    t: PropTypes.func.isRequired
};

const styled = withStyles(styles)(DeleteKeyMenuItem);
export default translate('KeyListAdminPage')(styled);

// import React from 'react';
// import { connect } from 'react-redux';
// import { logout } from 'actions/auth';
// import { bindActionCreators } from 'redux';

// class LoginScreen extends React.Component {
//     componentDidMount() {
//         const { actions } = this.props;
//         actions.logout(true);
//     }

//     render() {
//         return null;
//     }
// }

// const mapDispatchToProps = dispatch => ({
//     actions: {
//         logout: bindActionCreators(logout, dispatch)
//     }
// });

// export default connect(null, mapDispatchToProps)(LoginScreen);
import config from 'config.json';
import storage from 'helpers/storage';

export default () => {
    const { pathname, search } = window.location;
    if (pathname !== '/') {
        storage.setItem('backUrl', pathname + (search || ''));
    }

    window.location = config.authLink || '/redirect/auth';
    return null;
};

import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import {
    Toolbar,
    Tooltip,
    IconButton,
    withStyles
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import printJS from 'print-js';

import classNames from 'classnames';

import base64ToBlob from 'helpers/base64ToBlob';

import FileViewer from 'react-file-viewer';

import AceEditor from 'react-ace';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/theme-twilight';

import XlsxViewer from './components/xslx/xslx.jsx';

const styles = theme => ({
    root: {
        position: 'relative',
        backgroundColor: '#a9a9a9',
        [theme.breakpoints.down('xs')]: {
            '& .pg-viewer': {
                maxWidth: '100%'
            },
            '& .document-container': {
                maxWidth: '100%',
                padding: 10
            }
        }
    },
    xlsx: {
        backgroundColor: '#fff',
        '& .react-grid-Grid': {
            minHeight: '420px!important'
        },
        '& .react-grid-Canvas': {
            height: '400px!important'
        }
    },
    toolbar: {
        position: 'absolute',
        top: 0,
        padding: '0 10px'
    },
    unsupportedContainer: {
        padding: 40
    },
    printIcon: {
        position: 'absolute',
        top: 10,
        left: 10,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
});

class FilePreview extends React.Component {
    unsupportedComponent = (file) => {
        const { t, classes } = this.props;
        switch (file.fileType) {
            case 'json':
                return (
                    <AceEditor
                        mode={file.fileType}
                        theme="twilight"
                        enableSnippets={true}
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={JSON.stringify(file, null, 4)}
                        width="100%"
                        height={600}
                        readOnly={true}
                        setOptions={
                            {
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 4
                            }
                        }
                    />
                );
            case 'bpmn':
                // eslint-disable-next-line no-case-declarations
                const content = atob(file.filePath.split(',').pop());

                return (
                    <AceEditor
                        mode={'xml'}
                        theme="twilight"
                        enableSnippets={true}
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={content}
                        width="100%"
                        height={600}
                        readOnly={true}
                        setOptions={
                            {
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 4
                            }
                        }
                    />
                );
            default:
                return (
                    <div className={classes.unsupportedContainer}>
                        {t('UnsupportedFormat')}
                    </div>
                );
        }
    };

    render() {
        const { t, classes, file, fileType } = this.props;

        return (
            <div className={classNames(classes.root, classes[fileType])}>
                {
                    fileType === 'xlsx' ? (
                        <XlsxViewer filePath={file} fileType={fileType}/>
                    ) : (
                        <FileViewer filePath={file} fileType={fileType} unsupportedComponent={this.unsupportedComponent} />
                    )
                }
                {
                    fileType === 'pdf' ? (
                        <Toolbar className={classes.toolbar}>
                            <Tooltip title={t('PrintBtn')} placement="top">
                                <IconButton
                                    className={classes.printIcon}
                                    onClick={
                                        () => {
                                            const pdfBlob = base64ToBlob(file.split(',').pop());
                                            const url = URL.createObjectURL(pdfBlob);
                                            printJS(url);
                                        }
                                    }
                                >
                                    <PrintIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    ) : null
                }
            </div>
        );
    }
}

FilePreview.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    file: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired
};

FilePreview.defaultProps = {};

const translated = translate('TaskPage')(FilePreview);
export default withStyles(styles)(translated);

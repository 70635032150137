import React from 'react';
import PropTypes from 'prop-types';

import {
    Tooltip,
    IconButton
} from '@material-ui/core';

import AddCirleImage from '@material-ui/icons/AddCircleOutline';

const AddItemButton = ({ t, actions, readOnly }) => (
    <Tooltip title={t('AddItem')}>
        <IconButton onClick={actions.addItem} disabled={readOnly}>
            <AddCirleImage />
        </IconButton>
    </Tooltip>
);

AddItemButton.propTypes = {
    actions: PropTypes.object,
    readOnly: PropTypes.bool
};

AddItemButton.defaultProps = {
    actions: {},
    readOnly: false
};

export default AddItemButton;

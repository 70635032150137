import React from 'react';

import AceEditor from 'react-ace';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-twilight';

import { withEditor } from 'components/JsonSchema/editor/JsonSchemaProvider';

import waiter from 'helpers/waitForAction';

const CodeEditor = ({ rootValue, onChange, onValidate }) => {
    const [value, setValue] = React.useState(JSON.stringify(rootValue, null, 4));

    return (
        <AceEditor
            mode="json"
            theme="twilight"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            wrapEnabled={true}
            value={value}
            width="100%"
            height="100%"
            onValidate={onValidate}
            onChange={
                (newValue) => {
                    setValue(newValue);
                    try {
                        const parsed = JSON.parse(newValue);
                        waiter.addAction('CodeEditor', () => onChange(parsed), 100);
                    } catch (e) {
                        // Nothing to do
                    }
                }
            }
            setOptions={
                {
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 4
                }
            }
        />
    );
};

export default withEditor(CodeEditor);

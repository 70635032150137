import React from 'react';
import { makeStyles } from '@material-ui/core';

import classNames from 'classnames';

const $size = 60;

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        padding: '25px 25px'
    },
    nopadding: {
        padding: 0
    },

    //cube only
    folding: {
        width: $size,
        height: $size,
        display: 'inline-block',
        transform: 'rotate(45deg)',
        fontSize: 0
    },
    rect: {
        position: 'relative',
        width: $size / 2,
        height: $size / 2,
        transform: 'scale(1.05)',
        display: 'inline-block',
        // animation: '$folding 2.50s infinite linear both',
        '&::before': {
            content: "''",
            backgroundColor: theme.palette.primary.dark,
            position: 'absolute',
            left: 0,
            top: 0,
            display: 'block',
            width: $size / 2,
            height: $size / 2,
            transformOrigin: '100% 100%',
            animation: '$folding 2.50s infinite linear both'
        }
    },
    rect1: {
        '&::before': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    rect2: {
        transform: 'rotateZ(90deg) scale(1.05)',
        '&::before': {
            animationDelay: '.30s',
            backgroundColor: theme.palette.primary.main
        }
    },
    rect3: {
        transform: 'rotateZ(270deg) scale(1.05)',
        '&::before': {
            animationDelay: '.90s',
            backgroundColor: theme.palette.primary.main
        }
    },
    rect4: {
        transform: 'rotateZ(180deg) scale(1.05)',
        '&::before': {
            animationDelay: '.60s',
            backgroundColor: theme.palette.primary.light
        }
    }, 
    //animation
    '@keyframes folding': {
        '0%, 10%': {
            transform: 'perspective(140px) rotateX(-180deg)',
            opacity: 0
        },
        '25%, 75%': {
            transform: 'perspective(140px) rotateX(0deg)',
            opacity: 1
        },
        '90%, 100%': {
            transform: 'perspective(140px) rotateY(180deg)',
            opacity: 0
        }
    }

}));



const Preloader = (props) => {
    const { nopadding = false, className } = props;

    const classes = useStyles();

    return (
        <div className={classNames(classes.container, className, { [classes.nopadding]: nopadding })}>

                <div className={classes.folding}>
                    <span className={classNames(classes.rect, classes.rect1)}></span>
                    <span className={classNames(classes.rect, classes.rect2)}></span>
                    <span className={classNames(classes.rect, classes.rect3)}></span>
                    <span className={classNames(classes.rect, classes.rect4)}></span>
                </div>

        </div>
    );
}

export default Preloader;
import { WorkflowUsers } from "./WorkflowUsers";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import {requestAllUnits} from "../../../../actions/units";
import {exportWorkflow, requestWorkflow} from "../../../../actions/workflow";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    getUnits: bindActionCreators(requestAllUnits, dispatch),
    getProcesses: bindActionCreators(requestWorkflow, dispatch),
    getProcessDetails: bindActionCreators(exportWorkflow, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowUsers);

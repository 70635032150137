import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import {
    withStyles,
    TextField,
    Paper,
    MenuItem
} from '@material-ui/core';
import FilterHandler from 'components/DataTable/components/FilterHandler';

const styles = {
    root: {
        display: 'flex',
        padding: 8
    },
    field: {
        width: '100%'
    },
    paper: {
        maxHeight: 'unset',
        top: '0!important',
        left: '0!important'
    }
};

class SelectFilterHandler extends FilterHandler {
    handleChange = ({ target: { value } }) => {
        const { onChange } = this.props;
        onChange(value);
    };

    renderChip = () => {
        const { name, options, value, t } = this.props;
        return [name, t((options || []).filter(el => el.id === value)[0].name)].join(': ');
    };

    renderHandler() {
        const { classes, options, t, value } = this.props;

        return (
            <Paper elevation={0} className={classes.root}>
                <TextField
                    value={value}
                    select={true}
                    onChange={this.handleChange}
                    className={classes.field}
                    SelectProps={
                        {
                            MenuProps: {
                                disablePortal: true,
                                classes: {
                                    paper: classes.paper
                                }
                            }
                        }
                    }
                >
                    {
                        (options || []).map((option, key) => (
                            <MenuItem key={key} value={option.id} >{t(option.name)}</MenuItem>
                        ))
                    }
                </TextField>
            </Paper>
        );
    }
}

SelectFilterHandler.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func
};

SelectFilterHandler.defaultProps = {
    value: '',
    onChange: () => null
};

const styled = withStyles(styles)(SelectFilterHandler);
export default translate('SelectFilterHandler')(styled);

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const SingleValue = ({selectProps, children, innerProps}) => (
    <Typography
        className={selectProps.classes.singleValue}
        {...innerProps}
    >
        {children}
    </Typography>
);

SingleValue.propTypes = {
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
    children: PropTypes.node
};

SingleValue.defaultProps = {
    innerProps: {},
    children: ''
};

export default SingleValue;

import React from 'react';
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import UserListPage from 'application/manager/modules/users/pages/UserList';

const access = {
    isUserUnitHead: true,
    unitHasAccessTo: [
        'navigation.users.list'
    ]
};

export default {
    routes: [
        {
            path: '/users',
            component: UserListPage,
            title: 'Users',
            access
        }
    ],
    navigation: [{
        id: 'Users',
        path: '/users',
        icon: <AccessibilityNewOutlinedIcon />,
        priority: 30,
        access
    }]
};

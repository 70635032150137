import HomePage from 'application/manager/modules/home/pages/Home';
import PageNotFound from 'application/manager/modules/home/pages/PageNotFound';

export default {
    routes: [
        {
            path: '/home',
            component: HomePage
        },
        {
            path: '/',
            component: HomePage
        },
        {
            path: '*',
            component: PageNotFound
        }
    ]
};

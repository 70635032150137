export default {
    properties: {},
    description: '',
    sample: '',
    outlined: false,
    value: null,
    error: null,
    multiple: false,
    required: false,
    onChange: () => null,
    keyId: null,
    path: [],
    originDocument: {},
    useOwnContainer: false,
    readOnly: false
};

import React from 'react';
import TimeLabel from 'components/Label/Time';

import KeyActions from '../components/KeyActions';

export default ({ t, registerId, actions }) => ({
    controls: {
        pagination: true,
        toolbar: true,
        search: false,
        header: true,
        refresh: true,
        customizateColumns: true
    },
    checkable: false,
    columns: [{
        id: 'id',
        align: 'left',
        sortable: false,
        name: t('KeyId')
    }, {
        id: 'name',
        align: 'left',
        sortable: false,
        name: t('KeyName')
    }, {
        id: 'description',
        align: 'left',
        sortable: false,
        name: t('KeyDescription')
    }, {
        id: 'createdBy',
        align: 'left',
        sortable: false,
        name: t('CreatedBy')
    }, {
        id: 'updatedBy',
        align: 'left',
        sortable: false,
        name: t('UpdatedBy')
    }, {
        id: 'createdAt',
        width: 160,
        align: 'center',
        sortable: false,
        padding: 'checkbox',
        name: t('CreatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'updatedAt',
        width: 160,
        align: 'center',
        sortable: false,
        padding: 'checkbox',
        name: t('UpdatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'actions',
        width: 32,
        align: 'right',
        sortable: false,
        padding: 'checkbox',
        hiddable: false,
        disableClick: true,
        render: (value, key) => {
            return <KeyActions registerKey={key} registerId={registerId} actions={actions} />;
        }
    }]
});

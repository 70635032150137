import React from 'react';

import {
    Dialog,
    Toolbar,
    Typography,
    IconButton,
    DialogContent,
    withStyles
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const styles = {
    header: {
        padding: 0,
        backgroundColor: '#232323',
        borderBottom: '#757575 1px solid',
        height: 32
    },
    title: {
        flexGrow: 1,
        color: '#E2E2E2',
        padding: '0 10px'
    },
    button: {
        color: '#E2E2E2!important'
    },
    dialog: {
        display: 'flex',
        '& .ace_editor': {
            flex: 1
        }
    },
    content: {
        padding: 0,
        overflow: 'hidden'
    }
};

const FullScreenDialog = ({
    classes,
    title,
    open,
    onClose,
    children,
    ...rest
}) => (
        <Dialog
            {...rest}
            open={open}
            onClose={onClose}
            fullScreen={true}
            fullWidth={true}
        >
            <Toolbar className={classes.header}>
                <Typography variant="subtitle1" className={classes.title}>
                    {title}
                </Typography>
                <IconButton className={classes.button} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
        </Dialog>
    );

export default withStyles(styles)(FullScreenDialog);

import React from 'react';
import { translate } from 'react-translate';

import {
    Typography,
    withStyles
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { withEditor } from 'components/JsonSchema/editor/JsonSchemaProvider';
import getElementType from 'components/JsonSchema/editor/helpers/getElementType';
import SchemaItem from 'components/JsonSchema/editor/components/ElementDesktop/components/VisualEditor/components/SchemaItem';

const styles = {
    expandButton: {
        display: 'flex',
        textDecoration: 'underline',
        cursor: 'pointer'
    }
};

const PropertyList = ({ t, classes, path, value }) => {
    const [open, setOpen] = React.useState(false);
    const { properties } = value;

    if (!properties) {
        return null;
    }

    const content = Object.keys(properties).map(property => (
        <SchemaItem
            key={property}
            draggable={true}
            path={path.concat(property)}
            value={properties[property]}
            elementType={getElementType(properties[property], path.concat(property))}
        />
    ));

    if (!path.length) {
        return content;
    }

    return (
        <>
            <Typography
                className={classes.expandButton}
                onClick={(e) => { e.stopPropagation(); setOpen(!open); }}
            >
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                {t('Components')}
            </Typography>
            {open ? content : null}
        </>
    );
};

const styled = withStyles(styles)(PropertyList);
const translated = translate('JsonSchemaEditor')(styled);
export default withEditor(translated);

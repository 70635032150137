import React from 'react';
import { translate } from 'react-translate';
import { Tab, Tabs, withStyles } from '@material-ui/core';

import { withEditor } from 'components/JsonSchema/editor/JsonSchemaProvider';
import VisualEditor from './components/VisualEditor';
import CodeEditor from './components/CodeEditor';
import FormPreview from './components/FormPreview';

const editors = {
    VisualEditor,
    CodeEditor,
    FormPreview
};

const styles = {
    root: {
        width: 'calc(100% - 640px)',
        flexGrow: 1,
        borderRight: '#757575 1px solid',
        display: 'flex',
        flexDirection: 'column'
    },
    tabs: {
        borderBottom: '#757575 1px solid',
        marginLeft: 0
    },
    tab: {
        textTransform: 'none',
        color: '#fff',
        '&:focus': {
            opacity: 1
        }
    }
};

const ElementDesktop = ({ t, classes, errors }) => {
    const [active, setActive] = React.useState(0);
    const Editor = Object.values(editors)[active];

    return (
        <div className={classes.root}>
            <Tabs
                value={active}
                onChange={(event, newEditor) => setActive(newEditor)}
                className={classes.tabs}
            >
                {Object.keys(editors).map(editorName => (
                    <Tab
                        key={editorName}
                        label={t(editorName)}
                        disabled={!!errors.length}
                        className={classes.tab}
                    />
                ))}
            </Tabs>
            <Editor />
        </div>
    );
};

const styled = withStyles(styles)(ElementDesktop);
const translated = translate('JsonSchemaEditor')(styled);
export default withEditor(translated);

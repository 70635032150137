import MessagePage from 'application/manager/modules/messages/pages/Message';
import MessageListPage from 'application/manager/modules/messages/pages/MessageList';

import MessagesNavigation from 'application/manager/modules/messages/components/Navigation';
// import MessageNotify from 'application/manager/modules/messages/components/Notify';

export default {
    // appbar: [{
    //     component: MessageNotify
    // }],
    routes: [{
        path: '/messages/:messageId',
        component: MessagePage
        // access: { isUnitedUser: false, unitHasAccessTo: 'navigation.messages.MessageListPage' }
    }, {
        path: '/messages',
        component: MessageListPage,
        title: 'InboxTitle',
        defaultFilters: { deleted: 0 }
        // access: { isUnitedUser: false, unitHasAccessTo: 'navigation.messages.MessageListPage' }
    }],
    navigation: [{
        priority: 50,
        Component: MessagesNavigation
        // access: { isUnitedUser: false, unitHasAccessTo: 'navigation.messages.MessageListPage' }
    }]
};

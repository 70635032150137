import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Item from 'layouts/components/Navigator/Item';

import processList from 'services/processList';
import { getUnitUnreadTaskCount } from 'application/manager/actions/task';

class UnitTaskNavigation extends React.Component {
    componentDidMount() {
        const {
            actions,
            location: { pathname }
        } = this.props;

        if (pathname === '/tasks/unit-tasks' && !processList.has('getUnitUnreadTaskCount')) {
            processList.set('getUnitUnreadTaskCount', actions.getUnitUnreadTaskCount);
        }
    }

    render() {
        const {
            unreadUnitCount,
            location: { pathname }
        } = this.props;

        return (
            <Item
                menuItem={
                    {
                        id: 'UnitInboxTasks',
                        title: 'UnitInboxTasks',
                        path: '/tasks/unit-tasks',
                        pathname,
                        badge: unreadUnitCount
                    }
                }
            />
        );
    }
}

const mapStateToProps = ({ task }) => (task);
const mapDispatchToProps = dispatch => ({
    actions: {
        getUnitUnreadTaskCount: bindActionCreators(getUnitUnreadTaskCount, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitTaskNavigation);

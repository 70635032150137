import React from 'react';

import {
    GridListTile,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    withStyles
} from '@material-ui/core';

const styles = {
    card: {
        marginRight: 10,
        marginBottom: 10,
        padding: 0,
        width: 320
    }
};

const DataTableCard = ({ classes, item, selected, selectable, checkable, columns, onClick, onSelect }) => (
    <GridListTile cols={2}>
        <Card className={classes.card}>
            <CardContent>
                <List>
                    {columns.map(({ id, name, render }, columnKey) => (
                        <ListItem key={columnKey}>
                            <ListItemText
                                primary={render ? render(item[id], item, columnKey) : item[id]}
                                secondary={name}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    </GridListTile>
);

export default withStyles(styles)(DataTableCard);

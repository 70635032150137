import React from 'react';
import DoneAll from '@material-ui/icons/DoneAll';

import TaskListPage from 'application/manager/modules/tasks/pages/TaskList';
import TableTools from 'application/manager/modules/tasks/pages/TaskList/components/TableTools';
import TaskPage from 'application/manager/modules/tasks/pages/Task';
import OnboardingTaskPage from 'application/manager/modules/tasks/pages/OnboardingTask';
import UncreatedTask from 'application/manager/modules/tasks/pages/UncreatedTask';
import MultisignTask from 'application/manager/modules/tasks/pages/MultisignTask';
import CreateTaskButton from 'application/manager/modules/tasks/components/CreateTaskButton';

import taskEndPoint from 'application/manager/endPoints/task';
import unitTaskEndPoint from 'application/manager/endPoints/unitTask';
import closedTaskEndPoint from 'application/manager/endPoints/closedTask';
import closedUnitTaskEndPoint from 'application/manager/endPoints/closedUnitTask';

import MyTaskNavigation from './components/MyTaskNavigation';
import UnitTaskNavigation from './components/UnitTaskNavigation';

const taskModules = [{
    title: 'ClosedTasksTitle',
    rootPath: '/tasks/closed-tasks',
    endPoint: closedTaskEndPoint,
    defaultFilters: { finished: 1, deleted: 0, assigned_to: 'me' },
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.ClosedTasks'
    },
    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.ClosedTasks' }
}, {
    title: 'UnitClosedTasksTitle',
    rootPath: '/tasks/closed-unit-tasks',
    endPoint: closedUnitTaskEndPoint,
    defaultFilters: { finished: 1, deleted: 0, assigned_to: 'unit' },
    hiddenColumns: {
        notUnitedUser: ['performerUserNames'],
        isUnitedUser: 'properties.hiddenColumns.tasks.UnitClosedTasks'
    },
    access: { unitHasAccessTo: 'navigation.tasks.UnitClosedTasks' }
}, {
    title: 'UnitInboxTasksTitle',
    rootPath: '/tasks/unit-tasks',
    endPoint: unitTaskEndPoint,
    defaultFilters: { finished: 0, deleted: 0, assigned_to: 'unit' },
    hiddenColumns: {
        notUnitedUser: ['performerUserNames'],
        isUnitedUser: 'properties.hiddenColumns.tasks.UnitInboxTasks'
    },
    access: { unitHasAccessTo: 'navigation.tasks.UnitInboxTasks' }
}, {
    title: 'InboxTasksTitle',
    rootPath: '/tasks/my-tasks',
    endPoint: taskEndPoint,
    defaultFilters: { finished: 0, deleted: 0, assigned_to: 'me' },
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.InboxTasks'
    },
    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.InboxTasks' }
}, {
    title: 'InboxTasksTitle',
    rootPath: '/tasks',
    endPoint: taskEndPoint,
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.InboxTasks'
    }
}];

const taskRoutes = [].concat(...taskModules.map(({ rootPath, ...rest }) => ([
    {
        rootPath,
        path: rootPath + '/:taskId/signers/apply',
        component: MultisignTask
    },
    {
        rootPath,
        path: rootPath + '/redirect/:workflowId/:taskTemplateId',
        component: UncreatedTask
    },
    {
        rootPath,
        path: rootPath + '/create/:workflowTemplateId/:taskTemplateId',
        component: TaskPage
    }, {
        rootPath,
        path: rootPath + '/create/:workflowTemplateId',
        component: TaskPage
    }, {
        rootPath,
        path: rootPath + '/:taskId/:stepId',
        component: TaskPage
    }, {
        rootPath,
        path: rootPath + '/:taskId',
        component: TaskPage
    }, {
        ...rest,
        rootPath,
        path: rootPath,
        component: TaskListPage,
        TableToolbar: TableTools
    }
])));

export default {
    routes: [
        {
            path: '/tasks/onBoarding/:stepId',
            component: OnboardingTaskPage,
            isOnboarding: true
        }, {
            path: '/tasks/onBoarding',
            component: OnboardingTaskPage,
            isOnboarding: true
        },
        ...taskRoutes,
        {
            path: '*',
            to: '/tasks/onBoarding',
            redirect: true,
            isOnboarding: true
        }
    ],
    navigation: [{
        priority: 60,
        Component: CreateTaskButton,
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.CreateTaskButton' }
    }, {
        id: 'Tasks',
        icon: <DoneAll />,
        priority: 40,
        access: {
            isUnitedUser: false,
            unitHasAccessTo: [
                'navigation.tasks.InboxTasks',
                'navigation.tasks.UnitInboxTasks',
                'navigation.tasks.ClosedTasks',
                'navigation.tasks.UnitClosedTasks'
            ]
        },
        children: [{
            Component: MyTaskNavigation,
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.InboxTasks' }
        }, {
            Component: UnitTaskNavigation,
            access: { unitHasAccessTo: 'navigation.tasks.UnitInboxTasks' }
        }, {
            id: 'ClosedTasks',
            path: '/tasks/closed-tasks',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.ClosedTasks' }
        }, {
            id: 'UnitClosedTasks',
            path: '/tasks/closed-unit-tasks',
            access: { unitHasAccessTo: 'navigation.tasks.UnitClosedTasks' }
        }]
    }]
};

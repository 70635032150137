import Signer from 'services/eds/signer';
import HardwareSigner from 'services/eds/hardwareSigner';
import CAs from 'services/eds/CAs.json';

import config from 'config';

const { eds } = config;

const testACSK = {
    "issuerCNs": ["Тестовий ЦСК АТ \"ІІТ\""],
    "address": "ca.iit.com.ua",
    "ocspAccessPointAddress": "ca.iit.com.ua/services/ocsp/",
    "ocspAccessPointPort": "80",
    "cmpAddress": "ca.iit.com.ua",
    "tspAddress": "ca.iit.com.ua",
    "tspAddressPort": "80",
    "directAccess": true
};

class EDSService {
    /**
     * Constructor of electron digital signature service
     */
    constructor() {
        this.hardwareSigner = new HardwareSigner();
        this.signer = new Signer();
        // this.signer.init();
        setTimeout(() => this.hardwareSigner.init().catch(() => {
            // nothing to do
        }), 2000);
    }

    getSigner = () => [this.hardwareSigner, this.signer].filter(signer => signer.inited).shift();
}

const service = new EDSService();

export const serverList = eds.useTestACSK ? CAs.concat(testACSK) : CAs;

export default service;

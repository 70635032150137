import evalate from 'helpers/evalate';

export default (template, task) => {
    if (!template || !template.jsonSchema || !task.document.data) {
        return false;
    }

    const { jsonSchema: { signRequired } } = template;
    const { document: { data } } = task;

    if (!signRequired) {
        return false;
    }

    if (typeof signRequired === 'boolean') {
        return signRequired;
    }

    if (typeof signRequired === 'string') {
        const result = evalate(signRequired, data);

        if (result instanceof Error) {
            result.commit({
                type: 'signRequired',
                task,
                template
            });

            return false;
        }
        return result;
    }

    return !!signRequired;
};

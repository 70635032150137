import React from 'react';
import { translate } from 'react-translate';
import {
    Dialog,
    DialogTitle,
    withStyles,
    DialogContent
} from '@material-ui/core';

import EDSForm from 'components/EDSForm';

const styles = theme => ({
    title: {
        padding: '12px 24px 0',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 20,
            paddingLeft: 16,
            paddingRight: 17
        },
        '& h2': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 17
            }
        }
    },
    paperWidthSm: {
        [theme.breakpoints.down('xs')]: {
            padding: 5,
            margin: '40px auto!important',
            width: '95%',
            maxWidth: 'unset',
            minWidth: 'unset',
            paddingTop: 35
        }
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(100% - 32px)!important',
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingTop: 0
        }
    },
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 16,
            paddingRight: 16
        }
    },
    dialog: {
        padding: 5
    }
});

const SigningDialolg = ({ t, classes, open, onClose, onSelectKey }) => (
    <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'body'}
        className={classes.dialog}
        classes={
            {
                root: classes.dialogRoot,
                paperWidthSm: classes.paperWidthSm,
                paperScrollBody: classes.paperScrollBody
            }
        }
    >
        <DialogTitle className={classes.title}>{t('SigningDialogTitle')}</DialogTitle>
        <DialogContent
            classes={
                {
                    root: classes.root
                }
            }
        >
            <EDSForm classes={classes} onSelectKey={onSelectKey} onClose={onClose} />
        </DialogContent>
    </Dialog>
);

const translated = translate('TaskPage')(SigningDialolg);
export default withStyles(styles)(translated);

import React from 'react';
import {
    withStyles,
    Divider
} from '@material-ui/core';

import Scrollbar from 'components/Scrollbar';
import { withEditor } from 'components/JsonSchema/editor/JsonSchemaProvider';

import arrayUnique from 'helpers/arrayUnique';

import CollapseButton from './components/CollapseButton';
import GroupedElementList from './components/GroupedElementList';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '#757575 1px solid'
    },
    divider: {
        margin: '0 4px',
        backgroundColor: '#757575'
    }
};

const ElementList = ({ classes, elements }) => {
    const [open, setOpen] = React.useState(true);

    const groups = arrayUnique(Object.values(elements).map(({ group }) => group));

    return (
        <div className={classes.root}>
            <CollapseButton
                open={open}
                onClick={() => setOpen(!open)}
            />
            <Divider className={classes.divider} />
            <Scrollbar>
                {groups.map(group => (
                    <GroupedElementList
                        open={open}
                        key={group}
                        group={group}
                    />
                ))}
            </Scrollbar>
        </div>
    );
};

const styled = withStyles(styles)(ElementList);
export default withEditor(styled);

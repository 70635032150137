import React from 'react';
import { translate } from 'react-translate';
import { history } from 'store';
import _ from 'lodash/fp';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';

import endPoint from 'application/adminpanel/endPoints/processes';
import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import DataTable from 'components/DataTable';
import dataTableSettings from 'application/adminpanel/modules/processes/pages/ProcessesList/variables/dataTableSettings';

class ProcessesListPage extends ModulePage {

    componentDidMount() {
        const { actions } = this.props;
        actions.load();
    }

    render() {
        const {
            t,
            title,
            loading,
            location
        } = this.props;

        const settings = dataTableSettings({ t });

        return (
            <LeftSidebarLayout location={location} title={t(title)} loading={loading}>
                <DataTable
                    {..._.merge(settings, dataTableAdapter(this.props, settings))}
                    onRowClick={({ id }) => history.push(`/process/${id}`)}
                />
            </LeftSidebarLayout>
        );
    }
}

const translated = translate('ProcessesListPage')(ProcessesListPage);
export default dataTableConnect(endPoint)(translated);

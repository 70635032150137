const style = theme => ({
    appBar: {
        top: 'auto',
        backgroundColor: '#fafafa',
        bottom: 0,
        borderTop: '#919191 1px solid'
    },
    toolbar: {
        margin: 0,
        padding: 20,
        [theme.breakpoints.up('sm')]: {
            padding: '20px 32px'
        }
    },
    button: {
        marginRight: 16
    },
    createPDF: {
        '@media screen and (max-width: 425px)': {
            padding: 0,
            fontSize: '0.8rem'
        }
    },
    removeBtn: {
        color: 'rgba(0, 0, 0, 0.54)',
        borderColor: 'transparent',
        marginLeft: 'auto'
    },
    disabledBorder: {
        border: 'none!important'
    }
});

export default style;

import React from 'react';
import PropTypes from 'prop-types';

import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import formElement from 'components/JsonSchema/components/formElement';

const CurrencyInput = ({
    type,
    value,
    variant,
    readOnly,
    onChange,
    autoFocus,
    onKeyDown,
    description,
    formattedValue,
    currencySymbol,
    decimalCharacter,
    digitGroupSeparator,
    decimalPlaces
}) => {
    const [inputValue, setInputValue] = React.useState((typeof value === 'string') ? value.replace(/[^\d.-]/gi, '') : value);

    React.useEffect(() => {
        const propValue = Number((typeof value === 'string') ? value.replace(/[^\d.-]/gi, '') : value);

        if (propValue !== Number(inputValue)) {
            setInputValue(propValue);
        }
    }, [value, inputValue]);

    return (
        <CurrencyTextField
            value={inputValue}
            variant={variant}
            readOnly={readOnly}
            disabled={readOnly}
            label={description}
            outputFormat={type}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            decimalPlaces={decimalPlaces}
            currencySymbol={currencySymbol}
            decimalCharacter={decimalCharacter}
            digitGroupSeparator={digitGroupSeparator}
            onChange={
                ({ target: { value: formatted } }, newValue) => {
                    setInputValue(newValue);
                    if (formattedValue && type === 'string') {
                        return onChange(formatted);
                    }
                    return onChange(newValue);
                }
            }
        />
    );
};

CurrencyInput.propTypes = {
    type: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    variant: PropTypes.string,
    description: PropTypes.string,
    formattedValue: PropTypes.bool,
    currencySymbol: PropTypes.string,
    decimalCharacter: PropTypes.string,
    digitGroupSeparator: PropTypes.string,
    decimalPlaces: PropTypes.number,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func
};

CurrencyInput.defaultProps = {
    value: '',
    type: 'number',
    readOnly: false,
    autoFocus: false,
    variant: 'standard',
    description: '',
    formattedValue: true,
    currencySymbol: '',
    decimalCharacter: '.',
    digitGroupSeparator: ' ',
    decimalPlaces: 2,
    onChange: () => null,
    onKeyDown: () => null
};

export default formElement(CurrencyInput);

import React from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';

import {
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel
} from '@material-ui/core';

const DataTableHeader = ({ columns, hiddenColumns, checkable, sort, createSortHandler, classes }) => (
    <TableHead>
        <TableRow>
            {checkable ? (
                <TableCell padding="checkbox" width={64} />
            ) : null}
            {(columns || [])
                .filter(column => !hiddenColumns.includes(column.id))
                .map(({ render, disableClick, ...column }, columnKey) => {
                    const direction = objectPath.get(sort, column.id);
                    return (
                        <TableCell className={classes.TableCell} key={columnKey} {...column}>
                            {column.sortable ? (
                                <TableSortLabel
                                    active={!!direction}
                                    direction={direction}
                                    onClick={createSortHandler(column.id)}
                                >
                                    {column.name}
                                </TableSortLabel>
                            ) : column.name}
                        </TableCell>
                    );
                })}
        </TableRow>
    </TableHead>
);

DataTableHeader.propTypes = {
    columns: PropTypes.array,
    hiddenColumns: PropTypes.array,
    checkable: PropTypes.bool,
    sort: PropTypes.object,
    createSortHandler: PropTypes.func,
    classes: PropTypes.object
};

DataTableHeader.defaultProps = {
    columns: [],
    hiddenColumns: [],
    checkable: false,
    sort: {},
    createSortHandler: () => null,
    classes: {}
};

export default DataTableHeader;

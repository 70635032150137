import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Switch,
    FormControlLabel,
    withStyles
} from '@material-ui/core';

import ElementContainer from '../components/ElementContainer';


const styles = theme => ({
    onText: {
        paddingLeft: 10
    },
    offText: {
        marginLeft: 0
    },
    blurText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.9rem'
    },
    columns3: {
        display: 'inline-block',
        margin: 0,
        marginBottom: 15,
        width: '30%',
        '& > label > label': {
            paddingLeft: 0
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    }
});

class ToggleComponent extends React.Component {
    componentDidMount() {
        this.checkValue();
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.checkValue();
        }
    }

    checkValue = () => {
        const { value, onChange, defaultValue } = this.props;
        if (typeof value !== 'boolean') {
            onChange && onChange(defaultValue);
        }
    };

    handleChange = ({ target: { checked } }) => {
        const { onChange } = this.props;
        onChange && onChange(checked);
    };

    render() {
        const { classes, value, sample, description, noMargin, required, error, offText, onText, readOnly, path, hidden, columns } = this.props;

        if (hidden) return null;

        return !readOnly ? (
            <ElementContainer
                sample={sample}
                description={description}
                required={required}
                error={error}
                noMargin={noMargin}
                className={
                    classNames(
                        {
                            [classes.columns3]: columns && columns === 3
                        }
                    )
                }
            >
                <FormControlLabel
                    className={classes.offText}
                    control={(
                        <FormControlLabel
                            className={classes.onText}
                            control={(
                                <Switch
                                    id={path.join('-')}
                                    checked={value || false}
                                    onChange={this.handleChange}
                                    color="primary"
                                />
                            )}
                            label={onText}
                        />
                    )}
                    label={offText}
                    labelPlacement="start"
                />
            </ElementContainer>
        ) : (
                <div>
                    <span className={classes.blurText}>{description}</span>
                    {value
                        ? ` ${onText}` : ` ${offText}`
                    }
                </div>
            );
    }
}

ToggleComponent.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.bool,
    path: PropTypes.array,
    defaultValue: PropTypes.bool
};

ToggleComponent.defaultProps = {
    onChange: () => null,
    value: null,
    path: [],
    defaultValue: false
};

export default withStyles(styles)(ToggleComponent);

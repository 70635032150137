import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

const MultiValue = ({ removeProps, selectProps, children }) => (
    <div
        className={selectProps.classes.chipWrap}
        title={typeof children === 'string' ? children : ''}
    >
        <Chip
            label={children}
            onDelete={removeProps.onClick}
            deleteIcon={<CancelIcon {...removeProps} />}
        />
    </div>
);

MultiValue.propTypes = {
    removeProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
    children: PropTypes.node
};

MultiValue.defaultProps = {
    removeProps: {},
    children: ''
};

export default MultiValue;

import { defaultHtml } from 'components/CodeEditDialog';

export const entityToBody = ({
    documentTemplateEntity,
    taskTemplateEntity,
    workflowTemplateId
}) => {
    try {
        // documentTemplateEntity.jsonSchema = JSON.parse(documentTemplateEntity.jsonSchema || '{}');
        documentTemplateEntity.htmlTemplate = documentTemplateEntity.htmlTemplate || defaultHtml;
    } catch (e) {
        // nothing to do;
    }

    try {
        taskTemplateEntity.jsonSchema = JSON.parse(taskTemplateEntity.jsonSchema || {});
        taskTemplateEntity.htmlTemplate = taskTemplateEntity.htmlTemplate || defaultHtml;
    } catch (e) {
        // nothing to do;
    }

    return {
        documentTemplate: documentTemplateEntity,
        taskTemplate: taskTemplateEntity,
        workflowTemplateId
    };
};

export default {
    entityToBody
};

import { genTSbySchema } from './genTSbySchema';

export const genTSbyRegisterKey = async (key) => {
    const IName = `RK_${key.registerId}_${key.id}`;
    let src = await genTSbySchema(key.schema, IName);
    src = 
`/**
 * ${key.description}
 * ${key.name} register: ${key.registerId} key: ${key.id}
 * update: ${key.updatedAt}
 * This file was automatically generated. DO NOT MODIFY IT BY HAND!
 * generate: ${Date()}
 */    
    
${src}
`
    return [IName, src]
}
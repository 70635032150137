import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

import HighlightText from 'components/HighlightText';

const styles = {
    ids: {
        paddingLeft: 5
    },
    link: {
        textDecorationColor: '#000000'
    }
};

const ElementDetails = ({ classes, search, log: { type, details } }) => {
    const elementId = details[type + 'TemplateId'];
    const workflowId = String(elementId).slice(0, -3);
    return (
        <a className={classes.link} rel="noopener noreferrer" target="_blank" href={`/workflow/${workflowId}/${type}-${elementId}`}>
            <Typography variant="body2" className={classes.ids}>
                <HighlightText highlight={search} text={elementId + ' ' + (details.name || '')} />
            </Typography>
        </a>
    );
};

export default withStyles(styles)(ElementDetails);

import { getUnreadInboxCount } from 'application/manager/actions/inbox';
import { getUnreadMessageCount } from 'application/manager/actions/messages';
import { getMyUnreadTaskCount, getUnitUnreadTaskCount } from 'application/manager/actions/task';

import modules from 'application/manager/modules';
import reducers from './reducers';

export default {
    modules,
    reducers,
    initActions: {
        getUnreadInboxCount,
        getUnreadMessageCount,
        getMyUnreadTaskCount,
        getUnitUnreadTaskCount
    }
};

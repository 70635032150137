import React from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import ElementGroupContainer from 'components/JsonSchema/components/ElementGroupContainer';
import RadioButtons from 'components/JsonSchema/elements/DynamicRadioGroup/components/RadioButtons';

class DynamicRadioGroup extends React.Component {
    handleChange = value => () => {
        const { onChange, type, rootDocument, dataPath } = this.props;
        const list = this.uniq(objectPath.get(rootDocument.data, dataPath));
        onChange(type === 'object' ? list.find(({ id }) => id === value.id) : value);
    };

    removeUnexistedValues = (valueName, list) => {
        const { onChange } = this.props;

        if (!list || !valueName) return;

        const exist = list.map(({ id }) => id).includes(valueName.id);

        if (!exist) onChange('');
    };

    renderTitle = (obj) => {
        let string = '';
        Object.keys(obj).forEach((item) => {
            if (item === 'id') return;
            string += ' ' + obj[item];
        });
        return string;
    };

    getLabel = (key) => {
        const { labelKeys } = this.props;
    
        if (key.displayName) return key.displayName;

        if (labelKeys) return (labelKeys || []).map(el => el && key[el] && key[el]).join(' ');

        return this.renderTitle(key);
    };

    uniq = (array) => {
        if (!array) return [];
        const addId = array.map((item, index) => ({
            ...item,
            id: this.renderTitle(item).split(' ').join(`_${index}`).toLowerCase()
        }));

        const seen = {};

        return addId.filter((item) => {
            // eslint-disable-next-line no-prototype-builtins
            if (seen.hasOwnProperty(item.id)) return null;
            seen[item.id] = true;
            return item;
        });
    };

    renderElement() {
        const {
            value,
            dataPath,
            rootDocument
        } = this.props;

        const list = this.uniq(objectPath.get(rootDocument.data, dataPath));

        this.removeUnexistedValues(value, list);

        return (
            <RadioButtons
                {...this.props}
                list={list}
                getLabel={this.getLabel}
                onChange={this.handleChange}
            />
        );
    }

    render() {
        const { description, required, error, hidden, ...rest } = this.props;

        if (hidden) return null;

        return (
            <ElementGroupContainer
                description={description}
                required={required}
                error={error}
                variant="subtitle1"
                {...rest}
            >
                {this.renderElement()}
            </ElementGroupContainer>
        );
    }
}

DynamicRadioGroup.propTypes = {
    rowDirection: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.object,
    description: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    path: PropTypes.array,
    dataPath: PropTypes.string.isRequired,
    rootDocument: PropTypes.object.isRequired,
    hidden: PropTypes.bool,
    labelKeys: PropTypes.array
};

DynamicRadioGroup.defaultProps = {
    rowDirection: false,
    value: '',
    onChange: null,
    error: null,
    description: null,
    required: false,
    type: null,
    path: [],
    hidden: false,
    labelKeys: null
};

export default DynamicRadioGroup;

import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import {
    Tooltip,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/DeleteOutline';

import ConfirmDialog from 'components/ConfirmDialog';

class DeleteWorkflow extends React.Component {
    state = { showDialog: false, showErrorDialog: false, error: null };

    deleteWorkflow = async () => {
        const { actions, workflowId } = this.props;
        const result = await actions.deleteWorkflow(workflowId);
        this.setState({ showDialog: false });
        if (result instanceof Error) {
            this.setState({ showErrorDialog: true, error: result });
            return;
        }
        actions.load();
    };

    render() {
        const { t } = this.props;
        const { showDialog, showErrorDialog, error } = this.state;

        return (
            <>
                <Tooltip title={t('DeleteWorkflow')}>
                    <IconButton onClick={() => this.setState({ showDialog: true })} id="delete-workflow">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <ConfirmDialog
                    open={showDialog}
                    handleClose={() => this.setState({ showDialog: false })}
                    handleConfirm={this.deleteWorkflow}
                    title={t('DeleteWorkflowDialogTitle')}
                    description={t('DeleteWorkflowDialogDescription')}
                />
                {
                    showErrorDialog ? (
                        <Dialog
                            open={true}
                            onClose={() => this.setState({ showErrorDialog: false })}
                        >
                            <DialogTitle>{t('ErrorWhileDeletingWorkflow')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {error ? t(`${error.message}_deleting`) : t('WorkflowDeletingErrorMessage')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.setState({ showErrorDialog: false })}
                                    color="primary"
                                    autoFocus={true}
                                >
                                    {t('CloseErrorDialog')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null
                }
            </>
        );
    }
}

DeleteWorkflow.propTypes = {
    actions: PropTypes.object.isRequired
}

DeleteWorkflow.defaultProps = {
};

export default translate('WorkflowListAdminPage')(DeleteWorkflow);

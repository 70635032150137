import React from 'react';

import { Editor } from './JsonSchemaProvider';

import ElementList from './components/ElementList';
import ElementDesktop from './components/ElementDesktop';
import ElementProperties from './components/ElementProperties';

const JsonSchemaEditor = (props) => (
    <Editor {...props}>
        <ElementList />
        <ElementDesktop />
        <ElementProperties />
    </Editor>
);

export default JsonSchemaEditor;

import React from 'react';

import { Button, withStyles } from '@material-ui/core';

const styles = {
    button: {
        marginLeft: 8
    }
};

const TableToolbar = ({ t, classes, data, rowsSelected, actions }) => {
    const unreadMessages = data
        .filter(({ id }) => rowsSelected.includes(id))
        .filter(({ isRead }) => isRead === false)
        .map(({ id }) => id);

    if (!unreadMessages.length) {
        return null;
    }

    return (
        <Button
            id="mark-all-inbox-as-read"
            className={classes.button}
            variant="outlined"
            onClick={() => Promise.all(unreadMessages.map(actions.markInboxRead)).then(actions.load)}
        >
            {t('MarkAllAsRead')}
        </Button>
    );
};

export default withStyles(styles)(TableToolbar);

import React, { Fragment } from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import {
    Tooltip,
    IconButton
} from '@material-ui/core';

import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';

import ConfirmDialog from 'components/ConfirmDialog';

class RestoreTrash extends React.Component {
    state = { openConfirmDialog: false };

    handleOpenConfirmDialog = () => this.setState({ openConfirmDialog: true });

    handleCloseConfirmDialog = () => this.setState({ openConfirmDialog: false });

    handleRecover = async () => {
        const { data, rowsSelected, actions: { onRowsRecover } } = this.props;
        onRowsRecover && await onRowsRecover(rowsSelected.map(row => data.find(({ id }) => id === row).entryTaskId));
        this.handleCloseConfirmDialog();
    };

    render() {
        const { t } = this.props;
        const { openConfirmDialog } = this.state;
        return (
            <Fragment>
                <Tooltip title={t('RestoreTrash')}>
                    <IconButton onClick={this.handleOpenConfirmDialog} id="restore-trash">
                        <RestoreFromTrash />
                    </IconButton>
                </Tooltip>
                <ConfirmDialog
                    fullScreen={false}
                    open={openConfirmDialog}
                    title={t('RestoreTrashConfirmation')}
                    description={t('RestoreTrashConfirmationText')}
                    handleClose={this.handleCloseConfirmDialog}
                    handleConfirm={this.handleRecover}
                />
            </Fragment>
        );
    }
}

RestoreTrash.propTypes = {
    rowsSelected: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired
};

export default translate('TrashListPage')(RestoreTrash);

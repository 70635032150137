/* eslint-disable no-console */
import objectPath from 'object-path';
import evalate from 'helpers/evalate';

export default ({
    origin = {},
    triggers,
    stepData,
    documentData,
    userInfo
}) => {
    (triggers || []).forEach((trigger) => {
        const { calculate, target } = trigger;

        if (!calculate || !target) return;

        [].concat(target).forEach(targetPath => {
            const result = evalate(calculate, {}, stepData, documentData, {}, userInfo);

            if (result instanceof Error) {
                console.error('silent trigger error', { targetPath, calculate });
                result.commit({ type: 'calc trigger error', calculate, targetPath });
                return;
            }

            try {
                let value = result;

                if (typeof result !== 'boolean') value = result || undefined;

                objectPath.set(origin, targetPath, value);

                console.log('handle silent trigger', targetPath, value);
            } catch (e) {
                console.error('trigger error', e);
            }
        });
    });

    return origin;
};

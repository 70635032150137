import ComputerIcon from '@material-ui/icons/Computer';

export default {
    type: 'Element',
    group: 'Basic',
    Icon: ComputerIcon,
    defaultData: {
        calculatePath: undefined,
        control: 'calculate.button',
        description: 'Розрахувати',
        float: undefined
    }
};

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import DeletedIcon from '@material-ui/icons/Delete';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import diff from 'deep-diff';

import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    withStyles,
    Toolbar
} from '@material-ui/core';

import ConfirmDialog from 'components/ConfirmDialog';
import { SchemaForm, validateData, handleChangeAdapter } from 'components/JsonSchema';
import KeyVersionSelect from './KeyVersionSelect';

const styles = () => ({
    dialogContent: {
        overflowX: 'hidden'
    },
    toolbar: {
        padding: '0 4px'
    },
    grow: {
        flexGrow: 1
    }
});

class RegistryModal extends React.Component {
    constructor(props) {
        super(props);
        const { editMode } = props;
        this.state = { record: null, editMode, errors: [], showClosePrompt: false };
    }

    handleClose = () => {
        const { handleClose, value } = this.props;
        const { record, editMode } = this.state;

        const diffs = diff(value && value.data, record && record.data);

        if (editMode && diffs) {
            this.setState({ showClosePrompt: true });
            return;
        }

        this.setState({ editMode: false }, handleClose);
    };

    handleChange = (data) => {
        const { record } = this.state;

        this.setState({ record: { ...record, data } });
    };

    handleSave = () => {
        const { record, editMode } = this.state;
        const { selected, handleSave } = this.props;

        const errors = validateData(record.data || {}, selected.schema);
        this.setState({ errors });

        if (errors && errors.length) {
            return;
        }

        if (editMode && handleSave) {
            handleSave(record);
        }

        this.setState({ editMode: !editMode });
    };

    handleDelete = () => {
        const { handleDelete } = this.props;
        handleDelete();
        this.handleClose();
    };

    componentDidMount = () => {
        const { value: record } = this.props;
        this.setState({ record });
    };

    componentWillReceiveProps = ({ value: record }) => {
        this.setState({ record });
    };

    render() {
        const { t, classes, open, selected, handleDelete, handleSave, handleClose } = this.props;
        const { record, editMode, errors, showClosePrompt } = this.state;

        const isCreated = !!(record && record.id);
        const allowDelete = handleDelete && selected.access && selected.access.allowDelete;
        const allowUpdate = handleSave && selected.access && selected.access.allowUpdate && isCreated;
        const allowCreate = handleSave && selected.access && selected.access.allowCreate && !isCreated;

        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={open}
                    onClose={this.handleClose}
                >
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.grow} />
                        <KeyVersionSelect record={record} selectedKey={selected} onSelect={version => this.setState({ record: version.data })} />
                    </Toolbar>
                    <DialogContent className={classes.dialogContent}>
                        <SchemaForm
                            errors={errors}
                            schema={selected.schema}
                            readOnly={!editMode || !handleSave}
                            value={(record || {}).data}
                            onChange={handleChangeAdapter((record || {}).data, this.handleChange)}
                        />
                    </DialogContent>
                    <DialogActions>
                        {
                            allowDelete ? (
                                <Button
                                    id="registry-delete"
                                    onClick={this.handleDelete}
                                >
                                    <DeletedIcon />
                                    {t('Delete')}
                                </Button>
                            ) : null
                        }
                        {
                            (allowUpdate || allowCreate) ? (
                                <Button onClick={this.handleSave} variant="contained" color="primary" id="registry-save-btn">
                                    {editMode ? <SaveOutlinedIcon /> : <EditIcon />}
                                    {editMode ? t('Save') : t('Edit')}
                                </Button>
                            ) : null
                        }
                        <Button onClick={this.handleClose} color="primary" id="registry-close-btn">
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmDialog
                    title={t('HasUnsavedData')}
                    description={t('HasUnsavedDataPrompt')}
                    open={showClosePrompt}
                    handleClose={() => this.setState({ showClosePrompt: false })}
                    handleConfirm={() => this.setState({ editMode: false }, handleClose)}
                />
            </>
        );
    }
}

RegistryModal.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    selected: PropTypes.object.isRequired,
    open: PropTypes.bool,
    value: PropTypes.object,
    editMode: PropTypes.bool,
    handleClose: PropTypes.func,
    handleDelete: PropTypes.func,
    handleSave: PropTypes.func
};

RegistryModal.defaultProps = {
    value: {},
    editMode: false,
    handleClose: () => null,
    handleDelete: null,
    handleSave: null,
    open: false
};

const styled = withStyles(styles)(RegistryModal);
export default translate('RegistryPage')(styled);

import React from 'react';
import HeaderCell from './HeaderCell';

const SheetHeader = ({ data, items, headers, headAlign, headFontSize, headerRef }) => (
    <thead ref={headerRef}>
        {headers.map((header, headerKey) => (
            <tr key={headerKey}>
                {header.map((cell, cellKey) => (
                    <HeaderCell
                        cell={cell}
                        data={data}
                        key={cellKey}
                        cellKey={cellKey}
                        headAlign={headAlign}
                        headFontSize={headFontSize}
                    />
                ))}
            </tr>
        ))}
    </thead>
);

export default SheetHeader;

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { Redirect, Route } from 'react-router-dom';
import { history } from 'store';
import checkAccess from 'helpers/checkAccess';
import Preloader from 'components/Preloader';

const PrivateRoute = ({ location, redirect, component: Component, userInfo, userUnits, access, ...rest }) => {
    if (access && !checkAccess(access, userInfo, userUnits)) {
        history.replace('/');
        return <Preloader />;
    }

    const RouteComponent = redirect ? Redirect : Route;

    return (
        <RouteComponent
            {...rest}
            render={(props) => {
                const { match: { params } } = props;

                return <Component {...props} {...rest} {...params} />;
            }}
        />
    );
};

const translated = translate('PageTitles')(PrivateRoute);
export default connect(({
    auth: { userUnits, info }
}) => ({
    userUnits: userUnits || [],
    userInfo: info
}))(translated);

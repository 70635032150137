import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ElementContainer from 'components/JsonSchema/components/ElementContainer';
import ElementGroupContainer from 'components/JsonSchema/components/ElementGroupContainer';

import Select from 'components/Select';

import { requestRegisterRelatedKeyRecords } from 'application/manager/actions/registry';
import processList from 'services/processList';

class RelatedKeyRegister extends React.Component {
    async componentDidMount() {
        const { records, actions, value, onChange, demo } = this.props;
        const keyIds = this.getKeyIds();

        let keyRecords;
        if (!demo) {
            keyRecords = records[keyIds] || await processList.hasOrSet('requestRegisterRelatedKeyRecords', actions.requestRegisterRelatedKeyRecords, keyIds);
        }

        if (keyRecords instanceof Error || !value || value.propertiesHasOptions) {
            return;
        }

        onChange && onChange({
            ...(value || {}),
            propertiesHasOptions: this.propertiesHasOptions(value, keyRecords)
        });
    }

    getKeyIds = () => {
        const { properties } = this.props;
        return Object.values(properties).map(({ keyId }) => keyId).join(',');
    };

    getOptions = (propertyName, value = {}, keyRecords) => {
        const { properties, records } = this.props;
        let options = keyRecords || records[this.getKeyIds()];

        if (!options) {
            return options;
        }

        const property = properties[propertyName];
        options = options.filter(({ keyId }) => keyId === property.keyId);

        const propertyIndex = Object.keys(properties).indexOf(propertyName);
        if (propertyIndex) {
            const parentPropertyName = Object.keys(properties)[propertyIndex - 1];
            const parentValues = [].concat(value[parentPropertyName]).filter(Boolean);
            const parentRelationIds = parentValues.map(({ isRelationId }) => isRelationId);
            options = options.filter(({ isRelationLink }) => parentRelationIds.includes(isRelationLink));
        }

        return options && options
            .sort((a, b) => a.stringified.localeCompare(b.stringified))
            .map(option => ({ ...option, value: option.id, label: option.stringified }));
    }

    getValue = (propertyName) => {
        const { value } = this.props;
        return (value || {})[propertyName];
    }

    hasParentValue = (propertyName, value) => {
        const { properties } = this.props;
        const propertyIndex = Object.keys(properties).indexOf(propertyName);

        if (!propertyIndex) {
            return true;
        }

        const parentPropertyName = Object.keys(properties)[propertyIndex - 1];
        return [].concat(value[parentPropertyName]).filter(Boolean).length > 0;
    }

    handleChange = propertyName => (value) => {
        const { value: oldValue, onChange, properties } = this.props;
        const propertyNames = Object.keys(properties);
        const propertyIndex = propertyNames.indexOf(propertyName);

        const newValue = { ...(oldValue || {}), [propertyName]: value };
        propertyNames
            .filter((child, index) => index > propertyIndex)
            .forEach((child) => {
                const childPropertyIndex = propertyNames.indexOf(child);
                const parentPropertyName = propertyNames[childPropertyIndex - 1];
                const parentPropertyValues = [].concat(newValue[parentPropertyName]).filter(Boolean);
                const parentRelationIds = parentPropertyValues.map(({ isRelationId }) => isRelationId);

                const childValue = [].concat(newValue[child])
                    .filter(Boolean)
                    .filter(({ isRelationLink }) => parentRelationIds.includes(isRelationLink));

                const { multiple: childMultiple } = properties[child];
                newValue[child] = childMultiple ? childValue : childValue.shift();
            });


        const val = {
            ...newValue,
            propertiesHasOptions: this.propertiesHasOptions(newValue)
        };

        onChange && onChange(val);
    };

    propertiesHasOptions = (newValue, keyRecords) => {
        const { properties } = this.props;

        return Object.keys(properties).reduce((acc, propertyName) => {
            const options = this.getOptions(propertyName, newValue, keyRecords);
            return {
                ...acc,
                [propertyName]: !!(this.hasParentValue(propertyName, newValue) && options && options.length)
            };
        }, {});
    };

    renderProperty = (propertyName) => {
        const { properties, schema, errors, path, value, noMargin, readOnly } = this.props;

        const options = this.getOptions(propertyName, value || {});
        const error = errors.find(err => err.path === path.concat(propertyName).join('.'));

        if (!this.hasParentValue(propertyName, value || {}) || (options && !options.length)) {
            return null;
        }

        const { multiple, description, autoFocus, ...props } = properties[propertyName];

        return (
            <ElementContainer
                {...props}
                key={propertyName}
                bottomSample={true}
                error={error}
                noMargin={noMargin}
                required={Array.isArray(schema.required) && schema.required.includes(propertyName)}
            >
                <Select
                    id={path.concat(propertyName).join('-')}
                    autoFocus={autoFocus}
                    value={this.getValue(propertyName)}
                    description={description}
                    error={!!error}
                    multiple={!!multiple}
                    onChange={this.handleChange(propertyName)}
                    options={options}
                    readOnly={readOnly}
                />
            </ElementContainer>
        );
    }

    render() {
        const { description, sample, error, required, properties, outlined, hidden, width, maxWidth, path, ...rest } = this.props;

        if (hidden) return null;

        return (
            <ElementGroupContainer
                variant="subtitle1"
                outlined={outlined}
                error={error}
                sample={sample}
                required={required}
                description={description}
                width={width}
                maxWidth={maxWidth}
                path={path}
                {...rest}
            >
                {Object.keys(properties).map(this.renderProperty)}
            </ElementGroupContainer>
        );
    }
}

RelatedKeyRegister.propTypes = {
    demo: PropTypes.bool,
    records: PropTypes.object,
    actions: PropTypes.object.isRequired,
    properties: PropTypes.object,
    description: PropTypes.string,
    sample: PropTypes.string,
    outlined: PropTypes.bool,
    value: PropTypes.object,
    errors: PropTypes.array,
    error: PropTypes.object,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    path: PropTypes.array,
    schema: PropTypes.object,
    readOnly: PropTypes.bool
};

RelatedKeyRegister.defaultProps = {
    demo: false,
    properties: {},
    description: '',
    sample: '',
    value: {},
    records: {},
    errors: [],
    outlined: false,
    error: null,
    required: false,
    onChange: () => null,
    path: [],
    schema: {},
    readOnly: false
};

const mapStateToPops = ({ registry: { relatedRecords } }) => ({ records: relatedRecords });

const mapDispatchToProps = dispatch => ({
    actions: {
        requestRegisterRelatedKeyRecords: bindActionCreators(requestRegisterRelatedKeyRecords, dispatch)
    }
});

export default connect(mapStateToPops, mapDispatchToProps)(RelatedKeyRegister);
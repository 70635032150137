import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    MenuItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import promiseChain from 'helpers/promiseChain';
import KeyFormModal from '../KeyFormModal';

const EditKeyMenuItem = ({ t, registerKey, registerId, onClose, actions }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <MenuItem onClick={() => { setOpen(true); onClose(); }}>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary={t('EditKey')} />
            </MenuItem>
            <KeyFormModal
                open={open}
                value={registerKey}
                registerId={registerId}
                onClose={() => setOpen(false)}
                onChange={data => promiseChain([actions.saveKey, actions.load], data)}
            />
        </>
    );
};

EditKeyMenuItem.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    registerKey: PropTypes.object.isRequired,
    registerId: PropTypes.string.isRequired,
    onClose: PropTypes.func
};

EditKeyMenuItem.defaultProps = {
    onClose: () => null
};

export default translate('KeyListAdminPage')(EditKeyMenuItem);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';

import {
    Typography,
    Button,
    withStyles
} from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/ErrorOutline';

import { logout } from 'actions/auth';

const styles = {
    errorIcon: {
        fontSize: 82,
        color: 'red'
    },
    wrap: {
        width: 700,
        marginTop: 100,
        paddingLeft: 50,
        '@media (max-width: 767px)': {
            width: '100%',
            marginTop: 50,
            paddingLeft: 0
        }
    },
    title: {
        padding: '0 12px',
        color: '#00224e',
        marginTop: 15
    },
    errorText: {
        margin: '20px 0 28px'
    }
};

const ServiceMessage = ({ t, classes, error, actions, canSwitchUser }) => (
    <div className={classes.wrap}>
        <ErrorIcon className={classes.errorIcon} />
        <Typography
            className={classes.title}
            variant="h4"
            gutterBottom={true}
        >
            {t(error.message)}
        </Typography>
        <Typography
            className={classes.title}
            variant="subtitle1"
            gutterBottom={true}
        >
            {t(error.message + ' subtitle')}
        </Typography>
        {
            error.message === 'User without needed role.'
            || error.message === 'Access denied.'
            || canSwitchUser
            ? (
                <Button variant="outlined" onClick={actions.logout}>
                    {t('SwitchUser')}
                </Button>
            ) : null
        }
    </div>
);

const mapDispatchToProps = dispatch => ({
    actions: {
        logout: bindActionCreators(logout, dispatch)
    }
});

const styled = withStyles(styles)(ServiceMessage);
const translated = translate('App')(styled);
export default connect(null, mapDispatchToProps)(translated);

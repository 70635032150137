/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    chipWrap: {
        marginBottom: 16,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10
        }
    },
    chipRoot: {
        height: 'unset',
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 10,
        borderRadius: 50
    },
    chipLabel: {
        whiteSpace: 'initial',
        paddingLeft: 28,
        fontSize: 20,
        lineHeight: '20px',
        overflow: 'initial',
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px',
            padding: '6px 20px'
        }
    },
    deleteIcon: {
        width: 'unset',
        height: 'unset',
        position: 'relative',
        right: -3,
        margin: 0
    }
});

const RegisterChip = ({
    classes,
    label,
    onDelete
}) => (
        <div
            className={classes.chipWrap}
        >
            <Chip
                label={label}
                onDelete={onDelete}
                deleteIcon={(
                    <IconButton>
                        <ClearIcon style={{ color: '#000' }} />
                    </IconButton>
                )}
                classes={{
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                    deleteIcon: classes.deleteIcon
                }}
            />
        </div>
    );


RegisterChip.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired
};

const translated = translate('Elements')(RegisterChip);

const styled = withStyles(styles)(translated);

export default styled;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import evalate from 'helpers/evalate';

import {
    Radio,
    RadioGroup,
    FormControlLabel,
    withStyles
} from '@material-ui/core';
import renderHTML from 'helpers/renderHTML';

import { ElementGroupContainer, ChangeEvent } from 'components/JsonSchema';

const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily
    },
    radioMargin: {
        marginLeft: 30
    },
    row: {
        '& label:first-child span': {
            marginLeft: 0
        }
    },
    labelSize: {
        '& span': {
            fontSize: 16
        }
    },
    distance: {
        marginTop: 10,
        maxWidth: 1000
    }
});

class RadioGroupElement extends React.Component {
    componentDidMount() {
        this.init();
    }

    componentDidUpdate({ path, activeStep }) {
        const { path: newPath, activeStep: newActiveStep } = this.props;

        if (path.join() !== newPath.join() || newActiveStep !== activeStep) {
            this.init();
        }
    }

    init = () => {
        const { onChange, value, defaultValue, type, items } = this.props;

        if (value) {
            const selectedItem = items.find(({ id }) => id === (typeof value === 'object' ? value.id : value));
            if (selectedItem && this.isDisabled(selectedItem)) {
                onChange && onChange(new ChangeEvent(null, true));
            }
        }

        if (defaultValue && value === null) {
            const newValue = type === 'object' ? items.find(({ id }) => id === defaultValue) : defaultValue;
            onChange && onChange(new ChangeEvent(newValue, true));
        }
    }

    handleChange = itemId => () => {
        const { onChange, type, items } = this.props;
        const newValue = type === 'object' ? items.find(({ id }) => id === itemId) : itemId;
        onChange(new ChangeEvent(newValue, true));
    };

    isDisabled = ({ isDisabled }) => {
        const { rootDocument, value, steps, activeStep } = this.props;
        if (isDisabled && typeof isDisabled === 'string') {
            const result = evalate(isDisabled, value, rootDocument.data[steps[activeStep]], rootDocument.data);

            if (result instanceof Error) {
                result.commit({ type: 'radio group check disabled' });
                return false;
            }

            return result;
        }

        return isDisabled;
    }

    getSample = (key) => {
        const { rootDocument, value, steps, activeStep } = this.props;
        const { getSample, sample } = key;

        if (getSample && typeof getSample === 'string') {
            const result = evalate(getSample, value, rootDocument.data[steps[activeStep]], rootDocument.data);

            if (result instanceof Error) {
                result.commit({ type: 'Radio group get sample' });
                return '';
            }

            return result;
        }

        return sample;
    };

    renderElement() {
        const { classes, value, items, rowDirection, path, type, readOnly } = this.props;
        const valueId = type === 'object' ? (value || {}).id : value;

        return (
            <RadioGroup
                id={path.join('-')}
                row={rowDirection}
                className={classNames({
                    [classes.root]: true,
                    [classes.row]: rowDirection,
                    [classes.distance]: !rowDirection
                })}
            >
                {items.map(key => (
                    <div key={key.id}>
                        <FormControlLabel
                            className={classes.labelSize}
                            disabled={this.isDisabled(key) || readOnly}
                            control={(
                                <Radio
                                    id={path.concat(key.id).join('-')}
                                    checked={valueId === key.id}
                                    onChange={this.handleChange(key.id)}
                                    className={classNames({
                                        [classes.radioMargin]: rowDirection
                                    })}
                                />
                            )}
                            label={key.title}
                        />
                        {!rowDirection && (valueId === key.id) ? (
                            <span>{renderHTML(this.getSample(key) || '')}</span>
                        ) : null}
                    </div>
                ))}
            </RadioGroup>
        );
    }

    render() {
        const { sample, description, required, error, hidden, width, maxWidth, ...rest } = this.props;

        if (hidden) return null;

        return (
            <ElementGroupContainer
                sample={sample}
                description={description}
                required={required}
                error={error}
                variant="subtitle1"
                width={width}
                maxWidth={maxWidth}
                {...rest}
            >
                {this.renderElement()}
            </ElementGroupContainer>
        );
    }
}

RadioGroupElement.propTypes = {
    items: PropTypes.array,
    rowDirection: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    rootDocument: PropTypes.object.isRequired,
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    path: PropTypes.array,
    readOnly: PropTypes.bool
};

RadioGroupElement.defaultProps = {
    items: [],
    rowDirection: true,
    value: null,
    path: [],
    readOnly: false
};

export default withStyles(styles)(RadioGroupElement);

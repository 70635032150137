import qs from 'qs';

import { toUnderscoreObject } from 'helpers/toUnderscore';
import { markMessageRead } from 'application/manager/actions/messages';

const endPoint = {
    dataURL: 'messages',
    sourceName: 'messagesList',
    actions: {
        markMessageRead,
        isRowSelectable: ({ isRead }) => () => !isRead
    },
    startPage: 0
};

endPoint.mapData = (payload, { page }) => {
    const { meta } = payload;
    const { pagination: { total } } = meta || { pagination: { total: 0 }};

    return {
        data: Array.isArray(payload) ? payload.map(({ messageId, ...message }) => ({
            ...message,
            id: messageId
        })) : [],
        page: page || 1,
        count: total || 0
    };
};

endPoint.getDataUrl = (url, { page, rowsPerPage, filters: { name: search, ...filters }, sort }) => {
    const urlData = {
        filters: toUnderscoreObject(filters),
        sort: toUnderscoreObject(sort)
    };

    if (page) {
        urlData.page = (page || 1) - 1;
    }

    if (rowsPerPage) {
        urlData.count = rowsPerPage;
    }

    if (search) {
        urlData.search = search;
    }

    urlData.start = (urlData.page || 0) * (rowsPerPage || 10);

    const queryString = qs.stringify(urlData, { arrayFormat: 'index' });
    return url + (queryString && '?' + queryString);
};

export default endPoint;

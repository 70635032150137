/* eslint-disable no-mixed-operators */

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { healthCheck } from 'actions/app';
import {
    withStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import Preloader from 'components/Preloader';

const styles = {
    table: {
        marginTop: 70,
        marginBottom: 70,
        borderTop: '1px solid #e0e0e0'
    },
    details: {
        color: '#fff',
        backgroundColor: '#141414',
        padding: 10
    },
    pre: {
        margin: 0
    },
    icon: {
        fontSize: 14,
        display: 'flex',
        width: '100%'
    },
    cellRoot: {
        borderRight: '1px solid #e0e0e0'
    }
};

const Cell = ({
    key,
    component,
    scope,
    classes,
    children
}) => (
    <TableCell
        classes={
            {
                root: classes.cellRoot
            }
        }
        key={key}
        component={component}
        scope={scope}
    >
        {children}
    </TableCell>
);

Cell.propTypes = {
    key: PropTypes.string,
    component: PropTypes.string,
    scope: PropTypes.string,
    classes: PropTypes.object,
    children: PropTypes.node
};

Cell.defaultProps = {
    key: null,
    component: null,
    scope: null,
    classes: {},
    children: null
};

class HealthCheckList extends ModulePage {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            columns: []
        };
    }

    getColumns = (result) => {
        const columns = [];

        const services = Object.keys(result || {}) || [];

        services.map(servise => {
            const fields = Object.keys(result[servise] || {}) || [];
            fields.forEach(fieldName => {
                if (columns.includes(fieldName)) return;
                columns.push(fieldName);
            });
            return result[servise];
        });

        this.setState({ columns });
    };

    getRows = (result) => {
        const { columns } = this.state;
        const services = Object.keys(result || {}) || [];

        const rows = services.map(servise => {
            const serviseFields = Object.keys(result[servise] || {}) || [];

            const rowInfo = {};

            columns.forEach(field => { rowInfo[field] = ''; });

            serviseFields.forEach(field => { rowInfo[field] = result[servise][field]; });

            return rowInfo;
        });

        this.setState({ rows });
    };

    getTableDate = async() => {
        const { actions } = this.props;

        this.setState({ loading: true });

        try {
            const result = await actions.healthCheck();

            this.setState({ loading: false });

            if (!result) return;

            await this.getColumns(result);
            await this.getRows(result);
        } catch (e) {
            this.setState({
                loading: false,
                error: true
            })
        }
    };

    isActive = ({ message }) => (message === 'pong' ? '#19BE6F' : '#FA594F');

    renderServiceDetails = row => {
        const { classes } = this.props;
        const serviceProps = (Object.keys(row) || []);

        return (
            <>
                {
                    serviceProps.map(propName => {
                        const value = row[propName];
                        return (
                            <Cell
                                key={propName}
                                component="th"
                                scope="row"
                                classes={classes}
                            >
                                {typeof value === 'string' || typeof value === 'number' ? value : null}
                                {
                                    typeof value === 'boolean'
                                        ? (
                                            <FiberManualRecordIcon
                                                className={classes.icon}
                                                style={
                                                    {
                                                        color: this.isActive({
                                                            message: value ? 'pong' : 'nepong'
                                                        })
                                                    }
                                                }
                                            />
                                        )
                                        : null
                                }
                                {typeof value === 'object' ? JSON.stringify(value) : null}
                            </Cell>
                        );
                    })
                }
            </>
        );
    };

    componentDidMount = () => this.getTableDate();

    render = () => {
        const {
            t,
            title,
            location,
            classes
        } = this.props;
        const {
            rows,
            columns,
            loading,
            error
        } = this.state;

        const translates = ['name', 'version', 'message'];

        return (
            <LeftSidebarLayout location={location} title={t(title)}>
                {
                    error &&  "Ups, something went wrong ¯\\_(ツ)_/¯. Contact to support, plz." ||
                    loading && <Preloader /> || (
                        <TableContainer>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <Cell classes={classes}>
                                            {t('status')}
                                        </Cell>
                                        {
                                            (columns || []).map(col => (
                                                <Cell
                                                    key={col}
                                                    classes={classes}
                                                >
                                                    {translates.includes(col) ? t(col) : col}
                                                </Cell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (rows || []).map(row => (
                                            <TableRow hover={true} key={row.name}>
                                                <Cell
                                                    component="th"
                                                    scope="row"
                                                    classes={classes}
                                                >
                                                    <FiberManualRecordIcon
                                                        className={classes.icon}
                                                        style={
                                                            {
                                                                color: this.isActive(row)
                                                            }
                                                        }
                                                    />
                                                </Cell>
                                                {this.renderServiceDetails(row)}
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </LeftSidebarLayout>
        );
    }
}

HealthCheckList.propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

HealthCheckList.defaultProps = {

};

const mapDispatchToProps = dispatch => ({
    actions: {
        healthCheck: bindActionCreators(healthCheck, dispatch)
    }
});

const styled = withStyles(styles)(HealthCheckList);
const translated = translate('HealthCheckListPage')(styled);
export default connect(null, mapDispatchToProps)(translated);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { Popper, withStyles, List, ListItem, Paper, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = {
    popper: {
        zIndex: 10
    },
    list: {
        padding: 0
    }
};

class FilterHandlers extends React.Component {
    handleChange = (filterValue) => {
        const { activeFilter, onClose, filters, onFilterChange } = this.props;

        onFilterChange({
            ...filters,
            [activeFilter]: filterValue
        });

        onClose();
    }

    renderContent() {
        const { classes, filterHandlers, activeFilter, setActiveFilter, filters } = this.props;

        if (activeFilter) {
            const FilterHandler = filterHandlers[activeFilter];
            return (
                <>
                    <List component="nav" className={classes.list}>
                        <ListItem button={true} onClick={() => setActiveFilter(null)}>
                            <ListItemIcon>
                                <ArrowBackIcon />
                            </ListItemIcon>
                            <ListItemText primary={<FilterHandler type="name" />} />
                        </ListItem>
                    </List>
                    <FilterHandler value={filters[activeFilter]} onChange={this.handleChange} />
                </>
            );
        }

        return (
            <List component="nav" className={classes.list}>
                {Object.keys(filterHandlers).filter(filterName => !filters[filterName]).map((filterName, index) => {
                    const FilterHandler = filterHandlers[filterName];
                    return (
                        <ListItem key={index} button={true} onClick={() => setActiveFilter(filterName)}>
                            <ListItemIcon>
                                <FilterHandler type="icon" />
                            </ListItemIcon>
                            <ListItemText primary={<FilterHandler type="name" />} />
                        </ListItem>
                    );
                })}
            </List>
        );
    }

    render() {
        const { classes, anchorEl, rootRef } = this.props;
        const open = Boolean(anchorEl);

        return (
            <Popper
                open={open}
                anchorEl={anchorEl}
                container={rootRef}
                className={classes.popper}
                disablePortal={true}
                placement="bottom-start"
                modifiers={{
                    flip: {
                        enabled: false
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent'
                    }
                }}
            >
                <Paper>
                    {this.renderContent()}
                </Paper>
            </Popper>
        );
    }
}

FilterHandlers.propTypes = {
    classes: PropTypes.object.isRequired,
    rootRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    filterHandlers: PropTypes.object,
    activeFilter: PropTypes.string,
    setActiveFilter: PropTypes.func,
    filters: PropTypes.object,
    onClose: PropTypes.func,
    onFilterChange: PropTypes.func
};

FilterHandlers.defaultProps = {
    rootRef: null,
    filterHandlers: {},
    activeFilter: null,
    setActiveFilter: () => null,
    filters: {},
    onClose: () => null,
    onFilterChange: () => null
};

const styled = withStyles(styles)(FilterHandlers);
export default translate('DataTable')(styled);

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core';
import { SchemaForm, handleChangeAdapter } from 'components/JsonSchema';
import { setRegistersAccess, getRegistersAccess, putRegistersAccess } from 'application/adminpanel/actions/registry';
import UnitList from 'application/adminpanel/modules/users/pages/Unit/components/UnitList';
import ProgressLine from 'components/Preloader/ProgressLine';
import schema from '../variables/keyAccessSchema';

class KeyFormModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || {},
            busy: false,
            errors: [],
            unitAccesses: [],
            removed: []
        };
    }

    handleChange = value => this.setState({ value: this.setAllowRead(value) });

    handleDelete = (prevState, newState) => {
        const { value: { keyAccess } } = prevState;
        const newKeyAccess = newState.value.keyAccess;

        if (!keyAccess || !newKeyAccess) return;

        const arrayFiltered = keyAccess.filter(({ unitAccessId, unit }) => unitAccessId && unit[0]).map(({ unitAccessId }) => unitAccessId);
        const newArrayFiltered = newKeyAccess.filter(({ unitAccessId, unit }) => unitAccessId && unit[0]).map(({ unitAccessId }) => unitAccessId);

        if (arrayFiltered.length <= newArrayFiltered.length) return;

        const removedItem = arrayFiltered.filter(id => !newArrayFiltered.includes(id));

        if (!removedItem.length) return;

        const { removed } = this.state;

        const removedId = removedItem.shift();

        if (removed.includes(removedId)) return true;

        removed.push(removedId);

        this.setState({ removed });

        return true;
    };

    saveAccesses = (value, unitAccess, id, index) => {
        value.keyAccess[index].unitAccessId = unitAccess.id;
        value.keyAccess[index].keys = {};
        Object.keys(unitAccess.data.keys || []).forEach(el => {
            value.keyAccess[index].keys[el] = unitAccess.data.keys[el].includes(id);
        });
    };

    updateUnitAccesse = async () => {
        const { actions } = this.props;

        this.setState({ busy: true });

        const unitAccesses = await actions.getRegistersAccess();

        await this.setState({ busy: false, unitAccesses });

        this.fillExisting();
    };

    fillExisting = () => {
        const { value } = this.state;

        if (!value) return;

        const { value: { id }, unitAccesses } = this.state;

        if (!Array.isArray(unitAccesses) && !unitAccesses.length) return;

        const checkExisting = (unitAccess) => {
            const keys = Object.values((unitAccess.data && unitAccess.data.keys) || {});
            const flatArray = (keys || []).reduce((acc, val) => acc.concat(val), []);
            return flatArray.includes(id);
        };

        const existsIn = (unitAccesses || []).filter(checkExisting).map(({ unitId }) => ({ unit: [unitId]}));

        this.setState({
            value: {
                ...value,
                keyAccess: existsIn
            }
        });
    };

    getStrictState = (prevState) => {
        const { value: { strictAccess } } = prevState;
        const { value, value: { id }, unitAccesses } = this.state;
        const strictUnit = (unitAccesses || []).filter(unitAccess => unitAccess.unitId === null);

        if (!strictUnit.length || !!strictAccess) return;

        const strinctKeys = strictUnit[0].data && strictUnit[0].data.strictAccess && strictUnit[0].data.strictAccess.keys;
        const haveStrict = (strinctKeys || []).includes(id) || false;

        haveStrict && this.setState({
            value: {
                ...value,
                strictAccess: {
                    strictAccess: true
                }
            }
        });
    };

    setAllowRead = value => {
        const { keyAccess } = value;

        (keyAccess || []).forEach(key => {
            if (!key) return;

            const { keys } = key;

            if (!keys) return;

            (Object.keys(keys) || []).forEach(el => {
                if (keys[el] === true && !keys.allowRead) {
                    keys.allowRead = true;
                }
            });
            return key;
        });

        return value;
    };

    getUnitInfo = (prevState) => {
        const { open } = this.props;
        const { value: { keyAccess }, value, value: { id }, unitAccesses } = this.state;

        if (!unitAccesses) return;

        if (JSON.stringify(prevState) === JSON.stringify(this.state)) return;

        const removing = open && this.handleDelete(prevState, this.state);

        (keyAccess || []).forEach((key, index) => {
            const { unit } = key;

            if (!unit) return;

            const prevUnit = (prevState.value.keyAccess && prevState.value.keyAccess[index]) || {};
            const prevUnitId = (prevUnit && prevUnit.unit) || [];

            if (unit[0] !== prevUnitId[0] || !unit[0]) {
                if (removing) key.keys = {};
                delete key.unitAccessId;
            }

            const { unitAccessId } = key;

            if (unitAccessId || !unit[0]) return;

            (unitAccesses || []).forEach(unitAccess => {
                if (unit[0] === unitAccess.unitId) {
                    this.saveAccesses(value, unitAccess, id, index);
                    this.setState({ value });
                }
            });
        });
    };

    getSrictBody = (keyId) => {
        const { value, unitAccesses } = this.state;
        const unitExists = (unitAccesses || []).filter(unitAccess => unitAccess.unitId === null);
        const chosenValue = (value.strictAccess && value.strictAccess.strictAccess) || false;

        const body = {
            type: 'register',
            data: {
                strictAccess: {
                    keys: []
                }
            }
        };

        const oldValue = (unitExists[unitExists.length - 1] || { data: { strictAccess: { keys: {} } } }).data.strictAccess.keys || [];
        const newValue = unitExists.length ? [...new Set(oldValue.concat([keyId]))] : [keyId];
        body.data.strictAccess.keys = chosenValue ? newValue : newValue.filter(item => item !== keyId);

        const isEquil = JSON.stringify(oldValue) === JSON.stringify(body.data.strictAccess.keys);

        if (isEquil) return null;

        return body;
    };

    getBody = (
        unitId,
        id,
        userData
    ) => {
        const { unitAccesses } = this.state;
        const existingKeys = (unitAccesses || []).filter(unit => unit.unitId === unitId)[0];

        const body = {
            unitId,
            type: 'register',
            data: {
                keys: {}
            }
        };

        (Object.keys(userData || {}) || []).forEach(el => {
            const newValue = userData[el] ? [id] : [];
            const concatedValue = existingKeys && existingKeys.data && existingKeys.data.keys
                ? [...new Set((existingKeys.data.keys[el] || []).concat(newValue))]
                : newValue;
            body.data.keys[el] = newValue.length ? concatedValue : concatedValue.filter(item => item !== id);
        });

        return body;
    };

    checkStrictAccess = async () => {
        const { actions } = this.props;
        const { value: { id }, unitAccesses } = this.state;

        const body = this.getSrictBody(id);

        if (!body) return;

        const isExists = (unitAccesses || []).filter(unitAccess => unitAccess.unitId === null);

        isExists.length && await actions.putRegistersAccess(isExists[0].id, body);
        !isExists.length && await actions.setRegistersAccess(body);
    };

    checkIfUpdateNeed = body => {
        try {
            const { unitAccesses } = this.state;
            const oldBody = unitAccesses.filter(el => el.unitId === body.unitId);
            const isEquil = JSON.stringify(body.data.keys) === JSON.stringify(oldBody[0].data.keys);
            if (isEquil) return false;
            return true;
        } catch {
            return true;
        }
    };

    onClose = () => {
        const { onClose } = this.props;
        this.setState({ errors: [] }, onClose);
    };

    removeAccessKeys = async () => {
        const { removed } = this.state;

        if (!removed.length) return;

        const { actions } = this.props;
        const { unitAccesses, value: { id } } = this.state;

        await (removed || []).forEach(async (unitAccessId) => {
            const unitInfo = unitAccesses.filter(el => el.id === unitAccessId).shift();

            const body = {
                unitId: unitInfo.unitId,
                type: 'register',
                data: {
                    keys: {}
                }
            };

            const oldData = unitInfo.data.keys;

            (Object.keys(oldData || {}) || []).forEach(el => {
                body.data.keys[el] = oldData[el].filter(item => item !== id);
            });

            await actions.putRegistersAccess(unitAccessId, body);
        });

        this.setState({ removed: [] });
    };

    validate = async() => {
        const { t } = this.props;
        const { value: { keyAccess } } = this.state;
        let errors = [];

        (keyAccess || []).forEach((el, index) => {
            if (!el.keys) return;

            let isRequired = false;

            const keys = Object.keys(el.keys);

            keys.forEach(name => {
                const isSelected = !!(el.unit && el.unit[0]);
                if (el.keys[name] === true && !isSelected) {
                    isRequired = el.keys[name];
                }
            });

            const path = `keyAccess.${index}.unit`;

            if (isRequired) {
                errors.push({
                    keyword: 'required',
                    message: t('ReguiredFiled'),
                    path
                });
            } else {
                errors = errors.filter(err => path !== err.path);
            }
        });

        await this.setState({ errors });
    };

    handleSave = async () => {
        const { actions } = this.props;
        const { value } = this.state;

        if (!value) return;

        await this.validate();

        const { errors } = this.state;

        if (errors.length) return;

        this.setState({ busy: true });

        await this.checkStrictAccess();

        await this.removeAccessKeys();

        const { keyAccess, id } = value;

        await (keyAccess || []).filter(el => el.unit && el.unit[0]).forEach(arrayItem => {
            const {
                unit,
                unitAccessId,
                keys
            } = arrayItem;
            const [unitId] = unit;

            const body = this.getBody(unitId, id, keys);

            const needUpdate = this.checkIfUpdateNeed(body);

            if (!needUpdate) return;

            !unitAccessId && actions.setRegistersAccess(body);
            unitAccessId && actions.putRegistersAccess(unitAccessId, body);
        });

        this.setState({ busy: false }, this.onClose);

        setTimeout(() => this.updateUnitAccesse(), 1000);
    };

    componentDidUpdate = async (prevProps, prevState) => {
        const { open, value } = this.props;

        if (open !== prevProps.open) {
            await this.setState({ value });
            this.fillExisting();
            this.getStrictState(prevState);
        }

        this.getUnitInfo(prevState);
    };

    componentDidMount = () => this.updateUnitAccesse();

    render = () => {
        const { t, open } = this.props;
        const {
            value,
            busy,
            errors
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={busy ? undefined : this.onClose}
                scroll="body"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>{t('EditKeyAccess')}</DialogTitle>
                <DialogContent>
                    <SchemaForm
                        value={value}
                        schema={schema[0]}
                        onChange={handleChangeAdapter(value, this.handleChange)}
                    />
                    <SchemaForm
                        value={value}
                        errors={errors}
                        schema={schema[1]}
                        customControls={
                            {
                                UnitList: props => <UnitList {...props} required={true}/>
                            }
                        }
                        onChange={handleChangeAdapter(value, this.handleChange)}
                    />
                    <div style={{ width: '100%'}}>
                        <ProgressLine loading={busy} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div style={{ flexGrow: 1 }} />
                    <Button
                        onClick={this.onClose}
                        disabled={busy}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={busy}
                        onClick={this.handleSave}
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}

KeyFormModal.propTypes = {
    actions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    open: PropTypes.bool,
    value: PropTypes.object,
    onClose: PropTypes.func
};

KeyFormModal.defaultProps = {
    open: false,
    value: null,
    onClose: () => null
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    actions: {
        setRegistersAccess: bindActionCreators(setRegistersAccess, dispatch),
        getRegistersAccess: bindActionCreators(getRegistersAccess, dispatch),
        putRegistersAccess: bindActionCreators(putRegistersAccess, dispatch)
    }
});
const translated = translate('KeyListAdminPage')(KeyFormModal);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

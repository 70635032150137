import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestNextTask } from 'application/manager/actions/task';
import { history } from 'store';
import SuccessMessageLayout from 'application/manager/modules/tasks/pages/Task/components/SuccessMessageLayout';

const LOAD_NEXT_TASK_INTERVAL = 2000;

class SuccessMessage extends React.Component {
    state = { nextTasks: null, iteration: 0 };

    componentDidMount() {
        const { nextTasks } = this.state;
        const { taskId } = this.props;

        if (!nextTasks && taskId) {
            this.loadNextTask();
        }
    }

    loadNextTask = async () => {
        const { iteration } = this.state;
        const { actions, taskId, rootPath, forceRedirect } = this.props;
        const nextTasks = await actions.requestNextTask(taskId);

        if (nextTasks && nextTasks.length) {
            if (forceRedirect) {
                history.replace(`${rootPath}/${nextTasks.shift().id}`);
                return;
            }
            this.setState({ nextTasks });
        } else {
            if (iteration === 2) {
                return;
            }
            this.setState({ iteration: iteration + 1 }, () => setTimeout(this.loadNextTask, LOAD_NEXT_TASK_INTERVAL));
        }
    };

    render = () => <SuccessMessageLayout {...this.props} {...this.state} />;
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    actions: {
        requestNextTask: bindActionCreators(requestNextTask, dispatch)
    }
});

const translated = translate('TaskPage')(SuccessMessage);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

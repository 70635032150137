import React from 'react';
import PropTypes from 'prop-types';
import {
    MobileStepper,
    StepButton,
    Stepper,
    Step,
    StepLabel,
    Hidden,
    withStyles
} from '@material-ui/core';
import evalate from 'helpers/evalate';

const styles = {
    stepperToolbar: {
        padding: '0 4px'
    },
    steeper: {
        backgroundColor: '#fafafa',
        padding: '24px 0',
        overflow: 'hidden'
    },
    step: {
        wordWrap: 'break-word',
        boxSizing: 'border-box'
    },
    stepBtn: {
        '& span': {
            maxWidth: '100%'
        }
    },
    mobileStepper: {
        backgroundColor: '#fafafa',
        width: '100%',
        '& > div': {
            margin: 'auto'
        }
    },
    descriptionWord: {
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginRight: 4
    }
};

const getTitle = (string, data) => {
    const evalatedTitle = evalate(string, data);

    if (!(evalatedTitle instanceof Error)) return evalatedTitle;

    return string;
};

const SchemaStepper = (props) => {
    const {
        task,
        steps,
        classes,
        activeStep,
        errors,
        handleStep,
        jsonSchema: {
            properties
        }
    } = props;
    if (steps.length <= 1) return null;

    return (
        <>
            <Hidden
                smDown={true}
                implementation="css"
            >
                <Stepper
                    alternativeLabel={true}
                    activeStep={activeStep}
                    className={classes.steeper}
                    id="steper"
                >
                    {
                        steps.map((stepId, index) => (
                            <Step
                                key={stepId}
                                title={properties[stepId].description}
                                className={classes.step}
                                style={
                                    {
                                        maxWidth: `${100 / steps.length}%`
                                    }
                                }
                            >
                                <StepButton
                                    completed={false}
                                    className={classes.stepBtn}
                                    onClick={handleStep(index)}
                                    disabled={index > steps.length - 1}
                                >
                                    <StepLabel error={errors[stepId]}>
                                        {getTitle(properties[stepId].description || '', (task && task.document.data) || {})}
                                    </StepLabel>
                                </StepButton>
                            </Step>
                        ))
                    }
                </Stepper>
            </Hidden>
            <Hidden mdUp={true}>
                <MobileStepper
                    variant="dots"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    className={classes.mobileStepper}
                />
            </Hidden>
        </>
    );
};

SchemaStepper.propTypes = {
    steps: PropTypes.array.isRequired,
    errors: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    activeStep: PropTypes.number.isRequired,
    handleStep: PropTypes.func.isRequired,
    jsonSchema: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired
};

export default withStyles(styles)(SchemaStepper);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Router, Switch } from 'react-router-dom';

import { getRoutes } from 'application';
import { history } from 'store';

import PrivateRoute from 'components/PrivateRoute';

const AppRouter = ({ onboardingTaskId }) => {
    const routes = getRoutes()
    .filter(({ isOnboarding }) => (onboardingTaskId ? isOnboarding : !isOnboarding));

    return (
        <Router history={history}>
            <Switch>
                {routes.map((route, key) => <PrivateRoute exact={true} key={key} {...route} />)}
            </Switch>
        </Router>
    );
}

AppRouter.propTypes = {
    onboardingTaskId: PropTypes.string
};

AppRouter.defaultProps = {
    onboardingTaskId: null
};

const mapStateToProps = ({ auth: { info: { onboardingTaskId } } }) => ({ onboardingTaskId });
export default connect(mapStateToProps)(AppRouter);

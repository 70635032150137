import React from 'react';
import objectPath from 'object-path';
import { withEditor } from 'components/JsonSchema/editor/JsonSchemaProvider';
import {
    Divider,
    withStyles
} from '@material-ui/core';

import Scrollbar from 'components/Scrollbar';

import CollapseButton from './components/CollapseButton';
import PropertyTable from './components/PropertyTable';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    divider: {
        margin: '0 4px',
        backgroundColor: '#757575'
    }
};

const ElementProperties = ({ classes, rootValue, selection, onChangeProperty }) => {
    const [open, setOpen] = React.useState(true);

    const dataPath = selection.length ? 'properties.' + selection.join('.properties.') : selection;
    const values = objectPath.get(rootValue, dataPath);

    if (!values) {
        return null;
    }

    return (
        <div className={classes.root}>
            <CollapseButton
                open={open}
                onClick={() => setOpen(!open)}
            />
            <Divider className={classes.divider} />
            {open ? (
                <Scrollbar>
                    <PropertyTable
                        values={values}
                        path={selection}
                        onChage={onChangeProperty}
                    />
                </Scrollbar>
            ) : null}
        </div>
    );
};

const styled = withStyles(styles)(ElementProperties);
export default withEditor(styled);

import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import {
    Tooltip,
    IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import ConfirmDialog from 'components/ConfirmDialog';

const ROWS_PER_PAGE = 10;

class DeleteUnits extends React.Component {
    state = { showDialog: false };

    deleteUnits = async () => {
        const { rowsSelected, actions, unitActions: { onRowsDelete } } = this.props;
        this.setState({ showDialog: false });
        await onRowsDelete(rowsSelected);
        actions.onChangeRowsPerPage(ROWS_PER_PAGE, true);
    };

    render() {
        const { t } = this.props;
        const { showDialog } = this.state;
        return (
            <>
                <Tooltip title={t('DeleteUnits')}>
                    <IconButton onClick={() => this.setState({ showDialog: true })} id="export-units">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <ConfirmDialog
                    open={showDialog}
                    handleClose={() => this.setState({ showDialog: false })}
                    handleConfirm={this.deleteUnits}
                    title={t('DeleteUnitsDialogTitle')}
                    description={t('DeleteUnitsDialogDescription')}
                />
            </>
        );
    }
}

DeleteUnits.propTypes = {
    unitActions: PropTypes.object.isRequired,
    rowsSelected: PropTypes.array.isRequired
};


export default translate('UnitsListPage')(DeleteUnits);

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import objectPath from 'object-path';

import * as elements from './elements';

const { Provider, Consumer } = React.createContext();

const styles = {
    root: {
        display: 'flex',
        height: '100%',
        color: '#e2e2e2',
        background: '#232323'
    }
};

class JsonSchemaProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rootValue: props.value || {},
            editPath: [],
            errors: [],
            selection: []
        };
    }

    onValidate = errors => this.setState({ errors }, () => {
        const { onValidate } = this.props;
        onValidate && onValidate(errors);
    });

    onChange = rootValue => this.setState({ rootValue }, this.onSave);

    setEditPath = editPath => this.setState({ editPath });

    setSelection = selection => this.setState({ selection });

    onSave = () => {
        const { onChange } = this.props;
        const { rootValue } = this.state;
        onChange(rootValue);
    };

    moveElementTo = (sourcePath, targetPath) => {
        const { rootValue } = this.state;
        const source = objectPath.get(rootValue, 'properties.' + sourcePath.join('.properties.'));
        const target = objectPath.get(rootValue, 'properties.' + targetPath.join('.properties.'));
        console.log('moveElementTo', source, target);
    }

    createElementAt = ({ defaultData, snippet }, targetPath, elementId) => {
        const { rootValue } = this.state;
        const component = JSON.parse(JSON.stringify({
            ...defaultData,
            snippet
        }));

        const propertyPath = targetPath.length ? 'properties.' + targetPath.join('.properties.') + '.properties' : 'properties';

        objectPath.ensureExists(rootValue, propertyPath, {});
        objectPath.set(rootValue, 'properties.' + targetPath.concat(elementId).join('.properties.'), component);
        this.onChange(rootValue);
    }

    isElementExists = (elementId, targetPath) => {
        const { rootValue } = this.state;
        return !!objectPath.get(rootValue, 'properties.' + targetPath.concat(elementId).join('.properties.'));
    };

    deleteElementAt = (targetPath) => {
        const { rootValue } = this.state;
        objectPath.del(rootValue, 'properties.' + targetPath.join('.properties.'));

        this.onChange(rootValue);
        this.setSelection([]);
    }

    onChangeProperty = (path, value) => {
        const { rootValue } = this.state;
        const copy = JSON.parse(JSON.stringify(rootValue));
        objectPath.set(copy, path, value);
        this.onChange(copy);
    }

    render() {
        const { classes, children } = this.props;
        const { rootValue, editPath, selection, errors } = this.state;
        return (
            <DndProvider backend={HTML5Backend}>
                <Provider
                    value={{
                        errors,
                        rootValue,
                        editPath,
                        elements,
                        selection,
                        onSave: this.onSave,
                        onChange: this.onChange,
                        onValidate: this.onValidate,
                        setEditPath: this.setEditPath,
                        setSelection: this.setSelection,
                        moveElementTo: this.moveElementTo,
                        createElementAt: this.createElementAt,
                        deleteElementAt: this.deleteElementAt,
                        isElementExists: this.isElementExists,
                        onChangeProperty: this.onChangeProperty
                    }}
                >
                    <div className={classes.root}>
                        {children}
                    </div>
                </Provider>
            </DndProvider>
        );
    }
}

const withEditor = Component => props => (
    <Consumer>
        {context => (
            <Component {...props} {...context} />
        )}
    </Consumer>
);

const Editor = withStyles(styles)(JsonSchemaProvider);

export { Editor, Consumer, withEditor };

import config from 'config.json';
import * as Sentry from '@sentry/browser';

import qs from 'qs';
import { identity } from "lodash";

import storage from 'helpers/storage';
import { logout } from 'actions/auth';
import { showServiceMessage } from 'actions/error';

import objectProxy from './objectProxy';

const { backendUrl } = config;

export const API_URL = backendUrl + (backendUrl.charAt(backendUrl.length - 1) !== '/' ? '/' : '');

const disabledDebugActions = [
    'REQUEST_USER_INFO',
    'SEARCH_USERS'
];

/**
 * @param response
 * @param {null || 'json'} format
 * @returns {Promise<*|Blob>}
 */
const getResponseBody = async (response, format = null) => {
    if (response.headers.get('content-type').includes('application/json') || format === 'json') {
        const json = await response.json();
        return objectProxy(json, { headers: response.headers });
    }
    if (response.headers.get('content-type').includes('text/html')) {
        const text = response.text();
        return text;
    }
    const blob = response.blob();
    return blob;
};

async function createRequest(request, action, dispatch, payload, { postRequestHook = identity, format = null } = {}) {
    const { url, ...conf } = request;
    const { method } = request;
    dispatch({ type: action, payload, body: request.body, url, method });

    const debugUserId = storage.getItem('debug-user-id');

    const requestData = {
        ...conf,
        cache: 'reload',
        headers: {
            ...conf.headers,
            token: storage.getItem('token')
        }
    };

    if (debugUserId && !disabledDebugActions.includes(action)) {
        requestData.headers['debug-user-id'] = debugUserId;
    }

    try {
        const response = await fetch(url, requestData);

        if (response.status === 401) {
            dispatch(logout());
            throw new Error('401 unauthorized');
        }

        if (response.status === 404) {
            throw new Error('404 not found');
        }

        const responseBody = await getResponseBody(response, format);
        const errors = [].concat(responseBody.error, responseBody.errors).filter(Boolean);

        if (errors.length) {
            const errorMessage = errors.shift();
            const error = new Error(errorMessage.message || errorMessage.msg || errorMessage);
            error.response = responseBody;
            throw error;
        }

        const responseData = responseBody.data ? objectProxy(responseBody.data, { headers: response.headers }) : responseBody;
        if (responseBody.meta || responseBody.pagination) {
            responseData.meta = responseBody.meta || responseBody.pagination;
        }

        dispatch({
            type: `${action}_SUCCESS`,
            payload: await postRequestHook(responseData),
            request: payload,
            url,
            method,
            body: request.body
        });
        return responseData;
    } catch (error) {
        if (error.message === 'Failed to fetch' && navigator.onLine) {
            dispatch(showServiceMessage(error));
        }

        dispatch({ type: `${action}_FAIL`, payload: error, url, method, body: request.body, request: payload });

        if (error.message !== '401 unauthorized' && error.message !== '404 not found' && error.message !== 'NetworkError when attempting to fetch resource.' && error.message === 'Failed to fetch') {
            Sentry.withScope((scope) => {
                Object.keys(error).forEach(key => scope.setExtra(key, error[key]));
                scope.setTag('service', 'api');
                scope.setTag('Message', error.message);
                scope.setExtra('url', url);
                scope.setExtra('method', method);
                scope.setExtra('body', request.body);
                scope.setExtra('response', error.response);
                Sentry.captureException(error);
            });
        }
        throw (error);
    }
}

export function get(url, action, dispatch, payload, config = {}) {
    const request = {
        url: API_URL + url,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    };

    return createRequest(request, action, dispatch, payload, config );
}

export function post(url, body, action, dispatch, additional = {}, options = {}) {
    const { headers } = options;
    const request = {
        url: API_URL + url,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...(headers || {}) },
        body: JSON.stringify(body)
    };

    return createRequest(request, action, dispatch, { ...body, ...additional });
}

export function upload(url, file, params, action, dispatch) {
    const queryString = qs.stringify(params, {
        arrayFormat: 'index',
        encode: false
    });
    const request = {
        url: API_URL + url + (queryString ? '?' + queryString : ''),
        method: 'post',
        headers: { 'Content-Type': file.type },
        body: file
    };

    return createRequest(request, action, dispatch, file);
}

export function put(url, body, action, dispatch, additional = {}, options = {}) {
    const { headers } = options;

    const request = {
        url: API_URL + url,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...(headers || {}) },
        body: JSON.stringify(body)
    };

    return createRequest(request, action, dispatch, { ...body, ...additional });
}

export function del(url, body, action, dispatch, payload) {
    const request = {
        url: API_URL + url,
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return createRequest(request, action, dispatch, payload);
}

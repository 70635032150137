import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import classNames from 'classnames';
import { Toolbar, Typography, withStyles } from '@material-ui/core';
import toCamelCase from 'helpers/toCamelCase';
import HighlightText from 'components/HighlightText';
import MessagesDetails from './MessageDetails';

import ElementDetails from './ElementDetails';
import ErrorDetails from './ErrorDetails';

const styles = {
    errorLabel: {
        color: '#f44336'
    },
    ids: {
        paddingLeft: 5
    }
};

const ERROR_LOG_TYPES = ['error'];
const ELEMENT_LOG_TYPES = ['task', 'event', 'gateway'];
const MESSAGE_LOG_TYPES = ['workflow_incoming_message', 'workflow_outgoing_message'];

const LogDetails = ({ t, search, classes, processId, log, log: { type } }) => {
    let details = null;

    if (ELEMENT_LOG_TYPES.includes(type)) {
        details = <ElementDetails log={log} search={search} />;
    }

    if (MESSAGE_LOG_TYPES.includes(type)) {
        details = <MessagesDetails processId={processId} log={log} />;
    }

    if (ERROR_LOG_TYPES.includes(type)) {
        details = <ErrorDetails log={log} search={search} />;
    }

    return (
        <Toolbar disableGutters={true}>
            <Typography variant="body2" className={classNames({ [classes.errorLabel]: type && type === 'error' })}>
                <HighlightText highlight={search} text={t(toCamelCase(type))} />
            </Typography>
            {details}
        </Toolbar>
    );
};

LogDetails.propTypes = {
    t: PropTypes.func.isRequired,
    processId: PropTypes.string.isRequired,
    log: PropTypes.object.isRequired
};

const translated = translate('ProcessesListPage')(LogDetails);
const styled = withStyles(styles)(translated);

export default styled;

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    Toolbar,
    withStyles,
    IconButton,
    AppBar
} from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/SettingsApplicationsOutlined';

import schema from './schema';
import WorkflowSettingsDialog from './WorkflowSettingsDialog';

const styles = {
    toolbar: {
        // position: 'absolute',
        // top: 0,
        // right: 0,
        padding: 4,
        // zIndex: 5,
        '& > *': {
            marginRight: 10
        },
        '& > :last-child': {
            marginRight: 0
        }
    },
    dialogContent: {
        padding: '0 24px 0'
    }
};

class WorkflowToolbar extends React.Component {
    state = { openWorkflowSettingsDialog: false };

    render() {
        const { t, classes, workflow, onChange, children, taskTemplates, events, workflowStatuses, numberTemplates } = this.props;
        const { openWorkflowSettingsDialog } = this.state;

        const workflowSchema = schema({ t, taskTemplates, events, workflowStatuses, numberTemplates });

        return (
            <AppBar position="relative" color="transparent">
                <Toolbar disableGutters={true} className={classes.toolbar}>
                    {children}
                    <IconButton onClick={() => this.setState({ openWorkflowSettingsDialog: true })}>
                        <SettingsIcon />
                    </IconButton>
                    <WorkflowSettingsDialog
                        open={openWorkflowSettingsDialog}
                        onClose={() => this.setState({ openWorkflowSettingsDialog: false })}
                        onChange={onChange}
                        workflow={workflow}
                        workflowSchema={workflowSchema}
                    />
                </Toolbar>
            </AppBar>
        );
    }
}

WorkflowToolbar.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    workflow: PropTypes.object,
    onChange: PropTypes.func,
    children: PropTypes.node,
    taskTemplates: PropTypes.array,
    events: PropTypes.array,
    workflowStatuses: PropTypes.array
};

WorkflowToolbar.defaultProps = {
    workflow: {},
    onChange: () => null,
    children: null,
    taskTemplates: [],
    events: [],
    workflowStatuses: []
};

const styled = withStyles(styles)(WorkflowToolbar);
export default translate('WorkflowAdminPage')(styled);

import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import {
    withStyles,
    IconButton,
    SnackbarContent,
    // ExpansionPanel,
    // ExpansionPanelSummary,
    // Typography,
    // ExpansionPanelDetails
} from '@material-ui/core';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';

const styles = theme => ({
    root: {
        margin: '2px 2px 6px',
        display: 'flex'
    },
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.dark
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing()
    },
    message: {
        width: 'calc(100% - 36px)'
    },
    details: {
        overflow: 'auto',
        background: '#000000',
        color: '#fff',
        padding: '2px 12px'
    },
    action: {
        paddingLeft: 0
    }
});

const closeDelay = 3000;
const closeDelayLong = 6000;

const ErrorSnackbar = ({ classes, t, className, error: { message, content, variant, details }, onClose }) => {
    const isCyrillic = /[А-ЩЬЮЯҐЄІЇа-щьюяґєії]/.test(message);

    const text = content || (isCyrillic ? message : t(message));
    const longMessage = (text || '').length > 100;

    setTimeout(() => {
        if (variant !== 'default' && variant !== 'error') onClose();
    }, longMessage ? closeDelayLong : closeDelay);

    return (
        <SnackbarContent
            className={classNames(classes.root, classes[variant || 'error'], className)}
            classes={{ message: classes.message, action: classes.action }}
            aria-describedby="client-snackbar"
            message={
                (
                    <>
                        {
                            details ? (
                                // <ExpansionPanel>
                                //     <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                                //         <Typography className={classes.heading}>{text}</Typography>
                                //     </ExpansionPanelSummary>
                                //     <ExpansionPanelDetails className={classes.details}>
                                //         <pre>
                                //             {JSON.stringify(details, null, 4)}
                                //         </pre>
                                //     </ExpansionPanelDetails>
                                // </ExpansionPanel>
                                <span id="client-snackbar" className={classes.message}>
                                    {text}
                                    <div className={classes.details}>
                                        <pre>
                                            {JSON.stringify(details, null, 4)}
                                        </pre>
                                    </div>
                                </span>
                            ) : (
                                    <span id="client-snackbar" className={classes.message}>
                                        {text}
                                    </span>
                                )
                        }
                    </>
                )
            }
            action={
                [
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]
            }
        />
    );
};

ErrorSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    className: PropTypes.object
};

ErrorSnackbar.defaultProps = {
    className: {}
};

const styled = withStyles(styles)(ErrorSnackbar);
export default translate('Errors')(styled);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button
} from '@material-ui/core';

import CodeEditDialog from 'components/CodeEditDialog';
import { addError } from 'actions/error';
import ElementContainer from '../components/ElementContainer';

class CodeEditor extends React.Component {
    state = { open: false, validateErrors: [], showErrorDialog: false, alertOpen: false };

    blockNavigate = (open) => {
        if (open) {
            const path = window.location.pathname;

            if (!path || !path.length || !window.history) return;

            window.history.pushState(null, null, path.split('/')[path.length - 1]);
            window.addEventListener('popstate', this.blockNavigate);
            window.onpopstate = () => this.setState({ alertOpen: true });
        } else {
            window.removeEventListener('popstate', this.blockNavigate);
            window.onpopstate = null;
        }
    };

    handleOpen = () => {
        const { value, asJsonObject } = this.props;

        this.blockNavigate(true);

        this.setState({
            open: true,
            value: asJsonObject ? JSON.stringify(value || {}, null, 4) : (value || '')
        });
    };

    handleClose = () => {
        const { value, validateErrors } = this.state;
        const { validate, onChange, asJsonObject, value: oldValue } = this.props;

        this.blockNavigate(false);

        if (validate && validateErrors.length) {
            return this.setState({ showErrorDialog: true });
        }

        return this.setState({ open: false, alertOpen: false }, () => {
            try {
                const newValue = asJsonObject ? JSON.parse(value) : value;
                if (newValue !== oldValue) {
                    onChange(newValue);
                }
            } catch (e) {
                return null;
            }
        });
    };

    getJsonSchema = () => {
        const { parentValue, tasks } = this.props;
        const parentValueId = parentValue && parentValue.id;

        if (!parentValueId || !tasks.origin) return;

        const entity = tasks.origin[parentValueId];

        if (!entity) return;

        const { documentTemplateEntity: { jsonSchema } } = entity;

        // eslint-disable-next-line consistent-return
        return jsonSchema;
    };

    renderAlert = () => {
        const { t } = this.props;
        const { alertOpen } = this.state;

        return (
            <div>
                <Dialog
                    open={alertOpen}
                    onClose={() => this.setState({ alertOpen: false })}
                >
                    <DialogTitle>
                        {t('CodeEditorAlertTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('CodeEditorAlertText')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            color="primary"
                        >
                            {t('Yes')}
                        </Button>
                        <Button
                            onClick={() => this.setState({ alertOpen: false })}
                            autoFocus={true}
                            color="primary"
                        >
                            {t('No')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    render() {
        const { value, open, showErrorDialog, validateErrors } = this.state;
        const { t, description, helperText, required, error, mode, hidden, noMargin } = this.props;

        if (hidden) return null;

        return (
            <ElementContainer
                error={error}
                required={required}
                helperText={helperText}
                description={description}
                noMargin={noMargin}
            >
                <Button
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                    onClick={this.handleOpen}
                >
                    {t('EditMode', { mode })}
                </Button>
                <CodeEditDialog
                    open={open}
                    onClose={this.handleClose}
                    onChange={value => this.setState({ value })}
                    onValidate={validateErrors => this.setState({ validateErrors })}
                    value={value || ''}
                    description={description}
                    mode={mode}
                    schema={this.getJsonSchema()}
                />
                <Dialog
                    open={showErrorDialog}
                    onClose={() => this.setState({ showErrorDialog: false })}
                >
                    <DialogTitle>{t('ValidationErrors')}</DialogTitle>
                    <DialogContent>
                        <List dense={true}>
                            {
                                validateErrors.map((err, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`${err.row}:${err.column} ${err.text}`}
                                        />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ showErrorDialog: false })} color="primary" autoFocus>
                            {t('Apply')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.renderAlert()}
            </ElementContainer>
        );
    }
}

CodeEditor.propTypes = {
    t: PropTypes.func.isRequired,
    parentValue: PropTypes.object,
    tasks: PropTypes.object
};

CodeEditor.defaultProps = {
    parentValue: {},
    tasks: {}
};

const mapStateToProps = ({ tasks }) => ({ tasks });

const mapDispatchToProps = dispatch => ({
    actions: {
        addError: bindActionCreators(addError, dispatch)
    }
});

const translated = translate('Elements')(CodeEditor);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    Button,
    DialogActions,
    Paper,
    Popover,
    Tooltip,
    Checkbox,
    MenuList,
    MenuItem,
    IconButton,
    ClickAwayListener,
    Typography
} from '@material-ui/core';

import ViewColumnIcon from '@material-ui/icons/ViewColumn';

class CustomizateColumns extends React.Component {
    state = { anchorEl: null }

    handleMenuOpen = ({ currentTarget }) => this.setState({ anchorEl: currentTarget });

    handleMenuClose = () => this.setState({ anchorEl: null });

    render() {
        const { anchorEl } = this.state;
        const { t, columns, hiddenColumns, toggleColumnVisible } = this.props;

        return (
            <Fragment>
                <Tooltip title={t('CustomizateColumns')}>
                    <IconButton onClick={this.handleMenuOpen} id="customizate-columns">
                        <ViewColumnIcon />
                    </IconButton>
                </Tooltip>
                <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={this.handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <ClickAwayListener onClickAway={this.handleMenuClose}>
                        <Paper>
                            <MenuList>
                                {(columns || []).filter(({ hiddable }) => hiddable !== false).map((column, key) => (
                                    <MenuItem id={'column-' + key} key={key} onClick={() => toggleColumnVisible(column.id)} >
                                        <Checkbox checked={!(hiddenColumns || []).includes(column.id)} />
                                        <Typography variant="subtitle2">{column.name || column.id}</Typography>
                                    </MenuItem>
                                ))}

                                <DialogActions>
                                    <Button
                                        id="close-columns"
                                        size="small"
                                        variant="text"
                                        color="primary"
                                        onClick={this.handleMenuClose}
                                    >
                                        {t('Close')}
                                    </Button>
                                </DialogActions>
                            </MenuList>
                        </Paper>
                    </ClickAwayListener>
                </Popover>
            </Fragment>
        );
    }
}

CustomizateColumns.propTypes = {
    t: PropTypes.func.isRequired,
    columns: PropTypes.array,
    hiddenColumns: PropTypes.array,
    toggleColumnVisible: PropTypes.func
};

CustomizateColumns.defaultProps = {
    columns: [],
    hiddenColumns: [],
    toggleColumnVisible: () => null
};

export default translate('DataTable')(CustomizateColumns);

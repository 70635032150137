import React from 'react';
import { Button } from '@material-ui/core';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import { SchemaFormModal } from 'components/JsonSchema';
import promiseChain from 'helpers/promiseChain';
import ImportTemplate from './Actions/ImportTemplate';

import schema from '../variables/temlateSchema';

const CreateNewTemplate = ({ t, actions }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button
                variant="outlined"
                onClick={() => setOpen(true)}
            >
                {t('CreateNew')}
            </Button>
            <ImportTemplate actions={actions}/>
            <SchemaFormModal
                title={t('NewTemplate')}
                open={open}
                schema={schema}
                onClose={() => setOpen(false)}
                onChange={data => promiseChain([actions.createNumberTemplate, actions.load], data)}
            />
        </>
    );
};

CreateNewTemplate.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired
};

export default translate('NumberTemplateListPage')(CreateNewTemplate);

import React from 'react';
import TimeLabel from 'components/Label/Time';
import StringFilterHandler from 'components/DataTable/components/StringFilterHandler';

import TemplateActions from '../components/Actions';

export default ({ t, actions }) => ({
    controls: {
        pagination: true,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: true
    },
    checkable: true,
    columns: [{
        id: 'name',
        align: 'left',
        sortable: true,
        name: t('NumberTemplateName')
    }, {
        id: 'id',
        align: 'left',
        sortable: true,
        name: t('TemplateId')
    }, {
        id: 'createdAt',
        width: 160,
        align: 'center',
        sortable: 'true',
        padding: 'checkbox',
        name: t('CreatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'updatedAt',
        width: 160,
        align: 'center',
        sortable: 'true',
        padding: 'checkbox',
        name: t('UpdatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'actions',
        width: 32,
        align: 'right',
        sortable: false,
        padding: 'checkbox',
        hiddable: false,
        disableClick: true,
        render: (value, template) => <TemplateActions template={template} actions={actions} />
    }],
    filterHandlers: {
        id: props => <StringFilterHandler name={t('TemplateId')} {...props} />
    }
});

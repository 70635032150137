import React from 'react';
import { translate } from 'react-translate';
import _ from 'lodash/fp';

// import { history } from 'store';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import endPoint from 'application/adminpanel/endPoints/users';

import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import DataTable from 'components/DataTable';
import dataTableSettings from './variables/dataTableSettings';

class UserListPage extends ModulePage {
    componentDidMount() {
        super.componentDidMount();
        const { actions } = this.props;

        actions.load();
    }

    render() {
        const {
            t,
            title,
            actions,
            loading,
            location
        } = this.props;

        const settings = dataTableSettings({ t, actions });

        return (
            <LeftSidebarLayout location={location} title={t(title)} loading={loading}>
                <DataTable
                    {..._.merge(settings, dataTableAdapter(this.props, settings))}
                    // onRowClick={({ id }) => history.push(`/users/${id}`)}
                />
            </LeftSidebarLayout>
        );
    }
}

const translated = translate('UserListPage')(UserListPage);
export default dataTableConnect(endPoint)(translated);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItemIcon,
    ListItemText,
    Button
} from '@material-ui/core';

import GroupIcon from '@material-ui/icons/Group';

import { SchemaForm, handleChangeAdapter } from 'components/JsonSchema';
import UnitList from 'application/adminpanel/modules/users/pages/Unit/components/UnitList';

import dataTableConnect from 'services/dataTable/connect';
import unitListControlEndPoint from 'application/adminpanel/endPoints/unitListControl';

import { addUnitHeads, deleteUnitHeads, addUnitMembers, deleteUnitMembers } from 'application/adminpanel/actions/units';
import { addError } from 'actions/error';

import promiseChain from 'helpers/promiseChain';

class UserUnitsMenuItem extends React.Component {
    state = { open: false, userUnits: { heads: [], members: [] } };

    handleChange = userUnits => this.setState({ userUnits });

    handleSave = () => {
        const { unitActions, onChange, user: { id: userId, units: oldUserUnits } } = this.props;
        const { userUnits: newUserUnits } = this.state;

        const includeHeadUnits = newUserUnits.heads.filter(unitId => !oldUserUnits.heads.includes(unitId));
        const excludeHeadUnits = oldUserUnits.heads.filter(unitId => !newUserUnits.heads.includes(unitId));

        const includeMemberUnits = newUserUnits.members.filter(unitId => !oldUserUnits.members.includes(unitId));
        const excludeMemberUnits = oldUserUnits.members.filter(unitId => !newUserUnits.members.includes(unitId));

        const requests = [
            ...includeHeadUnits.map(unitId => () => unitActions.addUnitHeads(unitId, [userId])),
            ...excludeHeadUnits.map(unitId => () => unitActions.deleteUnitHeads(unitId, [userId])),
            ...includeMemberUnits.map(unitId => () => unitActions.addUnitMembers(unitId, [userId])),
            ...excludeMemberUnits.map(unitId => () => unitActions.deleteUnitMembers(unitId, [userId]))
        ];

        promiseChain(requests)
            .then(onChange)
            .catch(addError);

        this.setState({ open: false });
    }

    render() {
        const { t, user, onClose } = this.props;
        const { open, userUnits } = this.state;

        return (
            <>
                <MenuItem onClick={() => this.setState({ open: true, userUnits: user.units }, onClose)}>
                    <ListItemIcon>
                        <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('UserUnits')} />
                </MenuItem>
                <Dialog
                    onClose={() => this.setState({ open: false })}
                    open={open}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle>{t('SelectUserUnitsDialog')}</DialogTitle>
                    <DialogContent dividers={true}>
                        <SchemaForm
                            customControls={{ UnitList }}
                            value={userUnits}
                            onChange={handleChangeAdapter(userUnits, this.handleChange)}
                            schema={{
                                type: 'object',
                                properties: {
                                    members: {
                                        control: 'unit.list',
                                        description: t('UnitMember')
                                    },
                                    heads: {
                                        control: 'unit.list',
                                        description: t('UnitHead')
                                    }
                                },
                                required: ['members', 'heads']
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus={true} onClick={() => this.setState({ open: false })} color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={this.handleSave} color="primary">
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

UserUnitsMenuItem.propTypes = {
    t: PropTypes.func.isRequired,
    unitActions: PropTypes.object.isRequired,
    user: PropTypes.object,
    onClose: PropTypes.func,
    onChange: PropTypes.func
};

UserUnitsMenuItem.defaultProps = {
    user: {},
    onClose: () => null,
    onChange: () => null
};

const mapDispatchToProps = dispatch => ({
    unitActions: {
        addError: bindActionCreators(addError, dispatch),
        addUnitHeads: bindActionCreators(addUnitHeads, dispatch),
        deleteUnitHeads: bindActionCreators(deleteUnitHeads, dispatch),
        addUnitMembers: bindActionCreators(addUnitMembers, dispatch),
        deleteUnitMembers: bindActionCreators(deleteUnitMembers, dispatch)
    }
});

const translated = translate('UserListPage')(UserUnitsMenuItem);
const connected = connect(null, mapDispatchToProps)(translated);
export default dataTableConnect(unitListControlEndPoint)(connected);

export default t => ({
    type: 'object',
    properties: {
        taskTemplateEntity: {
            type: 'object',
            description: 'Шаблон заяви',
            properties: {
                jsonSchema: {
                    control: 'code.editor',
                    description: t('TaskJsonSchema'),
                    mode: 'json',
                    validate: true
                }
                // htmlTemplate: {
                //     control: 'code.editor',
                //     description: t('TaskHtmlSchema'),
                //     mode: 'html'
                // }
            },
            required: ['jsonSchema']
        },
        documentTemplateEntity: {
            type: 'object',
            // control: 'tabs',
            description: t('DocumentTemplate'),
            properties: {
                jsonSchema: {
                    control: 'schema.editor',
                    description: t('Schema')
                },
                accessJsonSchema: {
                    description: t('Accesses'),
                    type: 'object',
                    properties: {
                        inboxes: {
                            type: 'object',
                            properties: {
                                workflowCreator: {
                                    control: 'toggle',
                                    onText: t('InboxesAccess')
                                }
                            }
                        },
                        workflowFiles: {
                            type: 'object',
                            properties: {
                                workflowCreator: {
                                    control: 'toggle',
                                    onText: t('WorkflowFilesAccess')
                                }
                            }
                        }
                    }
                },
                htmlTemplate: {
                    control: 'code.editor',
                    description: t('DocumentHtmlSchema'),
                    mode: 'html'
                }
            },
            required: [
                'accessJsonSchema',
                'jsonSchema',
                'htmlTemplate'
            ]
        }
    },
    required: [
        'documentTemplateEntity',
        'taskTemplateEntity'
    ]
});

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Tabs, Tab, withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        margin: 0,
        marginBottom: 32
    },
    indicator: {
        display: 'none'
    },
    item: {
        background: '#F1F1F1',
        border: '2px solid #F1F1F1',
        borderRadius: 50,
        margin: 0,
        marginRight: 4,
        padding: '0 16px',
        opacity: 1,
        fontSize: 13,
        lineHeight: '18px',
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            marginRight: 16,
            minWidth: 136,
            padding: '0 10px'
        }
    },
    active: {
        border: '2px solid #000000',
        background: 'transparent'
    },
    lastChild: {
        marginRight: 0
    },
    errored: {
        border: '2px solid #ff0000'
    }
});

const ChipTabs = ({ classes, className, activeIndex, onChange, tabs, readOnly, errored }) => (
    <Tabs
        value={activeIndex}
        onChange={onChange}
        variant="scrollable"
        scrollButtons="off"
        classes={{
            root: classes.root,
            indicator: classes.indicator
        }}
    >
        {tabs.map(({ title }, index) => (
            <Tab
                key={index}
                label={title}
                disabled={readOnly}
                className={classNames(classes.item, {
                    [classes.active]: index === activeIndex,
                    [classes.lastChild]: index === (tabs.length - 1),
                    [classes.errored]: (errored || []).includes(index)
                }, className)}
            />
        ))}
    </Tabs>
);

ChipTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    activeIndex: PropTypes.number,
    onChange: PropTypes.func,
    tabs: PropTypes.array,
    className: PropTypes.object,
    errored: PropTypes.array
};

ChipTabs.defaultProps = {
    activeIndex: 0,
    onChange: () => null,
    tabs: [],
    className: {},
    errored: []
};

export default withStyles(styles)(ChipTabs);

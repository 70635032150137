import React from 'react';
import { translate } from 'react-translate';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';

const ElementIdDialog = ({ t, open, onClose, onSave }) => {
    const [value, setValue] = React.useState('');

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="body"
        >
            <DialogTitle>{t('NewElementId')}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus={true}
                    onChange={({ target: { value: newValue } }) => setValue(newValue)}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onSave(value);
                            setValue('');
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button
                    disabled={!value}
                    color="primary"
                    variant="contained"
                    onClick={() => { onSave(value); setValue(''); }}
                >
                    {t('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default translate('JsonSchemaEditor')(ElementIdDialog);

import React from 'react';
import { translate } from 'react-translate';
import _ from 'lodash/fp';
// import { history } from 'store';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import endPoint from 'application/adminpanel/endPoints/registryKeyList';
import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';
import DataTable from 'components/DataTable';

import CreateNewKey from './components/CreateNewKey';
import dataTableSettings from './variables/dataTableSettings';

class KeyListPage extends ModulePage {
    componentDidMount() {
        super.componentDidMount();
        const { actions, registerId } = this.props;

        actions.onFilterChange({ registerId });
    }

    render() {
        const {
            t,
            actions,
            registerId,
            title,
            loading,
            location
        } = this.props;

        const settings = dataTableSettings({ t, registerId, actions });

        return (
            <LeftSidebarLayout
                location={location}
                title={t(title)}
                loading={loading}
                backButton={'/registry'}
            >
                <DataTable
                    {..._.merge(settings, dataTableAdapter(this.props, settings))}
                    CustomToolbar={
                        () => (
                            <CreateNewKey registerId={registerId} actions={actions} />
                        )
                    }
                />
            </LeftSidebarLayout>
        );
    }
}

const translated = translate('KeyListAdminPage')(KeyListPage);
export default dataTableConnect(endPoint)(translated);

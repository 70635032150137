import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';
import queue from 'queue';
import diff from 'deep-diff';
import classNames from 'classnames';

import LeftSidebarLayout, { DrawerContent } from 'layouts/LeftSidebar';

import { withStyles, Dialog, DialogContent, DialogActions, Button, List, ListItem, ListItemText, DialogTitle, DialogContentText } from '@material-ui/core';

import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import {
    requestWorkflow,
    onElementChange,
    onElementSelect,
    changeWorkflowData,
    storeWorkflowData,
    requestWorkflowStatuses,
    getWorkflowVersions
} from 'application/adminpanel/actions/workflow';

import { requestNumberTemplates } from 'application/adminpanel/actions/numberTemplates';

import { saveTaskData, deleteTask } from 'application/adminpanel/actions/tasks';
import { saveGatewayData, deleteGateway } from 'application/adminpanel/actions/gateways';
import { saveEventData, deleteEvent, changeEventData } from 'application/adminpanel/actions/events';

import { addError, closeError } from 'actions/error';

import gatewayElementTypes from 'application/adminpanel/modules/workflow/variables/gatewayElementTypes';
import eventElementTypes from 'application/adminpanel/modules/workflow/variables/eventElementTypes';
import taskElementTypes from 'application/adminpanel/modules/workflow/variables/taskElementTypes';

import Preloader from 'components/Preloader';
import ModulePage from 'components/ModulePage';
import { BPMNEditor } from 'components/BpmnSchema';

import waiter from 'helpers/waitForAction';
import config from 'config';

import ElementDetails from './components/Details';
import WorkflowToolbar from './components/WorkflowToolbar';
import WorkflowVersions from './components/WorkflowVersions';
import CreateParallelGatewayEnding from './components/CreateParallelGatewayEnding';

import propsToData from './helpers/propsToData';
import elementsByType from './helpers/elementsByType';
import normalizeElementId from './helpers/normalizeElementId';
import elementToMenuItem from './helpers/elementToMenuItem';

import { elementCreate, elementDelete, elementChange } from './handlers';

const SAVE_INTERVAL = 2000;
const DELETE_INTERVAL = 100;

const styles = {
    saved: {
        display: 'flex',
        alignItems: 'center',
        color: 'green',
        fontWeight: 900
    },
    withError: {
        color: 'red'
    }
};

const getUnsaved = ({ actual, origin }, ids) => Object.keys(actual)
    .map(itemId => parseInt(itemId, 10))
    .filter(itemId => ids.includes(itemId))
    .filter(itemId => diff(actual[itemId], origin[itemId]));

class WorkflowPage extends ModulePage {
    constructor(props) {
        super(props);

        const { actions } = props;

        this.state = { error: null, busy: false, inited: false, blockHotkeys: false };
        this.queue = queue({ autostart: true, concurrency: 1 });
        this.queue.on('end', () => {
            this.setState({ busy: false, saved: true });
            setTimeout(() => this.setState({ saved: false, error: null }), 2000);
        });
        this.queue.on('success', (result) => {
            if (result instanceof Error) {
                if (result.message === 'Header Last-Workflow-History-Id expaired.') {
                    actions.addError(new Error('WorkflowOldVersionError'));
                } else {
                    const error = new Error('ErrorSavingWorkflow');
                    error.details = (result.response && result.response.errors) || result.message;
                    actions.addError(error);
                }
                this.setState({ error: result });
            }
        });

        const { workflowDisabled } = config;
        this.workflowDisabled = workflowDisabled || false;
    }

    componentDidMount() {
        this.init(this.props);
    }

    componentDidUpdate({ match: { params: { workflowId } } }) {
        const { actions } = this.props;
        if (workflowId !== this.props.match.params.workflowId) {
            this.init(this.props);
            actions.onElementSelect(null);
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     const { actions, match: { params: { workflowId } } } = nextProps;
    //     if (workflowId !== this.props.match.params.workflowId) {
    //         this.init(nextProps);
    //         actions.onElementSelect(null);
    //     }
    // }

    componentWillUnmount() {
        const { actions } = this.props;
        actions.onElementSelect(null);
    }

    init = async (props) => {
        const {
            actions,
            numberTemplates,
            workflowStatuses,
            // actualWorkflowList,
            match: { params: { workflowId } }
        } = props;

        actions.onElementSelect(null);

        this.setState({ inited: false });

        if (!numberTemplates) {
            await actions.requestNumberTemplates();
        }

        if (!workflowStatuses) {
            await actions.requestWorkflowStatuses();
        }

        await actions.getWorkflowVersions(workflowId);

        // if (!actualWorkflowList[workflowId]) {
        await actions.requestWorkflow(workflowId);
        // }

        // waiter.addAction(workflowId, this.handleSave, SAVE_INTERVAL);

        this.setState({ inited: true });
    };

    askEventType = (element) => {
        if (!element) return;
        if (element.type !== 'bpmn:IntermediateThrowEvent') return;

        setTimeout(() => {
            const { actions } = this.props;

            this.setState({ askEventType: true });

            actions.onElementSelect(element);
        }, 500);
    };

    showReadOnlyMessage = () => {
        const { actions } = this.props;

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            actions.closeError(0);
            actions.addError(new Error('FailReadOnlySavingWorkflow'));
        }, 250);
    };

    handleElementChange = (element) => {
        const { actions } = this.props;
        waiter.addAction('changeElement' + element.id, () => elementChange(this.modeler)(element), DELETE_INTERVAL);
        actions.onElementChange(element);
    };

    handleElementCreate = async (element) => {
        const { actions } = this.props;
        const elementId = element.businessObject.id.split('-').pop();

        if (this.workflowDisabled) {
            this.showReadOnlyMessage();
            return;
        }

        await waiter.addAction('createElement' + elementId, () => {
            elementCreate(this.modeler)(element);
            const existed = this.getElements().find(({ id }) => id === element.id);
            if (existed) {
                actions.onElementSelect(existed);
            }
        }, DELETE_INTERVAL);
        this.askEventType(element);
    }

    handleElementDelete = (element) => {
        const { actions, match: { params: { workflowId } } } = this.props;
        const elementId = element.businessObject.id.split('-').pop();

        if (this.workflowDisabled) {
            this.showReadOnlyMessage();
            return;
        }

        let deleteElementAction;

        if (taskElementTypes.includes(element.type)) {
            deleteElementAction = () => actions.deleteTask(elementId);
        }

        if (eventElementTypes.includes(element.type)) {
            deleteElementAction = () => actions.deleteEvent(elementId);
        }

        if (gatewayElementTypes.includes(element.type) && elementId !== 'end') {
            deleteElementAction = () => actions.deleteGateway(elementId);
        }

        actions.onElementSelect(null);

        deleteElementAction && waiter.addAction('deleteElement' + elementId, () => {
            const existed = this.getElements().find(({ id }) => id === element.id);
            if (!existed) {
                deleteElementAction();
            } else {
                actions.onElementSelect(existed);
            }

            elementDelete(this.modeler)(element);
            waiter.addAction(workflowId, this.handleSave, SAVE_INTERVAL);
        }, DELETE_INTERVAL);
    }

    handleReady = (modeler) => {
        this.modeler = modeler;

        if (!this.modeler) {
            return;
        }

        const { actions, match: { params: { selectionId } } } = this.props;
        actions.onElementSelect(this.modeler.get('elementRegistry').get(selectionId));
    };

    handleWorkflowChange = async (workflowData) => {
        const { actions, match: { params: { workflowId } } } = this.props;
        await actions.changeWorkflowData(workflowId, workflowData);
        waiter.addAction(workflowId, this.handleSave, SAVE_INTERVAL);
    };

    handleSave = async () => {
        const { actions, events, gateways, tasks } = this.props;
        const { workflowId, workflow, origin } = propsToData(this.props);

        if (this.workflowDisabled) {
            this.showReadOnlyMessage();
            return;
        }

        if (!workflow) return;

        const diffs = diff(workflow, origin);

        this.setState({ busy: true });

        if (this.modeler) {
            const elementIds = this.getElements().map(({ businessObject: { id } }) => id);

            const storeUnsaved = (type, storage, handler) => {
                const ids = elementIds.filter(elementsByType(type)).map(normalizeElementId);

                getUnsaved(storage, ids).forEach(id => this.queue.push(() => handler([{
                    ...storage.actual[id],
                    workflowTemplateId: workflowId
                }])));
            };

            storeUnsaved('event', events, actions.saveEventData);
            storeUnsaved('gateway', gateways, actions.saveGatewayData);
            storeUnsaved('task', tasks, actions.saveTaskData);
        }

        if (diffs) {
            this.handleSaveWorkflow();
        }
    };

    handleSaveWorkflow = () => {
        const { workflow, workflow: { description, workflowTemplateCategoryId } } = propsToData(this.props);
        const { actions, match: { params: { workflowId } } } = this.props;

        if (this.workflowDisabled) {
            this.showReadOnlyMessage();
            return;
        }

        this.queue.push(() => actions.storeWorkflowData(workflowId, {
            ...workflow,
            description: description || undefined,
            workflowTemplateCategoryId: workflowTemplateCategoryId || null
        }));
    }

    handleChangeElement = async (elementData) => {
        const { selection, actions, match: { params: { workflowId } } } = this.props;
        const sequenceFlowElement = this.modeler.get('elementRegistry').get(selection.id);

        if (!sequenceFlowElement) {
            return;
        }

        if (elementData) {
            const modeling = this.modeler.get('modeling');
            try {
                const { id, name } = elementData;
                modeling.updateProperties(sequenceFlowElement, { id, name });
            } catch (e) {
                // nothing to do
            }
            if (selection.id !== elementData.id) {
                actions.onElementSelect(this.modeler.get('elementRegistry').get(selection.id));
            }
        }
        waiter.addAction('changeElement' + sequenceFlowElement.id, () => elementChange(this.modeler)(sequenceFlowElement), DELETE_INTERVAL);
        waiter.addAction(workflowId, this.handleSave, SAVE_INTERVAL);
    };

    getElements = () => (this.modeler ? this.modeler.get('elementRegistry').getAll() : []);

    componentGetTitle() {
        const { workflow } = propsToData(this.props);
        return workflow && workflow.name;
    }

    blockHotkeysEvent = blockHotkeys => this.setState({ blockHotkeys });

    hasUnsavedItems = () => {
        const { events, gateways, tasks } = this.props;
        const { workflow, origin } = propsToData(this.props);
        const diffs = diff(workflow, origin);

        if (!this.modeler) {
            return diff;
        }

        const elementIds = this.getElements().map(({ id }) => id);

        const eventIds = elementIds.filter(elementsByType('event')).map(normalizeElementId);
        const gatewayIds = elementIds.filter(elementsByType('gateway')).map(normalizeElementId);
        const taskIds = elementIds.filter(elementsByType('task')).map(normalizeElementId);

        const unsavedEvents = getUnsaved(events, eventIds);
        const unsavedGateways = getUnsaved(gateways, gatewayIds);
        const unsavedTasks = getUnsaved(tasks, taskIds);

        return diffs || [].concat(unsavedEvents, unsavedGateways, unsavedTasks).length;
    };

    renderAskEventType = () => {
        const { t, events: { types } } = this.props;
        const { askEventType } = this.state;

        const onClose = () => this.setState({ askEventType: false });

        const updateEvent = async (id) => {
            const { actions, events: { actual }, selection } = this.props;
            if (!selection) {
                return onClose();
            }
            const elementId = selection.businessObject.id.split('-').pop();
            const event = actual[elementId];
            event.eventTypeId = id;
            await actions.changeEventData(elementId, event);
            this.handleChangeElement();
            onClose();
        };

        return (
            <Dialog
                open={askEventType}
                scroll="body"
                fullWidth={true}
                maxWidth="sm"
                onClose={onClose}
            >
                <DialogTitle>{t('AskEventTypeTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('AskEventTypeDescr')}</DialogContentText>
                    <List component="nav">
                        {
                            (types || []).map(el => (
                                <ListItem
                                    button={true}
                                    key={el.id}
                                    selected={el.id === 1}
                                    onClick={() => updateEvent(el.id)}
                                >
                                    <ListItemText primary={el.name} />
                                </ListItem>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t('Close')}</Button>
                </DialogActions>
            </Dialog>
        );
    };

    renderContent() {
        const { t, classes, actions, selection, workflowStatuses, workflowVersions, numberTemplates, match: { params: { workflowId, selectionId } } } = this.props;
        const { workflow } = propsToData(this.props);
        const { saved, error, inited, blockHotkeys } = this.state;

        if (!workflow || !inited) {
            return <Preloader />;
        }

        const workflowElements = this.getElements();

        const currentVersion = workflowVersions && workflowVersions.find(({ isCurrentVersion }) => isCurrentVersion);
        const version = currentVersion && currentVersion.version;

        return (
            <DrawerContent>
                <WorkflowToolbar
                    workflow={workflow}
                    events={workflowElements.filter(elementsByType('event')).map(elementToMenuItem)}
                    taskTemplates={workflowElements.filter(elementsByType('task')).map(elementToMenuItem)}
                    workflowStatuses={workflowStatuses}
                    numberTemplates={numberTemplates}
                    onChange={this.handleWorkflowChange}
                >
                    <WorkflowVersions workflowId={workflowId} />
                    <div style={{ flexGrow: 1 }} />
                    {
                        saved ? (
                            <div className={classNames(classes.saved, { [classes.withError]: !!error })}>
                                <DoneOutlineIcon />
                                {error ? t('SavedWithError') : t('Saved')}
                            </div>
                        ) : null
                    }
                    {this.renderAskEventType()}
                    <ElementDetails
                        t={t}
                        openSelection={selectionId}
                        numberTemplates={numberTemplates}
                        selectionId={selection && selection.businessObject.id}
                        selection={selection}
                        workflow={workflow}
                        modeler={this.modeler}
                        onChange={this.handleChangeElement}
                        blockHotkeysEvent={this.blockHotkeysEvent}
                    />
                    <CreateParallelGatewayEnding
                        t={t}
                        modeler={this.modeler}
                        selection={selection}
                    />
                </WorkflowToolbar>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <BPMNEditor
                        schemaId={workflowId + version}
                        diagram={workflow.xmlBpmnSchema}
                        onReady={this.handleReady}
                        onChange={xmlBpmnSchema => this.handleWorkflowChange({ xmlBpmnSchema })}
                        onElementChange={this.handleElementChange}
                        onElementSelect={actions.onElementSelect}
                        onElementCreate={this.handleElementCreate}
                        onElementDelete={this.handleElementDelete}
                        blockHotkeys={blockHotkeys}
                    />
                </div>
            </DrawerContent>
        );
    }

    render() {
        const {
            t,
            title,
            loading,
            location
        } = this.props;

        const { workflow } = propsToData(this.props);
        const layoutTitle = workflow ? workflow.name : t(title);

        return (
            <LeftSidebarLayout
                location={location}
                title={layoutTitle}
                loading={loading}
                flexContent={true}
            >
                {this.renderContent()}
            </LeftSidebarLayout>
        );
    }
}

const mapStateToProps = ({
    workflow: { selection, actual, origin, categories, statuses, workflowVersions },
    numberTemplates: { list: numberTemplates },
    events,
    gateways,
    tasks
}) => ({
    selection,
    actualWorkflowList: actual,
    originWorkflowList: origin,
    workflowCategories: categories,
    workflowStatuses: statuses,
    workflowVersions,
    numberTemplates,
    events,
    gateways,
    tasks
});

const mapDispatchToProps = dispatch => ({
    actions: {
        addError: bindActionCreators(addError, dispatch),
        closeError: bindActionCreators(closeError, dispatch),
        deleteTask: bindActionCreators(deleteTask, dispatch),
        saveTaskData: bindActionCreators(saveTaskData, dispatch),
        deleteEvent: bindActionCreators(deleteEvent, dispatch),
        saveEventData: bindActionCreators(saveEventData, dispatch),
        deleteGateway: bindActionCreators(deleteGateway, dispatch),
        saveGatewayData: bindActionCreators(saveGatewayData, dispatch),
        requestWorkflow: bindActionCreators(requestWorkflow, dispatch),
        changeWorkflowData: bindActionCreators(changeWorkflowData, dispatch),
        storeWorkflowData: bindActionCreators(storeWorkflowData, dispatch),
        onElementChange: bindActionCreators(onElementChange, dispatch),
        onElementSelect: bindActionCreators(onElementSelect, dispatch),
        requestNumberTemplates: bindActionCreators(requestNumberTemplates, dispatch),
        requestWorkflowStatuses: bindActionCreators(requestWorkflowStatuses, dispatch),
        getWorkflowVersions: bindActionCreators(getWorkflowVersions, dispatch),
        changeEventData: bindActionCreators(changeEventData, dispatch)
    }
});

const styled = withStyles(styles)(WorkflowPage);
const translated = translate('WorkflowAdminPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

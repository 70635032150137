export default [
    'bpmn:Task',
    'bpmn:SendTask',
    'bpmn:ReceiveTask',
    'bpmn:UserTask',
    'bpmn:ManualTask',
    'bpmn:BusinessRuleTask',
    'bpmn:ServiceTask',
    'bpmn:ScriptTask',
    'bpmn:CallActivity',
    'bpmn:SubProcess'
];

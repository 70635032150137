import React from 'react';
import PropTypes from 'prop-types';
import queue from 'queue';
import ChangeEvent from 'components/JsonSchema/ChangeEvent';
import renderHTML from 'helpers/renderHTML';

import { Typography, withStyles, FormHelperText } from '@material-ui/core';

import EJVError from 'components/JsonSchema/components/EJVError';
import FileDataTable from 'components/FileDataTable';
import SelectFileArea from './components/SelectFileArea';

const styles = {
    root: {
        marginTop: 10,
        marginBottom: 20,
        maxWidth: 640
    },
    label: {
        marginTop: 20
    }
};

class SelectFiles extends React.Component {
    constructor(props) {
        super(props);
        const { actions } = props;
        this.state = { uploadFileList: [] };

        this.queue = queue({ autostart: true, concurrency: 1 });

        if (actions && actions.setBusy) {
            this.queue.on('end', () => actions.setBusy(false));
        }
    }

    componentDidMount() {
        this.handleRemoveHidden();
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if ((prevProps.value && prevProps.value.length) !== (value && value.length)) {
            this.handleRemoveHidden();
        }
    }

    handleRemoveHidden = () => {
        const { value, hidden } = this.props;

        if (value && hidden) {
            value.forEach(item => this.handleDeleteFile(item));
        }
    }

    handleDeleteFile = (attach) => {
        const { actions, value } = this.props;

        actions.handleDeleteFile(attach);
        this.handleChange(Object.values(value).filter(Boolean).filter(({ id }) => id !== attach.id), true, true);
    }

    uploadFile = (file, labels) => async () => {
        const { value, actions: { uploadDocumentAttach } } = this.props;
        const fileList = Object.values(value || {});

        const uploadedFile = await uploadDocumentAttach(file, labels);

        if (uploadedFile) {
            fileList.push({ ...uploadedFile, labels });
            this.handleChange(fileList, true, true);
        }

        return new Promise((resolve) => {
            const { uploadFileList } = this.state;
            this.setState({ uploadFileList: uploadFileList.slice(1) }, resolve);
        });
    };

    handleChange = async (data, force = false, hard = false) => {
        const { onChange, schema } = this.props;
        let updateData = data;

        if (schema.type === 'object') {
            updateData = { ...data };
        }

        return onChange && onChange(new ChangeEvent(updateData, force, hard));
    };

    onDrop = labels => async (acceptedFiles) => {
        const { uploadFileList } = this.state;
        const { actions, demo } = this.props;

        if (!acceptedFiles || !acceptedFiles.length) {
            return;
        }

        actions && actions.setBusy && actions.setBusy(true);
        this.setState({
            uploadFileList: uploadFileList
                .concat(acceptedFiles.map((file) => {
                    file.labels = labels;
                    return file;
                }))
        }, () => {
            if (!demo) {
                acceptedFiles.forEach(file => this.queue.push(this.uploadFile(file, labels)));
            }
        });
    };

    render() {
        const {
            view,
            path,
            name,
            error,
            accept,
            hidden,
            labels,
            value,
            sample,
            classes,
            maxSize,
            actions,
            active,
            readOnly,
            fileStorage,
            description
        } = this.props;

        const canUpload = !readOnly || (readOnly && !active);

        const { uploadFileList } = this.state;

        if (hidden) return null;

        const fileList = []
            .concat(Object.values(value || {}), uploadFileList)
            .filter(Boolean);

        const renderDataTable = dragEvents => (fileList && fileList.length ? (
            <FileDataTable
                dragEvents={dragEvents}
                view={view}
                data={fileList}
                fileStorage={fileStorage}
                groupBy={labels ? 'labels' : undefined}
                actions={
                    {
                        handleDeleteFile: canUpload ? this.handleDeleteFile : null,
                        handleDownloadFile: actions.handleDownloadFile
                    }
                }
            />
        ) : null);

        return (
            <div
                className={classes.root}
                id={(path || []).join('-')}
            >
                {
                    description ? (
                        <Typography variant="h5">{description}</Typography>
                    ) : null
                }
                {
                    sample ? (
                        <div className={classes.raw}>
                            {renderHTML(sample)}
                        </div>
                    ) : null
                }
                {
                    labels && labels.length ? (
                        <>
                            {
                                labels.map(label => (
                                    <div key={label}>
                                        <Typography variant="h5" className={classes.label}>{label}</Typography>
                                        <SelectFileArea
                                            path={path}
                                            name={name}
                                            sample={sample}
                                            maxSize={maxSize}
                                            accept={accept}
                                            labels={labels}
                                            readOnly={!canUpload}
                                            onSelect={this.onDrop([label])}
                                        />
                                    </div>
                                ))
                            }
                            {renderDataTable()}
                        </>
                    ) : (
                            <SelectFileArea
                                path={path}
                                name={name}
                                sample={sample}
                                maxSize={maxSize}
                                accept={accept}
                                readOnly={!canUpload}
                                onSelect={this.onDrop([])}
                                renderContent={renderDataTable}
                            />
                        )
                }
                {
                    error ? (
                        <FormHelperText
                            error={!!error}
                        >
                            <EJVError error={error} />
                        </FormHelperText>
                    ) : null
                }
            </div>
        );
    }
}

SelectFiles.propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.object,
    sample: PropTypes.string,
    accept: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    maxSize: PropTypes.number,
    path: PropTypes.array
};

SelectFiles.defaultProps = {
    sample: '',
    accept: '',
    actions: {},
    maxSize: null,
    value: null,
    path: []
};

export default withStyles(styles)(SelectFiles);

import React from 'react';
import { translate } from 'react-translate';
import { Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from 'components/ConfirmDialog';
import { ChangeEvent } from 'components/JsonSchema';

const ClearDataButton = ({ t, onChange }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Tooltip title={t('ClearData')}>
                <IconButton onClick={() => setOpen(true)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <ConfirmDialog
                open={open}
                title={t('ClearData')}
                description={t('ClearDataPrompt')}
                handleClose={() => setOpen(false)}
                handleConfirm={() => { setOpen(false); onChange(new ChangeEvent([], true)); }}
            />
        </>
    );
};

export default translate('Elements')(ClearDataButton);

import React from 'react';

import { withStyles } from '@material-ui/core';

import AceEditor from 'react-ace';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-twilight';

const styles = {
    root: {
        display: 'flex',
        height: '100%'
    }
};

const TaskDataTools = ({ classes, task, template }) => (
    <div className={classes.root}>
        <AceEditor
            mode="json"
            theme="twilight"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={JSON.stringify(task && task.document.data, null, 4)}
            width="100%"
            height="100%"
            readOnly={true}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 4
            }}
        />
        <AceEditor
            mode="json"
            theme="twilight"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={JSON.stringify(template && template.jsonSchema, null, 4)}
            width="100%"
            height="100%"
            readOnly={true}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 4
            }}
        />
    </div>
);

export default withStyles(styles)(TaskDataTools);

import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import DeleteTemplate from './DeleteTemplate';
import EditTemplate from './EditTemplate';
import ExportTemplate from './ExportTemplate';

const TemplateActions = ({ template, actions }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => setAnchorEl(null);

    const menuItemProps = {
        actions,
        template,
        onClose: handleClose,
        onChange: actions.load
    };

    return (
        <>
            <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                <MenuIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted={true}
            >
                <EditTemplate {...menuItemProps} />
                <Divider light={true} />
                <ExportTemplate {...menuItemProps} />
                <Divider light={true} />
                <DeleteTemplate {...menuItemProps} />
            </Menu>
        </>
    );
};

TemplateActions.propTypes = {
    template: PropTypes.object,
    actions: PropTypes.object
};

TemplateActions.defaultProps = {
    template: {},
    actions: {}
};

export default TemplateActions;

import React from 'react';
import { MenuItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { translate } from 'react-translate';

import ConfirmDialog from 'components/ConfirmDialog';
import promiseChain from 'helpers/promiseChain';

const styles = {
    root: {
        color: '#ff0000'
    }
};

const DeleteTemplate = ({
    t,
    classes,
    onClose,
    template,
    actions: {
        deleteNumberTemplate,
        onRowsSelect,
        load
    }
}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <MenuItem
                className={classes.root}
                onClick={() => { setOpen(true); onClose(); }}
            >
                <ListItemIcon>
                    <DeleteOutlineIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.root }} primary={t('DeleteTemplate')} />
            </MenuItem>
            <ConfirmDialog
                open={open}
                handleClose={() => setOpen(false)}
                handleConfirm={
                    () => promiseChain([
                        deleteNumberTemplate,
                        () => onRowsSelect([]),
                        () => setOpen(false),
                        load
                    ], template.id)
                }
                title={t('DeleteTemplateDialogTitle')}
                description={t('DeleteTemplateDialogDescription')}
            />
        </>
    );
};

const styled = withStyles(styles)(DeleteTemplate);
export default translate('NumberTemplateListPage')(styled);

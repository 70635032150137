import React from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import WorkflowPage from 'application/manager/modules/workflow/pages/Workflow';
import WorkflowListPage from 'application/manager/modules/workflow/pages/WorkflowList';

import DraftsTableToolbar from 'application/manager/modules/workflow/pages/WorkflowList/components/TableToolbar';
import TrashTableTools from 'application/manager/modules/workflow/pages/WorkflowList/components/TableToolbar/TrashTableTools';

import workflowEndPoint from 'application/manager/endPoints/workflow';
import workflowDraftEndPoint from 'application/manager/endPoints/workflowDraft';
import workflowTrashEndPoint from 'application/manager/endPoints/workflowTrash';

export default {
    routes: [
        {
            path: '/workflow/drafts',
            component: WorkflowListPage,
            endPoint: workflowDraftEndPoint,
            TableToolbar: DraftsTableToolbar,
            title: 'Drafts',
            defaultFilters: { tasks: { deleted: 0 }, is_draft: true },
            defaultSort: { columnName: 'documents.updated_at', direction: 'desc' },
            hiddenColumns: {
                notUnitedUser: [],
                isUnitedUser: 'properties.hiddenColumns.workflow.Drafts'
            },
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.Drafts' }
        },
        {
            path: '/workflow/trash',
            component: WorkflowListPage,
            endPoint: workflowTrashEndPoint,
            TableToolbar: TrashTableTools,
            title: 'TrashPage',
            defaultFilters: { tasks: { deleted: 1 }, is_draft: true },
            defaultSort: { columnName: 'tasks.created_at', direction: 'desc' },
            hiddenColumns: {
                notUnitedUser: [],
                isUnitedUser: 'properties.hiddenColumns.workflow.Trash'
            },
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.Trash' }
        },
        {
            path: '/workflow/:workflowId',
            component: WorkflowPage,
            access: {
                isUnitedUser: false,
                unitHasAccessTo: [
                    'navigation.workflow.MyWorkflow',
                    'navigation.workflow.Drafts',
                    'navigation.workflow.Trash'
                ]
            }
        },
        {
            path: '/workflow',
            component: WorkflowListPage,
            endPoint: workflowEndPoint,
            TableToolbar: DraftsTableToolbar,
            title: 'InboxTitle',
            defaultFilters: { 'tasks.deleted': 0, is_draft: false },
            defaultSort: { columnName: 'tasks.finished_at', direction: 'desc' },
            hiddenColumns: {
                notUnitedUser: [],
                isUnitedUser: 'properties.hiddenColumns.workflow.MyWorkflow'
            },
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
        }
    ],
    navigation: [{
        id: 'Workflow',
        icon: <DoneAllIcon />,
        priority: 30,
        access: {
            isUnitedUser: false,
            unitHasAccessTo: [
                'navigation.workflow.MyWorkflow',
                'navigation.workflow.Drafts',
                'navigation.workflow.Trash'
            ]
        },
        children: [{
            id: 'MyWorkflow',
            path: '/workflow',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
        }, {
            id: 'Drafts',
            path: '/workflow/drafts',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.Drafts' }
        }, {
            id: 'Trash',
            path: '/workflow/trash',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.Trash' }
        }]
    }]
};

import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import EDSForm from 'components/EDSForm';

const styles = {

};

class TestPage extends React.Component {
    state = {};

    handleSelectKey = async (encryptedKey, signer, resetPrivateKey) => {
        const { t } = this.props;
        throw new Error(t('Sign not available.'));
    }

    render() {
        return (
            <EDSForm onSelectKey={this.handleSelectKey} />
        );
    }
}

TestPage.propTypes = {

};

TestPage.defaultProps = {

};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({ actions: {} });

const styled = withStyles(styles)(TestPage);
const translated = translate('TaskPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

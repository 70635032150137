import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const UserActiveMenuItem = ({ t, actions: { blockUser, unblockUser }, user: { id, isActive }, onClose }) => (isActive
    ? (
        <MenuItem onClick={() => blockUser(id)}>
            <ListItemIcon>
                <BlockIcon />
            </ListItemIcon>
            <ListItemText primary={t('BlockUser')} />
        </MenuItem>
    )
    : (
        <MenuItem onClick={() => unblockUser(id)}>
            <ListItemIcon>
                <CheckCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={t('UnblockUser')} />
        </MenuItem>
    ));

UserActiveMenuItem.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object,
    user: PropTypes.object,
    onClose: PropTypes.func
};

UserActiveMenuItem.defaultProps = {
    actions: {
        blockUser: () => null,
        unblockUser: () => null
    },
    user: {},
    onClose: () => null
};

export default translate('UserListPage')(UserActiveMenuItem);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addMessage } from 'actions/error';
import Message from 'components/Snackbars/Message';
import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Tooltip
} from '@material-ui/core';

import RepeatIcon from '@material-ui/icons/Repeat';

import { restartProcessFromPoint } from 'application/adminpanel/actions/workflowProcess';

class MessagesDetails extends React.Component {
    state = { open: false };

    handleOpen = () => {
        const { log: { details: { data } } } = this.props;

        this.setState({
            open: true,
            data
        });
    }

    handleRestart = async () => {
        const { actions, processId } = this.props;
        const { data } = this.state;
        this.setState({ open: false });
        const result = await actions.restartProcessFromPoint(processId, data);
        if (result instanceof Error) {
            actions.addMessage(new Message('FailRestartingWorkflowProcessFromPoint', 'error'));
            return null;
        } else {
            window.location.reload();
        }
    }

    render() {
        const { t } = this.props;
        const { open } = this.state;

        return (
            <>
                <Tooltip title={t('RestartProcessFromPoint')}>
                    <IconButton onClick={this.handleOpen}>
                        <RepeatIcon />
                    </IconButton>
                </Tooltip>
                <Dialog
                    onClose={() => this.setState({ open: false })}
                    open={open}
                    fullWidth={true}
                    maxWidth="xs"
                >
                    <DialogTitle id="simple-dialog-title">{t('RestartProcessFromPoint')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('RestartProcessConfirmation')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ open: false })}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleRestart}
                        >
                            {t('RestartProcess')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

MessagesDetails.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    processId: PropTypes.string.isRequired,
    log: PropTypes.object.isRequired
};

MessagesDetails.defaultProps = {

};

const mapDispatchToProps = dispatch => ({
    actions: {
        restartProcessFromPoint: bindActionCreators(restartProcessFromPoint, dispatch),
        addMessage: bindActionCreators(addMessage, dispatch)
    }
});

const translated = translate('ProcessesListPage')(MessagesDetails);
export default connect(null, mapDispatchToProps)(translated);

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import MobileDetect from 'mobile-detect';
import { translate } from 'react-translate';
import { withStyles } from '@material-ui/core/styles';
import components from 'components/Select/components';
import styles from 'components/Select/components/styles';

const md = new MobileDetect(window.navigator.userAgent);
const menuHeight = 300;
const labelHeight = 15;

const selectPosition = el => el.current.select.controlRef.getBoundingClientRect();
const getPosition = selectEl => {
    const { bottom } = selectPosition(selectEl);
    const itsMobile = !!md.mobile();
    const bottomPosition = window.screen.height - bottom || false;
    return {
        isBottom: itsMobile ? false : bottomPosition < menuHeight,
        bottomPosition: bottomPosition - window.scrollY
    };
};

const MultiSelect = ({
    t,
    id,
    multiple,
    readOnly,
    options,
    isLoading,
    classes,
    recomponents,
    useOwnContainer,
    ...props
}) => {
    const selectEl = React.useRef(null);
    const [pos, setPos] = React.useState(null);

    const savePositionOnBottom = () => {
        const { isBottom, bottomPosition } = getPosition(selectEl);
        if (!isBottom || pos) return;
        const bottom = bottomPosition + menuHeight - labelHeight;
        setPos({ bottom });
    };

    const updateMenuPosition = async () => {
        const { isBottom, bottomPosition } = getPosition(selectEl);
        if (!isBottom) return;
        setTimeout(() => {
            const menu = document.getElementById(`${id}Menu`);
            if (!menu) return;
            const { height } = menu.getBoundingClientRect();
            const bottom = height + bottomPosition - labelHeight;
            const isSmaller = height < bottomPosition;
            setPos({ bottom, isSmaller });
        }, 100);
    };

    return (
        <Select
            {...props}
            id={id}
            ref={selectEl}
            classes={classes}
            className={classes.select}
            options={options || []}
            loadingMessage={() => t('Loading')}
            isDisabled={!options || readOnly}
            isLoading={!readOnly ? (!options || isLoading) : false}
            menuShouldBlockScroll={!useOwnContainer}
            isMulti={multiple}
            isClearable={true}
            placeholder={null}
            menuPortalTarget={useOwnContainer ? null : document.body}
            onMenuOpen={savePositionOnBottom}
            onKeyDown={updateMenuPosition}
            onMenuClose={() => setPos(null)}
            components={
                {
                    ...components,
                    ...recomponents
                }
            }
            styles={
                {
                    menuPortal: base => (
                        {
                            ...base,
                            zIndex: 9999,
                            ...(pos && !pos.isSmaller ? {
                                top: 'auto',
                                bottom: pos.bottom
                            } : {})
                        }
                    )
                }
            }
        />
    );
};

MultiSelect.propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    multiple: PropTypes.bool,
    wrapClassName: PropTypes.string,
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
    useOwnContainer: PropTypes.bool,
    options: PropTypes.array,
    recomponents: PropTypes.object,
    readOnly: PropTypes.bool
};

MultiSelect.defaultProps = {
    onChange: () => null,
    label: '',
    value: null,
    multiple: false,
    wrapClassName: '',
    error: false,
    isLoading: false,
    useOwnContainer: false,
    options: null,
    recomponents: {},
    readOnly: false
};

const translated = translate('TaskPage')(MultiSelect);
export default withStyles(styles, { withTheme: true })(translated);

import diff from 'deep-diff';

export default (claim, origin) => (diff(origin, claim) || []).map((props) => {
    const {path, lhs, rhs, kind, index, item} = props;
    if (kind === 'A'){
        return {
            path: path.concat([index]).join('.'),
            value: item.rhs,
            previousValue: item.lhs
        };
    }

    return {
        path: path.join('.'),
        value: rhs,
        previousValue: lhs
    };
}).filter(({path}) => path);

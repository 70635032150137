import queue from 'queue';

class QueueFactory {
    queues = {};

    get = (queueId) => {
        if (!this.queues[queueId]) {
            this.queues[queueId] = queue({ autostart: true, concurrency: 1 });
        }

        return this.queues[queueId];
    }
}

const queueFactory = new QueueFactory();

export default queueFactory;

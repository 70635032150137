import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const iconStyle = {
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: 5
};

const SortingIcon = ({ direction }) => (
    direction === 'asc'
      ? <ArrowUpward style={iconStyle} />
      : <ArrowDownward style={iconStyle} />
);

SortingIcon.propTypes = {
    direction: PropTypes.string.isRequired
};

const titleStyle = {
    margin: 0,
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%'
};

const SortLabel = ({ onSort, children, direction }) => (
    <Tooltip
        title={children}
        placement="bottom"
    >
        <Typography
            onClick={onSort}
            variant="body2"
            gutterBottom={true}
            style={titleStyle}
        >
            {children}
            {(direction && <SortingIcon direction={direction} />)}
        </Typography>
    </Tooltip>
);

SortLabel.propTypes = {
    onSort: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    direction: PropTypes.string.isRequired
};

export default SortLabel;

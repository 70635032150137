import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import {
    Chip,
    List,
    ListItem,
    ListItemText,
    Divider,
    withStyles,
    Typography
} from '@material-ui/core';

import evalate from 'helpers/evalate';

const styles = {
    statusItem: {
        display: 'flex',
        paddingRight: 0
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 320
    },
    listItem: {
        padding: '6px 16px'
    },
    divider: {
        backgroundColor: '#e1e1e1'
    },
    statusTitle: {
        flex: 1,
        lineHeight: '32px'
    },
    itemValue: {
        display: 'block'
    }
};

const isItemShown = (row) => {
    let hidden;
    if (typeof row.hidden === 'string') {
        hidden = evalate(row.hidden, document.data);

        if (hidden instanceof Error) {
            hidden.commit({
                type: 'task page header'
            });
            return false;
        }

        return !hidden;
    }

    return !row.hidden;
};

const HeaderInfo = ({ t, classes, template, task, children }) => {
    const rows = (template && Object.values(template.jsonSchema.header || {})) || [];
    const { deleted, document } = task || {};
    const { isFinal } = document || {};

    if (!rows.length && (!children || !children.filter(Boolean).length)) {
        return null;
    }

    return (
        <>
            <List className={classes.list}>
                <ListItem alignItems="flex-start" className={classes.listItem}>
                    <ListItemText
                        className={classes.statusItem}
                        primary={(
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                className={classes.statusTitle}
                            >
                                {t('Status')}
                            </Typography>
                        )}
                        secondary={(
                            <Typography
                                component="span"
                                variant="body1"
                                color="textPrimary"
                            >
                                <Chip
                                    color={isFinal || deleted ? 'default' : 'primary'}
                                    label={t(deleted ? 'DeletedStatus' : (isFinal ? 'FinalStatus' : 'ActiveStatus'))}
                                    className={classes.chip}
                                    variant="default"
                                />
                            </Typography>
                        )}
                    />
                </ListItem>
                <Divider component="li" className={classes.divider} />
                {rows
                    .map((row) => {
                        let text = evalate(row.value, document.data);

                        if (text instanceof Error) {
                            text.commit({ type: 'task info' });
                            text = '';
                        }

                        return { ...row, text };
                    })
                    .filter(({ text }) => !!text)
                    .filter(isItemShown)
                    .map((row, index) => (
                        <>
                            <ListItem key={index} alignItems="flex-start" className={classes.listItem}>
                                <ListItemText
                                    primary={(
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {row.description}
                                        </Typography>
                                    )}
                                    secondary={(
                                        <Typography
                                            component="span"
                                            variant="body1"
                                            color="textPrimary"
                                            className={classes.itemValue}
                                        >
                                            {row.text}
                                        </Typography>
                                    )}
                                />
                            </ListItem>
                            <Divider component="li" className={classes.divider} />
                        </>
                    ))}
            </List>
            {children}
        </>
    );
};

HeaderInfo.propTypes = {
    header: PropTypes.object,
    task: PropTypes.object.isRequired
};

HeaderInfo.defaultProps = {
    header: {}
};

const styled = withStyles(styles)(HeaderInfo);
export default translate('TaskListPage')(styled);

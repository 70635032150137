import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';

import {
    Toolbar,
    Typography,
    Button,
    withStyles
} from '@material-ui/core';

import Preloader from 'components/Preloader';
import FileDataTable from 'components/FileDataTable';
import FilePreview from 'components/FilePreview';

import {
    loadTaskDocument,
    downloadDocumentAttach,
    getPDFDocumentDecoded,
    downloadDocumentAsicContainer
} from 'application/manager/actions/task';

import SaveIcon from '@material-ui/icons/ArchiveOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PrintIcon from '@material-ui/icons/Print';
import WarningIcon from '@material-ui/icons/Warning';

import printJS from 'print-js';
import downloadBase64Attach from 'helpers/downloadBase64Attach';
import base64ToBlob from 'helpers/base64ToBlob';


const styles = theme => ({
    leftIcon: {
        marginRight: theme.spacing()
    },
    backButton: {
        marginRight: 10
    },
    printButton: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    toolbar: {
        position: 'sticky',
        width: '100%',
        left: 0,
        bottom: 0,
        padding: 8,
        background: '#fff',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        '@media screen and (max-width: 600px)': {
            paddingLeft: '8px'
        }
    },
    errorWrapper: {
        padding: 30
    }
});

class InboxFileDetails extends React.Component {
    state = { busy: false };

    componentDidMount() {
        const { documentId } = this.props;

        if (documentId) {
            this.init(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { documentId: oldDocumentId } = this.props;

        if (nextProps.documentId && nextProps.documentId !== oldDocumentId) {
            this.init(nextProps);
        }
    }

    handleDownloadAsicContainer = async () => {
        const { actions, documentId, name } = this.props;

        this.setState({ busy: true });
        const container = await actions.downloadDocumentAsicContainer(documentId);
        this.setState({ busy: false });
        if (container instanceof Error) {
            return;
        }
        downloadBase64Attach({ fileName: name + '.asice' }, container);
    };

    init = ({ actions, documentId }) => {
        const { document, pdfDocument } = this.getData(this.props);

        if (!document) {
            actions.loadTaskDocument(documentId);
        }

        if (!pdfDocument) {
            actions.getPDFDocumentDecoded({ documentId });
        }
    };

    getData = ({ documentId, documents, pdfDocuments }) => ({
        document: documents[documentId],
        pdfDocument: pdfDocuments[documentId]
    });

    render() {
        const { busy } = this.state;
        const { t, classes, actions, fileStorage } = this.props;
        const { document, pdfDocument } = this.getData(this.props);

        if (!document || !pdfDocument) {
            return (<Preloader />);
        }

        const { attachments, fileName, asic } = document;

        if (pdfDocument instanceof Error) {
            return (
                <div className={classes.errorWrapper}>
                    <WarningIcon style={{ color: '#d32f2f' }} />
                    <Typography variant={'body2'}>{t('FileLoadingError')}</Typography>
                </div>
            );
        }

        return (
            <>
                <FilePreview file={pdfDocument} fileName={fileName} fileType={'pdf'} />
                {
                    asic.asicmanifestFileId ? (
                        <Toolbar className={classes.toolbar}>
                            <Button
                                disabled={busy}
                                size="large"
                                color="primary"
                                variant="contained"
                                className={classes.leftIcon}
                                onClick={this.handleDownloadAsicContainer}
                            >
                                <SaveIcon className={classes.leftIcon} />
                                {t('DownloadAsic')}
                            </Button>
                            <Button
                                size="large"
                                onClick={() => {
                                    const pdfBlob = base64ToBlob(pdfDocument.split(',').pop());
                                    const url = URL.createObjectURL(pdfBlob);
                                    printJS(url);
                                }}
                                variant="contained"
                                color="primary"
                                className={[classes.printButton, classes.leftIcon]}
                                id="print-btn"
                            >
                                <PrintIcon className={classes.leftIcon} />
                                {t('PrintBtn')}
                            </Button>
                            <Button
                                size="large"
                                onClick={() => downloadBase64Attach({ fileName }, pdfDocument)}
                                variant="contained"
                                color="primary"
                                id="save-btn"
                            >
                                <SaveAltIcon className={classes.leftIcon} />
                                {t('SaveBtn')}
                            </Button>
                        </Toolbar>
                    ) : null
                }
                {
                    (attachments || []).length ? (
                        <FileDataTable
                            data={attachments}
                            fileStorage={fileStorage}
                            groupBy="labels"
                            actions={
                                {
                                    handleDownloadFile: actions.downloadDocumentAttach
                                }
                            }
                        />
                    ) : null
                }
            </>
        );
    }
}

InboxFileDetails.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    fileStorage: PropTypes.object,
    documentId: PropTypes.string.isRequired,
    name: PropTypes.string
};

InboxFileDetails.defaultProps = {
    fileStorage: {},
    name: ''
};

const mapStateToProps = ({
    task: { documents },
    files: { list, pdfDocuments }
}) => ({ documents, fileStorage: list, pdfDocuments });

const mapDispatchToProps = dispatch => ({
    actions: {
        getPDFDocumentDecoded: bindActionCreators(getPDFDocumentDecoded, dispatch),
        loadTaskDocument: bindActionCreators(loadTaskDocument, dispatch),
        downloadDocumentAttach: bindActionCreators(downloadDocumentAttach, dispatch),
        downloadDocumentAsicContainer: bindActionCreators(downloadDocumentAsicContainer, dispatch)
    }
});

const styled = withStyles(styles)(InboxFileDetails);
const translated = translate('InboxFilesPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

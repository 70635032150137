import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';

import {
    getWorkflowVersions,
    createWorkflowVersion,
    restoreWorkflowVersion,
    requestWorkflow
} from 'application/adminpanel/actions/workflow';

import { addError } from 'actions/error';
import { addMessage } from 'actions/error';
import Message from 'components/Snackbars/Message';

import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles,
    DialogActions
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = {
    formControl: {
        marginBottom: 10
    },
    version: {
        marginRight: 10
    }
};

class WorkflowVersions extends React.Component {
    state = {
        openRestoreWorkflowDialog: false,
        workflowVersion: null
    };

    componentDidMount() {
        this.handleGetWorkflowVersions();
    }

    handleGetWorkflowVersions = async () => {
        const { workflowActions, workflowId } = this.props;

        const result = await workflowActions.getWorkflowVersions(workflowId);

        if (result instanceof Error) {
            workflowActions.addError(new Error('FailGettingWorkflowVersions'));
            return;
        }

        return result;
    }

    handleCreateWorkflowVersion = async () => {
        const { workflowActions, workflowId } = this.props;
        
        const result = await workflowActions.createWorkflowVersion(workflowId);

        if (result instanceof Error) {
            workflowActions.addError(new Error('FailCreatingWorkflowVersion'));
            return;
        }

        workflowActions.addMessage(new Message('CreatingVersionSuccess', 'success'));
        this.handleGetWorkflowVersions();
        window.location.reload();
    }

    handleChangeVersion = ({ target: { value: workflowVersion } }) => this.setState({ workflowVersion });

    handleRestoreWorkflowVersion = async () => {
        const { workflowActions, workflowId } = this.props;
        const { workflowVersion } = this.state;

        const result = await workflowActions.restoreWorkflowVersion(workflowId, workflowVersion);

        if (result instanceof Error) {
            workflowActions.addError(new Error('FailRestoringWorkflowVersion'));
            return;
        }

        this.setState({ openRestoreWorkflowDialog: false });
        this.handleGetWorkflowVersions();
        await workflowActions.requestWorkflow(workflowId);
        workflowActions.addMessage(new Message('RestoringVersionSuccess', 'success'));
        window.location.reload();
    }

    render() {
        const { t, classes, workflowVersions } = this.props;
        const { openRestoreWorkflowDialog, workflowVersion } = this.state;
        const currentVersion = workflowVersions && workflowVersions.find(({ isCurrentVersion }) => isCurrentVersion);

        return (
            <div>
                {currentVersion ? (
                    <Button
                        variant="outlined"
                        className={classes.version}
                        onClick={() => this.setState({ openRestoreWorkflowDialog: true })}
                    >
                        {t('CurrentVersion', { version: currentVersion.version })}
                        <ArrowDropDownIcon />
                    </Button>
                ) : null}
                <Button variant="outlined" onClick={this.handleCreateWorkflowVersion}>
                    {t('CreateWorkflowVersion')}
                </Button>

                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    onClose={() => this.setState({ openRestoreWorkflowDialog: false })}
                    open={openRestoreWorkflowDialog}
                >
                    <DialogTitle>{t('RestoreWorkflowVersion')}</DialogTitle>
                    <DialogContent>

                        {(workflowVersions && workflowVersions.length > 1) ? (
                            <FormControl className={classes.formControl} fullWidth={true}>
                                <InputLabel htmlFor="version-select">{t('Version')}</InputLabel>
                                <Select
                                    id="version-select"
                                    value={workflowVersion || currentVersion.version}
                                    onChange={this.handleChangeVersion}
                                >
                                    {workflowVersions.map(({ version }) => (
                                        <MenuItem key={version} value={version}>{version}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : null}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!workflowVersion || (!currentVersion && (workflowVersions || []).length > 1)}
                            onClick={this.handleRestoreWorkflowVersion}
                        >
                            {t('Restore')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }


}

const mapStateToProps = ({
    workflow: { workflowVersions }
}) => ({
    workflowVersions
});
const mapDispatchToProps = dispatch => ({
    workflowActions: {
        addError: bindActionCreators(addError, dispatch),
        getWorkflowVersions: bindActionCreators(getWorkflowVersions, dispatch),
        createWorkflowVersion: bindActionCreators(createWorkflowVersion, dispatch),
        restoreWorkflowVersion: bindActionCreators(restoreWorkflowVersion, dispatch),
        addMessage: bindActionCreators(addMessage, dispatch),
        requestWorkflow: bindActionCreators(requestWorkflow, dispatch)
    }
});

const styled = withStyles(styles)(WorkflowVersions);
const translated = translate('WorkflowAdminPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
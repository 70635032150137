
export default [
    {
        id: 1,
        name: 'doing'
    },
    {
        id: 2,
        name: 'done'
    },
    {
        id: 3,
        name: 'rejected'
    },
    {
        id: 'is_draft',
        name: 'isDraft'
    }
];

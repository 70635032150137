import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    withStyles,
    Button
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import NavigateNextIcon from '@material-ui/icons/NavigateNextRounded';

const styles = {
    icon: {
        fontSize: 82,
        color: 'green'
    },
    title: {
        textAlign: 'center'
    },
    button: {
        textDecoration: 'none'
    },
    actionButton: {
        marginRight: 10
    }

};

const SuccessMessageLayout = ({ t, classes, finalScreen, nextTasks, rootPath }) => (
    <Fragment>
        <DialogTitle className={classes.title}><CheckRoundedIcon className={classes.icon} /></DialogTitle>
        <DialogTitle className={classes.title}>{finalScreen.title || t('SuccessCommitMessageHeader')}</DialogTitle>
        <DialogContent className={classes.title}>
            <DialogContentText>
                {finalScreen.subtitle || t('SuccessCommitMessageText')}
            </DialogContentText>
        </DialogContent>
        {nextTasks && Array.isArray(nextTasks) ? nextTasks.slice(0, 1).map((nextTask, key) => (
            <DialogContent className={classes.title} key={key}>
                <Link to={`${rootPath}/${nextTask.id}`} className={classes.button}>
                    <Button variant="contained" color="primary" size="large">
                        {t('GoToNextTask')}
                        <NavigateNextIcon />
                    </Button>
                </Link>
            </DialogContent>
        )) : null}
        {finalScreen.actions && Array.isArray(finalScreen.actions) ? (
            <DialogContent className={classes.title}>
                {finalScreen.actions.map(({ title, link, variant, color }, key) => (
                    <a key={key} href={link} className={classes.button}>
                        <Button variant={variant} color={color} size="large" className={classes.actionButton}>
                            {title}
                        </Button>
                    </a>
                ))}
            </DialogContent>
        ) : null}
    </Fragment>
);

SuccessMessageLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    finalScreen: PropTypes.object,
    nextTasks: PropTypes.array
};

SuccessMessageLayout.defaultProps = {
    finalScreen: {},
    nextTasks: null
};

const translated = translate('TaskPage')(SuccessMessageLayout);
export default withStyles(styles)(translated);

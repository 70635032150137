import React from 'react';
import PropTypes from 'prop-types';

import {
    withStyles,
    ClickAwayListener
} from '@material-ui/core';

import InputComponent from 'components/DataTable/components/SearchInput/InputComponent';
import FilterChips from 'components/DataTable/components/SearchInput/FilterChips';
import FilterHandlers from 'components/DataTable/components/SearchInput/FilterHandlers';


import waiter from 'helpers/waitForAction';

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        marginLeft: 10
    },
    searchIcon: {
        padding: '0 8px'
    }
};

const SEARCH_INTERVAL = 1000;

class SearchInput extends React.Component {
    state = { anchorEl: null, activeFilter: null };

    handleChange = ({ target: { value } }) => {
        const { actions } = this.props;

        actions.onSearchChange && actions.onSearchChange(value, false);
        waiter.addAction('dataTableSearch', actions.load, SEARCH_INTERVAL);
    }

    handleClose = () => this.setState({ anchorEl: null, activeFilter: null });

    render() {
        const { anchorEl, activeFilter } = this.state;
        const { classes, search, filters, filterHandlers, actions } = this.props;

        return (
            <ClickAwayListener onClickAway={this.handleClose} >
                <div
                    className={classes.root}
                    ref={(ref) => {
                        this.rootRef = ref;
                    }}
                >
                    <FilterChips
                        filters={filters}
                        onClose={this.handleClose}
                        filterHandlers={filterHandlers}
                        onFilterChange={actions.onFilterChange}
                        onClick={(currentTarget, filterName) => this.setState({ anchorEl: currentTarget, activeFilter: filterName })}
                    />
                    <InputComponent
                        value={search}
                        onChange={this.handleChange}
                        onKeyPress={({ key }) => key === 'Enter' && actions.load()}
                        onFocus={({ currentTarget }) => this.setState({ anchorEl: currentTarget, activeFilter: null })}
                    />
                    <FilterHandlers
                        filters={filters}
                        filterHandlers={filterHandlers}
                        onFilterChange={actions.onFilterChange}
                        onClose={this.handleClose}
                        anchorEl={anchorEl}
                        rootRef={this.rootRef}
                        activeFilter={activeFilter}
                        setActiveFilter={filterName => this.setState({ activeFilter: filterName })}
                    />
                </div>
            </ClickAwayListener>
        );
    }
}

SearchInput.propTypes = {
    classes: PropTypes.object.isRequired,
    search: PropTypes.string,
    actions: PropTypes.object,
    filters: PropTypes.object,
    filterHandlers: PropTypes.object
};

SearchInput.defaultProps = {
    search: '',
    actions: {},
    filters: {},
    filterHandlers: {}
};

export default withStyles(styles)(SearchInput);

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import _ from 'lodash/fp';
import { history } from 'store';
import checkAccess from 'helpers/checkAccess';
import { Button } from '@material-ui/core';
import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import endPoint from 'application/adminpanel/endPoints/units';
import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';
import DataTable from 'components/DataTable';
import dataTableSettings from './variables/dataTableSettings';
import ImportUnits from './components/ImportUnits';
import ExportUnits from './components/ExportUnits';
import DeleteUnits from './components/DeleteUnits';

class UnitsListPage extends ModulePage {
    componentDidMount() {
        super.componentDidMount();
        const { actions } = this.props;
        actions.load();
    }

    renderNewUnitButton = () => {
        const {
            t,
            rowsSelected,
            actions,
            userUnits,
            userInfo
        } = this.props;

        const hasAccess =  checkAccess({ unitHasAccessTo: 'navigation.users.editable' }, userInfo || {}, userUnits || {});

        if (!hasAccess) return null;

        return (
            <>
                <Button variant="contained" color="primary" onClick={() => history.push('/users/units/new')}>
                    {t('CreateNewUnit')}
                </Button>
                <ImportUnits unitActions={actions} />
                {(rowsSelected || []).length ? <ExportUnits {...this.props} /> : null}
                {(rowsSelected || []).length ? <DeleteUnits {...this.props} unitActions={actions} /> : null}
            </>
        );
    };

    render = () => {
        const {
            t,
            title,
            loading,
            location
        } = this.props;

        const settings = dataTableSettings({ t });

        return (
            <LeftSidebarLayout location={location} title={t(title)} loading={loading}>
                <DataTable
                    {..._.merge(settings, dataTableAdapter(this.props, settings))}
                    onRowClick={({ id }) => history.push(`/users/units/${id}`)}
                    CustomToolbar={this.renderNewUnitButton}
                />
            </LeftSidebarLayout>
        );
    }
}

const translated = translate('UnitsListPage')(UnitsListPage);
const mapStateToProps = ({ auth: { userUnits, info } }) => ({ userUnits, userInfo: info });
const conected = dataTableConnect(endPoint)(translated);
export default connect(mapStateToProps, null)(conected);

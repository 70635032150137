import React from 'react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import {
    Card,
    CardHeader,
    IconButton,
    withStyles
} from '@material-ui/core';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { withEditor } from 'components/JsonSchema/editor/JsonSchemaProvider';

import PropertyList from './PropertyList';
import ElementIdDialog from './ElementIdDialog';
import ExistedElementDialog from './ExistedElementDialog';

const styles = {
    root: {
        margin: 4,
        borderColor: '#aaaaaa',
        overflowWrap: 'anywhere'
    },
    selected: {
        borderColor: '#6c6c9e',
        background: '#e8e8ff'
    },
    isOver: {
        background: 'darkgreen'
    }
};

const SchemaItem = ({
    path,
    value,
    classes,
    selection,
    elementType,
    setSelection,
    createElementAt,
    deleteElementAt,
    isElementExists
}) => {
    const [newElement, setNewElement] = React.useState(null);
    const [existed, setExisted] = React.useState(null);

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: elementType.accept || 'Element',
        canDrop: () => elementType.isContainer || value.properties,
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop()
        }),
        drop(item, monitor) {
            const didDrop = monitor.didDrop();
            if (didDrop || !monitor.isOver({ shallow: true })) {
                return;
            }
            // debugger;
            setNewElement(item);
        }
    });

    const selected = selection.join() === path.join();

    const createNewElement = (elementId) => {
        createElementAt(newElement, path, elementId);
        setNewElement(null);
    };

    return (
        <>
            <Card
                ref={drop}
                variant="outlined"
                onClick={(e) => { e.stopPropagation(); setSelection(path); }}
                className={classNames(classes.root, {
                    [classes.selected]: selected,
                    [classes.isOver]: isOver && canDrop
                })}
            >
                <CardHeader
                    avatar={elementType.Icon ? <elementType.Icon /> : null}
                    action={path.length ? (
                        <IconButton onClick={(e) => { e.stopPropagation(); deleteElementAt(path); }}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    ) : null}
                    title={value.description || path[path.length - 1] || 'root'}
                    subheader={path.join('.')}
                />
                <PropertyList
                    path={path}
                    value={value}
                    elementType={elementType}
                    isOver={isOver}
                    canDrop={canDrop}
                />
            </Card>
            <ElementIdDialog
                open={!!newElement}
                onClose={() => setNewElement(null)}
                onSave={(elementId) => {
                    if (isElementExists(elementId, path)) {
                        return setExisted(elementId);
                    }

                    createNewElement(elementId);
                }}
            />
            <ExistedElementDialog
                open={!!existed}
                onClose={() => setExisted(null)}
                onSave={() => { createNewElement(existed); setExisted(null); }}
            />
        </>
    );
};

const styled = withStyles(styles)(SchemaItem);
export default withEditor(styled);

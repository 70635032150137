import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { withStyles, TablePagination } from '@material-ui/core';

const styles = {
    root: {
        padding: 0
    },
    toolbar: {
        height: 'auto',
        minHeight: 'auto',
        padding: '0px 4px'
    }
};

const SelectPagination = ({ classes, count, limit, offset, onChangePage }) => {
    const page = Math.ceil(offset / limit);

    return (
        <TablePagination
            classes={classes}
            count={count}
            onChangePage={onChangePage}
            rowsPerPage={limit}
            rowsPerPageOptions={[]}
            labelDisplayedRows={() => null}
            page={page}
        />
    );
};

SelectPagination.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number,
    limit: PropTypes.number,
    offset: PropTypes.number,
    onChangePage: PropTypes.func
};

SelectPagination.defaultProps = {
    count: 0,
    limit: 10,
    offset: 0,
    onChangePage: () => null
};

const styled = withStyles(styles)(SelectPagination);
export default translate('SelectPagination')(styled);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { history } from 'store';
import { Link } from 'react-router-dom';
import {
    Button,
    withStyles,
    Toolbar,
    Chip,
    Tooltip,
    FormGroup,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
    Popover,
    MenuList,
    MenuItem,
    Paper,
    ClickAwayListener
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import ErrorScreen from 'components/ErrorScreen';
import DataTable from 'components/DataTable';
import ConfirmDialog from 'components/ConfirmDialog';

import checkAccess from 'helpers/checkAccess';

import dataTableSettings from 'application/adminpanel/modules/processes/pages/Processes/variables/dataTableSettings';

import { requestWorkflowProcess, restartProcess, checkAsNotError } from 'application/adminpanel/actions/workflowProcess';
import { requestWorkflowProcessLogs } from 'application/adminpanel/actions/workflowProcessLogs';

const styles = {
    toolbar: {
        padding: '0 4px'
    },
    flexGrow: {
        flexGrow: 1
    },
    toggle: {
        paddingTop: 20
    },
    search: {
        marginLeft: 15,
        marginTop: -10,
        width: 400
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    item: {
        color: 'inherit',
        display: 'inline'
    },
    dropDownIcon: {
        display: 'inline-block',
        position: 'relative',
        top: 7
    }
};

const colors = {
    1: '#3a9ae6',
    2: '#60b52a',
    3: '#bf3229',
    null: '#848788'
};

class ProcessesListPage extends ModulePage {
    state = { error: null, checked: false, search: '', anchorEl: null, showDialog: false };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        const { match: { params: { processId: oldProcessId } } } = prevProps;
        const { match: { params: { processId: newProcessId } } } = this.props;
        if (newProcessId !== oldProcessId) {
            this.init();
        }
    }

    init = async () => {
        const {
            actions,
            workflowProcess,
            workflowProcessLogs,
            match: { params: { processId } }
        } = this.props;

        if (!workflowProcess[processId]) {
            const process = await actions.requestWorkflowProcess(processId);

            if (process instanceof Error) {
                this.setState({ error: process });
            }
        }

        if (!workflowProcessLogs[processId]) {
            const logs = await actions.requestWorkflowProcessLogs(processId);

            if (logs instanceof Error) {
                this.setState({ error: logs });
            }
        }
    }

    handleRestartProcess = async () => {
        const {
            actions,
            match: { params: { processId } }
        } = this.props;

        this.handleMenuClose();
        await actions.restartProcess(processId);
        actions.requestWorkflowProcessLogs(processId);
        window.location.reload();
    };

    handleCheckAsNotError = async () => {
        const {
            actions,
            match: { params: { processId } }
        } = this.props;

        this.handleMenuClose();
        this.setState({ showDialog: false });
        const result = await actions.checkAsNotError(processId);
        if (!(result instanceof Error)) {
            actions.requestWorkflowProcessLogs(processId);
            window.location.reload();
        }
    };

    componentGetTitle() {
        const {
            t,
            workflowProcess,
            match: { params: { processId } }
        } = this.props;

        if (!workflowProcess[processId]) {
            return t('Loading');
        }

        //  const proc = workflowProcess[processId];

        //  return t('ProcessListPageTitle', { number: proc.number, name: proc.workflowTemplate.name });
        return t('ProcessTitle');
    }

    handleChange = (event) => {
        const checked = event && event.target && event.target.checked;
        this.setState({ checked });
    };

    handleSearch = ({ target: { value } }) => this.setState({ search: value });

    isEditable = () => {
        const { userUnits, userInfo } = this.props;
        return checkAccess({ unitHasAccessTo: 'navigation.process.editable' }, userInfo || {}, userUnits || {});
    }

    handleMenuOpen = ({ currentTarget }) => this.setState({ anchorEl: currentTarget });

    handleMenuClose = () => this.setState({ anchorEl: null });

    renderContent() {
        const {
            t,
            classes,
            workflowProcess,
            workflowProcessLogs,
            match: { params: { processId } }
        } = this.props;

        const { error, checked, search, anchorEl, showDialog } = this.state;

        const dataWithoutMessages = workflowProcessLogs[processId] && workflowProcessLogs[processId].filter(({ type }) => type !== 'workflow_incoming_message' && type !== 'workflow_outgoing_message');
        const tableData = checked ? workflowProcessLogs[processId] : dataWithoutMessages;

        const proc = workflowProcess[processId];

        const statusLabels = {
            1: t('Doing'),
            2: t('Done'),
            3: t('Rejected'),
            null: t('NoStatus')
        };

        if (error) {
            return <ErrorScreen error={error} />;
        }

        const editable = this.isEditable();

        return (
            <>
                <Toolbar className={classes.toolbar}>
                    <Button onClick={() => history.push('/process')}>
                        <ArrowBackIcon />
                        {t('BackButton')}
                    </Button>
                    {
                        proc && proc.workflowStatusId ? (
                            <Chip
                                style={{ cursor: 'inherit', backgroundColor: colors[proc.workflowStatusId], color: 'white', margin: '0 10px' }}
                                label={statusLabels[proc.workflowStatusId]}
                            />
                        ) : null
                    }
                    {
                        proc && proc.hasUnresolvedErrors ? (
                            <Chip
                                style={{ cursor: 'inherit', backgroundColor: '#bf3229', color: 'white' }}
                                label={t('HasError')}
                            />
                        ) : null
                    }
                    <div className={classes.flexGrow} />
                    {
                        editable && proc && proc.hasUnresolvedErrors ? (
                            <div className={classes.root}>
                                <Link to="#" className={classes.link} onClick={this.handleMenuOpen}>
                                    <Typography variant="body1" className={classes.item}>
                                        {t('ActionsWithProcess')}
                                    </Typography>
                                    <div className={classes.dropDownIcon}>
                                        {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </div>
                                </Link>
                                <Popover
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    onClose={this.handleMenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                >
                                    <ClickAwayListener onClickAway={this.handleMenuClose}>
                                        <Paper>
                                            <MenuList classes={{ root: classes.menuListRoot }}>
                                                {
                                                    editable ? (
                                                        <MenuItem
                                                            onClick={this.handleRestartProcess}
                                                        >
                                                            <AutorenewIcon />
                                                            {t('RestartProcessButton')}
                                                        </MenuItem>
                                                    ) : null
                                                }
                                                <MenuItem
                                                    onClick={() => this.setState({ showDialog: true })}
                                                >
                                                    <ArrowBackIcon />
                                                    {t('CheckAsNotError')}
                                                </MenuItem>
                                            </MenuList>
                                        </Paper>
                                    </ClickAwayListener>
                                </Popover>
                            </div>
                        ) : null}
                    <ConfirmDialog
                        open={showDialog}
                        handleClose={() => this.setState({ showDialog: false })}
                        handleConfirm={this.handleCheckAsNotError}
                        title={t('CheckAsNotErrorTitle')}
                        description={t('CheckAsNotErrorDescription')}
                    />
                </Toolbar>
                <Toolbar className={classes.toolbar}>
                    <TextField
                        className={classes.search}
                        onChange={this.handleSearch}
                        value={search}
                        label={t('SearchLabel')}
                        variant="standard"
                        type="search"
                    />
                    <div className={classes.flexGrow} />
                    {
                        editable ? (
                            <Tooltip title={t('ShowMessagesTooltip')} >
                                <FormGroup row={true}>
                                    <FormControlLabel
                                        control={
                                            (
                                                <Switch
                                                    checked={checked}
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            )
                                        }
                                        label={t('ShowMessages')}
                                    />
                                </FormGroup>
                            </Tooltip>
                        ) : null
                    }
                </Toolbar>
                <DataTable
                    {...dataTableSettings({ t, processId, editable, search })}
                    data={tableData}
                />
            </>
        );
    }

    render() {
        const { loading, location, t, workflowProcess, match: { params: { processId } } } = this.props;

        const proc = workflowProcess[processId];

        return (
            <LeftSidebarLayout
                location={location}
                title={t('ProcessListPageTitle', { number: proc && proc.number, name: proc && proc.workflowTemplate.name })}
                loading={loading}
            >
                {this.renderContent()}
            </LeftSidebarLayout>
        );
    }
}

const mapStateToProps = ({
    workflowProcess: { list },
    workflowProcessLogs,
    auth: { userUnits, info }
}) => ({
    workflowProcess: list,
    workflowProcessLogs,
    userUnits,
    userInfo: info
});

const mapDispatchToProps = dispatch => ({
    actions: {
        restartProcess: bindActionCreators(restartProcess, dispatch),
        requestWorkflowProcess: bindActionCreators(requestWorkflowProcess, dispatch),
        requestWorkflowProcessLogs: bindActionCreators(requestWorkflowProcessLogs, dispatch),
        checkAsNotError: bindActionCreators(checkAsNotError, dispatch)
    }
});

const styled = withStyles(styles)(ProcessesListPage);
const translated = translate('ProcessesListPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

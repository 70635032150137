import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    withStyles,
    Divider
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

const styles = {
    formControl: {
        padding: '0 0 10px'
    }
};

const SelectComponent = ({ t, classes, path, description, value, onChange, options, autoFocus, hidden, width }) => {
    if (hidden) return null;

    return (
        <FormControl fullWidth={true} className={classes.formControl} style={{ width }}>
            {description ? <InputLabel htmlFor={path.join('-')}>{description}</InputLabel> : null}
            <Select
                autoFocus={autoFocus}
                value={value || ''}
                onChange={({ target: { value: newValue } }) => onChange && onChange(newValue)}
                inputProps={{ id: path.join('-') }}
            >
                {value ? (
                    <MenuItem value={null}>
                        <ClearIcon />
                        {t('Clear')}
                    </MenuItem>
                ) : null}

                {value ? <Divider light={true} component="li" /> : null}

                {(!options || !options.length) ? (
                    <MenuItem value={null}>{t('EmptyData')}</MenuItem>
                ) : null}

                {options.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

SelectComponent.propTypes = {
    path: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    options: PropTypes.array
};

SelectComponent.defaultProps = {
    path: [],
    value: null,
    onChange: () => null,
    options: []
};

const styled = withStyles(styles)(SelectComponent);
export default translate('Elements')(styled);

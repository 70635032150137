import React from 'react';
import { translate } from 'react-translate';
import classNames from 'classnames';
import {
    Button,
    DialogActions,
    ListItem,
    ListItemIcon,
    ListItemText,
    CircularProgress,
    Popover,
    TextField,
    DialogTitle,
    DialogContent,
    withStyles,
    IconButton,
    Paper,
    ClickAwayListener,
    Fade
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import { deepObjectFindAll } from 'helpers/deepObjectFind';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StringElement from 'components/JsonSchema/elements/StringElement';
import TreeList from 'components/TreeList/index';
import { styles as treeListItemStyles } from 'components/TreeList/TreeListItem';

const styles = theme => ({
    ...treeListItemStyles(theme),
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
    dialogTitle: {
        padding: 0
    },
    dialogContent: {
        padding: 0
    },
    clearButton: {
        padding: 0
    },
    popoverMobile: {
        width: '100%',
        maxHeight: 300,
        overflow: 'hidden',
        overflowY: 'scroll'
    }
});

const relevancySort = (search = '') => (a, b) => {
    let aIndexOf = (a.name || '').toLowerCase().indexOf(search.toLowerCase());
    let bIndexOf = (b.name || '').toLowerCase().indexOf(search.toLowerCase());

    if (aIndexOf < 0 && bIndexOf < 0) {
        return (a.name || '').localeCompare(b.name || '');
    }

    if (aIndexOf < 0) {
        aIndexOf = 100;
    }

    if (bIndexOf < 0) {
        bIndexOf = 100;
    }

    return aIndexOf > bIndexOf ? 1 : -1;
};

class TreeListSelect extends React.Component {
    constructor(props) {
        super(props);
        this.popoverTarget = React.createRef();
        this.state = { anchorEl: null, open: false, search: '', items: this.props.items };
    }

    componentWillReceiveProps(nextProps) {
        this.updateItems(nextProps.items);
    }

    handleMenuOpen = ({ currentTarget }) => this.setState({ anchorEl: currentTarget, open: true });

    handleMenuClose = () => this.setState({ open: false, search: '' });

    handleSelect = (selected) => {
        const { onSelect } = this.props;
        onSelect && onSelect(selected);
        this.handleMenuClose();
    }

    handleClearValue = () => {
        const { onSelect } = this.props;
        onSelect && onSelect(null);
    }

    trySelect = () => {
        const { onSelect } = this.props;
        const { items } = this.state;

        if (items && items.length === 1 && onSelect) {
            onSelect(items.shift());
            this.handleMenuClose();
        }
    }

    handleSearch = ({ target: { value } }) => this.setState({ search: value }, this.updateItems);

    handleSearchRegister = value => this.setState({ search: value }, this.updateItems);

    updateItems = (items) => {
        const { search } = this.state;

        const treeItems = items || this.props.items;

        if (!search) {
            this.setState({ items: treeItems }, this.updatePosition);
            return;
        }

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        this.setState({
            items: deepObjectFindAll(treeItems || {}, item => item && !item.items && item.name && regex.test(item.name))
                .sort(relevancySort(search))
                .slice(0, 100)
        }, this.updatePosition);
    }

    renderButton() {
        const { open } = this.state;
        const { t, classes, selected, placeholder, error, items, disabled, id } = this.props;

        let icon = (!selected || selected.items) ? <FolderIcon /> : <InsertDriveFile />;
        let text = selected ? selected.name : (placeholder || t('Select'));

        if (!items) {
            icon = <CircularProgress size={20} />;
            text = text || t('Loading');
        }

        return (
            <ListItem
                button={true}
                disabled={disabled}
                onClick={this.handleMenuOpen}
                className={classNames(classes.item, { [classes.error]: error })}
                id={id + '-select-button'}
            >
                <ListItemIcon className={classes.mobileIcon}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
        );
    }

    renderSelected = () => {
        const { selected, classes, id } = this.props;

        return (
            <ListItem className={classes.item}>
                <ListItemIcon className={classes.mobileIcon}>
                    <CheckRoundedIcon />
                </ListItemIcon>
                <ListItemText inset={true} primary={selected.name} />
                <IconButton
                    id={id + '-remove-button'}
                    className={classes.clearButton}
                    onClick={this.handleClearValue}
                >
                    <ClearIcon />
                </IconButton>
            </ListItem>
        );
    }

    renderDefaultContent = () => {
        const { anchorEl, open, search, items } = this.state;
        const { t, createLink, classes, selected, id } = this.props;

        return (
            <>
                {this.renderButton()}
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleMenuClose}
                    classes={{
                        paper: classes.paper
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={(actions) => {
                        this.updatePosition = actions && actions.updatePosition;
                    }}
                    PaperProps={{
                        style: {
                            width: (anchorEl || {}).offsetWidth,
                            padding: '2px 8px'
                        }
                    }}
                >
                    <DialogTitle
                        id="dialog-title"
                        className={classes.dialogTitle}
                    >
                        <TextField
                            id={id + '-search-input'}
                            label={t('Search')}
                            fullWidth={true}
                            type="search"
                            margin="dense"
                            variant="outlined"
                            value={search}
                            onChange={this.handleSearch}
                            onKeyPress={({ key }) => key === 'Enter' && this.trySelect()}
                            autoComplete="off"
                            inputRef={input => input && input.focus()}
                        />
                    </DialogTitle>
                    <DialogContent
                        className={classes.dialogContent}
                    >
                        {selected && this.renderSelected()}
                        <TreeList
                            id={id + '-tree-list'}
                            items={items}
                            onChange={this.handleSelect}
                            createLink={createLink}
                            onMenuOpen={this.updatePosition}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            size="small"
                            variant="text"
                            color="primary"
                            onClick={() => this.setState({ open: false })}
                        >
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Popover>
            </>
        );
    };

    renderRegisterSelect = () => {
        const { search, open, items } = this.state;
        const { classes, description, id, createLink, loading, required, disabled } = this.props;
        const { current } = this.popoverTarget;

        const isMobile = window.innerWidth < 500;
        const isTyping = search.length;
        const isOpen = open || isTyping;

        return (
            <div ref={this.popoverTarget}>
                <StringElement
                    description={description}
                    value={search}
                    fullWidth={true}
                    onChange={this.handleSearchRegister}
                    required={required}
                    onKeyDown={this.updatePosition}
                    readOnly={disabled}
                    endAdornment={
                        (
                            <>
                                {
                                    loading
                                        ? <CircularProgress size={20}/>
                                        : (
                                            <IconButton
                                                classes={{ root: classes.iconBtnRoot }}
                                                onClick={this.handleMenuOpen}
                                                disabled={disabled}
                                            >
                                                {
                                                    search.length
                                                        ? <ArrowForwardIcon />
                                                        : <AddCircleOutlineIcon />
                                                }
                                            </IconButton>
                                        )
                                }
                            </>
                        )
                    }
                />
                {
                    isMobile && isTyping ? (
                        <>
                            {
                                isOpen ? (
                                    <ClickAwayListener
                                        onClickAway={() => this.handleMenuClose()}
                                    >
                                        <Fade in={isOpen}>
                                            <Paper
                                                className={classes.popoverMobile}
                                            >
                                                <TreeList
                                                    id={`${id}-tree-list`}
                                                    items={items || []}
                                                    onChange={this.handleSelect}
                                                    onMenuOpen={this.updatePosition}
                                                    createLink={createLink}
                                                    registerSelect={true}
                                                    loading={loading}
                                                />
                                            </Paper>
                                        </Fade>
                                    </ClickAwayListener>
                                ) : null
                            }
                        </>
                    ) : (
                        <Popover
                            anchorEl={current}
                            open={isOpen}
                            disableAutoFocus={true}
                            disableEnforceFocus={true}
                            canAutoPosition={false}
                            action={
                                (actions) => {
                                    if (actions && actions.updatePosition) {
                                        this.updatePosition = actions.updatePosition;
                                    }
                                }
                            }
                            onClose={
                                () => {
                                    this.handleMenuClose();
                                    this.updatePosition && this.updatePosition();
                                }
                            }
                            anchorOrigin={
                                {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }
                            }
                            transformOrigin={
                                {
                                    vertical: 'top',
                                    horizontal: 'left'
                                }
                            }
                            PaperProps={
                                {
                                    style: {
                                        width: current ? current.offsetWidth : '100%',
                                        maxHeight: 320,
                                        marginTop: 2,
                                        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)'
                                    }
                                }
                            }
                        >
                            <TreeList
                                id={`${id}-tree-list`}
                                items={items || []}
                                onChange={this.handleSelect}
                                onMenuOpen={this.updatePosition}
                                createLink={createLink}
                                registerSelect={true}
                                loading={loading}
                            />
                        </Popover>
                    )
                }
            </div>
        );
    };

    render() {
        const { registerSelect } = this.props;
        return registerSelect ? this.renderRegisterSelect() : this.renderDefaultContent();
    }
}

const translated = translate('TreeListSelect')(TreeListSelect);
export default withStyles(styles)(translated);

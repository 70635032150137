import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import objectPath from 'object-path';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash/fp';

import {
    withStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent
} from '@material-ui/core';

import CloseIcon from 'assets/img/ic_close_big.svg';

import { SchemaForm, handleChangeAdapter, validateData } from 'components/JsonSchema';

import { setPopupData } from 'actions/debugTools';

const styles = theme => ({
    contentRoot: {
        overflowY: 'visible',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 16,
            paddingRight: 16
        }
    },
    paperWidthSm: {
        padding: 56,
        paddingBottom: 80,
        maxWidth: 800,
        minWidth: 800,
        overflow: 'unset',
        maxHeight: 'unset',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
            margin: '40px auto!important',
            width: '95%',
            maxWidth: 'unset',
            minWidth: 'unset',
            paddingTop: 35
        }
    },
    paperScrollBody: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(100% - 32px)!important',
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingTop: 40
        }
    },
    dialogRoot: {
        position: 'absolute!important',
        '& > div:first-child': {
            position: 'absolute!important'
        }
    },
    dialogActions: {
        justifyContent: 'start',
        marginTop: 20,
        paddingLeft: 24,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
            padding: 0,
            paddingLeft: 16
        }
    },
    closeIcon: {
        position: 'absolute',
        top: 42,
        right: 42,
        fontSize: 50,
        padding: 6,
        minWidth: 40,
        [theme.breakpoints.down('sm')]: {
            top: 7,
            right: 10
        }
    },
    closeIconImg: {
        width: 37,
        height: 37,
        [theme.breakpoints.down('sm')]: {
            width: 25,
            height: 25
        }
    },
    actionButton: {
        margin: 0,
        marginRight: 15
    },
    treeSelectData: {
        display: 'inline-block',
        padding: '10px 17px',
        borderRadius: 50,
        backgroundColor: '#F1F1F1',
        marginBottom: 15
    },
    dialogTitleRoot: {
        marginBottom: 20,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            paddingLeft: 24
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 16,
            paddingRight: 16
        },
        '&>h6': {
            fontSize: '2.125rem',
            lineHeight: '1.17',
            [theme.breakpoints.down('sm')]: {
                fontSize: '26px'
            }
        }
    },
    dialogDescRoot: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 2,
            '&>h2': {
                fontSize: 14
            }
        }
    },
    btnpadding: {
        padding: '14px 36px!important'
    }
});

class DialogWrapper extends React.Component {
    constructor(props) {
        super(props);
        const {
            rootDocument,
            rootValue
        } = this.props;

        this.state = {
            rootDocument: rootValue ? { data: rootValue } : rootDocument,
            errors: []
        };
    }

    componentDidMount = () => {
        this.updateDebugInfo();
    };

    componentWillUnmount = () => {
        const { popupActions } = this.props;
        popupActions.setPopupData(null);
    };

    updateState = () => {
        const { popup } = this.props;
        const { rootDocument } = this.state;

        if (JSON.stringify(popup.rootDocument) === JSON.stringify(rootDocument)) return;

        this.setState({
            rootDocument: popup.rootDocument
        });
    };

    updateDebugInfo = async() => {
        const {
            popupActions,
            schema,
            popup
        } = this.props;

        const merjedData = _.merge(popup || {}, {
            ...this.state,
            ...this.getPopupProps(),
            data: this.getData(),
            schema
        });

        await popupActions.setPopupData(merjedData);

        this.updateState();
    };

    handleChange = async (data) => {
        const { rootDocument } = this.state;
        this.setState({ rootDocument: { ...rootDocument, data } }, this.updateDebugInfo);
    };

    getPopupProps = () => {
        const { rootDocument } = this.state;
        const { schema, stepName, path, useOwnData, template, rootValue } = this.props;

        let pageSchema = schema;

        if (template && template.jsonSchema && !useOwnData && !rootValue) {
            const { jsonSchema: { properties } } = template;
            pageSchema = properties[stepName];
        }

        let rootPath = [stepName].filter(Boolean);
        if (useOwnData) {
            rootPath = rootPath.concat(path);
        }

        const pageData = objectPath.get(rootDocument.data, rootPath);
        return { pageSchema, pageData };
    };

    removeEmptyFields = object => {
        const recursiveObj = obj => {
            (Object.keys(obj) || []).forEach(key => {
                if (obj[key] === null) delete obj[key];
                if (typeof obj[key] === 'object') recursiveObj(obj[key]);
            });
            return obj;
        };
        return recursiveObj(object);
    };

    handleSave = async () => {
        const { rootDocument } = this.state;
        const { actions, handleClose, path, useOwnData } = this.props;
        const { pageSchema, pageData } = this.getPopupProps();

        let errors = validateData(this.removeEmptyFields(pageData), pageSchema, rootDocument.data);

        if (useOwnData) {
            errors = errors.map(({ path: errorPath, ...error }) => ({
                ...error,
                path: [].concat(path, errorPath).join('.')
            }));
        } else {
            errors = errors.filter(({ path: errorPath }) => errorPath.indexOf(path.join('.')) === 0);
        }

        console.log('popup.validation.errors', errors);

        this.setState({ errors });

        if (errors && errors.length) {
            actions.scrollToInvalidField(errors);
            return;
        }

        await actions.setValues(rootDocument.data);
        handleClose();
    };

    getData = () => {
        const { rootDocument } = this.state;
        return objectPath.get(rootDocument.data, this.getPath());
    };

    getPath = () => {
        const { stepName, path } = this.props;
        return [stepName].concat(path).filter(p => p !== null && p !== undefined && p !== '');
    };

    render() {
        const {
            t,
            classes,
            actions,
            properties,
            dialogTitle,
            description,
            template,
            readOnly,
            path,
            steps,
            taskId,
            originDocument,
            stepName,
            schema,
            activeStep,
            saveText,
            handleClose,
            clickOpen,
            useOwnData,
            silentActionTitle
        } = this.props;

        const { rootDocument, errors } = this.state;

        const rootPath = this.getPath();
        const value = this.getData();

        return (
            <Dialog
                open={true}
                onClose={handleClose}
                scroll={'body'}
                disableEnforceFocus={true}
                classes={
                    {
                        root: classes.dialogRoot,
                        paperWidthSm: classes.paperWidthSm,
                        paperScrollBody: classes.paperScrollBody
                    }
                }
                container={() => document.getElementById('main-container')}
            >
                <Button
                    onClick={handleClose}
                    className={classes.closeIcon}
                >
                    <img
                        src={CloseIcon}
                        alt="Close dialog"
                        className={classes.closeIconImg}
                    />
                </Button>

                {
                    dialogTitle && clickOpen ? (
                        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                            {dialogTitle}
                        </DialogTitle>
                    ) : null
                }

                {
                    silentActionTitle && !clickOpen ? (
                        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                            {silentActionTitle}
                        </DialogTitle>
                    ) : null
                }

                {
                    description ? (
                        <DialogTitle classes={{ root: classes.dialogDescRoot }}>
                            {description}
                        </DialogTitle>
                    ) : null
                }

                <DialogContent classes={{ root: classes.contentRoot }}>
                    <SchemaForm
                        steps={steps}
                        taskId={taskId}
                        actions={actions}
                        activeStep={activeStep}
                        rootDocument={useOwnData ? { data: value || {} } : rootDocument}
                        originDocument={originDocument}
                        template={template}
                        stepName={stepName}
                        errors={errors}
                        schema={{ type: 'object', properties }}
                        path={path}
                        readOnly={readOnly}
                        value={value || {}}
                        onChange={handleChangeAdapter(rootDocument.data, this.handleChange, false, template.jsonSchema).bind(null, ...rootPath)}
                        required={schema.required}
                    />
                </DialogContent>

                {
                    !readOnly ? (
                        <DialogActions
                            classes={{ root: classes.dialogActions }}
                        >
                            <Button
                                onClick={this.handleSave}
                                color="primary"
                                size="large"
                                variant="contained"
                                className={classes.actionButton}
                                classes={
                                    {
                                        label: classes.btnpadding
                                    }
                                }
                            >
                                {saveText || t('Edit')}
                            </Button>
                        </DialogActions>
                    ) : null
                }
            </Dialog>
        );
    }
}

DialogWrapper.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
    originDocument: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    popup: PropTypes.object.isRequired,
    stepName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    description: PropTypes.string,
    dialogTitle: PropTypes.string,
    silentActionTitle: PropTypes.string,
    path: PropTypes.array,
    steps: PropTypes.array,
    taskId: PropTypes.string,
    rootDocument: PropTypes.object,
    activeStep: PropTypes.number,
    handleClose: PropTypes.func.isRequired,
    saveText: PropTypes.string,
    actions: PropTypes.object,
    popupActions: PropTypes.object,
    clickOpen: PropTypes.bool
};

DialogWrapper.defaultProps = {
    description: null,
    dialogTitle: null,
    silentActionTitle: null,
    readOnly: false,
    path: null,
    steps: [],
    taskId: null,
    rootDocument: null,
    activeStep: null,
    saveText: null,
    actions: {},
    popupActions: {},
    clickOpen: false
};

const mapStateToProps = ({ debugTools: { popup } }) => ({ popup });

const mapDispatchToProps = dispatch => ({
    popupActions: {
        setPopupData: bindActionCreators(setPopupData, dispatch)
    }
});

const styled = withStyles(styles)(DialogWrapper);
const translated = translate('Elements')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

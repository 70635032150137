import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import MessageIcon from '@material-ui/icons/Message';
import CategoryHeader from 'layouts/components/Navigator/CategoryHeader';

import { getUnreadMessageCount } from 'application/manager/actions/messages';
// import processList from 'services/processList';

class MessageNavigation extends React.Component {
    componentDidMount() {
        // const {
        //     actions,
        //     location: { pathname }
        // } = this.props;

        // if (pathname === '/messages' && !processList.has('getUnreadMessageCount')) {
        //     processList.set('getUnreadMessageCount', actions.getUnreadMessageCount);
        // }
    }

    render() {
        const {
            unreadCount,
            location: { pathname }
        } = this.props;

        return (
            <CategoryHeader
                id="Messages"
                path="/messages"
                pathname={pathname}
                badge={unreadCount}
                icon={(
                    <MessageIcon />
                )}
            />
        );
    }
}

MessageNavigation.propTypes = {
    // actions: PropTypes.object.isRequired,
    location: PropTypes.object,
    unreadCount: PropTypes.number
};

MessageNavigation.defaultProps = {
    location: { pathname: '' },
    unreadCount: 0
};

const mapStateToProps = ({ messages: { unreadCount } }) => ({ unreadCount });
const mapDispatchToProps = dispatch => ({
    actions: {
        getUnreadMessageCount: bindActionCreators(getUnreadMessageCount, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageNavigation);

import React from 'react';
import { translate } from 'react-translate';

import {
    Menu,
    MenuItem,
    Tooltip,
    IconButton,
    CircularProgress
} from '@material-ui/core';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import JSZip from 'jszip';

import downloadBase64Attach from 'helpers/downloadBase64Attach';

class DownloadAllButton extends React.Component {
    state = { anchorEl: null, busy: false };

    handleDownload = async (asics) => {
        const { busy } = this.state;

        if (busy) {
            return;
        }

        const { actions, rowsSelected, data, t } = this.props;
        const files = data.filter(({ id }) => rowsSelected.includes(id));

        this.setState({ busy: true });

        try {
            const fileList = await Promise.all(files.map(async (file) => {
                return actions.handleDownloadFile(file, asics);
            }));

            if (files.length === 1) {
                this.setState({ busy: false });
                const blob = await fetch(fileList[0]).then(res => res.blob());
                let fileName = (files[0].fileName || files[0].name).split('.');
                fileName.pop();
                downloadBase64Attach({ fileName: fileName.join('.') }, blob);
                return;
            }

            const zip = new JSZip();
            files.forEach((file, index) => {
                let fileName = `${index + 1}-${file.fileName || file.name}`;
                if (asics) {
                    fileName = fileName + '.zip';
                }
                zip.file(fileName, fileList[index].split(',').pop(), { base64: true });
            });
            const zipFile = await zip.generateAsync({ type: 'blob' });
            this.setState({ busy: false });
            downloadBase64Attach({ fileName: t('ArchiveName') }, zipFile);
            return;
        } catch (e) {
            this.setState({ busy: false });
        }
    };

    render() {
        const { t, asics: useAsics, data } = this.props;
        const { busy, anchorEl } = this.state;

        return (
            <>
                <Tooltip title={t('Download')}>
                    <IconButton
                        id="download-btn"
                        onClick={({ currentTarget }) => {
                            if (useAsics && data.every(({ signature }) => !!signature)) {
                                this.setState({ anchorEl: currentTarget });
                                return;
                            }
                            this.handleDownload(false);
                        }}
                    >
                        {busy
                            ? (
                                <CircularProgress size={24} />
                            )
                            : (
                                <SaveAltIcon />
                            )
                        }
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                >
                    <MenuItem onClick={() => this.handleDownload(false)}>{t('Originals')}</MenuItem>
                    <MenuItem onClick={() => this.handleDownload(true)}>{t('Asics')}</MenuItem>
                </Menu>
            </>
        );
    }
}

export default translate('FileDataTable')(DownloadAllButton);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    MenuItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import { SchemaFormModal } from 'components/JsonSchema';
import promiseChain from 'helpers/promiseChain';

import schema from '../../variables/temlateSchema';

const EditTemplate = ({ t, template, onClose, actions }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <MenuItem onClick={() => { setOpen(true); onClose(); }}>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary={t('EditTemplate')} />
            </MenuItem>
            <SchemaFormModal
                title={t('EditTemplateData')}
                open={open}
                schema={schema}
                value={template}
                onClose={() => setOpen(false)}
                onChange={data => promiseChain([actions.updateNumberTemplate, actions.load], data)}
            />
        </>
    );
};

EditTemplate.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    register: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

EditTemplate.defaultProps = {
    onClose: () => null
};

export default translate('NumberTemplateListPage')(EditTemplate);

import momentJS from 'moment';
import EvalateError from './EvalateError';
/* eslint-disable no-eval */
export default (func, ...params) => {
    try {
        // eslint-disable-next-line no-unused-vars
        const moment = momentJS;
        return eval(func)(...params);
    } catch (e) {
        return new EvalateError(e.message, func, params);
    }
};

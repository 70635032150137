import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SortableTree from 'react-sortable-tree';

import ModulePage from 'components/ModulePage';
import LeftSidebarLayout from 'layouts/LeftSidebar';

import {
    requestWorkflowCategories,
    updateWorkflowCategory,
    createWorkflowCategory,
    deleteWorkflowCategory
} from 'application/adminpanel/actions/workflow';

import 'react-sortable-tree/style.css';

import arrayToTree from 'array-to-tree';
import {
    Toolbar,
    Button,
    TextField,
    withStyles,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import StringElement from 'components/JsonSchema/elements/StringElement';
import ProgressLine from 'components/Preloader/ProgressLine';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';

const categoryTree = (list, firstLevelId = null) => arrayToTree(list.map(item => ({
    ...item,
    title: item.name,
    expanded: true,
    parentId: item.parentId === firstLevelId ? 0 : item.parentId
})), {
    customID: 'id',
    parentProperty: 'parentId',
    childrenProperty: 'children'
});

const styles = {
    toolbar: {
        background: '#eee',
        boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    },
    container: {
        height: 'calc(100% - 48px)'
    }
};

class WorkflowCategoryListPage extends ModulePage {
    constructor(props){
        super(props);
        this.state = {
            treeData: [],
            newCategoryName: '',
            error: null,
            showErrorDialog: false,
            open: false,
            anchorEl: null,
            userInput: '',
            activeItem: {},
            loading: false,
            errorEmpty: null
        };
    }

    async componentDidMount() {
        const { actions } = this.props;

        const categories = this.props.categories || await actions.requestWorkflowCategories();

        if (categories instanceof Error) {
            this.setState({ error: categories });
            return;
        }

        const treeData = categoryTree(categories || []);
        this.setState({ treeData });
    }

    handleCreateCategory = async () => {
        const { actions } = this.props;
        const { newCategoryName, treeData } = this.state;
        this.setState({ newCategoryName: '' });

        const newCategory = await actions.createWorkflowCategory({ name: newCategoryName });

        this.setState({
            treeData: categoryTree([newCategory]).concat(treeData)
        });
    };

    updateChild = (item, parentId = 0) => {
        const { actions } = this.props;
        if (item.parentId !== parentId) {
            actions.updateWorkflowCategory(item.id, { ...item, parentId });
        }

        return {
            ...item,
            parentId,
            children: item.children ? item.children.map(child => this.updateChild(child, item.id)) : undefined
        };
    };

    handleChange = (treeData) => {
        this.setState({ treeData: treeData.map(child => this.updateChild(child)) });
    };

    handleDelete = async () => {
        const { categories, actions } = this.props;
        const { activeItem: { id } } = this.state;

        this.handleMenuClose();

        const result = await actions.deleteWorkflowCategory(id);

        if (result instanceof Error) {
            this.setState({ error: result, showErrorDialog: true });
            return;
        }

        this.setState({
            treeData: categoryTree(categories.filter(category => category.id !== id))
        });
    };

    toggleChangeName = open => {
        this.setState({ open });
        this.handleMenuClose();
    };

    handleChangeName = userInput => this.setState({ userInput });

    handleSaveChangeName = async () => {
        const { actions, t } = this.props;
        const { activeItem, activeItem: { id }, userInput } = this.state;

        if (!activeItem) return;

        if (!userInput.length) {
            this.setState({ errorEmpty: { message: t('RequiredField') } });
            return;
        }

        this.setState({ loading: true, errorEmpty: null });

        await actions.updateWorkflowCategory(id, {
            activeItem,
            name: userInput
        });

        const categories = await actions.requestWorkflowCategories();
        const treeData = categoryTree(categories || []);

        this.setState({
            treeData,
            open: false,
            loading: false
        });
    }

    handleMenuOpen = ({ currentTarget }, activeItem) => {
        this.setState({
            anchorEl: currentTarget,
            activeItem,
            userInput: activeItem.name
        });
    };

    handleMenuClose = () => this.setState({ anchorEl: null });

    renderContent = () => {
        const { t, classes } = this.props;
        const {
            treeData,
            newCategoryName,
            error,
            showErrorDialog,
            open,
            anchorEl,
            userInput,
            loading,
            errorEmpty
        } = this.state;

        return (
            <>
                {
                    (showErrorDialog && error)
                        ? (
                            <Dialog
                                open={true}
                                onClose={() => this.setState({ showErrorDialog: false })}
                            >
                                <DialogTitle>{t('ErrorWhileDeletingCategory')}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {t('CategoryDeletingErrorMessage')}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => this.setState({ showErrorDialog: false })}
                                        color="primary"
                                        autoFocus={true}
                                    >
                                        {t('CloseErrorDialog')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        ) : null
                }
                <Toolbar className={classes.toolbar}>
                    <TextField
                        value={newCategoryName}
                        onChange={({ target: { value } }) => this.setState({ newCategoryName: value })}
                        inputProps={{ maxLength: 120 }}
                    />
                    <Button
                        disabled={!newCategoryName.length}
                        onClick={this.handleCreateCategory}
                    >
                        {t('CreateCategory')}
                    </Button>
                </Toolbar>
                <div className={classes.container}>
                    <SortableTree
                        treeData={treeData}
                        onChange={this.handleChange}
                        isVirtualized={true}
                        generateNodeProps={
                            rowInfo => ({
                                buttons: [(
                                    <>
                                        <IconButton
                                            onClick={event => this.handleMenuOpen(event, rowInfo.node)}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    </>
                                )]
                            })
                        }
                    />
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={this.handleMenuClose}
                    keepMounted={true}
                >
                    <MenuItem onClick={() => this.toggleChangeName(true)}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('EditCategory')} />
                    </MenuItem>
                    <MenuItem onClick={this.handleDelete}>
                        <ListItemIcon>
                            <CloseIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('DeleteCategory')} />
                    </MenuItem>
                </Menu>
                <Dialog
                    open={open}
                    onClose={() => this.toggleChangeName(false)}
                >
                    <DialogTitle>{t('ChangeCategoryNameTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <StringElement
                                description={t('Name')}
                                required={true}
                                value={userInput}
                                onChange={this.handleChangeName}
                                width={300}
                                error={errorEmpty}
                                maxLength={255}
                            />
                            <ProgressLine loading={loading}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.toggleChangeName(false)}
                            color="primary"
                        >
                            {t('CloseErrorDialog')}
                        </Button>
                        <Button
                            onClick={this.handleSaveChangeName}
                            variant="contained"
                            color="primary"
                        >
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    render = () => {
        const {
            t,
            title,
            loading,
            location
        } = this.props;

        return (
            <LeftSidebarLayout
                location={location}
                title={t(title)}
                loading={loading}
                flexContent={true}
            >
                {this.renderContent()}
            </LeftSidebarLayout>
        );
    };
}

WorkflowCategoryListPage.propTypes = {

};

WorkflowCategoryListPage.defaultProps = {

};

const mapStateToProps = ({ workflow: { categories } }) => ({ categories });

const mapDispatchToProps = dispatch => ({
    actions: {
        deleteWorkflowCategory: bindActionCreators(deleteWorkflowCategory, dispatch),
        createWorkflowCategory: bindActionCreators(createWorkflowCategory, dispatch),
        updateWorkflowCategory: bindActionCreators(updateWorkflowCategory, dispatch),
        requestWorkflowCategories: bindActionCreators(requestWorkflowCategories, dispatch)
    }
});

const styled = withStyles(styles)(WorkflowCategoryListPage);
const translated = translate('WorkflowCategoryListPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

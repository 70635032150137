/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@material-ui/core';

import HighlightText from 'components/HighlightText';

const Option = ({ innerRef, isFocused, isSelected, children, innerProps, selectProps: { inputValue } }) => (
    <MenuItem
        buttonRef={innerRef}
        selected={isFocused}
        component="div"
        style={
            {
                fontWeight: isSelected ? 500 : 400,
                whiteSpace: 'normal',
                minHeight: 36,
                height: 'auto'
            }
        }
        {...innerProps}
    >
        <Typography>
            <HighlightText highlight={inputValue} text={children} />
        </Typography>
    </MenuItem>
);

Option.propTypes = {
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired
};

Option.defaultProps = {
    innerRef: () => null,
    innerProps: {},
    isFocused: false,
    isSelected: false,
    children: ''
};

export default Option;

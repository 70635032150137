import config from 'config.json';

import manager from 'application/manager';
import adminpanel from './adminpanel';

const { modules, reducers, access, initActions } = { adminpanel, manager }[config.application.type || 'manager'];

const prioritySort = (a, b) => {
    const aPriority = a.priority || 0;
    const bPriority = b.priority || 0;

    if (aPriority > bPriority) {
        return -1;
    }
    if (aPriority < bPriority) {
        return 1;
    }
    return 0;
};

export const getAccess = () => access;
export const getNavigation = () => [].concat(...modules.map(module => module.navigation || [])).sort(prioritySort);
export const getRoutes = () => [].concat(...modules.map(module => module.routes || [])).sort(({ redirect: a }, { redirect: b }) => ((a === b) ? 0 : a ? 1 : -1));
export const getAppbar = () => [].concat(...modules.map(module => module.appbar || []));
export const getReducers = () => reducers;
export const getInitActions = () => initActions || {};

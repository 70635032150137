import React from 'react';
import { translate } from 'react-translate';
import _ from 'lodash/fp';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import endPoint from 'application/adminpanel/endPoints/numberTemplates';

import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import DataTable from 'components/DataTable';

import CreateNewTemplate from './components/CreateNewTemplate';
import DeleteTemplates from './components/DeleteTemplates';
import dataTableSettings from './variables/dataTableSettings';

class NumberTemplateListPage extends ModulePage {
    state = { openConfirmDialog: false, busy: false };

    componentDidMount() {
        super.componentDidMount();
        const { actions } = this.props;

        actions.load();
    }

    renderToolBar = () => {
        const { rowsSelected } = this.props;

        return (
            <>
                {(rowsSelected || []).length ? <DeleteTemplates {...this.props}/> : null}
                <CreateNewTemplate {...this.props} />
            </>
        );
    }

    render() {
        const {
            t,
            title,
            loading,
            location,
            actions
        } = this.props;

        const { busy } = this.state;

        const settings = dataTableSettings({ t, actions });

        return (
            <LeftSidebarLayout location={location} title={t(title)} loading={loading || busy}>
                <DataTable
                    {..._.merge(settings, dataTableAdapter(this.props, settings))}
                    CustomToolbar={this.renderToolBar}
                />
            </LeftSidebarLayout>
        );
    }
}

const translated = translate('NumberTemplateListPage')(NumberTemplateListPage);
export default dataTableConnect(endPoint)(translated);

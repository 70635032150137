/* eslint-disable no-nested-ternary */
import React from 'react';
import { translate } from 'react-translate';

import {
    Toolbar,
    Button,
    withStyles,
    AppBar
} from '@material-ui/core';

import ProgressLine from 'components/Preloader/ProgressLine';

import evalate from 'helpers/evalate';
import propsToData from 'application/manager/modules/tasks/pages/Task/helpers/propsToData';
import signRequired from 'application/manager/modules/tasks/pages/Task/helpers/signRequired';
import style from 'application/manager/modules/tasks/pages/Task/screens/EditScreen/components/actionsStyles.js';

class Actions extends React.Component {
    checkActive = () => {
        const { task } = this.props;
        const { template } = propsToData(this.props);

        if (!template) {
            return false;
        }

        const { jsonSchema } = template;

        if (jsonSchema.checkActive && typeof jsonSchema.checkActive === 'string') {
            const result = evalate(jsonSchema.checkActive, task.document.data);

            if (result instanceof Error) {
                result.commit({ type: 'checkActiveButton', jsonSchema });
                return false;
            }

            return result === true;
        }

        return true;
    };

    render() {
        const {
            t,
            active,
            finished,
            canCommit,
            classes,
            activeStep,
            steps,
            task,
            template,
            jsonSchema: {
                pdfRequired
            },
            actions: {
                handleNextStep,
                handlePrevStep,
                handleFinish
            },
            blockForward
        } = this.props;

        const finishBtnText = pdfRequired === false ? (signRequired(template, task) ? t('SignBtn') : t('FinishBtn')) : t('CreatePDFBtn');
        const isLastStep = activeStep === steps.length - 1 && this.checkActive();
        const isDisabled = !active && !finished;

        return (
            <AppBar position="relative" className={classes.appBar} elevation={0}>
                {isDisabled ? <ProgressLine loading={true} style={{ maxWidth: 640 }} /> : null}
                <Toolbar className={classes.toolbar}>
                    {
                        activeStep !== 0 ? (
                            <Button
                                size="large"
                                variant="outlined"
                                disabled={isDisabled}
                                className={classes.button}
                                onClick={handlePrevStep}
                                classes={{ disabled: classes.disabledBorder }}
                                id="prev-step-button"
                            >
                                {t('PrevStepBtn')}
                            </Button>
                        ) : null
                    }
                    {
                        (activeStep < steps.length - 1) ? (
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                disabled={isDisabled || blockForward}
                                className={classes.button}
                                onClick={handleNextStep}
                                id="next-step-button"
                            >
                                {t('NextStepBtn')}
                            </Button>
                        ) : null
                    }
                    {
                        finished ? (
                            <>
                                {
                                    isLastStep && finishBtnText !== t('FinishBtn') ? (
                                        <Button
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            disabled={!canCommit || isDisabled}
                                            className={classes.createPDF}
                                            onClick={handleFinish}
                                            id="finish-button"
                                        >
                                            {t('NextStepBtn')}
                                        </Button>
                                    ) : null
                                }
                            </>
                        ) : (
                                <>
                                    {
                                        isLastStep ? (
                                            <Button
                                                size="large"
                                                color="primary"
                                                variant="contained"
                                                disabled={!canCommit || !active}
                                                className={classes.createPDF}
                                                onClick={handleFinish}
                                                id="finish-button"
                                            >
                                                {finishBtnText}
                                            </Button>
                                        ) : null
                                    }
                                </>
                            )
                    }
                </Toolbar>
            </AppBar>
        );
    }
}

const translated = translate('TaskPage')(Actions);
export default withStyles(style)(translated);

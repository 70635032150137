import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';
import capitalizeLetters from 'helpers/capitalizeLetters';
import evalate from 'helpers/evalate';
import Layout from 'components/JsonSchema/elements/StringElement/components/layout';

import { ChangeEvent } from 'components/JsonSchema';

const style = theme => ({
    menuItem: {
        minHeight: 36,
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '22px'
        }
    }
});

class StringElement extends React.Component {
    replaceLatinCharactersAnalog = value => {
        const isLatin = /[a-zA-Z]/.test(value);

        if (!isLatin) return value;

        const upperLat = 'OIYTEKHAMBCX';
        const lowerLat = 'oiyekacx';
        const upperCir = 'ОІУТЕКНАМВСХ';
        const lowerCir = 'оіуекасх';

        let str = (' ' + value).slice(1);

        (upperLat.split('') || []).forEach((char, index) => {
            const regexp = new RegExp(char, 'g');
            str = str.replace(regexp, upperCir[index]);
        });

        (lowerLat.split('') || []).forEach((char, index) => {
            const regexp = new RegExp(char, 'g');
            str = str.replace(regexp, lowerCir[index]);
        });

        return str;
    };

    changeCaseFunctions = (target, method) => {
        const { value } = target;
        const position = target.selectionStart;

        const isChanging = value.length !== position;
        isChanging && setTimeout(() => {
            target.selectionStart = position;
            target.selectionEnd = position;
        });

        switch (method) {
            case 'toLowerCase':
                return value.toLowerCase();
            case 'toUpperCase':
                return value.toUpperCase();
            case 'toCapitalize':
                return capitalizeFirstLetter(value);
            case 'capitalizeLetters':
                return capitalizeLetters(value);
            default:
                return value;
        }
    };

    getMask = () => {
        const { mask, stepName, value, rootDocument, parentValue, defaultMask } = this.props;

        if (typeof mask === 'string') return mask;

        if (mask && Array.isArray(mask)) {
            const activePattern = mask.map(({ isActive, pattern }) => {
                const result = evalate(isActive, value, rootDocument.data[stepName] || {}, rootDocument.data || {}, parentValue);
                if (result instanceof Error) return null;
                if (result) return pattern;
                return null;
            }).filter(patt => patt).pop() || null;

            if (!activePattern) return defaultMask || '';
            return activePattern;
        }

        return '';
    };

    removeHtml = value => {
        const hasTags = /<\/?[^>]+>/.test(value);
        if (hasTags) return value.replace(/<\/?[^>]+>/gi, '');
        return value;
    };

    handleChange = ({ target, target: { value } }) => {
        const { changeOnBlur } = this.props;
        const { onChange, changeCase, replaceLatinAnalogs, cutTags } = this.props;
        const caseValue = changeCase ? this.changeCaseFunctions(target, changeCase) : value;
        const replaceLatinCharecters = replaceLatinAnalogs ? this.replaceLatinCharactersAnalog(caseValue) : caseValue;
        const withoutHtml = cutTags ? this.removeHtml(replaceLatinCharecters) : replaceLatinCharecters;

        const changes = changeOnBlur ? new ChangeEvent(withoutHtml, true) : withoutHtml;
        onChange && onChange(changes);
    };

    renderChildren = () => {
        const { children, classes, options, emptyValue } = this.props;

        if (options) {
            const items = Object.values(options).map((option, key) => {
                const optionName = typeof option === 'string' ? option : option.name;
                return <MenuItem key={key} value={option.id} className={classes.menuItem}>{optionName}</MenuItem>;
            });
            emptyValue && items.unshift(<MenuItem value={''} className={classes.menuItem}>-</MenuItem>);
            return items;
        }

        return children;
    };

    render = () => (
        <Layout
            {...this.props}
            mask={this.getMask()}
            renderChildren={this.renderChildren}
            handleChange={this.handleChange}
        />
    );
}

StringElement.propTypes = {
    useTrim: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    changeCase: PropTypes.string,
    options: PropTypes.array,
    replaceLatinAnalogs: PropTypes.bool,
    cutTags: PropTypes.bool,
    emptyValue: PropTypes.bool,
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    stepName: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    rootDocument: PropTypes.object.isRequired,
    parentValue: PropTypes.object.isRequired,
    defaultMask: PropTypes.string
};

StringElement.defaultProps = {
    children: '',
    onChange: undefined,
    useTrim: false,
    changeCase: null,
    options: null,
    replaceLatinAnalogs: false,
    cutTags: true,
    emptyValue: false,
    mask: '',
    defaultMask: null
};

export default withStyles(style)(StringElement);

import React from 'react';
import PropTypes from 'prop-types';
import ChipTabs from 'components/ChipTabs';
import evalate from 'helpers/evalate';
import {
    withStyles
} from '@material-ui/core';

// eslint-disable-next-line import/no-cycle
import SchemaForm from '../SchemaForm';

const styles = {
    tabs: {
        margin: 0
    },
    tab: {
    },
    scrollButtons: {
        width: 'auto'
    },
    chips: {
        margin: '0 16px 0 0'
    },
    error: {
        color: '#ff0000'
    }
};

class TabsControl extends React.Component {
    componentWillMount() {
        this.init();
    }

    componentDidUpdate({ path, activeStep }) {
        const { path: newPath, activeStep: newActiveStep, hidden, value } = this.props;

        if (path.join() !== newPath.join() || newActiveStep !== activeStep) {
            this.init();
        }

        if (!hidden && !value) this.init();
    }

    init = () => {
        const { value, onChange, hidden, properties } = this.props;

        if (hidden && value && Object.keys(value).length) {
            onChange(null);
        }

        if (properties && (!value || !value.active)) {
            const tabs = Object.keys(properties).filter(this.isTabHidden);
            onChange.bind(null, 'active')(tabs[0]);
        }
    };

    handleChange = (event, activeTab) => {
        const { onChange, properties, emptyHidden, value } = this.props;

        const tabs = Object.keys(properties).filter(this.isTabHidden);

        if (emptyHidden) {
            onChange && onChange({ active: tabs[activeTab] });
        } else {
            onChange && onChange({
                ...(value || {}),
                active: tabs[activeTab]
            });
        }
    }

    isTabHidden = (tabName) => {
        const { properties, rootDocument, value } = this.props;
        const { hidden } = properties[tabName];

        if (!hidden) {
            return true;
        }

        if (typeof hidden === 'string') {
            const result = evalate(hidden, rootDocument.data, (value || {})[tabName], value || {});

            if (result instanceof Error) {
                result.commit({ type: 'schema form isHidden', rootDocument });
                return false;
            }

            return result !== true;
        }

        return !hidden;
    };

    render() {
        const { classes, properties, required, schema, readOnly, value = {}, onChange, path, type, hidden, errors, ...rest } = this.props;

        const tabs = Object.keys(properties).filter(this.isTabHidden);
        if (hidden || !tabs.length) return null;
        const activeTab = tabs.indexOf((value && value.active) || tabs[0]);

        const tabKey = tabs[activeTab];
        const activeSchema = Object.values(properties)[activeTab];

        const erroredTabs = tabs
            .map((tabName, index) => {
                const tabPath = path.concat(tabName).join('.');
                return errors.some(error => error.path.indexOf(tabPath) === 0) ? index : false;
            })
            .filter(Boolean);

        return (
            <>
                <ChipTabs
                    errored={erroredTabs}
                    activeIndex={activeTab}
                    onChange={this.handleChange}
                    tabs={tabs.map(key => ({ title: properties[key].description }))}
                    className={classes.chips}
                    readOnly={readOnly}
                />
                {activeSchema ? (
                    <SchemaForm
                        {...rest}
                        errors={errors}
                        schema={{ ...activeSchema, description: '' }}
                        path={path.concat(tabKey)}
                        readOnly={readOnly || activeSchema.readOnly}
                        value={(value || {})[tabKey]}
                        onChange={onChange.bind(null, tabKey)}
                    />
                ) : null}
            </>
        );
    }
}

TabsControl.propTypes = {
    properties: PropTypes.object,
    errors: PropTypes.array,
    value: PropTypes.object,
    path: PropTypes.array,
    onChange: PropTypes.func,
    emptyHidden: PropTypes.bool,
    hidden: PropTypes.bool,
    stepName: PropTypes.string.isRequired,
    rootDocument: PropTypes.object
};

TabsControl.defaultProps = {
    properties: {},
    errors: [],
    value: null,
    path: [],
    onChange: () => null,
    emptyHidden: true,
    hidden: false,
    rootDocument: {}
};

export default withStyles(styles)(TabsControl);

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormHelperText } from '@material-ui/core';

import { SchemaForm, handleChangeAdapter, validateData } from 'components/JsonSchema';

import KeySelect from './KeySelect';
import schema from '../variables/keySchema';

class KeyFormModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || {},
            errors: [],
            error: null,
            busy: false
        };
    }

    componentDidUpdate(prevProps) {
        const { open, value } = this.props;

        if (open !== prevProps.open) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value, errors: [] });
        }
    }

    handleChange = value => this.setState({ value });

    handleSave = async () => {
        const { t, onChange, onClose, registerId } = this.props;
        const { value, value: { name } } = this.state;
        const registrySchema = schema({ t });

        const errors = validateData(value, registrySchema);
        const exists = this.nameExists(name);

        if (exists) {
            errors.push({
                dataPath: '.name',
                path: 'name',
                message: t('KeyNameExists')
            });
        }

        this.setState({ errors });

        if (!errors.length) {
            this.setState({ busy: true });
            try {
                await onChange({ ...value, registerId });
                this.setState({ busy: false }, onClose);
            } catch (e) {
                this.setState({ busy: false, error: e });
            }
        }
    };

    nameExists = name => {
        const { registerId, registryKeyList } = this.props;
        const { value: { id } } = this.state;
        const checkFunc = el => (Number(el.registerId) === Number(registerId) && el.name === name && el.id !== id);
        return (Object.values(registryKeyList.data || {}) || []).filter(checkFunc).length;
    };

    render() {
        const { t, open, onClose, value: origin, registerId } = this.props;
        const { value, errors, error, busy } = this.state;

        if (value && typeof value.toString !== 'string') {
            value.toString = '';
        }

        return (
            <Dialog
                open={open}
                onClose={busy ? undefined : onClose}
                scroll="body"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>{t(origin ? 'EditKey' : 'NewKey')}</DialogTitle>
                <DialogContent>
                    <SchemaForm
                        value={value}
                        errors={errors}
                        readOnly={busy}
                        schema={schema({ t })}
                        customControls={
                            {
                                KeySelect: props => <KeySelect {...props} registerId={registerId} excludeKey={value && value.id} />
                            }
                        }
                        onChange={handleChangeAdapter(value, this.handleChange)}
                    />
                </DialogContent>
                <DialogActions>
                    {
                        error ? (
                            <FormHelperText error={true}>
                                {error.message}
                            </FormHelperText>
                        ) : null
                    }
                    <div style={{ flexGrow: 1 }} />
                    <Button
                        onClick={onClose}
                        disabled={busy}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={busy}
                        onClick={this.handleSave}
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

KeyFormModal.propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool,
    value: PropTypes.object,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    registerId: PropTypes.string.isRequired,
    registryKeyList: PropTypes.object.isRequired
};

KeyFormModal.defaultProps = {
    open: false,
    value: null,
    onClose: () => null,
    onChange: () => null
};

const mapStateToProps = ({ registryKeyList }) => ({ registryKeyList });
const translated = translate('KeyListAdminPage')(KeyFormModal);
export default connect(mapStateToProps, null)(translated);

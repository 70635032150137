import qs from 'qs';
import { toUnderscoreObject } from 'helpers/toUnderscore';

export default (url, { page, rowsPerPage, filters, sort, search }) => {
    const urlData = {
        filters: toUnderscoreObject(filters),
        sort: toUnderscoreObject(sort)
    };

    if (page) {
        urlData.page = page;
    }

    if (rowsPerPage) {
        urlData.count = rowsPerPage;
    }

    if (search) {
        urlData.search = search;
    }

    const queryString = qs.stringify(urlData, { arrayFormat: 'index' });
    return url + (queryString && '?' + queryString);
};

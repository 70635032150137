import React, { useState } from 'react';
import { translate } from 'react-translate';
import classNames from 'classnames';
import { withStyles, Button, Typography, IconButton, Tooltip } from '@material-ui/core';
import Edit from 'assets/img/ic_edit.svg';
import PropTypes from 'prop-types';
import TextBlock from 'components/JsonSchema/elements/TextBlock';

const styles = theme => ({
    blockWrapper: {
        border: '2px solid #000',
        maxWidth: 640,
        padding: 30,
        [theme.breakpoints.down('sm')]: {
            padding: 16
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 15
        }
    },
    blockHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'end'
        }
    },
    values: {
        marginTop: 15
    },
    removeMargin: {
        margin: 0,
        marginTop: -5
    },
    editButton: {
        '&>span': {
            fontSize: 13
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
            padding: 0
        }
    },
    editImg: {
        marginRight: 8,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    editText: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    title: {
        paddingRight: 15,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    fixTop: {
        top: 7,
        position: 'relative'
    },
    dynamicTitleButton: {
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    withoutPadding: {
        padding: '0 8px',
        minWidth: 125,
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            minWidth: 73
        }
    }
});

const EditTooltip = ({
    t,
    open,
    setOpen,
    handleClickOpen
}) => (
    <Tooltip title={t('Edit')} open={open}>
        <IconButton
            onMouseOver={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onFocus={() => setOpen(false)}
            onClick={handleClickOpen}
        >
            <img src={Edit} alt="Edit" />
        </IconButton>
    </Tooltip>
);

EditTooltip.propTypes = {
    t: PropTypes.func.isRequired,
    handleClickOpen: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    open: PropTypes.object
};

EditTooltip.defaultProps = {
    open: false
};

const RenderValues = ({
    htmlBlock,
    params,
    value,
    rootDocument,
    properties,
    renderDataItem,
    useParentData
}) => (
    <div>
        {
            htmlBlock
            ? (
                <TextBlock
                    htmlBlock={htmlBlock}
                    params={params}
                    parentValue={value}
                    rootDocument={rootDocument}
                    useParentData={useParentData}
                />
            )
            : (Object.keys(properties || {}).map((item, index) => renderDataItem(item, index)))
        }
    </div>
);

RenderValues.propTypes = {
    renderDataItem: PropTypes.func.isRequired,
    htmlBlock: PropTypes.string,
    params: PropTypes.object,
    rootDocument: PropTypes.object,
    properties: PropTypes.object,
    useParentData: PropTypes.bool
};

RenderValues.defaultProps = {
    htmlBlock: null,
    params: null,
    rootDocument: null,
    properties: null,
    useParentData: false
};

const Wrapper = ({
    t,
    classes,
    description,
    handleClickOpen,
    renderDataItem,
    properties,
    htmlBlock,
    params,
    useOwnParams,
    rootDocument,
    editTooltip,
    value,
    dynamicTitle,
    useParentData,
    style
}) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={classNames(classes.blockWrapper, style && style.wrapper)}>
            {
                description ? (
                    <div className={
                        classNames(
                            classes.blockHead,
                            style && style.header
                        )
                    }
                    >
                        <Typography
                            variant={'subtitle1'}
                            className={classNames(classes.title, style && style.title)}
                        >
                            {description}
                        </Typography>

                        {
                            editTooltip ? (
                                <EditTooltip
                                    t={t}
                                    open={open}
                                    setOpen={setOpen}
                                    handleClickOpen={handleClickOpen}
                                />
                            ) : (
                                    <Button
                                        onClick={handleClickOpen}
                                        className={
                                            classNames(
                                                classes.editButton,
                                                classes.withoutPadding,
                                                style && style.editButton
                                            )
                                        }
                                    >
                                        <img src={Edit} alt="Edit" className={classes.editImg} />
                                        <span className={classes.editText}>{t('Edit')}</span>
                                    </Button>
                                )
                        }
                    </div>
                ) : null
            }
            <div className={
                classNames(
                    classes.values,
                    !description && classes.flex,
                    !description && classes.removeMargin,
                    dynamicTitle && classes.dynamicTitleButton
                )
            }
            >
                <RenderValues
                    htmlBlock={htmlBlock}
                    params={params}
                    value={value}
                    rootDocument={useOwnParams ? value : rootDocument}
                    properties={properties}
                    renderDataItem={renderDataItem}
                    useParentData={useParentData}
                />
                {
                    !description && !dynamicTitle ? (
                        <>
                            <span className={classes.fixTop}>
                                <EditTooltip
                                    t={t}
                                    open={open}
                                    setOpen={setOpen}
                                    handleClickOpen={handleClickOpen}
                                />
                            </span>
                        </>
                    ) : null
                }
                {
                    dynamicTitle && !description ? (
                        <Button
                            onClick={handleClickOpen}
                            className={
                                classNames(
                                    classes.editButton,
                                    classes.withoutPadding,
                                    style && style.editButton
                                )
                            }
                        >
                            <img src={Edit} alt="Edit" className={classes.editImg} />
                            <span className={classes.editText}>{t('Edit')}</span>
                        </Button>
                    ) : null
                }
            </div>
        </div>
    );
};

Wrapper.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    handleClickOpen: PropTypes.func.isRequired,
    renderDataItem: PropTypes.func.isRequired,
    properties: PropTypes.object.isRequired,
    rootDocument: PropTypes.object.isRequired,
    description: PropTypes.string,
    htmlBlock: PropTypes.string,
    params: PropTypes.object,
    editTooltip: PropTypes.bool,
    useOwnParams: PropTypes.bool,
    value: PropTypes.object,
    dynamicTitle: PropTypes.bool,
    style: PropTypes.object,
    useParentData: PropTypes.bool,
    readOnly: PropTypes.bool
};

Wrapper.defaultProps = {
    value: {},
    editTooltip: false,
    dynamicTitle: false,
    useOwnParams: false,
    description: null,
    htmlBlock: null,
    params: null,
    style: null,
    useParentData: false,
    readOnly: false
};

const translated = translate('Elements')(Wrapper);
const styled = withStyles(styles)(translated);
export default styled;

import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    withStyles
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import checkAccess from 'helpers/checkAccess';
import { getAppbar } from 'application';

const styles = theme => ({
    menuButton: {
        marginLeft: `-${theme.spacing()}px`, // value as -theme.spacing() rice error 
        [theme.breakpoints.down('xs')]: {
            color: '#fff'
        }
    },
    header: {
        color: '#000',
        padding: 16,
        paddingRight: 10,
        backgroundColor: theme.headerBg,
        [theme.breakpoints.down('xs')]: {
            padding: 8,
            backgroundColor: theme.leftSidebarBg,
            color: '#fff'
        }
    },
    toolbar: {
        padding: '0 4px',
        minHeight: 'auto',
        alignItems: 'center',
        '& > *': {
            marginRight: 4
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    flex: {
        display: 'flex'
    },
    whiteSm: {
        flexGrow: 1,
        lineHeight: 1.5,
        [theme.breakpoints.down('xs')]: {
            color: '#fff'
        }
    },
    userInfo: {
        justifyContent: 'flex-end'
    },
    iconButtonRoot: {
        width: 40,
        height: 40
    },
    backLink: {
        color: 'rgba(0, 0, 0, 0.54)',
        [theme.breakpoints.down('xs')]: {
            color: '#fff'
        }
    }
});

class Header extends React.Component {
    checkAccess = ({ access }) => {
        const { userUnits, userInfo } = this.props;
        return !access || checkAccess(access, userInfo, userUnits);
    };

    render() {
        const { classes, title, onDrawerToggle, hideMenuButton, backButton } = this.props;
        const widgets = getAppbar();

        return (
            <AppBar className={classes.header} position="relative" elevation={1}>
                <Toolbar className={classes.toolbar}>
                    {
                        backButton ? (
                            <IconButton
                                classes={
                                    {
                                        root: classes.iconButtonRoot
                                    }
                                }
                            >
                                <Link to={backButton} className={classes.backLink}>
                                    <ArrowBackIcon />
                                </Link>
                            </IconButton>
                        ) : (
                            <>
                                {
                                    !hideMenuButton ? (
                                        <IconButton
                                            aria-label="Open drawer"
                                            onClick={onDrawerToggle}
                                            className={classes.menuButton}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    ) : null
                                }
                            </>
                        )
                    }
                    <Typography className={classes.whiteSm} variant="h5">{title}</Typography>
                    {
                        widgets.filter(this.checkAccess).map((widget, key) => (
                            <widget.component key={key} />
                        ))
                    }
                </Toolbar>
            </AppBar>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
    hideMenuButton: PropTypes.bool
};

Header.defaultProps = {
    hideMenuButton: false
};

const mapStateToProps = ({ auth: { userUnits, info } }) => ({ userUnits, userInfo: info });

const translated = translate('Navigator')(Header);
const connected = connect(mapStateToProps)(translated);
export default withStyles(styles)(connected);

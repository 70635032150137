import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TreeListSelect } from 'components/TreeList';

import {
    requestWorkflowCategories
} from 'application/adminpanel/actions/workflow';

import arrayToTree from 'array-to-tree';

const categoryTree = (list, firstLevelId = null) => arrayToTree(list.map(item => ({
    ...item,
    parentId: item.parentId === firstLevelId ? 0 : item.parentId
})), {
    customID: 'id',
    parentProperty: 'parentId',
    childrenProperty: 'items'
});

class WorkflowCategorySelect extends React.Component {
    state = {};

    componentDidMount() {
        const { actions, categories } = this.props;
        if (!categories) {
            actions.requestWorkflowCategories();
        }
    }

    handleSelect = (value) => {
        const { onChange } = this.props;
        onChange(value && value.id);
    };

    render() {
        const { t, categories, value } = this.props;

        const selected = (categories || []).find(({ id }) => id === value);

        return (
            <TreeListSelect
                editable={true}
                placeholder={t('WorkflowCategory')}
                selected={selected}
                items={categoryTree(categories || [])}
                onSelect={this.handleSelect}
            />
        );
    }
}

WorkflowCategorySelect.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    categories: PropTypes.array,
    onChange: PropTypes.func
};

WorkflowCategorySelect.defaultProps = {
    categories: null,
    onChange: () => null
};

const mapStateToProps = ({ workflow: { categories } }) => ({ categories });

const mapDispatchToProps = dispatch => ({
    actions: {
        requestWorkflowCategories: bindActionCreators(requestWorkflowCategories, dispatch)
    }
});

const translated = translate('WorkflowAdminPage')(WorkflowCategorySelect);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

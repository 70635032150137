import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import evalate from 'helpers/evalate';
import {
    FormControl,
    FormHelperText,
    Typography,
    withStyles
} from '@material-ui/core';

import { DrawerContent, Content } from 'layouts/LeftSidebar';

import {
    EJVError,
    SchemaForm,
    SchemaStepper
} from 'components/JsonSchema';

import Scrollbar from 'components/Scrollbar';

import Actions from 'application/manager/modules/tasks/pages/Task/screens/EditScreen/components/Actions';
import ImportActions from 'application/manager/modules/tasks/pages/Task/screens/EditScreen/components/ImportActions';
import TaskDetails from 'application/manager/modules/tasks/pages/Task/components/TaskDetails';
import StoreEventError from 'application/manager/modules/tasks/pages/Task/components/StoreEventError';

import checkAccess from 'helpers/checkAccess';

const styles = {
    schemaTitle: {
        padding: 0,
        marginBottom: 10
    },
    oneStepTitle: {
        paddingTop: 30
    },
    poper: {
        fontSize: 16,
        marginBottom: 20,
        marginTop: 20,
        padding: 16,
        maxWidth: 640,
        background: 'rgb(255, 244, 215)'
    },
    drawer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        flexGrow: 1,
        overflow: 'hidden',
        paddingBottom: 20
    }
};

const EditScreenLayout = ({
    busy,
    task,
    tasks,
    origin,
    origins,
    classes,
    actions,
    storeEventError,
    validationErrors,
    validationPageErrors,
    externalReaderErrors,
    setStoreEventError,
    steps,
    stepName,
    userUnits,
    activeStep,
    template,
    templates,
    handleSetStep,
    computedMatch,
    fileStorage,
    handleImport,
    handleChange,
    handleStore,
    handleNextStep,
    handlePrevStep,
    handleFinish,
    isUserUnitHead,
    pendingMessage,
    task: { isEntry },
    blockForward
}) => (
        <DrawerContent
            disableScrolls={true}
            collapseButton={true}
            drawer={
                checkAccess({ isUnitedUser: true }, null, userUnits) && !isEntry ? (
                    <TaskDetails
                        task={task}
                        template={template}
                        isUserUnitHead={isUserUnitHead}
                    />
                ) : null
            }
        >
            <div className={classes.drawer}>
                <StoreEventError
                    error={storeEventError}
                    onClose={() => setStoreEventError(null)}
                />
                <Scrollbar>
                    <div className={classes.content}>
                        <Content>
                            <ImportActions handleImport={handleImport} importSchema={template.jsonSchema.importSchema} />
                            <SchemaStepper
                                task={task}
                                steps={steps}
                                jsonSchema={template.jsonSchema}
                                activeStep={activeStep}
                                errors={validationPageErrors}
                                handleStep={step => () => step < activeStep && handleSetStep(step)}
                            />
                            <Typography
                                variant="h4"
                                className={classNames(classes.schemaTitle, { [classes.oneStepTitle]: steps.length === 1 })}
                            >
                                {
                                    (() => {
                                        const { description } = template.jsonSchema.properties[stepName];
                                        const result = evalate(description, task.document.data);

                                        if (result instanceof Error) {
                                            return description;
                                        }
                                        return result;
                                    })()
                                }
                            </Typography>
                            {
                                validationPageErrors.length ? (
                                    <div>
                                        <FormControl
                                            error={true}
                                            className={classes.root}
                                        >
                                            {
                                                validationPageErrors.map((error, index) => (
                                                    <FormHelperText key={index}>
                                                        <EJVError error={error} />
                                                    </FormHelperText>
                                                ))
                                            }
                                        </FormControl>
                                    </div>
                                ) : null
                            }
                            <SchemaForm
                                taskId={computedMatch.params.taskId}
                                schema={template.jsonSchema.properties[stepName]}
                                stepName={stepName}
                                steps={steps}
                                active={!busy}
                                fileStorage={fileStorage}
                                actions={actions}
                                activeStep={activeStep}
                                readOnly={task.finished || task.deleted || busy}
                                errors={validationErrors}
                                rootDocument={task.document}
                                template={template}
                                originDocument={origin.document}
                                value={task.document.data[stepName]}
                                onChange={handleChange.bind(null, stepName)}
                                handleStore={handleStore.bind(this)}
                            />
                            {
                                busy && pendingMessage ? (
                                    <div className={classes.poper}>
                                        {pendingMessage.map((mss, index) => <Typography key={index}>{mss}</Typography>)}
                                    </div>
                                ) : null
                            }
                            {
                                !busy && externalReaderErrors.length ? (
                                    <FormControl className={classes.root}>
                                        <div className={classes.poper}>
                                            {externalReaderErrors.map((error, index) => <Typography key={index}>{error}</Typography>)}
                                        </div>
                                    </FormControl>
                                ) : null
                            }
                        </Content>
                    </div>
                </Scrollbar>
                <Actions
                    steps={steps}
                    active={!busy}
                    task={task}
                    template={template}
                    finished={task.finished}
                    canCommit={!task.deleted}
                    actions={
                        {
                            handleNextStep,
                            handlePrevStep,
                            handleFinish
                        }
                    }
                    activeStep={activeStep}
                    jsonSchema={template.jsonSchema}
                    taskId={task.id}
                    tasks={tasks}
                    origins={origins}
                    templates={templates}
                    blockForward={blockForward}
                />
            </div>
        </DrawerContent>
    );

EditScreenLayout.propTypes = {
    busy: PropTypes.bool.isRequired,
    task: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    origin: PropTypes.object.isRequired,
    origins: PropTypes.object.isRequired,
    templates: PropTypes.object.isRequired,
    storeEventError: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    validationPageErrors: PropTypes.object,
    setStoreEventError: PropTypes.func.isRequired,
    steps: PropTypes.array.isRequired,
    stepName: PropTypes.string.isRequired,
    activeStep: PropTypes.number.isRequired,
    template: PropTypes.object.isRequired,
    handleSetStep: PropTypes.func.isRequired,
    computedMatch: PropTypes.object.isRequired,
    fileStorage: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleStore: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    handlePrevStep: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired,
    isUserUnitHead: PropTypes.bool.isRequired,
    showSignerList: PropTypes.bool.isRequired,
    externalReaderErrors: PropTypes.array,
    pendingMessage: PropTypes.array,
    blockForward: PropTypes.bool
};

EditScreenLayout.defaultProps = {
    validationErrors: null,
    validationPageErrors: null,
    externalReaderErrors: null,
    pendingMessage: null,
    blockForward: false
};

export default withStyles(styles)(EditScreenLayout);

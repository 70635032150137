import evalate from 'helpers/evalate';

export default (props) => {
    const {
        tasks,
        origins,
        templates,
        taskId,
        stepId,
        workflowTemplateId
    } = props;

    let task;
    let origin;
    let template;
    let steps;

    if (taskId) {
        task = tasks[taskId];
        origin = origins[taskId];
        if (task) {
            template = templates[task.taskTemplateId];
            if (template) {
                steps = evalate(template.jsonSchema.stepOrders, task.document.data);

                if (steps instanceof Error) {
                    steps.commit({ type: 'step orders', template, task });
                    steps = [];
                }
            }
        }
    }

    return { taskId, task, origin, steps, template, stepId, workflowTemplateId };
};

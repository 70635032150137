import React from 'react';
import { translate } from 'react-translate';
import _ from 'lodash/fp';
import { history } from 'store';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import endPoint from 'application/adminpanel/endPoints/registry';
import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import DataTable from 'components/DataTable';

import dataTableSettings from './variables/dataTableSettings';

import ImportRegisters from './components/ImportRegisters';
import ImportRegistersKeysXLS from './components/ImportRegistersKeysXLS';
import CreateNewRegister from './components/CreateNewRegister';

class RegistryListPage extends ModulePage {
    componentDidMount() {
        super.componentDidMount();
        const { actions } = this.props;

        actions.load();
    }

    render() {
        const {
            t,
            actions,
            data,
            title,
            loading,
            location
        } = this.props;

        const settings = dataTableSettings({ t, data, actions });

        return (
            <LeftSidebarLayout location={location} title={t(title)} loading={loading}>
                <DataTable
                    {..._.merge(settings, dataTableAdapter(this.props, settings))}
                    onRowClick={({ id }) => history.push(`/registry/${id}`)}
                    CustomToolbar={
                        () => (
                            <>
                                <CreateNewRegister actions={actions} />
                                <ImportRegisters actions={actions} />
                                <ImportRegistersKeysXLS actions={actions} />
                            </>
                        )
                    }
                />
            </LeftSidebarLayout>
        );
    }
}

const translated = translate('RegistryListAdminPage')(RegistryListPage);
export default dataTableConnect(endPoint)(translated);

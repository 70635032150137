export default ({ outgoing }) => ({
    type: 'object',
    properties: {
        description: {
            type: 'string',
            description: 'Опис',
            helperText: 'Приклад: шлюз',
            maxLength: 255
        },
        jsonSchema: {
            type: 'object',
            properties: {
                formulas: {
                    control: 'table',
                    items: {
                        isDefault: {
                            description: 'За замовчуванням',
                            control: 'toggle',
                            align: 'center'
                        },
                        condition: {
                            control: 'code.editor',
                            description: 'Функція',
                            mode: 'javascript',
                            validate: true,
                            align: 'center'
                        }
                    },
                    rows: outgoing.map(({ id, labels }) => {
                        const label = labels[0];
                        return label ? label.businessObject.name : id;
                    })
                }
            }
        }
    },
    required: [
        'gatewayTypeId',
        'jsonSchema'
    ]
});

class ChangeEvent {
    data = null;

    force = false;

    hard = false;

    constructor(data, force = false, hard = false) {
        this.data = data;
        this.force = force;
        this.hard = hard;
    }
}

export default ChangeEvent;

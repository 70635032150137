import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FileDataTable from 'components/FileDataTable';

class PreviewDocument extends React.Component {
    componentDidMount() {
        const { actions, demo } = this.props;
        const { files } = this.getData();

        if (!files && !demo) {
            actions.getDocumentWorkflowFiles();
        }
    }

    getData = () => {
        const { rootDocument, workflowFiles, demo } = this.props;

        if (demo) {
            return { files: [] };
        }

        return { files: workflowFiles[rootDocument.id] };
    };

    render() {
        const { actions: { handleDeleteFile, ...actions }, fileStorage, hidden } = this.props;
        const { files } = this.getData();

        if (hidden) return null;

        return (
            <FileDataTable
                data={files}
                actions={actions}
                groupBy="labels"
                asics={true}
                fileStorage={fileStorage}
            />
        );
    }
}

PreviewDocument.propTypes = {
    actions: PropTypes.object,
    workflowFiles: PropTypes.object
};

PreviewDocument.defaultProps = {
    actions: {},
    workflowFiles: {}
};

const mapStateToProps = ({ task }) => ({ workflowFiles: task && task.workflowFiles });
export default connect(mapStateToProps)(PreviewDocument);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

import Preloader from 'components/Preloader';
import { SchemaForm, handleChangeAdapter } from 'components/JsonSchema';

import emptyTask from 'application/adminpanel/modules/workflow/variables/emptyTask';
import taskElementTypes from 'application/adminpanel/modules/workflow/variables/taskElementTypes';

import { requestTask, changeTaskData, saveTaskData } from 'application/adminpanel/actions/tasks';

import minUnusedIndex from 'helpers/minUnusedIndex';
import padWithZeroes from 'helpers/padWithZeroes';

import processList from 'services/processList';

import elementSchema from '../../defaultSchema.json';
import schema from './schema';

class TaskElement extends React.Component {
    state = { error: null, showErrorDialog: false, validationErrors: [] };

    async componentDidMount() {
        this.loadTask();
    }

    async componentDidUpdate(prevProps) {
        const { selectionId } = this.props;

        if (prevProps.selectionId !== selectionId) {
            this.loadTask();
        }
    }

    getNextTaskId = (element) => {
        const { workflow, modeler } = this.props;

        const ids = modeler.get('elementRegistry').getAll()
            .filter(({ type, id }) => taskElementTypes.includes(type) && id !== element.businessObject.id)
            .filter(({ businessObject: { id } }) => !this.isLocalId(id))
            .map(this.getTaskId)
            .map(String)
            .map(taskId => taskId.replace(workflow.id, ''))
            .map(numStr => parseInt(numStr, 10));

        return workflow.id + padWithZeroes(minUnusedIndex(ids, 1), 3);
    }

    isLocalId = id => taskElementTypes.some((type) => {
        const suffix = type.split(':').pop();
        return id.indexOf(suffix) === 0;
    });

    loadTask = async () => {
        const { t, element, workflow, actualTaskList, actions, onChange } = this.props;

        const taskId = this.getTaskId(element);
        if (actualTaskList[taskId]) {
            return;
        }

        if (this.isLocalId(element.businessObject.id)) {
            const nextTaskId = this.getNextTaskId(element);
            element.businessObject.id = ['task', nextTaskId].join('-');
            element.businessObject.name = t('NewTask');
            onChange(element.businessObject);
            // this.forceUpdate();
            return;
        }

        if (!processList.has('requestTask', taskId)) {
            const task = await processList.set('requestTask', actions.requestTask, taskId);
            if (task instanceof Error) {
                await actions.saveTaskData(emptyTask(taskId, { t, workflow }));
            }
        }
    }

    getTaskId = ({ businessObject: { id } }) => parseInt(id.split('-').pop(), 10);

    handleChange = (task) => {
        const { actions, element } = this.props;

        // const validationErrors = validateData(task, schema(t));
        // this.setState({ validationErrors });

        actions.changeTaskData(this.getTaskId(element), task);
        this.handleChangeBusiness();
    }

    handleChangeBusiness = (businessObject) => {
        const { onChange } = this.props;
        onChange && onChange(businessObject);
        // this.forceUpdate();
    }

    renderForm() {
        const { validationErrors } = this.state;
        const { t, actualTaskList, element } = this.props;
        const taskId = this.getTaskId(element);

        const task = actualTaskList[taskId];

        if (!task) {
            return <Preloader />;
        }

        return (
            <SchemaForm
                schema={schema(t)}
                value={task}
                errors={validationErrors}
                onChange={handleChangeAdapter(task, this.handleChange)}
            />
        );
    }

    render() {
        const { t, element } = this.props;
        const { error, showErrorDialog } = this.state;

        return (
            <div>
                <SchemaForm
                    schema={elementSchema}
                    value={element.businessObject}
                    onChange={handleChangeAdapter(element.businessObject, this.handleChangeBusiness)}
                />
                {this.renderForm()}
                {(showErrorDialog && error)
                    ? (
                        <Dialog
                            open={true}
                            onClose={() => this.setState({ showErrorDialog: false })}
                        >
                            <DialogTitle>{t('ErrorWhileSavingTask')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {error.message}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.setState({ showErrorDialog: false })}
                                    color="primary"
                                    autoFocus={true}
                                >
                                    {t('CloseErrorDialog')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null}
            </div>
        );
    }
}

TaskElement.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    actualTaskList: PropTypes.object,
    element: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    workflow: PropTypes.object,
    selectionId: PropTypes.string,
    modeler: PropTypes.object
};

TaskElement.defaultProps = {
    actualTaskList: {},
    workflow: {},
    selectionId: null,
    modeler: null
};

const mapStateToProps = ({ tasks: { actual } }) => ({ actualTaskList: actual });

const mapDispatchToProps = dispatch => ({
    actions: {
        requestTask: bindActionCreators(requestTask, dispatch),
        saveTaskData: bindActionCreators(saveTaskData, dispatch),
        changeTaskData: bindActionCreators(changeTaskData, dispatch)
    }
});

const translated = translate('WorkflowAdminPage')(TaskElement);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

import objectPath from 'object-path';
import cleanDeep from 'clean-deep';
import store from 'store';
import { ChangeEvent, handleTriggers } from 'components/JsonSchema';

const addParent = (path, data) => {
    path.forEach((e, index) => {
        const itemPath = path.filter((el, i) => i <= index);
        const itemData = objectPath.get(data, itemPath);
        const isNull = itemData === null || itemData === undefined;
        if (isNull && path[index + 1]) objectPath.set(data, itemPath, {});
    });
};

export default (origin = {}, handleChange, clean = false, jsonSchema = {}) => (...path) => {
    const changes = path.pop();
    const dataPath = path.join('.');
    const { auth: { info } } = store.getState() || {};

    const changesData = (changes instanceof ChangeEvent) ? changes.data : changes;
    let documentData = JSON.parse(JSON.stringify(origin));
    documentData = cleanDeep(documentData, { emptyObjects: false, emptyArrays: false, nullValues: false });

    const parentPath = path.filter((el, index) => index < path.length - 1);

    addParent(path, documentData);

    objectPath.set(documentData, path, changesData);

    const parentData = objectPath.get(documentData, parentPath);

    jsonSchema.calcTriggers && handleTriggers(
        documentData,
        jsonSchema.calcTriggers,
        dataPath,
        changesData,
        documentData[path[0]],
        documentData,
        parentData,
        null,
        info
    );

    if (clean) {
        documentData = cleanDeep(documentData, { emptyObjects: false, emptyArrays: false, nullValues: false });
    }

    handleChange && handleChange(documentData);
};

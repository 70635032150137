import React from 'react';
import { translate } from 'react-translate';

import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    withStyles
} from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/ErrorOutline';

const styles = {
    icon: {
        fontSize: 82,
        color: 'red'
    },
    title: {
        textAlign: 'center'
    }
};

const ErrorScreen = ({ t, classes, error }) => {
    if (!error) return null;

    return (
        <>
            <DialogTitle className={classes.title}>
                <ErrorIcon className={classes.icon} />
            </DialogTitle>
            <DialogTitle className={classes.title}>
                {t('ErrorMessageHeader')}
            </DialogTitle>
            <DialogContent className={classes.title}>
                <DialogContentText>
                    {error.message}
                </DialogContentText>
            </DialogContent>
        </>
    );
};

const translated = translate('TaskPage')(ErrorScreen);
export default withStyles(styles)(translated);

import React from 'react';

import { Popover } from '@material-ui/core';
import { SchemaForm, ChangeEvent } from 'components/JsonSchema';

const EDITOR_MIN_WIDTH = 240;

const DataEditor = ({ classes, col, row, value, items, path, name, readOnly, parentValue, onChange, onCommit, onCellChange, cellRef, ...rest }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    let jsonValue;

    React.useEffect(() => {
        if (cellRef && cellRef.current) {
            setAnchorEl(cellRef.current);
        }
    }, [cellRef]);

    const properties = Object.keys(items.properties || {});
    const propName = properties[col];
    const schema = items.properties[propName];
    const { options, type, mask } = schema;
    // debugger;

    const width = Math.max(
        cellRef && cellRef.current && cellRef.current.offsetWidth,
        EDITOR_MIN_WIDTH
    );

    try {

        if (type !== 'string') {
            jsonValue = JSON.parse(value);
        }
    } catch (e) {
        // nothing to do
    }

    return (
        <Popover
            classes={classes}
            open={true}
            anchorEl={anchorEl}
            transitionDuration={0}
            onClose={() => onCommit(value)}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
        >
            <SchemaForm
                {...rest}
                {...schema}
                path={path}
                multiline={!mask}
                width={width}
                schema={schema}
                noMargin={true}
                autoFocus={true}
                required={(schema.required || []).includes(propName)}
                name={propName}
                readOnly={readOnly || schema.readOnly}
                value={jsonValue || value}
                parentValue={parentValue && parentValue[name] && parentValue[name][row]}
                useOwnContainer={true}
                usedInTable={true}
                onChange={
                    newValue => {
                        onChange(newValue instanceof ChangeEvent ? newValue.data : newValue);
                        options && type === 'string' && onCommit(newValue);
                    }
                }
            />
        </Popover>
    );
};

export default DataEditor;

import * as api from 'services/api';
import * as Sentry from '@sentry/browser';

import { addError } from 'actions/error';

const REQUEST_UNIT = 'UNITS/REQUEST_UNIT';
const UPDATE_UNIT_DATA = 'UNITS/UPDATE_UNIT_DATA';
const SAVE_UNIT = 'UNITS/SAVE_UNIT';
const CREATE_UNIT = 'UNITS/CREATE_UNIT';
const CLEAR_NEW_UNIT = 'UNITS/CLEAR_NEW_UNIT';
const EXPORT_UNITS = 'EXPORT_UNITS';
const IMPORT_UNITS = 'IMPORT_UNITS';

const ADD_UNIT_HEADS = 'UNITS/ADD_UNIT_HEADS';
const DELETE_UNIT_HEAD = 'UNITS/DELETE_UNIT_HEAD';
const ADD_UNIT_MEMBERS = 'UNITS/ADD_UNIT_MEMBERS';
const DELETE_UNIT_MEMBER = 'UNITS/DELETE_UNIT_MEMBER';
const REQUEST_ALL_UNITS = 'UNITS/REQUEST_ALL_UNITS';

export const requestUnit = unitId => dispatch => api.get(`units/${unitId}`, REQUEST_UNIT, dispatch)
    .catch((error) => {
        dispatch(addError(new Error('FailFetchingUnit')));
        Sentry.captureException(error);
        return error;
    });

export const updateUnitData = unit => ({
    type: UPDATE_UNIT_DATA,
    payload: unit
});

export const clearNewUnit = () => ({
    type: CLEAR_NEW_UNIT
});

export const saveUnit = ({ id: unitId, parentId, ...unitData }) => dispatch => api.put(`units/${unitId}`, unitData, SAVE_UNIT, dispatch)
    .catch((error) => {
        dispatch(addError(new Error('FailSavingUnit')));
        Sentry.captureException(error);
        return error;
    });

export const createUnit = unitData => dispatch => api.post('units', unitData, CREATE_UNIT, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailCreatingUnit')));
        Sentry.captureException(error);
        return error;
    });

export const exportUnits = rowsExported => dispatch => api.post(`units/export`, { ids: rowsExported }, EXPORT_UNITS, dispatch)
    .catch((error) => {
        dispatch(addError(new Error('FailExportingUnits')));
        Sentry.captureException(error);
        return error;
    });

export const importUnits = (file, force = false) => dispatch => api.upload(`units/import?force=${force}`, file, {}, IMPORT_UNITS, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailImportingUnits')));
        Sentry.captureException(error);
        return error;
    });

export const addUnitHeads = (unitId, heads) => dispatch => api.post(`units/${unitId}/heads`, { heads }, ADD_UNIT_HEADS, dispatch, { unitId, heads })
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const deleteUnitHeads = (unitId, heads) => dispatch => api.del(`units/${unitId}/heads`, { heads }, DELETE_UNIT_HEAD, dispatch, { unitId, heads })
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const addUnitMembers = (unitId, members) => dispatch => api.post(`units/${unitId}/members`, { members }, ADD_UNIT_MEMBERS, dispatch, { unitId, members })
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const deleteUnitMembers = (unitId, members) => dispatch => api.del(`units/${unitId}/members`, { members }, DELETE_UNIT_MEMBER, dispatch, { unitId, members })
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const requestAllUnits = () => dispatch => api.get('units/all', REQUEST_ALL_UNITS, dispatch)
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

import objectPath from 'object-path';

import evalate from 'helpers/evalate';

export default (origin = {}, triggers, dataPath, changesData, stepData, documentData, parentData, sheetsIndex, userInfo) => {
    (triggers || []).forEach((trigger) => {
        const { calculate } = trigger;

        if (!calculate || !trigger.source || !trigger.target) {
            return;
        }

        [].concat(trigger.source).forEach((source) => {
            const params = {};
            const pathElements = dataPath.split('.');
            const sourcePath = source
                .split('.')
                .map((element, index) => {
                    if (/\$\{.+?}/.test(element)) {
                        params[element] = pathElements[index];
                        return pathElements[index];
                    }
                    return element;
                })
                .join('.');

            if (dataPath !== sourcePath) {
                return;
            }

            [].concat(trigger.target).forEach((target) => {
                let targetPath = Object.keys(params).reduce((acc, key) => acc.replace(key, params[key]), target);
                // eslint-disable-next-line no-template-curly-in-string
                targetPath = sheetsIndex ? targetPath.replace('${index}', sheetsIndex.index) : targetPath;

                const calculateFunc = Object.keys(params).reduce((acc, key) => {
                    const regexp = new RegExp(key.replace('$', '\\$').replace('{', '\\{').replace('}', '\\}'), 'g');
                    return acc.replace(regexp, params[key]);
                }, calculate);

                const result = evalate(calculateFunc, changesData, stepData, documentData, parentData, userInfo);

                if (result instanceof Error) {
                    console.error('trigger error', { sourcePath, targetPath, calculateFunc });
                    result.commit({ type: 'calc trigger error', calculateFunc, targetPath });
                    return;
                }

                // debugger;
                try {
                    let value = result;
                    if (typeof result !== 'boolean') {
                        value = result || undefined;
                    }
                    console.log('handle trigger', source, targetPath, value);
                    objectPath.set(origin, targetPath, value);
                } catch (e) {
                    console.error('trigger error', e);
                }
            });
        });
    });

    return origin;
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { translate } from 'react-translate';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import {
    DataTypeProvider,
    CustomPaging,
    PagingState,
    SearchState,
    SortingState,
    IntegratedSorting,
    FilteringState
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    Toolbar,
    TableHeaderRow,
    PagingPanel,
    ColumnChooser,
    DragDropProvider,
    TableColumnVisibility,
    TableColumnReordering,
    TableColumnResizing,
    TableFilterRow
} from '@devexpress/dx-react-grid-material-ui';
import { connectProps } from '@devexpress/dx-react-core';
import TimeLabel from 'components/Label/Time';
import HighlightText from 'components/HighlightText';
import endPoint from 'application/manager/endPoints/registryRecord';
import dataTableConnect from 'services/dataTable/connect';
import evalate from 'helpers/evalate';
import storage from 'helpers/storage';
import processList from 'services/processList';
import ExportToExelButton from './ExportToExelButton';
import CreateNewRecordButton from './CreateNewRecordButton';
import RegistryModal from './RegistryModal';
import SortLabel from './SortLabel';
import FilterCell from './FilterCell';
import TableRow from './TableRow';
import SearchInput from './SearchInput';

const initialState = t => ({
    columns: [{
        name: 'data',
        title: t('Name'),
        sortingEnabled: true
    }, {
        name: 'createdBy',
        title: t('CreatedBy'),
        sortingEnabled: true
    }, {
        name: 'createdAt',
        title: t('CreatedAt'),
        sortingEnabled: true
    }],
    tableColumnExtensions: [
        { columnName: 'createdBy', align: 'right' },
        { columnName: 'createdAt', align: 'right' }
    ],
    columnWidths: [
        { columnName: 'data', width: 640 },
        { columnName: 'createdBy', width: 240 },
        { columnName: 'createdAt', width: 240 }
    ],
    columnOrder: ['data', 'createdBy', 'createdAt'],
    hiddenColumns: ['data', 'createdBy', 'createdAt'],
    customColumns: []
});

class RegistryKeyTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.propsToState(initialState(props.t), props);
        this.searchInput = connectProps(SearchInput, () => {
            const { useSearch } = this.state;
            return { useSearch };
        });
        this.filterCell = connectProps(FilterCell, () => {
            const { useSearch } = this.state;
            return { useSearch };
        });
    }

    hiddenColumnsNames = () => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return [];
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');
        const { hiddenColumnNames } = registryTableSettings[selectedKey.id] || {};

        return hiddenColumnNames || ['data', 'createdBy', 'createdAt'];
    };

    setTableSettings = (settings) => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return;
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');

        registryTableSettings[selectedKey.id] = {
            ...(registryTableSettings[selectedKey.id] || {}),
            ...settings
        };

        storage.setItem('registryTableSettings', JSON.stringify(registryTableSettings));
        this.setState(settings);
    };

    propsToState = (defaultProps, { selectedKey }) => {
        if (!selectedKey || !selectedKey.schema) {
            return defaultProps;
        }

        let customColumns = [];

        if (typeof selectedKey.schema.toTable === 'object') {
            customColumns = Object.keys(selectedKey.schema.toTable);
        } else {
            customColumns = Object.keys(selectedKey.schema.properties || {})
                .filter(propertyName => !['object', 'array'].includes(selectedKey.schema.properties[propertyName].type));
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');
        const { columnWidths, columnOrder } = registryTableSettings[selectedKey.id] || {};

        return {
            columns: [
                ...defaultProps.columns,
                ...customColumns.map(propertyName => ({
                    name: ['data', propertyName].join('.'),
                    title: (selectedKey.schema.properties[propertyName] || {}).description || propertyName,
                    hidden: !!(selectedKey.schema.properties[propertyName] || {}).hidden,
                    sortingEnabled: !(selectedKey.schema.properties[propertyName] || {}).disableSort,
                    propertyName
                }))
            ],
            tableColumnExtensions: defaultProps.tableColumnExtensions,
            columnWidths: columnWidths || [
                ...defaultProps.columnWidths,
                ...customColumns.map(propertyName => ({
                    columnName: ['data', propertyName].join('.'),
                    width: 240
                }))
            ],
            columnOrder: columnOrder || [
                ...defaultProps.columnOrder,
                ...customColumns
            ],
            customColumns: customColumns.map(propertyName => ['data', propertyName].join('.'))
        };
    };

    deleteHiddenColumns = array => array.filter(item => !item.hidden);

    setColumnOrder = columnOrder => this.setTableSettings({ columnOrder });

    onHiddenColumnNamesChange = hiddenColumnNames => this.setTableSettings({ hiddenColumnNames });

    setColumnWidths = columnWidths => this.setTableSettings({ columnWidths });

    handleStore = async (record) => {
        const { actions } = this.props;
        this.setState({ selectedRecord: record });
        await actions.storeRecord(record.id, record);
        await actions.load();
    };

    handleStoreNewRecord = async (newRecord) => {
        const { actions } = this.props;
        await actions.createRecord(newRecord);
        await actions.load();
        this.setState({ newRecord: null });
    };

    getSorting = () => {
        const { sort } = this.props;

        return Object.keys(sort).map(columnName => ({
            columnName,
            direction: sort[columnName]
        }));
    };

    setSorting = ([sorting]) => {
        if (!sorting) return;
        const { actions } = this.props;
        actions.onColumnSortChange(sorting.columnName, sorting.direction, true, true);
    };

    setFilters = (filters) => {
        if (!filters) return;
        const { actions, filters: { name: search } } = this.props;
        actions.onSearchChange(search, true, filters);
    };

    setSearchVisible = () => {
        const { useSearch, savedFirstElement } = this.state;
        const { data, selectedKey } = this.props;

        const { toSearchString } = selectedKey || {};
        const [firstElement] = Array.isArray(data) ? data : [];
        const useSearchUpdate = toSearchString && savedFirstElement && evalate(toSearchString, savedFirstElement) !== null;

        const updateFirstElement = firstElement && (JSON.stringify(firstElement) !== JSON.stringify(savedFirstElement));

        if (updateFirstElement) {
            this.setState({ savedFirstElement: firstElement });
        }

        if (useSearch !== useSearchUpdate) {
            this.setState({
                useSearch: useSearchUpdate
            });
        }
    };

    renderGrid = () => {
        const { columns, tableColumnExtensions, columnWidths, columnOrder, customColumns } = this.state;
        const { t, data, selectedKey, rowsPerPage, page, /* loading, */count, actions, filters: { name: search } } = this.props;

        const tableMessages = { noData: t('NoData') };
        const tableData = selectedKey ? data : [];
        const pagingPanelMessages = { info: t('Pagination'), rowsPerPage: t('RowsPerPage') };
        const columnChooserMessages = { showColumnChooser: t('ChooseColumns') };

        this.setSearchVisible();

        const sortingStateColumnExtensions = (columns || []).map(({ name, sortingEnabled }) => ({
            columnName: name, sortingEnabled: !!sortingEnabled
        }));

        return (
            <Grid
                rows={Array.isArray(tableData) ? tableData : []}
                columns={this.deleteHiddenColumns(columns) || []}
            >
                <DataTypeProvider
                    for={['createdAt']}
                    formatterComponent={({ row }) => (row.createdAt ? <TimeLabel date={row.createdAt} /> : null)}
                />
                <DataTypeProvider
                    for={['data']}
                    formatterComponent={
                        ({ row }) => {
                            if (!selectedKey) {
                                return null;
                            }

                            const content = evalate(selectedKey.toString, row);

                            if (content instanceof Error) {
                                content.commit({
                                    type: 'registry',
                                    selectedKey
                                });

                                return null;
                            }

                            return content || null;
                        }
                    }
                />
                <DataTypeProvider
                    key={customColumns}
                    for={customColumns}
                    formatterComponent={
                        ({ row, column }) => {
                            let text;
                            if (typeof selectedKey.schema.toTable === 'object') {
                                text = evalate(selectedKey.schema.toTable[column.title], row);
                            } else {
                                text = objectPath.get(row, column.name);
                            }
                            if (text instanceof Error) {
                                text = evalate(selectedKey.schema.toTable[column.propertyName], row);
                            }
                            // console.log('text', text, row, column.name);

                            const displayText = typeof text === 'object' ? JSON.stringify(text) : text;

                            return <HighlightText highlight={search} text={displayText} />;
                        }
                    }
                />
                <FilteringState
                    onFiltersChange={this.setFilters}
                />
                <SortingState
                    sorting={this.getSorting()}
                    onSortingChange={this.setSorting}
                    columnExtensions={sortingStateColumnExtensions}
                />
                <SearchState
                    value={search}
                    onValueChange={actions.onSearchChange}
                />
                <PagingState
                    currentPage={page}
                    onCurrentPageChange={newPage => actions.onChangePage(newPage - 1)}
                    pageSize={rowsPerPage}
                    onPageSizeChange={actions.onChangeRowsPerPage}
                />
                <CustomPaging
                    totalCount={selectedKey && count ? count : 0}
                />
                <IntegratedSorting />
                {/* <IntegratedFiltering /> */}
                <DragDropProvider />
                <Table
                    messages={tableMessages}
                    columnExtensions={tableColumnExtensions}
                    rowComponent={TableRow(record => this.setState({ selectedRecord: record }))}
                />

                <TableFilterRow
                    showFilterSelector={false}
                    messages={{ filterPlaceholder: t('SearchFieldLabel') }}
                    cellComponent={this.filterCell}
                />

                <TableColumnReordering
                    order={columnOrder}
                    onOrderChange={this.setColumnOrder}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={this.setColumnWidths}
                />
                <TableHeaderRow
                    showSortingControls={true}
                    sortLabelComponent={SortLabel}
                />
                <PagingPanel
                    messages={pagingPanelMessages}
                    pageSizes={selectedKey ? [10, 50, 100] : []}
                />
                <TableColumnVisibility
                    hiddenColumnNames={this.hiddenColumnsNames()}
                    onHiddenColumnNamesChange={this.onHiddenColumnNamesChange}
                />

                <Toolbar />

                {/* <SearchPanel inputComponent={this.searchInput} messages={{ searchPlaceholder: t('SearchFieldLabel') }} /> */}
                <ColumnChooser
                    messages={columnChooserMessages}
                />
                <ExportToExelButton selectedKey={selectedKey} />
                <CreateNewRecordButton
                    disabled={!selectedKey || !selectedKey.access.allowCreate}
                    onClick={() => this.setState({ newRecord: { registerId: selectedKey.registerId, keyId: selectedKey.id, data: {} } })}
                />
            </Grid>
        );
    };

    init = () => {
        const { actions, selectedKey } = this.props;
        if (selectedKey && !processList.has('RegistryKeysTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true)) {
            actions.clearFilters();
            processList.set('RegistryKeysTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true);
        }
    };

    componentDidMount = () => this.init();

    componentDidUpdate = () => {
        const { filters: { keyId }, selectedKey } = this.props;
        if (selectedKey && keyId !== selectedKey.id) this.init();
        this.searchInput.update();
        this.filterCell.update();
    };

    componentWillReceiveProps = nextProps => {
        const defaultProps = initialState(nextProps.t);
        this.setState(this.propsToState(defaultProps, nextProps), this.forceUpdate);
    };

    render = () => {
        const { selectedRecord, newRecord } = this.state;
        const { selectedKey, actions } = this.props;

        return (
            <Paper>
                {this.renderGrid()}
                {
                    selectedRecord ? (
                        <RegistryModal
                            open={!!(selectedKey && selectedRecord)}
                            selected={selectedKey || {}}
                            value={(selectedRecord || {})}
                            handleSave={this.handleStore}
                            handleClose={() => this.setState({ selectedRecord: null })}
                            handleDelete={actions.onRowsDelete.bind(null, [(selectedRecord || {}).id])}
                        />
                    ) : null
                }
                {
                    newRecord ? (
                        <RegistryModal
                            editMode={true}
                            open={true}
                            selected={selectedKey || {}}
                            value={(newRecord || {})}
                            handleClose={() => this.setState({ newRecord: null })}
                            handleSave={this.handleStoreNewRecord}
                        />
                    ) : null
                }
            </Paper>
        );
    };
}

RegistryKeyTable.propTypes = {
    filters: PropTypes.object,
    selectedKey: PropTypes.object,
    actions: PropTypes.object,
    t: PropTypes.func.isRequired,
    data: PropTypes.array
};

RegistryKeyTable.defaultProps = {
    filters: {},
    selectedKey: {},
    actions: {},
    data: []
};

const translated = translate('RegistryPage')(RegistryKeyTable);
export default dataTableConnect(endPoint)(translated);

import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import diff from 'deep-diff';
import deepObjectFind from 'helpers/deepObjectFind';

import { history } from 'store';

import { Button, Toolbar } from '@material-ui/core';

import LeftSidebarLayout, { Content } from 'layouts/LeftSidebar';

import ConfirmDialog from 'components/ConfirmDialog';
import ModulePage from 'components/ModulePage';
import Preloader from 'components/Preloader';
import ProgressLine from 'components/Preloader/ProgressLine';
import { SchemaForm, validateData, handleChangeAdapter } from 'components/JsonSchema';
import ChipTabs from 'components/ChipTabs';

import { requestUnit, updateUnitData, saveUnit, createUnit, clearNewUnit } from 'application/adminpanel/actions/units';

import unitListControlEndPoint from 'application/adminpanel/endPoints/unitListControl';
import dataTableConnect from 'services/dataTable/connect';

import UnitList from 'application/adminpanel/modules/users/pages/Unit/components/UnitList';
import schema from 'application/adminpanel/modules/users/pages/Unit/variables/unitJsonSchema.json';
import schemaParts from 'application/adminpanel/modules/users/pages/Unit/variables/unitSchemaParts.json';

const ROWS_PER_PAGE = 1000;

class UnitPage extends ModulePage {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            error: null,
            activePart: 0,
            loading: false
        };
    }

    componentDidMount() {
        const {
            unitActions,
            match: { params: { unitId } }
        } = this.props;

        if (unitId !== 'new') {
            unitActions.requestUnit(unitId);
        }
    }

    componentDidUpdate({ match: { params: { unitId: oldUnitId } } }) {
        const {
            unitActions,
            actualUnitList,
            match: { params: { unitId: newUnitId } }
        } = this.props;

        if (newUnitId !== oldUnitId && !actualUnitList[newUnitId]) {
            unitActions.requestUnit(newUnitId);
        }
    }

    validate = (unit) => {
        const errors = validateData(unit, schema);
        this.setState({ errors }, () => this.scrollToInvalidField(errors));

        return !errors.length;
    };

    handleChange = (unit) => {
        const { unitActions } = this.props;
        unitActions.updateUnitData(unit);
    };

    handleSave = () => {
        const {
            unitActions,
            actualUnitList,
            originUnitList,
            match: { params: { unitId } }
        } = this.props;

        if (!this.validate(actualUnitList[unitId])) {
            return null;
        }

        if (unitId === 'new') {
            return this.handleCreateUnit();
        }

        return unitActions.saveUnit({
            ...actualUnitList[unitId], ...{
                previousBasedOn: originUnitList[unitId].basedOn,
                previousMembers: originUnitList[unitId].members,
                previousHeads: originUnitList[unitId].heads,
                previousAllowTokens: originUnitList[unitId].allowTokens,
                previousHeadsIpn: originUnitList[unitId].headsIpn,
                previousMembersIpn: originUnitList[unitId].membersIpn
            }
        });
    };

    handleSaveAction = async () => {
        const {
            unitActions,
            match: { params: { unitId } }
        } = this.props;

        this.setState({ loading: true });

        await this.handleSave();

        if (unitId !== 'new') {
            await unitActions.requestUnit(unitId);
        }

        this.setState({ loading: false });
    }

    handleCreateUnit = async () => {
        const {
            actions,
            unitActions,
            actualUnitList
        } = this.props;

        console.log('UNIT', actualUnitList);

        const newUnit = await unitActions.createUnit(actualUnitList.new);

        if (newUnit instanceof Error) {
            this.setState({ error: newUnit });
            return;
        }

        actions.onChangeRowsPerPage(ROWS_PER_PAGE, true);
        history.replace(`/users/units/${newUnit.id}`);
        unitActions.clearNewUnit();
    };

    componentGetTitle() {
        const {
            t,
            originUnitList,
            match: { params: { unitId } }
        } = this.props;

        if (unitId === 'new') {
            return t('NewUnit');
        }

        const unit = originUnitList[unitId];
        return unit ? unit.name : t('Loading');
    }

    scrollToInvalidField = (errors) => {
        if (!errors) return;

        try {
            const firstError = deepObjectFind(errors, ({ path }) => !!path);

            if (!firstError) return;

            const replacepath = firstError.path.replace(/\./g, '-');

            const firstInvalidField = document.getElementById(firstError.path)
                || document.getElementById(replacepath)
                || document.querySelector(`input[name=${replacepath}]`);

            if (!firstInvalidField) return;

            const type = firstInvalidField.getAttribute('type');
            const isHidden = type === 'hidden' || firstInvalidField.style.display === 'none';

            if (isHidden) {
                const parent = firstInvalidField.parentNode;
                parent && parent.scrollIntoView({ block: 'center' });
            } else {
                firstInvalidField.scrollIntoView({ block: 'center' });
            }
        } catch {
            console.log('scrollToInvalidField errors', errors);
        }
    };

    renderContent() {
        const {
            t,
            actualUnitList,
            originUnitList,
            match: { params: { unitId } }
        } = this.props;

        const {
            errors,
            error,
            activePart,
            loading
        } = this.state;

        const unit = actualUnitList[unitId];
        const origin = originUnitList[unitId];

        if (!unit) {
            return <Preloader />;
        }

        const diffs = diff(unit, origin || {});

        return (
            <Content>
                <SchemaForm
                    errors={errors}
                    schema={schema}
                    value={unit}
                    customControls={
                        {
                            UnitList: props => <UnitList {...props} excludeUnit={unit.id} />
                        }
                    }
                    onChange={handleChangeAdapter(unit, this.handleChange)}
                />
                <ChipTabs
                    activeIndex={activePart}
                    onChange={(e, active) => this.setState({ activePart: active })}
                    tabs={schemaParts.map(({ description }) => ({ title: description }))}
                />
                <SchemaForm
                    errors={errors}
                    schema={schemaParts[activePart]}
                    value={unit}
                    customControls={
                        {
                            UnitList: props => <UnitList {...props} excludeUnit={unit.id} />
                        }
                    }
                    onChange={handleChangeAdapter(unit, this.handleChange)}
                />
                <ProgressLine loading={loading} style={{ maxWidth: 640 }} />
                <Toolbar disableGutters={true}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!diffs || loading}
                        onClick={this.handleSaveAction}
                    >
                        {t('Save')}
                    </Button>
                </Toolbar>
                <ConfirmDialog
                    fullScreen={false}
                    open={!!error}
                    title={t('UnitErrorTitle')}
                    description={error && t(error.message)}
                    handleClose={() => this.setState({ error: null })}
                />
            </Content>
        );
    }

    render() {
        const {
            loading,
            location
        } = this.props;

        return (
            <LeftSidebarLayout location={location} title={this.componentGetTitle()} loading={loading}>
                {this.renderContent()}
            </LeftSidebarLayout>
        );
    }
}

const mapStateToProps = ({ units: { actual, origin } }) => ({
    actualUnitList: actual,
    originUnitList: origin
});

const mapDispatchToProps = dispatch => ({
    unitActions: {
        saveUnit: bindActionCreators(saveUnit, dispatch),
        createUnit: bindActionCreators(createUnit, dispatch),
        clearNewUnit: bindActionCreators(clearNewUnit, dispatch),
        requestUnit: bindActionCreators(requestUnit, dispatch),
        updateUnitData: bindActionCreators(updateUnitData, dispatch)
    }
});

const translated = translate('UnitPage')(UnitPage);
const connected = connect(mapStateToProps, mapDispatchToProps)(translated);
export default dataTableConnect(unitListControlEndPoint)(connected);

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Fuse from 'fuse.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModulePage from 'components/ModulePage';
import Layout from 'application/manager/modules/users/pages/UserList/components/Layout';

import { requestUnitInfo, addUnitUser, deleteUnitUser } from 'application/manager/actions/users';

import userHeadUnitList from 'helpers/userHeadUnitList';

const UserList = ({
    actions,
    title,
    location,
    userUnits
}) => {
    const [search, setSearch] = React.useState('');
    const [unitId, setUnitId] = React.useState(null);
    const [users, setUsers] = React.useState(null);
    const unitList = userHeadUnitList(userUnits);

    const loadUserList = async () => {
        if (!unitId) {
            return;
        }
        setUsers(null);
        const data = await actions.requestUnitInfo(unitId);
        setUsers(data && [].concat(data.membersUsers, data.requestedMembers).filter(Boolean));
    };

    React.useEffect(() => {
        if (!unitId && unitList && unitList.length) {
            setUnitId(unitList[0].id);
        }
    });

    React.useEffect(() => {
        loadUserList();
    }, [unitId]);

    const fuse = new Fuse(users || [], {
        includeScore: true,
        minMatchCharLength: 2,
        keys: ['email', 'ipn', 'firstName', 'lastName', 'middleName', 'phone']
    });

    const userList = search
        ? fuse.search(search)
            .filter(({ score }) => score < 0.5)
            .map(({ item }) => item)
        : users;

    return (
        <Layout
            data={userList}
            unitId={unitId}
            setUnitId={(newUnitId) => { setUnitId(newUnitId); setSearch(''); }}
            title={title}
            loading={!users}
            location={location}
            unitList={unitList}
            search={search}
            onSearchChange={setSearch}
            load={loadUserList}
            handleAddUnitUser={user => actions.addUnitUser(unitId, user)}
            handleDelete={user => actions.deleteUnitUser(unitId, user)}
        />
    );
};

class UserListPage extends ModulePage {
    render() {
        return <UserList {...this.props} />
    }
}

UserListPage.getComponentTitle = () => {
    console.log(this);
    return 'saasdas';
}

const mapState = ({ auth: { userUnits } }) => ({
    userUnits
});

const mapDispatch = dispatch => ({
    actions: {
        requestUnitInfo: bindActionCreators(requestUnitInfo, dispatch),
        addUnitUser: bindActionCreators(addUnitUser, dispatch),
        deleteUnitUser: bindActionCreators(deleteUnitUser, dispatch)
    }
});

export default connect(mapState, mapDispatch)(UserListPage);

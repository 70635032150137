import React from 'react';
import { translate } from 'react-translate';
import { Button } from '@material-ui/core';

import ElementContainer from 'components/JsonSchema/components/ElementContainer';
import { JsonSchemaEditor } from 'components/JsonSchema';

import ConfirmDialog from 'components/ConfirmDialog';
import FullScreenDialog from 'components/FullScreenDialog';

const SchemaEditor = ({
    t,
    error,
    required,
    helperText,
    description,
    noMargin,
    value,
    onChange
}) => {
    const [open, setOpen] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);

    return (
        <ElementContainer
            error={error}
            required={required}
            helperText={helperText}
            description={description}
            noMargin={noMargin}
        >
            <Button
                size="small"
                variant="outlined"
                fullWidth={true}
                onClick={() => setOpen(true)}
            >
                {t('OpenEditor')}
            </Button>
            <FullScreenDialog
                open={open}
                title={description}
                onClose={() => {
                    if (errors.length) {
                        setOpenErrorDialog(true);
                        return;
                    }
                    setOpen(false);
                }}
            >
                <JsonSchemaEditor
                    value={value}
                    onChange={onChange}
                    onValidate={setErrors}
                />
                <ConfirmDialog
                    open={openErrorDialog}
                    title={t('ResolveErrors')}
                    description={t('ResolveErrorsPrompt')}
                    handleClose={() => setOpenErrorDialog(false)}
                />
            </FullScreenDialog>
        </ElementContainer>
    );
};

export default translate('Elements')(SchemaEditor);

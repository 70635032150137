export default ({
    actions,
    count,
    page,
    data,
    rowsSelected,
    hiddenColumns,
    sort,
    rowsPerPage,
    filters: { name: search, ...filters }
}) => ({
    data,
    page,
    actions,
    rowsSelected,
    hiddenColumns,
    rowsPerPage,
    sort,
    count,
    search: search || '',
    filters: filters || {}
});

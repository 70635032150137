import React from 'react';
import { Provider } from 'react-redux';
import { TranslatorProvider } from 'react-translate';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import * as Sentry from '@sentry/browser';
import { hotjar } from 'react-hotjar';
import config from 'config';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import store from 'store';

import translation from 'translation';
import theme from 'theme';

import AppRouter from 'components/AppRouter';
import Auth from 'components/Auth';
import WebChat from 'components/WebChat';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/css/mgrpdf.theme.css';
import 'assets/css/main.css';

const { sentryDns, hotjar: hotjarConfig, application: { environment, version } } = config;

if (sentryDns) {
    Sentry.init({ dsn: sentryDns, environment, release: version, ignoreErrors: ['401 unauthorized', '404 not found', 'NetworkError when attempting to fetch resource.', 'Failed to fetch', 'Перелік закінчено', 'NS_BINDING_ABORTED:', 'Error: ESOCKETTIMEDOUT'] });
}

if (hotjarConfig && hotjarConfig.id && hotjarConfig.sv) {
    hotjar.initialize(hotjarConfig.id, hotjarConfig.sv);
}

const router = <AppRouter />;
const withChat = (
    <>
        {router}
        <WebChat />
    </>
);
const auth = <TranslatorProvider translations={translation}><Auth>{withChat}</Auth></TranslatorProvider>;
const storeProvider = <Provider store={store}>{auth}</Provider>;
const themeProvider = <MuiThemeProvider theme={createMuiTheme(theme)}>{storeProvider}</MuiThemeProvider>;

export default <MuiPickersUtilsProvider utils={MomentUtils}>{themeProvider}</MuiPickersUtilsProvider>;

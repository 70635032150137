export default ({ t }) => ({
    type: 'object',
    properties: {
        name: {
            type: 'string',
            description: t('Name'),
            useTrim: true,
            maxLength: 255,
            checkValid: [
                {
                    isValid: '(propertyData) => !!(propertyData && propertyData.length)',
                    errorText: 'Обов\'язкове поле'
                }
            ]
        },
        description: {
            type: 'string',
            description: t('Description'),
            useTrim: true,
            maxLength: 255,
            checkValid: [
                {
                    isValid: '(propertyData) => !!(propertyData && propertyData.length)',
                    errorText: 'Обов\'язкове поле'
                }
            ]
        },
        parentId: {
            control: 'register.select',
            description: t('ParentId')
        },
        meta: {
            type: 'object',
            properties: {
                isTest: {
                    control: 'toggle',
                    onText: t('IsTest')
                }
            }
        }
    },
    required: [
        'name',
        'description'
    ]
});

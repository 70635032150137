import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';

import { history } from 'store';

import {
    loadTask,
    createTask,
    commitTask,
    setTaskStep,
    markTaskRead,
    setTaskScreen,
    clearStepAndScreen,
    generatePDFDocument,
    validateDocument
} from 'application/manager/actions/task';

import { validateData } from 'components/JsonSchema';

import { loadDocumentTemplate } from 'application/manager/actions/documentTemplate';

import processList from 'services/processList';
import waiter from 'helpers/waitForAction';
import evalate from 'helpers/evalate';

import ErrorScreen from 'components/ErrorScreen';
import Preloader from 'components/Preloader';
import ModulePage from 'components/ModulePage';

import SuccessMessage from 'application/manager/modules/tasks/pages/Task/components/SuccessMessage';
import TaskPageLayout from 'application/manager/modules/tasks/pages/Task/components/TaskPageLayout';

import PreviewScreen from 'application/manager/modules/tasks/pages/Task/screens/PreviewScreen';
import EditScreen from 'application/manager/modules/tasks/pages/Task/screens/EditScreen';

import propsToData from 'application/manager/modules/tasks/pages/Task/helpers/propsToData';
import signRequired from 'application/manager/modules/tasks/pages/Task/helpers/signRequired';

const screens = {
    EDIT: 'edit',
    PREVIEW: 'preview',
    SUCCESS: 'success',
    ERROR: 'error'
};

class TaskPage extends ModulePage {
    state = { busy: false, error: null };

    evalStepDescription = (step) => {
        const { origins, taskId } = this.props;
        const documentData = origins && taskId && origins[taskId] ? origins[taskId].document.data : {};
        const result = evalate(step.description, documentData);
        if (result instanceof Error) return step.description;
        return result;
    };

    componentGetTitle() {
        const { error } = this.state;
        const { t, taskScreens } = this.props;
        const { template, stepId, taskId } = propsToData(this.props);

        if (error) {
            return error.message;
        }

        if (!template || !template.jsonSchema.properties) {
            return '';
        }

        const step = template.jsonSchema.properties[stepId] || {};

        return [
            template.name,
            this.evalStepDescription(step),
            taskScreens[taskId] === screens.PREVIEW && t('Preview')
        ].filter(Boolean).join(': ');
    }

    componentDidMount() {
        super.componentDidMount();
        this.init(this.props);
    }

    componentWillUnmount() {
        const { actions } = this.props;
        const { taskId } = propsToData(this.props);
        if (this.getCurrentScreen() === screens.SUCCESS) {
            actions.clearStepAndScreen(taskId);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { actions, taskScreens } = nextProps;

        const {
            taskId: oldTaskId,
            stepId: oldStepId,
            workflowTemplateId: oldWorkflowTemplateId,
            taskTemplateId: oldTasktemplateId
        } = this.props;

        const {
            taskId: newTaskId,
            stepId: newStepId,
            workflowTemplateId: newWorkflowTemplateId,
            taskTemplateId: newTasktemplateId
        } = nextProps;

        if (newTaskId !== oldTaskId || oldWorkflowTemplateId !== newWorkflowTemplateId || oldTasktemplateId !== newTasktemplateId) {
            this.init(nextProps);
            return;
        }

        if (oldStepId !== newStepId) {
            const { task, taskId, stepId, steps } = propsToData(nextProps);
            const { document: { fileId } } = task;

            if (!taskScreens[taskId]) {
                if (fileId && !stepId) {
                    actions.setTaskScreen(taskId, screens.PREVIEW);
                } else if (steps.length) {
                    actions.setTaskScreen(taskId, screens.EDIT);
                }
            }
        }
    }

    setBusy = busy => this.setState({ busy });

    getRootPath = () => {
        const { rootPage, rootPath, taskId } = this.props;

        return rootPage || `${rootPath}/${taskId || ''}`;
    }

    init = async (props) => {
        const {
            t,
            actions,
            taskScreens,
            tasks,
            templates,
            taskId,
            stepId,
            workflowTemplateId,
            taskTemplateId
        } = props;

        if (workflowTemplateId) {
            const newTask = await actions.createTask({ workflowTemplateId, taskTemplateId });

            if (newTask instanceof Error) {
                const isCyrillic = /^[-'‘’,.№" АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЬьЮюЯя0-9]+$/.test(newTask.message);
                this.setState({ error: new Error(isCyrillic ? newTask.message : t(newTask.message)) });
                return;
            }

            await actions.markTaskRead(newTask.id);
            history.replace(this.getRootPath() + newTask.id);
            return;
        }

        const task = tasks[taskId] || await actions.loadTask(taskId);

        if (task instanceof Error) {
            const translatedMessage = t(task.message);
            const isCyrillic = /[А-ЩЬЮЯҐЄІЇа-щьюяґєії]/.test(translatedMessage);
            const message = isCyrillic ? translatedMessage : t('ErrorLoadingTaks');

            this.setState({ error: new Error(message) });
            return;
        }

        const template = templates[task.taskTemplateId] || await actions.loadDocumentTemplate(task.taskTemplateId);

        if (template instanceof Error) {
            this.setState({ error: new Error(t('ErrorLoadingTemplate')) });
            return;
        }

        if (!template.jsonSchema.stepOrders) {
            this.setState({ error: new Error(t('InvalidTemplate')) });
            return;
        }

        let steps = evalate(template.jsonSchema.stepOrders, task.document.data);

        if (steps instanceof Error) {
            steps.commit({ type: 'step orders', template, task });
            steps = [];
        }

        const { document: { fileId, data }, finished } = task;

        if (steps && !steps.length && !finished && !fileId) {
            this.handleFinishEditing();
            return;
        }

        if (!taskScreens[taskId]) {
            if (fileId && !stepId) {
                const invalidStep = steps
                    .filter(stepName => validateData(data[stepName], template.jsonSchema.properties[stepName], data).length)
                    .shift();

                if (invalidStep) {
                    actions.setTaskScreen(taskId, screens.EDIT);
                    actions.setTaskStep(taskId, steps.indexOf(invalidStep));
                    history.replace(this.getRootPath() + `/${invalidStep}`);
                } else {
                    actions.setTaskScreen(taskId, screens.PREVIEW);
                }
            } else if (steps.length) {
                actions.setTaskScreen(taskId, screens.EDIT);
            }
        }

        if (!task.meta.isRead && !task.finished && !processList.has('markTaskRead', taskId)) {
            processList.set('markTaskRead', actions.markTaskRead, taskId);
        }

        if (!task.finished && task.document && (task.document.signatures.length || task.document.signatureRejections.length)) {
            actions.setTaskScreen(taskId, screens.PREVIEW);
            return history.push(this.getRootPath());
        }
    };

    handleFinishEditing = async () => {
        const { t, actions } = this.props;
        const { taskId, task, template, template: { jsonSchema: { pdfRequired } } } = propsToData(this.props);
        const { finished } = task;

        await waiter.run(taskId);

        if (pdfRequired === false && !signRequired(template, task)) {
            return this.commitDocument();
        }

        this.setState({ busy: true });

        if (!finished) {
            const validateDocumentBeforeCommit = await actions.validateDocument(task.documentId);

            if (validateDocumentBeforeCommit instanceof Error) {
                this.setState({ error: new Error(t('ErrorValidatingDocument')) });
                return;
            } else {
                await actions.generatePDFDocument(task.documentId);
                await actions.loadTask(taskId);
            }
        }
        this.setState({ busy: false });
        actions.setTaskScreen(taskId, screens.PREVIEW);
        return history.push(this.getRootPath());
    };

    backToEdit = () => {
        const { actions } = this.props;
        const { taskId, steps } = propsToData(this.props);
        actions.setTaskScreen(taskId, screens.EDIT);
        history.replace(this.getRootPath() + `/${steps.pop()}`);
    };

    commitDocument = async () => {
        const { t, actions } = this.props;
        const { taskId, task, task: { isMeSigner, signerUsers }, template } = propsToData(this.props);

        if (!isMeSigner && signRequired(template, task) && signerUsers.length) {
            return history.push('/tasks');
        }

        this.setState({ busy: true });
        const commitResult = await actions.commitTask(taskId);

        if (commitResult instanceof Error) {
            if (commitResult.message === 'Commit not available.') {
                commitResult.message = t('Commit not available.');
            } else {
                commitResult.message = t('ErrorCommitDocument');
            }

            this.setState({ error: commitResult });
            actions.setTaskScreen(taskId, screens.ERROR);
        } else {
            actions.setTaskScreen(taskId, screens.SUCCESS);
        }

        this.setState({ busy: false });

        return commitResult;
    };

    getCurrentScreen = () => {
        const { error } = this.state;
        const { taskScreens } = this.props;
        const { task, template, taskId, steps, stepId } = propsToData(this.props);

        if (error) {
            return screens.ERROR;
        }

        if (!steps || !task || !template) {
            return null;
        }

        const { document: { fileId } } = task;
        const taskScreen = taskScreens[taskId];

        if (task.finished) {
            if (screens.SUCCESS === taskScreen) {
                return screens.SUCCESS;
            }
            if (screens.PREVIEW === taskScreen && !stepId && fileId) {
                return screens.PREVIEW;
            }
            return screens.EDIT;
        }

        if (![screens.EDIT, screens.SUCCESS].includes(taskScreen) && fileId && !stepId) {
            return screens.PREVIEW;
        }

        return taskScreen || screens.EDIT;
    };

    renderScreens() {
        const { busy, error } = this.state;
        const { rootPath, onboardingTaskId } = this.props;
        const { steps } = propsToData(this.props);

        switch (this.getCurrentScreen()) {
            case screens.SUCCESS: {
                const { taskId, template: { jsonSchema } } = propsToData(this.props);
                return <SuccessMessage {...jsonSchema} taskId={taskId} rootPath={rootPath} />;
            }
            case screens.PREVIEW:
                return (
                    <PreviewScreen
                        {...this.props}
                        busy={busy}
                        setBusy={this.setBusy}
                        handleFinish={this.commitDocument}
                        backToEdit={this.backToEdit}
                        steps={steps}
                    />
                );
            case screens.EDIT:
                return (
                    <EditScreen
                        {...this.props}
                        busy={busy}
                        setBusy={this.setBusy}
                        handleFinish={this.handleFinishEditing}
                        getRootPath={this.getRootPath}
                    />
                );
            case screens.ERROR:
                return <ErrorScreen error={error} />;
            default:
                return <Preloader background={onboardingTaskId ? '#E7EEF3' : '#fff'} />;
        }
    }

    getDetails = () => {
        const { template, stepId } = propsToData(this.props);
        switch (this.getCurrentScreen()) {
            case screens.EDIT: {
                const { steepDetails } = (template && stepId && template.jsonSchema.properties[stepId]) || {};
                return steepDetails;
            }
            case screens.PREVIEW:
                return template && template.jsonSchema.printScreen;
            default:
                return null;
        }
    };

    getTitle = () => {
        const { t } = this.props;
        const { error } = this.state;

        if (error) {
            return t('ErrorDialogTitle');
        }

        const { task, template } = propsToData(this.props);
        if (!template || !task) {
            return t('Loading');
        }

        const evalatedTitle = evalate(template.jsonSchema.title, task.document.data);

        if (!(evalatedTitle instanceof Error)) {
            return evalatedTitle;
        }

        return template.jsonSchema.title || template.name;
    };

    render() {
        const { error } = this.state;
        const { location } = this.props;
        const { task, template } = propsToData(this.props);

        const showSignerList = (template && template.jsonSchema && signRequired(template, task)) && task && task.signerUsers && !!task.signerUsers.length;

        const loading = (!task || !template) && !error;

        return (
            <TaskPageLayout
                {...propsToData(this.props)}
                details={this.getDetails()}
                flexContent={this.getCurrentScreen() === screens.PREVIEW}
                location={location}
                title={this.getTitle()}
                loading={loading}
                showSignerList={showSignerList}
            >
                {this.renderScreens()}
            </TaskPageLayout>
        );
    }
}

const mapStateToProps = ({
    task,
    documentTemplate,
    auth: { info: { onboardingTaskId } }
}) => ({
    tasks: task.actual,
    taskScreens: task.screens,
    origins: task.origin,
    templates: documentTemplate.actual,
    onboardingTaskId
});

const mapDispatchToProps = dispatch => ({
    actions: {
        loadTask: bindActionCreators(loadTask, dispatch),
        createTask: bindActionCreators(createTask, dispatch),
        commitTask: bindActionCreators(commitTask, dispatch),
        setTaskStep: bindActionCreators(setTaskStep, dispatch),
        setTaskScreen: bindActionCreators(setTaskScreen, dispatch),
        clearStepAndScreen: bindActionCreators(clearStepAndScreen, dispatch),
        generatePDFDocument: bindActionCreators(generatePDFDocument, dispatch),
        loadDocumentTemplate: bindActionCreators(loadDocumentTemplate, dispatch),
        markTaskRead: bindActionCreators(markTaskRead, dispatch),
        validateDocument: bindActionCreators(validateDocument, dispatch)
    }
});

const translated = translate('TaskPage')(TaskPage);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import EditKeyMenuItem from './EditKeyMenuItem';
import DeleteKeyMenuItem from './DeleteKeyMenuItem';
import AccessKeyMenuItem from './AccessKeyMenuItem';
import ExportRegisterKeys from './ExportRegisterKeys';
import GenTSKeyMenuItem from './GenTSKeyMenuItem';


const KeyActions = ({ registerKey, registerId, actions }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => setAnchorEl(null);

    const menuItemProps = {
        actions,
        registerKey,
        registerId,
        onClose: handleClose,
        onChange: actions.load
    };

    return (
        <>
            <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                <MenuIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted={true}
            >
                <EditKeyMenuItem {...menuItemProps} />
                <ExportRegisterKeys {...menuItemProps} />
                <GenTSKeyMenuItem {...menuItemProps} />
                <AccessKeyMenuItem {...menuItemProps} />
                <DeleteKeyMenuItem {...menuItemProps} />
            </Menu>
        </>
    );
};

KeyActions.propTypes = {
    key: PropTypes.object,
    actions: PropTypes.object,
    registryId: PropTypes.string
};

KeyActions.defaultProps = {
    key: {},
    actions: {},
    registryId: null
};

export default KeyActions;

export default ({ eventTypes }) => ({
    type: 'object',
    properties: {
        description: {
            type: 'string',
            description: 'Опис',
            helperText: 'Приклад: подія',
            maxLength: 255
        },
        eventTypeId: {
            description: 'Тип події',
            control: 'string.element',
            options: (eventTypes || []).filter(({ id }) => id)
        },
        jsonSchema: {
            control: 'code.editor',
            description: 'JSON схема події',
            mode: 'json',
            validate: true
        }
        // htmlTemplate: {
        //     control: 'code.editor',
        //     description: 'HTML шаблон події',
        //     mode: 'html'
        // }
    },
    required: [
        'eventTypeId',
        'jsonSchema',
        'htmlTemplate'
    ]
});

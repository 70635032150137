import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import LeftSidebarLayout from 'layouts/LeftSidebar';
import Preloader from 'components/Preloader';

import TaskTable from 'application/manager/modules/tasks/pages/TaskList/components/TaskTable';

class TaskListLayout extends React.Component {
    renderContent() {
        const { templates, data, endPoint, handleItemClick } = this.props;

        if (templates === null || data === null) {
            return <Preloader />;
        }

        return (
            <TaskTable
                endPoint={endPoint}
                handleItemClick={handleItemClick}
            />
        );
    }

    render() {
        const {
            title,
            loading,
            location
        } = this.props;

        return (
            <LeftSidebarLayout location={location} title={title} loading={loading}>
                {this.renderContent()}
            </LeftSidebarLayout>
        );
    }
}

TaskListLayout.propTypes = {
    templates: PropTypes.array,
    endPoint: PropTypes.object.isRequired,
    TableToolbar: PropTypes.instanceOf(React.Component),
    checkable: PropTypes.bool,
    location: PropTypes.object.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

TaskListLayout.defaultProps = {
    templates: null,
    TableToolbar: null,
    checkable: false,
    title: null,
    loading: false,
    handleItemClick: () => null
};

export default translate('TaskListPage')(TaskListLayout);

import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import ExportRegisters from './ExportRegisters';
import EditRegisterMenuItem from './EditRegisterMenuItem';
import DeleteRegister from './DeleteRegister';

const RegisterActions = ({ register, actions }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => setAnchorEl(null);

    const menuItemProps = {
        actions,
        register,
        onClose: handleClose,
        onChange: actions.load
    };

    return (
        <>
            <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                <MenuIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted={true}
            >
                <EditRegisterMenuItem {...menuItemProps} />
                <ExportRegisters {...menuItemProps} />
                <DeleteRegister {...menuItemProps} />
            </Menu>
        </>
    );
};

RegisterActions.propTypes = {
    register: PropTypes.object,
    actions: PropTypes.object
};

RegisterActions.defaultProps = {
    register: {},
    actions: {}
};

export default RegisterActions;

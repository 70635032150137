import React from 'react';
import Mustache from 'mustache';
import tokens from './tokens';

const TAGS = ['{{', '}}'];

const renderToken = ([tokenName, tokenBody], index) => {
    const TokenComponent = tokens[tokenName];

    if (!TokenComponent) {
        return (
            <div>
                {`${tokenName} token not defined`}
            </div>
        );
    }

    return <TokenComponent key={index} body={tokenBody} />;
};

export default ({ template }) => Mustache.parse(template, TAGS).map(renderToken);

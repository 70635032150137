import * as api from 'services/api';
import * as Sentry from '@sentry/browser';

// import { addError } from 'actions/error';

const REQUEST_WORKFLOW_PROCESS_LOGS = 'REQUEST_WORKFLOW_PROCESS_LOGS';

export const requestWorkflowProcessLogs = processId => dispatch => api.get(`workflow-logs/${processId}`, REQUEST_WORKFLOW_PROCESS_LOGS, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailFetchingWorkflowProcessLogs')));
        Sentry.captureException(error);
        return error;
    });

export default { requestWorkflowProcessLogs };

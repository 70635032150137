import * as api from 'services/api';
import * as Sentry from '@sentry/browser';

const BLOCK_USER = 'USERS/BLOCK_USER';
const UNBLOCK_USER = 'USERS/UNBLOCK_USER';

const SET_USER_ADMIN = 'USERS/SET_USER_ADMIN';
const UNSET_USER_ADMIN = 'USERS/UNSET_USER_ADMIN';

export const blockUser = userId => dispatch => api.post(`users/${userId}/block`, {}, BLOCK_USER, dispatch, { userId })
    .catch((error) => {
        // dispatch(addError(new Error('FailFetchingUnit')));
        Sentry.captureException(error);
        return error;
    });


export const unblockUser = userId => dispatch => api.post(`users/${userId}/unblock`, {}, UNBLOCK_USER, dispatch, { userId })
    .catch((error) => {
        // dispatch(addError(new Error('FailFetchingUnit')));
        Sentry.captureException(error);
        return error;
    });

export const setAdmin = userId => dispatch => api.post(`users/${userId}/set-admin`, {}, SET_USER_ADMIN, dispatch, { userId })
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const unsetAdmin = userId => dispatch => api.post(`users/${userId}/unset-admin`, {}, UNSET_USER_ADMIN, dispatch, { userId })
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const quillModules = {
    toolbar: [
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{'align': []}],
        //['blockquote', 'code-block'],

        //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        //[{ 'font': [] }],
        //['link', 'image'],
        ['clean']
    ]
    /* ... other modules */
};

export const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'align',
    'list', 'bullet', 'indent',
    'background', 'color',
    //'link', 'image'
];
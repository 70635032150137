import React from 'react';
import Dropzone from 'react-dropzone';
import { translate } from 'react-translate';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core';

import Limits from 'components/JsonSchema/elements/SelectFiles/components/Limits';
import styles from 'components/JsonSchema/elements/SelectFiles/components/styles';

const SelectFileArea = ({
    t,
    classes,
    name,
    accept,
    maxSize,
    onSelect,
    multiple = true,
    renderContent,
    readOnly
}) => {
    const [active, setActive] = React.useState(false);

    return (
        <Dropzone
            name={name}
            accept={accept}
            maxSize={maxSize || undefined}
            multiple={multiple}
            activeClassName={classes.dropZoneActive}
            onDragEnter={() => setActive(true)}
            onDragLeave={() => setActive(false)}
            onDrop={
                val => {
                    onSelect(val);
                    setActive(false);
                }
            }
        >
            {
                ({ getRootProps, getInputProps }) => {
                    const { onDragEnter, onDragLeave, onDragOver, onDrop } = getRootProps();
                    return (
                        <div
                            className={
                                classNames(classes.dropZone, {
                                    [classes.dropZoneActive]: active
                                })
                            }
                        >
                            {
                                !readOnly ? (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className={classes.uploadButtonContainer}>
                                            {
                                                t('DropFiles', {
                                                    link: (
                                                        <div className={classes.link}>
                                                            {t('SelectFiles')}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <Limits accept={accept} maxSize={maxSize} />
                                    </div>
                                ) : null
                            }
                            {renderContent ? renderContent({ onDragEnter, onDragLeave, onDragOver, onDrop }) : null}
                        </div>
                    );
                }
            }
        </Dropzone>
    );
};

const styled = withStyles(styles)(SelectFileArea);
export default translate('Elements')(styled);

import React, { Fragment } from 'react';

import ShowPreview from './ShowPreview';
// import DownloadFile from './DownloadFile';
// import DeleteFile from './DeleteFile';

export default ({ item, actions, fileStorage }) => (
    <Fragment>
        <ShowPreview item={item} fileStorage={fileStorage} handleDownloadFile={(actions || {}).handleDownloadFile} />
        {/* <DownloadFile item={item} fileStorage={fileStorage} handleDownloadFile={actions.handleDownloadFile} /> */}
        {/* {actions.handleDeleteFile ? <DeleteFile item={item} handleDeleteFile={actions.handleDeleteFile} /> : null} */}
    </Fragment>
);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { withStyles } from '@material-ui/core';

const styles = {
    root: {
        display: 'flex'
    }
};

class SearchFilterChips extends React.Component {
    handleDelete = filterName => () => {
        const { filters, onFilterChange, onClose } = this.props;

        const value = Object.keys(filters)
            .filter(fName => fName !== filterName)
            .reduce((acc, fName) => ({
                ...acc,
                [fName]: filters[fName]
            }), {});

        onFilterChange(value);
        onClose();
    };

    render() {
        const { classes, filterHandlers, filters, onClick } = this.props;

        return (
            <div className={classes.root}>
                {Object.keys(filterHandlers).map((filterName) => {
                    const FilterHandler = filterHandlers[filterName];

                    if (!filters[filterName]) {
                        return null;
                    }

                    return (
                        <FilterHandler
                            key={filterName}
                            type="chip"
                            value={filters[filterName]}
                            onClick={({ currentTarget }) => onClick(currentTarget, filterName)}
                            onDelete={this.handleDelete(filterName)}
                        />
                    );
                })}
            </div>
        );
    }
}

SearchFilterChips.propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.object,
    filterHandlers: PropTypes.object,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onFilterChange: PropTypes.func
};

SearchFilterChips.defaultProps = {
    filters: {},
    filterHandlers: {},
    onClick: () => null,
    onClose: () => null,
    onFilterChange: () => null
};

const styled = withStyles(styles)(SearchFilterChips);
export default translate('DataTable')(styled);

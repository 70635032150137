import React, { Fragment } from 'react';

import DeleteWorkflow from 'application/manager/modules/workflow/pages/WorkflowList/components/TableToolbar/DeleteWorkflow';
import SelectStatus from './SelectStatus';

const TableTools = (props) => {
    const { rowsSelected, actions, filters } = props;

    return (
        <Fragment>
            {!filters.is_draft ? (
                <Fragment>
                    <SelectStatus
                        filters={filters}
                        value={filters.workflowStatusId || 0}
                        onChange={({ target: { value } }) => actions.onFilterChange({
                            workflowStatusId: value > 0 ? value : undefined,
                            is_draft: false,
                            filtered: true
                        })}
                    />
                </Fragment>
            ) : null}
            {!!(rowsSelected || []).length ? <DeleteWorkflow {...props} /> : null}
        </Fragment>
    );
};

export default TableTools;

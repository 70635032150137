import React from 'react';
import { translate } from 'react-translate';
import { Tooltip, IconButton } from '@material-ui/core';

import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import ConfirmDialog from 'components/ConfirmDialog';

const DeleteUserAction = ({ t, user, handleDelete, load }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Tooltip title={t('Delete')}>
                <IconButton onClick={() => setOpen(true)}>
                    <HighlightOffOutlinedIcon />
                </IconButton>
            </Tooltip>
            <ConfirmDialog
                open={open}
                title={t('DeleteUser')}
                description={t('DeleteUserPropmt')}
                handleClose={() => setOpen(false)}
                handleConfirm={async () => {
                    setOpen(false);
                    await handleDelete(user);
                    load();
                }}
            />
        </>
    );
};

export default translate('UserListPage')(DeleteUserAction);

import cleanDeep from 'clean-deep';
import { ChangeEvent } from 'components/JsonSchema';

const getLastNotEmptyIndex = (list) => {
    for (let i = list.length - 1; i >= 0; i--) {
        if (Object.keys(list[i]).length) {
            return i;
        }
    }
    return -1;
};

export const input = (value, items = {}) => {
    const props = Object.keys(items.properties || {});
    return Object.values(value || {})
        .map(item => props.map((prop) => ({ value: item[prop] || '' })));
};

export const output = (onChange, value, items = {}) => (changes, additions) => {
    const props = Object.keys(items.properties || {});
    let data = [...(value || [])];
    [].concat(changes, additions)
        .filter(Boolean)
        .forEach(({ row, col, value: newValue }) => {
            const propName = props[col];

            if (!data[row]) {
                data[row] = {};
            }
            if (!newValue) {
                if (data[row]) {
                    data[row][propName] = undefined;
                }
                return;
            }

            data[row][propName] = newValue;
        });

    data = cleanDeep(data, { emptyObjects: false, emptyArrays: false });
    const lastIndex = getLastNotEmptyIndex(data);
    onChange(new ChangeEvent(data.slice(0, lastIndex + 1), true));
};

export const headerData = headers => headers.map(header => header.map((cell) => {
    if (typeof cell === 'object') {
        return { value: cell.label, colSpan: cell.colspan || 1, hint: 'Valid', readOnly: true, disableEvents: true };
    }

    return { value: cell, hint: 'Valid', readOnly: true, disableEvents: true };
}));

import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Paper,
    Toolbar,
    withStyles
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LeftSidebarLayout, { Content } from 'layouts/LeftSidebar';
import humanDateFormat from 'helpers/humanDateFormat';
import Preloader from 'components/Preloader';
import SmartMessage from 'application/manager/modules/messages/pages/Message/components/SmartMessage';

const styles = {
    wrapper: {
        backgroundColor: '#eeeeee',
        marginTop: 20
    },
    chip: {
        marginRight: 20,
        border: 'none'
    },
    paper: {
        marginTop: 20,
        padding: 15
    }
};

const MessageLayout = ({
    t,
    classes,
    location,
    title,
    loading,
    message
}) => (
        <LeftSidebarLayout
            location={location}
            title={title}
            loading={loading}
            backButton={'/messages'}
        >
            <Content>
                {
                    message ? (
                        <>
                            <div className={classes.wrapper}>
                                <Toolbar>
                                    <Chip
                                        icon={<CalendarTodayIcon />}
                                        label={t('CreatedAt', { time: humanDateFormat(message.createdAt) })}
                                        className={classes.chip}
                                        variant="outlined"
                                    />
                                </Toolbar>
                            </div>
                            <Paper className={classes.paper} elevation={1}>
                                <SmartMessage template={message.fullMessage} />
                            </Paper>
                        </>
                    ) : <Preloader />
                }
            </Content>
        </LeftSidebarLayout>
    );

MessageLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    message: PropTypes.object
};

MessageLayout.defaultProps = {
    loading: false,
    message: null
};

export default withStyles(styles)(MessageLayout);

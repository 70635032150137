import React from 'react';
import PersonIcon from '@material-ui/icons/Person';

import UnitListPage from 'application/adminpanel/modules/users/pages/UnitList';
import UnitPage from 'application/adminpanel/modules/users/pages/Unit';

import UserListPage from 'application/adminpanel/modules/users/pages/UserList';

export default {
    routes: [{
        path: '/users/units/:unitId',
        component: UnitPage,
        title: 'Unit',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.users.Units' }
    }, {
        path: '/users/units',
        component: UnitListPage,
        title: 'UnitList',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.users.Units' }
    }, {
        path: '/users',
        component: UserListPage,
        title: 'UserList',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.users.Users' }
    }],
    navigation: [{
        id: 'Users',
        icon: <PersonIcon />,
        path: '/users',
        children: [{
            id: 'Users',
            title: 'List',
            path: '/users',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.users.Users' }
        }, {
            id: 'Units',
            title: 'UnitList',
            path: '/users/units',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.users.Units' }
        }]
    }]
};

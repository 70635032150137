import React from 'react';
import TimeLabel from 'components/Label/Time';
import userName from 'helpers/userName';
import StringFilterHandler from 'components/DataTable/components/StringFilterHandler';

import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';

import { Chip } from '@material-ui/core';
import UserActions from '../components/UserActions';
// import UserUnits from '../components/UserUnits';

export default ({ t, actions }) => ({
    controls: {
        pagination: true,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: true
    },
    checkable: false,
    columns: [{
        id: 'status',
        align: 'center',
        sortable: false,
        width: 120,
        padding: 'checkbox',
        hiddable: false,
        render: (value, { isLegal, isActive }) => (
            <>
                {isLegal ? <BusinessIcon /> : <PersonIcon />}
                {isActive ? <CheckCircleOutlineIcon /> : <BlockIcon />}
            </>
        )
    }, {
        id: 'name',
        align: 'left',
        sortable: false,
        name: t('Name'),
        padding: 'none',
        render: (value, user) => userName(user) + ` (${user.id})`
    }, {
        id: 'role',
        width: 160,
        align: 'left',
        sortable: false,
        hiddable: false,
        render: (value, { role }) => (role.split(';').includes('admin') ? <Chip label={t('Administrator')} /> : null)
    }, {
        id: 'ipn',
        width: 160,
        align: 'left',
        sortable: false,
        name: t('IPN')
    }, {
        id: 'phone',
        width: 160,
        align: 'left',
        sortable: false,
        name: t('Phone')
    }, {
        id: 'email',
        width: 160,
        align: 'left',
        sortable: false,
        name: t('Email')
    }, {
        id: 'createdAt',
        width: 160,
        align: 'center',
        sortable: false,
        padding: 'checkbox',
        name: t('CreatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'actions',
        width: 32,
        align: 'right',
        sortable: false,
        padding: 'checkbox',
        hiddable: false,
        render: (value, user) => <UserActions user={user} actions={actions} />
    }],
    filterHandlers: {
        id: props => <StringFilterHandler name={t('UserId')} {...props} />,
        role: props => <StringFilterHandler name={t('OnlyAdministrator')} filterValue="admin" {...props} />,
        ipn: props => <StringFilterHandler name={t('IPN')} {...props} />,
        email: props => <StringFilterHandler name={t('Email')} {...props} />,
        phone: props => <StringFilterHandler name={t('Phone')} {...props} />
    }
});

import React from 'react';

import logo from 'assets/img/logo-invert.svg';
import blockchainLogo from 'assets/img/Blockchain-logo.png';

import LeftSidebarLogo from './components/LeftSidebarLogo';

export default {
    navigation: [{
        priority: 100,
        Component: () => <LeftSidebarLogo logo={logo}/>
    }, {
        priority: -1,
        Component: () => <LeftSidebarLogo logo={blockchainLogo}/>
    }]
};

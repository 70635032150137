import React from 'react';
import { translate } from 'react-translate';

import {
    Typography,
    withStyles,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar
} from '@material-ui/core';

import { formatUserName } from 'helpers/userName';

const styles = {
    emptyList: {
        textAlign: 'center',
        opacity: 0.8,
        padding: '6px 0'
    }
};

const PerformerUserList = ({ t, classes, task: { performerUsers, performerUserNames } }) => {
    if (!Array.isArray(performerUserNames) || !performerUserNames.length) {
        return (
            <Typography variant="body2" className={classes.emptyList}>
                {t('Nobody')}
            </Typography>
        );
    }

    return (
        <List>
            {performerUserNames.map((performerUserName, index) => (
                <ListItem key={index}>
                    <ListItemAvatar>
                        <Avatar />
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatUserName(performerUserName)}
                    />
                </ListItem>
            ))}
        </List>
    );
};

const styled = withStyles(styles)(PerformerUserList);
export default translate('TaskPage')(styled);

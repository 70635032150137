import React from 'react';
import TimeLabel from 'components/Label/Time';

export default ({ t }) => ({
    controls: {
        pagination: true,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: true
    },
    checkable: true,
    columns: [{
        id: 'name',
        align: 'left',
        sortable: true,
        name: t('UnitName')
    }, {
        id: 'createdAt',
        width: 160,
        align: 'center',
        sortable: 'true',
        padding: 'checkbox',
        name: t('CreatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'updatedAt',
        width: 160,
        align: 'center',
        sortable: 'true',
        padding: 'checkbox',
        name: t('UpdatedAt'),
        render: value => <TimeLabel date={value} />
    }]
});

import React from 'react';
import { translate } from 'react-translate';
import _ from 'lodash/fp';

import LeftSidebarLayout from 'layouts/LeftSidebar';
import ModulePage from 'components/ModulePage';
import endPoint from 'application/adminpanel/endPoints/workflow';
import downloadBase64Attach from 'helpers/downloadBase64Attach';
import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import DataTable from 'components/DataTable';

import dataTableSettings from './variables/dataTableSettings';

import WorkflowTableToolbar from './components/WorkflowTableToolbar';

class WorkflowListPage extends ModulePage {
    componentDidMount() {
        super.componentDidMount();
        const { actions } = this.props;

        actions.load();
    }

    handleItemClick = ({ id }) => {
        const { history } = this.props;
        return history.push(`/workflow/${id}`);
    };

    exportWorkflow = workflowId => async () => {
        const { actions, data } = this.props;
        const workflow = data.find(({ id }) => id === workflowId);
        const blob = await actions.exportWorkflow(workflowId);
        return downloadBase64Attach({ fileName: workflow.name + '.bpmn' }, blob);
    };

    render() {
        const {
            t,
            actions,
            title,
            loading,
            location
        } = this.props;

        const settings = dataTableSettings({ t, exportWorkflow: this.exportWorkflow, actions });

        return (
            <LeftSidebarLayout location={location} title={t(title)} loading={loading}>
                <DataTable
                    {..._.merge(settings, dataTableAdapter(this.props, settings))}
                    onRowClick={this.handleItemClick}
                    CustomToolbar={() => <WorkflowTableToolbar actions={actions} />}
                />
            </LeftSidebarLayout>
        );
    }
}

const translated = translate('WorkflowListAdminPage')(WorkflowListPage);
export default dataTableConnect(endPoint)(translated);

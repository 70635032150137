import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Divider } from '@material-ui/core';

const styles = theme => ({
    divider: {
        margin: '40px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0'
        }
    }
});

const DividerElement = ({ classes, hidden }) => {
    if (hidden) {
        return null;
    }

    return (
        <Divider className={classes.divider} />
    );
};

DividerElement.propTypes = {
    classes: PropTypes.object.isRequired,
    hidden: PropTypes.bool
};

DividerElement.defaultProps = {
    hidden: false
};

export default withStyles(styles)(DividerElement);

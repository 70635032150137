import React from 'react';

import { Button } from '@material-ui/core';

const TableToolbar = ({ t, data, rowsSelected, actions }) => {
    const unreadMessages = data
        .filter(({ id }) => rowsSelected.includes(id))
        .filter(({ isRead }) => isRead === 0)
        .map(({ id }) => id);

    if (!unreadMessages.length) {
        return null;
    }

    return (
        <Button
            id="mark-all-msg-as-read"
            variant="outlined"
            onClick={async () => {
                await Promise.all(unreadMessages.map(actions.markMessageRead));
                await actions.onRowsSelect([]);
                actions.load();
            }
            }
        >
            {t('MarkAllAsRead')}
        </Button>
    );
};

export default TableToolbar;

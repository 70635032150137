import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import hotkeys from 'hotkeys-js';

import {
    Drawer,
    withStyles,
    CssBaseline
} from '@material-ui/core';

// import Scrollbar from 'components/Scrollbar';
import SplitPane from 'react-split-pane';

import Snackbars from 'components/Snackbars';
import ProgressLine from 'components/Preloader/ProgressLine';

import { setOpenSidebar } from 'actions/app';
import { closeError } from 'actions/error';
import { toggleDebugMode } from 'actions/auth';

import Header from 'layouts/components/Header';
import Navigator from 'layouts/components/Navigator';
import DebugTools from 'layouts/components/DebugTools';

import checkAccess from 'helpers/checkAccess';

const drawerWidth = 256;

const styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden'
    },
    sidebarWrapper: {
        width: drawerWidth,
        flexShrink: 0,
        '& .scrollbar-container::-webkit-scrollbar': {
            display: 'none'
        },
        '& .scrollbar-container': {
            scrollbarWidth: 'none'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.leftSidebarBg,
        position: 'inherit'
    },
    appContent: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        flex: 1,
        overflowY: 'auto',
        marginLeft: -drawerWidth,
        transition: 'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        overflowX: 'hidden'
    },
    toolbar: {
        backgroundColor: theme.leftSidebarBg,
        padding: 6
    },
    collapseButton: {
        padding: 5,
        minWidth: 5
    },
    flexContent: {
        display: 'flex',
        flexDirection: 'column'
    }
});

const LARGE_SCREEN_WIDTH = 600;

class Layout extends React.Component {
    constructor(props) {
        super(props);

        const { actions, openSidebar } = this.props;

        if (openSidebar === null) {
            actions.setOpenSidebar(window.innerWidth > LARGE_SCREEN_WIDTH);
        }
    }

    handleDrawerToggle = () => {
        const { actions, openSidebar } = this.props;
        actions.setOpenSidebar(!openSidebar);
    };

    componentDidMount() {
        const { actions } = this.props;
        window.addEventListener('resize', this.updateWindowDimensions);
        hotkeys('ctrl+x', actions.toggleDebugMode);
    }

    componentWillUnmount() {
        const { actions, openSidebar } = this.props;
        const open = window.innerWidth > LARGE_SCREEN_WIDTH;
        if (openSidebar && !open) {
            actions.setOpenSidebar(open);
        }
        window.removeEventListener('resize', this.updateWindowDimensions);
        hotkeys.unbind('ctrl+x');
    }

    updateWindowDimensions = () => {
        const { actions, openSidebar } = this.props;
        const open = window.innerWidth > LARGE_SCREEN_WIDTH;

        if (open !== openSidebar) {
            actions.setOpenSidebar(window.innerWidth > LARGE_SCREEN_WIDTH);
        }
    }

    renderNavigation() {
        const { classes, location, openSidebar } = this.props;

        return (
            <Drawer
                className={classes.sidebarWrapper}
                variant="persistent"
                open={openSidebar}
                onClose={this.handleDrawerToggle}
                classes={
                    {
                        paper: classes.drawerPaper
                    }
                }
            >
                <Navigator
                    location={location}
                />
            </Drawer>
        );
    }

    renderMainPane() {
        const {
            classes,
            title,
            // loading,
            children,
            // disableScrolls,
            flexContent,
            openSidebar,
            onboardingTaskId,
            backButton
        } = this.props;

        if (onboardingTaskId) {
            return <div id="main-container">{children}</div>;
        }

        return (
            <div id="main-container" className={classNames(classes.root, 'root-layout')}>
                {this.renderNavigation()}
                <div
                    className={
                        classNames(classes.appContent, {
                            [classes.contentShift]: openSidebar,
                            [classes.flexContent]: flexContent
                        })
                    }
                >
                    <Header
                        title={title}
                        open={openSidebar}
                        backButton={backButton}
                        onDrawerToggle={this.handleDrawerToggle}
                    />
                    {/* {disableScrolls ? children : ( */}
                    {/* <Scrollbar> */}
                    {children}
                    {/* </Scrollbar> */}
                    {/* )} */}
                </div>
            </div>
        );
    }

    renderPanes() {
        const { debugMode, userInfo, userUnits, debugTools } = this.props;

        const userIsGod = checkAccess({ userIsGod: true }, userInfo, userUnits);
        const userIsAdmin = checkAccess({ userIsAdmin: true }, userInfo, userUnits);

        const useDebugPane = userIsGod && userIsAdmin && debugMode;

        const mainPane = this.renderMainPane();

        if (!useDebugPane) {
            return mainPane;
        }

        return (
            <SplitPane split="horizontal" minSize="calc(100% - 400px)">
                {mainPane}
                <DebugTools debugTools={debugTools} />
            </SplitPane>
        );
    }

    render() {
        const { errors, actions, loading } = this.props;
        return (
            <>
                <CssBaseline />
                <ProgressLine loading={loading} />
                <Snackbars errors={errors} onClose={errorIndex => () => actions.closeError(errorIndex)} />
                {this.renderPanes()}
            </>
        );
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    disableScrolls: PropTypes.bool,
    openSidebar: PropTypes.bool,
    actions: PropTypes.object.isRequired
};

Layout.defaultProps = {
    disableScrolls: false,
    openSidebar: null
};

const mapStateToProps = ({
    app: { openSidebar },
    errors: { list },
    auth: { debugMode, userUnits, info, info: { onboardingTaskId } }
}) => ({
    errors: list,
    openSidebar,
    debugMode,
    userUnits,
    userInfo: info,
    onboardingTaskId
});

const mapDispatchToProps = dispatch => ({
    actions: {
        closeError: bindActionCreators(closeError, dispatch),
        setOpenSidebar: bindActionCreators(setOpenSidebar, dispatch),
        toggleDebugMode: bindActionCreators(toggleDebugMode, dispatch)
    }
});

const styled = withStyles(styles)(Layout);

export { default as Content } from 'layouts/components/Content';
export { default as DrawerContent } from 'layouts/components/DrawerContent';

export { drawerWidth };

export default connect(mapStateToProps, mapDispatchToProps)(styled);

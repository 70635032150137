class Message {
    constructor(message, variant, details){
        this.message = message;
        this.variant = variant;
        this.details = details;
    }
}

export default Message;


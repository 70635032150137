import { routerReducer } from 'react-router-redux';

import authReducer from 'reducers/auth';
import errorReducer from 'reducers/error';
import edsReducer from 'reducers/eds';
import usersReducer from 'reducers/users';
import appReducer from 'reducers/app';

import dataTableReducer from 'services/dataTable/reducer';
import workflowEndPoint from 'application/adminpanel/endPoints/workflow';
import unitsEndPoint from 'application/adminpanel/endPoints/units';
import usersEndPoint from 'application/adminpanel/endPoints/users';
import unitListControlEndPoint from 'application/adminpanel/endPoints/unitListControl';
import registryEndPoint from 'application/adminpanel/endPoints/registry';
import processesEndPoint from 'application/adminpanel/endPoints/processes';
import userProcessesEndPoint from 'application/adminpanel/endPoints/userProcesses';
import numberTemplatesEndPoint from 'application/adminpanel/endPoints/numberTemplates';
import registryKeyListEndPoint from 'application/adminpanel/endPoints/registryKeyList';

import workflowReducer from 'application/adminpanel/reducers/workflow';
import unitsReducer from 'application/adminpanel/reducers/units';
import tasksReducer from 'application/adminpanel/reducers/tasks';
import gatewaysReducer from 'application/adminpanel/reducers/gateways';
import eventsReducer from 'application/adminpanel/reducers/events';
import registryReducer from 'application/adminpanel/reducers/registry';
import workflowProcessReducer from 'application/adminpanel/reducers/workflowProcess';
import workflowProcessLogsReducer from 'application/adminpanel/reducers/workflowProcessLogs';
import numberTemplatesReducer from 'application/adminpanel/reducers/numberTemplates';

export default {
    app: appReducer,
    router: routerReducer,
    eds: edsReducer,
    auth: authReducer,
    errors: errorReducer,
    users: usersReducer,
    workflow: workflowReducer,
    units: unitsReducer,
    tasks: tasksReducer,
    gateways: gatewaysReducer,
    events: eventsReducer,
    registry: registryReducer,
    workflowProcess: workflowProcessReducer,
    workflowProcessLogs: workflowProcessLogsReducer,
    numberTemplates: numberTemplatesReducer,
    [workflowEndPoint.sourceName]: dataTableReducer(workflowEndPoint),
    [unitsEndPoint.sourceName]: dataTableReducer(unitsEndPoint),
    [usersEndPoint.sourceName]: dataTableReducer(usersEndPoint),
    [registryEndPoint.sourceName]: dataTableReducer(registryEndPoint),
    [processesEndPoint.sourceName]: dataTableReducer(processesEndPoint),
    [userProcessesEndPoint.sourceName]: dataTableReducer(userProcessesEndPoint),
    [unitListControlEndPoint.sourceName]: dataTableReducer(unitListControlEndPoint),
    [numberTemplatesEndPoint.sourceName]: dataTableReducer(numberTemplatesEndPoint),
    [registryKeyListEndPoint.sourceName]: dataTableReducer(registryKeyListEndPoint)
};

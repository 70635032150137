import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Select from 'components/Select';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';

import { requestAllUnits } from 'application/adminpanel/actions/units';

import processList from 'services/processList';
import { bindActionCreators } from 'redux';

const optionsToMenu = option => ({ ...option, value: option.id, label: option.name });

class UnitList extends React.Component {
    componentDidMount() {
        const { unitList, actions } = this.props;

        if (!unitList) {
            processList.hasOrSet('requestAllUnits', actions.requestAllUnits);
        }
    }

    handleChange = (value) => {
        const { onChange } = this.props;
        onChange((Array.isArray(value) ? value : [value] || []).map(opt => opt && opt.id).filter(Boolean));
    };

    render() {
        const { description, sample, required, error, path, width, value, unitList, excludeUnit, noMargin, multiple } = this.props;

        const values = value
            .map(unitId => (unitList || []).find(({ id }) => unitId === id))
            .filter(Boolean)
            .map(optionsToMenu);

        return (
            <ElementContainer
                sample={sample}
                description={description}
                required={required}
                error={error}
                bottomSample={true}
                width={width}
                noMargin={noMargin}
            >
                <Select
                    value={values}
                    error={error}
                    id={path.join('-')}
                    multiple={multiple}
                    onChange={this.handleChange}
                    options={unitList && unitList.filter(({ id }) => id !== excludeUnit).map(optionsToMenu)}
                />
            </ElementContainer>
        );
    }
}

UnitList.propTypes = {
    actions: PropTypes.object.isRequired,
    description: PropTypes.string,
    sample: PropTypes.string,
    value: PropTypes.object,
    error: PropTypes.object,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    path: PropTypes.array,
    multiple: PropTypes.bool
};

UnitList.defaultProps = {
    description: '',
    sample: '',
    value: [],
    error: null,
    required: false,
    onChange: () => null,
    path: [],
    multiple: true
};

const mapStateToProps = ({ units: { list } }) => ({ unitList: list });

const mapDispatchToProps = dispatch => ({
    actions: {
        requestAllUnits: bindActionCreators(requestAllUnits, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitList);

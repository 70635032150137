import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';

import {
    Toolbar,
    Tabs,
    Tab,
    IconButton,
    withStyles
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Scrollbar from 'components/Scrollbar';

import { toggleDebugMode } from 'actions/auth';

import tools from './tools';

const styles = {
    root: {
        height: '100%'
    },
    header: {
        padding: 0,
        minHeight: 32,
        borderBottom: 'rgb(199, 199, 199) 1px solid',
        height: 16,
        background: '#f1f1f1'
    },
    tabs: {
        flexGrow: 1,
        margin: 0,
        minHeight: 'auto'
    },
    tab: {
        minHeight: 31,
        fontSize: 12,
        margin: 0
    },
    toolContainer: {
        height: 'calc(100% - 32px)'
    },
    indicator: {
        display: 'none'
    }
};

class DebugTools extends React.Component {
    state = { expanded: false, activeTool: 0, height: 400 };

    handleResize = (e, { size: { height } }) => this.setState({ height });

    setActiveTool = (event, activeTool) => this.setState({ activeTool });

    getTools = () => {
        const { debugTools } = this.props;
        return Object.assign({}, tools, debugTools);
    }

    render() {
        const { t, classes, actions } = this.props;
        const { activeTool } = this.state;

        const debugTools = this.getTools();
        const activeToolComponent = Object.values(debugTools)[activeTool];

        return (
            <div className={classes.root}>
                <Toolbar className={classes.header}>
                    <Tabs
                        className={classes.tabs}
                        classes={{ indicator: classes.indicator }}
                        value={activeTool}
                        onChange={this.setActiveTool}
                    >
                        {Object.keys(debugTools).map(toolName => <Tab className={classes.tab} key={toolName} label={t(toolName)} />)}
                    </Tabs>
                    <IconButton className={classes.button} onClick={actions.toggleDebugMode}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <div className={classes.toolContainer}>
                    <Scrollbar>
                        {activeToolComponent || null}
                    </Scrollbar>
                </div>
            </div>
        );
    }
}

DebugTools.propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
};

DebugTools.defaultProps = {

};

const mapStateToProps = ({ auth: { info: userInfo, userUnits } }) => ({
    userInfo, userUnits
});

const mapDispatchToProps = dispatch => ({
    actions: {
        toggleDebugMode: bindActionCreators(toggleDebugMode, dispatch)
    }
});

const styled = withStyles(styles)(DebugTools);
const translated = translate('DebugTools')(styled);

export default connect(mapStateToProps, mapDispatchToProps)(translated);

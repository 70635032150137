import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { withStyles, InputBase } from '@material-ui/core';

const styles = theme => ({
    root: {
        color: 'inherit',
        width: '100%',
        backgroundColor: '#fafafa',
        borderBottom: `1px solid ${theme.borderColor}`
    },
    input: {
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            minWidth: 200
        }
    }
});

const SearchInputComponent = ({ t, classes, value, onChange, onKeyPress, onFocus, onBlur }) => (
    <InputBase
        id="search-input"
        value={value}
        placeholder={t('Search')}
        autoComplete="off"
        onChange={onChange}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
        classes={classes}
    />
);

SearchInputComponent.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func
};

SearchInputComponent.defaultProps = {
    value: '',
    onChange: () => null,
    onKeyPress: () => null
};

const styled = withStyles(styles)(SearchInputComponent);
export default translate('DataTable')(styled);

import React from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import {
    Dialog,
    Toolbar,
    Paper,
    ListItem,
    IconButton,
    Typography,
    withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AceEditor from 'react-ace';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/theme-twilight';

export const defaultHtml = `<!DOCTYPE html>
<html lang="uk">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
    </head>
    <body>
        
    </body>
</html>`;

const styles = {
    header: {
        padding: 0,
        backgroundColor: '#232323',
        minHeight: 32
    },
    title: {
        flexGrow: 1,
        color: '#E2E2E2',
        padding: '0 10px'
    },
    button: {
        color: '#E2E2E2!important'
    },
    dialog: {
        display: 'flex',
        '& .ace_editor': {
            flex: 1
        }
    },
    paper: {
        position: 'fixed',
        background: '#fff',
        zIndex: 1000,
        maxHeight: 300,
        overflow: 'auto'
    }
};

class CodeEditDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonSchema: {},
            showSuggestion: [],
            coords: {}
        };
        this.aceComponent = React.createRef();
    }

    insertSuggestion = (suggested) => {
        const { suggest, position } = this.state;
        const { editor } = this.aceComponent.current;

        if (!suggest.length) {
            editor.session.insert(position, suggested);
        } else {
            const { row } = position;

            const rangeProps = {
                wrap: true,
                caseSensitive: true,
                wholeWord: true,
                regExp: false,
                preventScroll: true
            };

            const range = editor.find(suggest, rangeProps);

            range && (range.start.row === row)
                && editor.session.replace(range, suggested);
        }
    };

    saveCoordinates = event => {
        const x = event.clientX;
        const y = event.clientY;
        this.setState({ coords: { x, y } });
    };

    loopSuggestions = (suggestOrigin) => {
        const { jsonSchema: { properties } } = this.state;

        const path = suggestOrigin.split('.');
        const lastPosition = path.length - 1;
        const suggest = path[lastPosition];

        const firstLevel = Object.keys(properties || {}) || [];
        const pathComputed = path
            .filter((el, i) => el.length && i !== path.length - 1)
            .map(el => el + '.properties')
            .join('.');
        const pathInSchema = objectPath.get(properties, pathComputed);

        const showSuggestion = (
            path.length === 1
                ? firstLevel
                : (Object.keys(pathInSchema || {}) || []))
            .filter(item => item.indexOf(suggest) !== -1 && suggest !== item);

        this.setState({
            showSuggestion,
            suggest
        });
    };

    onCursorChange = ({ cursor: { row, column } }) => {
        this.setState({
            showSuggestion: [],
            position: { row, column }
        });

        const getKeyWordPositions = (string, start, end) => {
            let posStart = string.indexOf(start);
            let posEnd = string.indexOf(end);

            while (posStart >= 0 && posEnd >= 0) {
                if (column >= posStart + start.length && column <= posEnd) {
                    const keyWord = string.slice(posStart + 2, posEnd);
                    this.loopSuggestions(keyWord);
                }
                posStart = string.indexOf(start, posStart + 1);
                posEnd = string.indexOf(end, posEnd + 1);
            }
        };

        setTimeout(() => {
            const { editor } = this.aceComponent.current;
            const line = editor.session.getLine(row);
            getKeyWordPositions(line, '{{', '}}');
        }, 100);
    };

    getJsonSchema = () => {
        const { schema } = this.props;

        if (!schema) return;

        try {
            this.setState({
                jsonSchema: JSON.parse(schema)
            });
        } catch (e) {
            // nothig to do
        }
    };

    setDefaultValue = (value, mode) => (mode === 'html' && !value.length ? defaultHtml : value) || '';

    renderSuggestion = () => {
        const { classes } = this.props;
        const { showSuggestion, coords } = this.state;

        if (!showSuggestion.length) return null;

        return (
            <Paper
                className={classes.paper}
                style={
                    {
                        top: coords.y + 10,
                        left: coords.x + 10
                    }
                }
            >
                {
                    (showSuggestion || []).map((suggest, index) => (
                        <ListItem
                            key={index}
                            button={true}
                            onClick={() => this.insertSuggestion(suggest)}
                        >
                            <Typography>{suggest}</Typography>
                        </ListItem>
                    ))
                }
            </Paper>
        );
    };

    componentDidMount = async () => {
        await this.getJsonSchema();
        document.body.addEventListener('click', this.saveCoordinates);
    };

    componentWillUnmount = () => {
        document.body.removeEventListener('click', this.saveCoordinates);
    };

    render = () => {
        const {
            classes,
            open,
            onClose,
            description,
            value,
            mode,
            ...rest
        } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen={true}
                fullWidth={true}
                classes={{ paper: classes.dialog }}
            >
                <Toolbar className={classes.header}>
                    <Typography variant="subtitle1" className={classes.title}>
                        {description}
                    </Typography>
                    <IconButton className={classes.button} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <AceEditor
                    {...rest}
                    mode={mode}
                    value={this.setDefaultValue(value, mode)}
                    ref={this.aceComponent}
                    theme="twilight"
                    enableSnippets={true}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    wrapEnabled={true}
                    width="100%"
                    height="100%"
                    setOptions={
                        {
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 4,
                            highlightActiveLine: true
                        }
                    }
                    onCursorChange={this.onCursorChange}
                />
                {this.renderSuggestion()}
            </Dialog>
        );
    };
}

CodeEditDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    value: PropTypes.string,
    description: PropTypes.string,
    mode: PropTypes.string,
    schema: PropTypes.string
};

CodeEditDialog.defaultProps = {
    open: false,
    onClose: () => null,
    onChange: () => null,
    onValidate: () => null,
    value: '',
    description: '',
    mode: 'json',
    schema: null
};

export default withStyles(styles)(CodeEditDialog);

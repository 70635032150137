export const stringify = (value) => {
    if (typeof value === 'string') {
        return value;
    }
    try {
        return JSON.stringify(value, null, 4);
    } catch (e) {
        return value;
    }
};

export const parse = (value) => {
    // if (typeof origin === 'string') {
    //     return value;
    // }
    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { translate } from 'react-translate';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
    Badge,
    ListItem,
    ListItemIcon,
    ListItemText,
    withStyles
} from '@material-ui/core';

const styles = theme => ({
    categoryWrapper: {
        display: 'block',
        marginLeft: -15,
        marginRight: -15,
        '&.active': {
            backgroundColor: theme.categoryWrapperActive,
            '& + a': {
                backgroundColor: theme.categoryWrapperActive,
                '&.active': {
                    backgroundColor: theme.navLinkActive
                },
                '&:hover': {
                    backgroundColor: theme.navLinkActive,
                    color: '#fff'
                },
                '& ~ a': {
                    backgroundColor: theme.categoryWrapperActive,
                    '&.active': {
                        backgroundColor: theme.navLinkActive
                    },
                    '&:hover': {
                        backgroundColor: theme.navLinkActive,
                        color: '#fff'
                    }
                }
            }
        }
    },
    categoryHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 0,
        '& svg': {
            fill: '#eee'
        }
    },
    itemActionable: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .1)'
        },
        '& svg': {
            fill: '#eee',
            backgroundColor: 'rgba(255, 255, 255, .1)'
        }
    },
    icon: {
        position: 'relative',
        minWidth: 40,
        top: '-1px',
        '& svg': {
            fontSize: 23,
            backgroundColor: 'transparent'
        }
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white
    },
    navLink: {
        color: '#fff',
        textDecoration: 'none',
        '&.active > div': {
            backgroundColor: theme.navLinkActive,
            color: '#fff'
        }
    },
    badge: {
        left: -4,
        height: 16,
        backgroundColor: '#a40000',
        padding: 4,
        minWidth: '25px'
    }
});

const hightlight = (pathName, id) => (pathName.indexOf(id.toLowerCase()) !== -1) && 'active';

const CategoryHeader = ({
    t,
    classes,
    pathname,
    id,
    title,
    path,
    icon,
    badge,
    oneChild,
    children
}) => (
    <div className={classNames(classes.categoryWrapper, hightlight(pathname, id))}>
        {
            oneChild ? null : (
                <ListItem
                    button={!!path}
                    className={
                        classNames(
                            classes.categoryHeader,
                            path && classes.itemActionable
                        )
                    }
                >
                    {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
                    <ListItemText
                        classes={
                            {
                                primary: classes.categoryHeaderPrimary
                            }
                        }
                    >
                        {t(title || id)}
                        {
                            badge && Number.isInteger(badge) ? (
                                <Badge
                                    badgeContent={badge}
                                    color="secondary"
                                    classes={
                                        {
                                            badge: classes.badge
                                        }
                                    }
                                >
                                    <span />
                                </Badge>
                            ) : null
                        }
                    </ListItemText>
                </ListItem>
            )
        }
        {children}
    </div>
);

CategoryHeader.propTypes = {
    classes: PropTypes.object.isRequired
};

const CategoryHeaderContainer = (props) => {
    const { classes, path, handleDrawerToggle, id } = props;

    const categoryHeader = <CategoryHeader {...props} />;

    return (
        <>
            {
                path ? (
                    <NavLink
                        exact={true}
                        to={path || ''}
                        onClick={handleDrawerToggle}
                        activeClassName="active"
                        className={classes.navLink}
                        id={id}
                    >
                        {categoryHeader}
                    </NavLink>
                ) : categoryHeader
            }
        </>
    );
};

CategoryHeaderContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired
};

const styled = withStyles(styles)(CategoryHeaderContainer);
export default translate('Navigator')(styled);

import React from 'react';

const DataSheetContainer = ({ headerRef, dataListRef, height, children, data, virtualizeRef }) => {
    const [calcHeight, setCalcHeight] = React.useState(height);
    const { averageRowHeight } = virtualizeRef || {};

    React.useEffect(() => {
        const headerHeight = (headerRef.current && headerRef.current.clientHeight) || 0;

        const bodyHeight = Math.max(
            (dataListRef.current && dataListRef.current.clientHeight) || 0,
            (data.length + 2) * (averageRowHeight || 20)
        );

        setCalcHeight((typeof height === 'number') ? Math.min(bodyHeight + headerHeight, height) : height);
    }, [headerRef, dataListRef, height, data, averageRowHeight]);

    return (
        <div style={{ height: calcHeight }}>
            {children}
        </div>
    );
};

export default DataSheetContainer;

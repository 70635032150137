import React from 'react';
import PropTypes from 'prop-types';

import Preloader from 'components/Preloader';
import LeftSidebarLayout from 'layouts/LeftSidebar';

import MessageTable from './MessageTable';

const MessageListLayout = ({ location, title, loading, data, handleItemClick }) => (
    <LeftSidebarLayout
        location={location}
        title={title}
        loading={loading}
    >
        {data === null ? <Preloader /> : <MessageTable handleItemClick={handleItemClick} />}
    </LeftSidebarLayout>
);

MessageListLayout.propTypes = {
    data: PropTypes.array,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

MessageListLayout.defaultProps = {
    data: null,
    loading: false,
    handleItemClick: () => null
};

export default MessageListLayout;

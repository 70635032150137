import React from 'react';

import AceEditor from 'react-ace';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-twilight';

const PropertyEditor = ({ value, onChange }) => {
    const updateHeight = (arg, activeEditor) => {
        const aceEditor = activeEditor;
        const newHeight = aceEditor.getSession().getScreenLength() *
            (aceEditor.renderer.lineHeight + aceEditor.renderer.scrollBar.getWidth());
        aceEditor.container.style.height = `${newHeight}px`;
        aceEditor.resize();
    };

    return (
        <AceEditor
            mode="json"
            theme="twilight"
            fontSize={14}
            showPrintMargin={false}
            showGutter={false}
            highlightActiveLine={true}
            value={value}
            onChange={onChange}
            width="100%"
            height={14}
            readOnly={false}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 4
            }}
            onLoad={editor => {
                editor.on('focus', updateHeight);
                editor.on('blur', (arg, activeEditor) => {
                    activeEditor.container.style.height = `${14}px`;
                    activeEditor.resize();
                });
                editor.on('change', updateHeight);
            }}
        />
    );
};

export default PropertyEditor;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = {
    root: {
        flexWrap: 'nowrap!important'
    },
    disabled: {
        opacity: 0.38
    }
};

const ValueContainer = ({ classes, selectProps, children, isDisabled }) => (
    <div
        className={classNames(selectProps.classes.valueContainer, classes.root, isDisabled && classes.disabled)}
    >
        {children}
    </div>
);

ValueContainer.propTypes = {
    selectProps: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    isDisabled: PropTypes.bool
};

ValueContainer.defaultProps = {
    children: '',
    isDisabled: false
};

export default withStyles(styles)(ValueContainer);

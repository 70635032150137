import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';

import {
    Tab,
    Tabs,
    Toolbar,
    withStyles
} from '@material-ui/core';

import { TreeListSelect } from 'components/TreeList';

import LeftSidebarLayout, { Content } from 'layouts/LeftSidebar';
import { requestRegisters, requestRegisterKeys } from 'application/manager/actions/registry';

import ModulePage from 'components/ModulePage';

import recordEndPoint from 'application/manager/endPoints/registryRecord';
import { load, onFilterChange, updateRecordValues, storeRecord, onRowsDelete, createRecord } from 'services/dataTable/actions';

import RegistryKeyTable from './components/RegistryKeyTable';
import RegistryHistoryTable from './components/RegistryHistoryTable';

const styles = {
    toolbar: {
        padding: '10px 0'
    },
    button: {
        width: 192,
        marginLeft: 20
    }
};

class RegistryPage extends ModulePage {
    state = { selected: null, newRecord: null, activeTab: 0 };

    componentGetTitle() {
        const { t } = this.props;
        return t('title');
    }

    handleSelectKey = (selected) => {
        const { keys } = this.props;
        const selectedKey = selected && keys.find(({ id }) => id === selected.id);

        if (!selected) {
            this.setState({ selected });
            return;
        }

        this.setState({
            selected: {
                ...selectedKey,
                name: selectedKey && selectedKey.description
            }
        });
    };

    componentDidMount() {
        super.componentDidMount();
        const { registers, keys, actions } = this.props;

        if (!registers) {
            actions.requestRegisters();
        }

        if (!keys) {
            actions.requestRegisterKeys();
        }
    }

    render() {
        const { selected, activeTab } = this.state;
        const { t, classes, location, registers, keys, loading } = this.props;

        let items = null;

        if (registers && keys) {
            items = (registers || []).map(({ id, description }) => ({
                id,
                name: description,
                items: (keys || []).filter(key => key.registerId === id).map(key => ({
                    id: key.id,
                    name: key.description
                }))
            })).map(parent => parent.items.length === 1 ? parent.items.shift() : parent);
        }

        return (
            <LeftSidebarLayout title={t('title')} location={location} loading={loading}>
                <Content>
                    <Toolbar className={classes.toolbar} id="select-key">
                        <TreeListSelect
                            placeholder={t('SelectKey')}
                            items={items}
                            selected={selected}
                            onSelect={this.handleSelectKey}
                            id="select-key"
                        />
                    </Toolbar>
                    <Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(event, tab) => this.setState({ activeTab: tab })}
                        aria-label="disabled tabs example"
                    >
                        <Tab label={t('Records')} />
                        {selected && selected.access && selected.access.allowHistory ? <Tab label={t('History')} /> : null}
                    </Tabs>
                    {activeTab === 0 ? <RegistryKeyTable selectedKey={selected} /> : null}
                    {activeTab === 1 ? <RegistryHistoryTable selectedKey={selected} /> : null}
                </Content>
            </LeftSidebarLayout>
        );
    }
}

const mapStateToProps = ({
    registry: { registers, keys },
    registryRecordList: { loading, data: records }
}) => ({
    registers,
    keys,
    loading,
    records
});

const mapDispatchToProps = dispatch => ({
    actions: {
        load: bindActionCreators(load(recordEndPoint), dispatch),
        onFilterChange: bindActionCreators(onFilterChange(recordEndPoint), dispatch),
        requestRegisters: bindActionCreators(requestRegisters, dispatch),
        requestRegisterKeys: bindActionCreators(requestRegisterKeys, dispatch),
        storeRecord: bindActionCreators(storeRecord(recordEndPoint), dispatch),
        updateRecordValues: bindActionCreators(updateRecordValues(recordEndPoint), dispatch),
        onRowsDelete: bindActionCreators(onRowsDelete(recordEndPoint), dispatch),
        createRecord: bindActionCreators(createRecord(recordEndPoint), dispatch)
    }
});

const translated = translate('RegistryPage')(RegistryPage);
const styled = withStyles(styles)(translated);
export default connect(mapStateToProps, mapDispatchToProps)(styled);

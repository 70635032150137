import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import Preloader from 'components/Preloader';
import FileDataTable from 'components/FileDataTable';

import { addError } from 'actions/error';
import { uploadFile } from 'application/manager/actions/files';

import SelectFileArea from './SelectFiles/components/SelectFileArea';

const File = ({
    t,
    value = [],
    actions,
    hidden,
    path,
    name,
    sample,
    maxSize,
    accept,
    readOnly,
    onChange
}) => {
    const [open, setOpen] = React.useState(false);
    const [busy, setBusy] = React.useState(false);

    if (hidden) return null;

    const files = [].concat(value)
        .filter(Boolean)
        .filter(file => Object.keys(file).length > 0);

    return (
        <FileDataTable
            directDownload={true}
            handleDeleteFile={(file) => {
                onChange(files.filter(({ url, link }) => {
                    if (file.url) {
                        return url !== file.url;
                    }
                    return link !== file.link;
                }));
            }}
            CustomToolbar={
                () => (readOnly ? null : (
                    <>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setOpen(true)}
                        >
                            {t(value && value.name ? 'UploadAnotherFile' : 'UploadFiles')}
                        </Button>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            fullWidth={true}
                            maxWidth="md"
                        >
                            <DialogTitle>{t('UploadFiles')}</DialogTitle>
                            <DialogContent>
                                {busy ? <Preloader /> : (
                                    <SelectFileArea
                                        path={path}
                                        name={name}
                                        sample={sample}
                                        maxSize={maxSize}
                                        accept={accept}
                                        multiple={false}
                                        readOnly={readOnly}
                                        onSelect={
                                            async (acceptedFiles) => {
                                                const acceptedFile = acceptedFiles.shift();
                                                setBusy(true);
                                                try {
                                                    const uploadResult = await actions.uploadFile(acceptedFile);

                                                    const uploadedFile = {
                                                        name: acceptedFile.name,
                                                        type: acceptedFile.type,
                                                        link: uploadResult.url
                                                    };

                                                    onChange(uploadedFile);
                                                    setOpen(false);
                                                } catch (e) {
                                                    // error handler
                                                }
                                                setBusy(false);
                                            }
                                        }
                                    />
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)}>
                                    {t('Close')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                ))
            }
            fileControl={true}
            data={files}
        />
    );
};

File.propTypes = {
    value: PropTypes.object.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    actions: {
        addError: bindActionCreators(addError, dispatch),
        uploadFile: bindActionCreators(uploadFile, dispatch)
    }
});

const translated = translate('Elements')(File);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import {
    MenuItem,
    ListItemIcon,
    ListItemText,
    Dialog
} from '@material-ui/core';
import Preloader from 'components/Preloader';
import Message from 'components/Snackbars/Message';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import downloadBase64Attach from 'helpers/downloadBase64Attach';

class ExportTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    exportTemplate = async () => {
        const { actions, template, onClose } = this.props;

        onClose();

        this.setState({ loading: true });

        const blob = await actions.exportTemplates(template.id);

        this.setState({ loading: false });

        if (blob instanceof Error) {
            blob.message === 'Max export limit reached.'
                ? this.handleErrorDialog()
                : actions.addMessage(new Message('FailExportingTemplates', 'error'));

            return null;
        }
        return downloadBase64Attach({ fileName: template.name + '.bpmn' }, blob);
    };

    render = () => {
        const { t } = this.props;
        const { loading } = this.state;

        return (
            <>
                {
                    loading ? (
                        <Dialog open={true}>
                            <Preloader />
                        </Dialog>
                    ) : null
                }
                <MenuItem onClick={this.exportTemplate}>
                    <ListItemIcon>
                        <SaveAltIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('ExportRegister')} />
                </MenuItem>
            </>
        );
    };
}

ExportTemplate.propTypes = {
    template: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

ExportTemplate.defaultProps = {
};

export default translate('RegistryListAdminPage')(ExportTemplate);

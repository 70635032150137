
export default [
    {
        id: true,
        name: 'withErrors'
    },
    {
        id: 'false',
        name: 'withoutErrors'
    },
];

import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import {
    Tooltip,
    IconButton
} from '@material-ui/core';
import downloadBase64Attach from 'helpers/downloadBase64Attach';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

class ExportUnits extends React.Component {

    exportUnits = async () => {
        const { rowsSelected, actions: { exportUnits } } = this.props;
        const blob = await exportUnits(rowsSelected);
        return downloadBase64Attach({ fileName: 'units.bpmn' }, blob);
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <Tooltip title={t('ExportUnits')}>
                    <IconButton onClick={this.exportUnits} id="export-units">
                        <CloudDownloadIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    }
}

ExportUnits.propTypes = {
    actions: PropTypes.object.isRequired,
    rowsSelected: PropTypes.array,
}

ExportUnits.defaultProps = {
    rowsSelected: []
};

export default translate('UnitsListPage')(ExportUnits);

import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const InputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

InputComponent.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const Control = ({ innerRef, selectProps, children, isDisabled, innerProps, hasValue, isFocused }) => (
    <TextField
        {...selectProps.textFieldProps}
        fullWidth={true}
        error={selectProps.error}
        label={selectProps.description}
        autoFocus={selectProps.autoFocus}
        disabled={isDisabled}
        InputLabelProps={{
            shrink: hasValue || isFocused,
            classes: {
                root: selectProps.classes.label
            }
        }}
        InputProps={{
            inputComponent: InputComponent,
            inputProps: {
                className: selectProps.classes.input,
                inputRef: innerRef,
                children,
                ...innerProps
            }
        }}
    />
);

Control.propTypes = {
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
    children: PropTypes.node
};

Control.defaultProps = {
    innerRef: () => null,
    innerProps: {},
    children: ''
};

export default Control;

import * as api from 'services/api';
import * as Sentry from '@sentry/browser';

import { addError } from 'actions/error';

const LOAD_DOCUMENT_TEMPLATE = 'LOAD_DOCUMENT_TEMPLATE';
const LOAD_DOCUMENT_TEMPLATES = 'LOAD_DOCUMENT_TEMPLATES';

export const loadDocumentTemplate = templateId => dispatch => api.get(`document-templates/${templateId}`, LOAD_DOCUMENT_TEMPLATE, dispatch).catch((error) => {
    // dispatch(addError(new Error('FailLoadingDocumentTemplate')));
    Sentry.captureException(error);
    return error;
});

export const loadDocumentTemplates = () => dispatch => api.get('document-templates', LOAD_DOCUMENT_TEMPLATES, dispatch).catch((error) => {
    dispatch(addError(new Error('FailLoadingDocumentTemplate')));
    Sentry.captureException(error);
    return error;
});

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {
    Toolbar,
    Tooltip,
    IconButton,
    TablePagination,
    withStyles
} from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIconOutlined from '@material-ui/icons/ViewModuleOutlined';

import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

import SelectAllButton from './components/SelectAllButton';
import SearchInput from './components/SearchInput';
import CustomizateColumns from './components/CustomizateColumns';

const styles = theme => ({
    toolbar: {
        padding: '12px 0',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 5,
            paddingLeft: 5,
            paddingBottom: 0
        },
        '& > *': {
            marginRight: 6
        },
        '&:first-child': {
            paddingLeft: 2
        },
        '&:last-child': {
            paddingRight: 12,
            marginRight: 0
        }
    },
    paginationToolbar: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 8
        }
    },
    grow: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0
    },
    container: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    flexContainer: {
        display: 'flex'
    },
    row: {
        borderBottom: 'none',
        padding: '8px 0'
    }
});
const DataTableToolbar = (props) => {
    const {
        t,
        classes,
        view,
        page,
        count,
        rowsPerPage,
        checkable,
        actions,
        data,
        search,
        filters,
        filterHandlers,
        CustomToolbar,
        toolbarPosition,
        rowsSelected,
        columns,
        hiddenColumns,
        controls,
        switchView,
        groupBy,
        grouping,
        toggleGrouping
    } = props;

    const selectableData = (data || []).filter(actions.isRowSelectable || Boolean);
    const selectedLength = selectableData.length;

    return (
        <div className={classNames(classes.container, {
            [classes.flexContainer]: !controls.pagination
        })}
        >
            <Toolbar className={classNames(classes.toolbar, classes.grow)}>
                {checkable ? (
                    <SelectAllButton
                        rowsSelected={rowsSelected}
                        selectableData={selectableData}
                        onRowsSelect={actions.onRowsSelect}
                    />
                ) : null}
                {(CustomToolbar && toolbarPosition === 'start') ? (
                    <CustomToolbar selectedLength={selectedLength} {...props} />
                ) : null}
                {controls.search ? (
                    <SearchInput
                        actions={actions}
                        search={search}
                        filters={filters}
                        filterHandlers={filterHandlers}
                    />
                ) : null}
                {controls.refresh ? (
                    <Tooltip title={t('Reload')}>
                        <IconButton onClick={(actions || {}).load} id="reload">
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
                {(CustomToolbar && toolbarPosition !== 'start') ? (
                    <CustomToolbar selectedLength={selectedLength} {...props} />
                ) : null}
            </Toolbar>
            {/* <div className={classes.grow} /> */}

            {controls.pagination ? (
                <TablePagination
                    id="table-pagination"
                    backIconButtonProps={{
                        id: 'table-pagination-back'
                    }}
                    nextIconButtonProps={{
                        id: 'table-pagination-next'
                    }}
                    className={classes.row}
                    classes={{ toolbar: classes.paginationToolbar }}
                    count={count}
                    onChangePage={(e, newPage) => actions.onChangePage && actions.onChangePage(newPage)}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={t('ROWS_PER_PAGE')}
                    labelDisplayedRows={({ from, to }) => t('DISPLAYED_ROWS', { from, to, total: count })}
                    rowsPerPageOptions={[10, 20, 50]}
                    onChangeRowsPerPage={({ target: { value } }) => actions.onChangeRowsPerPage && actions.onChangeRowsPerPage(value)}
                    page={page - 1}
                />
            ) : null}

            <Toolbar className={classes.toolbar}>
                {controls.switchView ? (
                    <Tooltip title={t('SwitchView')}>
                        <IconButton onClick={switchView} id="switch-view">
                            {view === 'table'
                                ? (<ViewModuleIconOutlined />)
                                : (<ViewListIcon />)}
                        </IconButton>
                    </Tooltip>
                ) : null}
                {groupBy ? (
                    <Tooltip title={t('ToggleGrouping')}>
                        <IconButton onClick={toggleGrouping} id="toggle-grouping">
                            {grouping
                                ? <ViewHeadlineIcon />
                                : <ViewAgendaIcon />}
                        </IconButton>
                    </Tooltip>
                ) : null}
                {controls.customizateColumns ? (
                    <CustomizateColumns
                        columns={columns}
                        hiddenColumns={hiddenColumns}
                        toggleColumnVisible={actions.toggleColumnVisible}
                    />
                ) : null}
            </Toolbar>
        </div>
    );
};

DataTableToolbar.propTypes = {
    t: PropTypes.func,
    classes: PropTypes.object,
    page: PropTypes.number,
    count: PropTypes.number,
    rowsPerPage: PropTypes.number,
    actions: PropTypes.object,
    data: PropTypes.array,
    rowsSelected: PropTypes.array,
    CustomToolbar: PropTypes.func,
    filterHandlers: PropTypes.object,
    toolbarPosition: PropTypes.string
};

DataTableToolbar.defaultProps = {
    t: null,
    classes: null,
    page: 0,
    count: 0,
    rowsPerPage: 10,
    actions: {},
    data: [],
    rowsSelected: [],
    CustomToolbar: null,
    toolbarPosition: 'middle',
    filterHandlers: {}
};

export default withStyles(styles)(DataTableToolbar);

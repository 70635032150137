import commonModule from 'application/manager/modules/common';
import tasksModule from 'application/manager/modules/tasks';
import workflowModule from 'application/manager/modules/workflow';
import registryModule from 'application/manager/modules/registry';
import profileModule from 'modules/profile';
import testModule from 'modules/test';
import messagesModule from 'application/manager/modules/messages';
import inboxModule from 'application/manager/modules/inbox';
import usersModules from 'application/manager/modules/users';
import homeModule from 'application/manager/modules/home';
import adminModule from 'application/manager/modules/admin';

const modules = [
    commonModule,
    messagesModule,
    adminModule,
    tasksModule,
    inboxModule,
    workflowModule,
    registryModule,
    profileModule,
    usersModules,
    testModule,
    homeModule
];

export default modules;

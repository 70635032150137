import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const MAX_ROW_WIDTH = 320;

const styles = {
    cell: {
        whiteSpace: 'normal !important',
        verticalAlign: 'middle',
        textAlign: 'center',
        wordBreak: 'break-word',
        color: 'rgb(0, 0, 0) !important'
    }
};

const HeaderCell = ({ classes, data, cell, cellKey, headAlign, headFontSize }) => {
    const [width, setWidth] = React.useState();
    const cellRef = React.useRef();

    React.useEffect(() => {
        const maxCellWidth = Math.max(...(data || []).map(({ [cellKey]: { value } }) => (value || '').length * 4));

        if (maxCellWidth && cellRef.current) {
            const minCellWidth = Math.min(MAX_ROW_WIDTH, maxCellWidth);
            setWidth(Math.max(cellRef.current.offsetWidth, minCellWidth));
        }
    }, [data, cellKey]);

    return (
        <th
            ref={cellRef}
            key={cellKey}
            className={classNames('cell read-only', classes.cell)}
            width={width}
            colSpan={cell.colspan}
            style={{
                verticalAlign: headAlign || 'middle',
                fontSize: headFontSize || 'inherit',
                padding: '0 2px'
            }}
        >
            {cell.label || cell}
        </th>
    );
};

export default withStyles(styles)(HeaderCell);

import React from 'react';

import AuthTools from './AuthTools';
import EDSFormTest from './EDSFormTest';
import EDSSignVerify from './EDSSignVerify';
import Curator from './Curator';

export default {
    AuthTools: <AuthTools />,
    EDSFormTest: <EDSFormTest />,
    EDSSignVerify: <EDSSignVerify />,
    Curator: <Curator />
};

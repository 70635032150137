import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    MenuItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import promiseChain from 'helpers/promiseChain';
import { SchemaFormModal } from 'components/JsonSchema';

import RegisterSelect from '../RegisterSelect';
import schema from '../../variables/registrySchema';

const EditRegisterMenuItem = ({ t, register, onClose, actions }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <MenuItem onClick={() => { setOpen(true); onClose(); }}>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary={t('EditRegister')} />
            </MenuItem>
            <SchemaFormModal
                open={open}
                value={register}
                translateError={t}
                schema={schema({ t })}
                title={t('EditRegister')}
                onClose={() => setOpen(false)}
                onChange={data => promiseChain([actions.saveRegister, actions.load], data)}
                customControls={{
                    RegisterSelect: props => <RegisterSelect excludeKey={register && register.id} {...props} />
                }}
            />
        </>
    );
};

EditRegisterMenuItem.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    register: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

EditRegisterMenuItem.defaultProps = {
    onClose: () => null
};

export default translate('RegistryListAdminPage')(EditRegisterMenuItem);

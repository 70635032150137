import * as api from 'services/api';
import * as Sentry from '@sentry/browser';
import { addError } from 'actions/error';

const EXPORT_REGISTERS = 'EXPORT_REGISTERS';
const IMPORT_REGISTERS = 'IMPORT_REGISTERS';
const GET_REGISTERS_KEYS = 'GET_REGISTERS_KEYS';
const GET_ALL_REGISTERS = 'GET_ALL_REGISTERS';
const GET_ALL_REGISTERS_KEYS = 'GET_ALL_REGISTERS_KEYS';
const IMPORT_REGISTERS_KEYS_XLS = 'IMPORT_REGISTERS_KEYS_XLS';
const CREATE_REGISTER = 'REGISTRY/CREATE_REGISTER';
const SAVE_REGISTER = 'REGISTRY/SAVE_REGISTER';
const CREATE_KEY = 'REGISTRY/CREATE_KEY';
const SAVE_KEY = 'REGISTRY/SAVE_KEY';
const DELETE_REGISTER = 'REGISTRY/DELETE_REGISTER';
const DELETE_KEY = 'REGISTRY/DELETE_KEY';
const SET_REGISTER_ACCESS = 'SET_REGISTER_ACCESS';
const GET_REGISTER_ACCESS = 'GET_REGISTER_ACCESS';
const PUT_REGISTER_ACCESS = 'PUT_REGISTER_ACCESS';
const EXPORT_REGISTERS_KEYS = 'EXPORT_REGISTERS_KEYS';

export const exportRegisters = (registerId, withData = false, useStream = true) => dispatch => (
    api.get(`registers/${registerId}/export?with_data=${withData}&file=${useStream}`, EXPORT_REGISTERS, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailExportingRegisters')));
        Sentry.captureException(error);
        return error;
    })
);

export const importRegisters = (file, force = false, rewriteSchema = false, clearRecords = false, addData = false, useStream = true) => dispatch => (
    api.upload(`registers/import?force=${force}&rewrite_schema=${rewriteSchema}&clear_records=${clearRecords}&add_data=${addData}&file=${useStream}`, file, {}, IMPORT_REGISTERS, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailImportingRegisters')));
        Sentry.captureException(error);
        return error;
    })
);

export const getRegistersKeys = registerId => dispatch => api.get(`registers/keys?register_id=${registerId}`, GET_REGISTERS_KEYS, dispatch, { registerId })
    .catch((error) => {
        // dispatch(addError(new Error('FailGettingRegistersKeys')));
        Sentry.captureException(error);
        return error;
    });

export const getAllRegistersKeys = registerId => dispatch => api.get(`registers/keys/all?register_id=${registerId}`, GET_ALL_REGISTERS_KEYS, dispatch, { registerId })
    .catch((error) => {
        // dispatch(addError(new Error('FailGettingRegistersKeys')));
        Sentry.captureException(error);
        return error;
    });

export const getAllRegisters = () => dispatch => api.get('registers/all', GET_ALL_REGISTERS, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailGettingRegistersKeys')));
        Sentry.captureException(error);
        return error;
    });

export const importRegistersKeysXLS = (file, registerId, keyId, unique = '') => dispatch => api.upload(`registers/${registerId}/keys/${keyId}/import-xlsx?unique=${unique}`, file, {}, IMPORT_REGISTERS_KEYS_XLS, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailImportingRegisters')));
        Sentry.captureException(error);
        return error;
    });

export const createRegister = data => dispatch => api.post('registers', data, CREATE_REGISTER, dispatch);
// .catch((error) => {
//     // dispatch(addError(new Error('FailCreatingRegister')));
//     Sentry.captureException(error);
//     return error;
// });

export const saveRegister = ({ id, ...data }) => dispatch => api.put(`registers/${id}`, data, SAVE_REGISTER, dispatch, { id, data });
// .catch((error) => {
//     // dispatch(addError(new Error('FailCreatingRegister')));
//     Sentry.captureException(error);
//     return error;
// });

export const deleteRegister = registerId => dispatch => api.del(`registers/${registerId}`, {}, DELETE_REGISTER, dispatch, { registerId });

export const deleteKey = ({registerId, keyId}) => dispatch => api.del(`registers/keys/${keyId}?register_id=${registerId}`, {}, DELETE_KEY, dispatch, {registerId, keyId});

export const createKey = data => dispatch => {
    const { registerId } = data;
    return api.post('registers/keys', data, CREATE_KEY, dispatch, { registerId, data });
};

export const saveKey = ({ id, ...data }) => dispatch => api.put(`registers/keys/${id}`, data, SAVE_KEY, dispatch, { id, data });

export const setRegistersAccess = (body) => dispatch => (
    api.post('unit-access', body, SET_REGISTER_ACCESS, dispatch)
    .catch((error) => {
        dispatch(addError(new Error('FailSettingRegistersAccess')));
        Sentry.captureException(error);
        return error;
    })
);

export const getRegistersAccess = () => dispatch => (
    api.get('unit-access', GET_REGISTER_ACCESS, dispatch)
    .catch((error) => {
        dispatch(addError(new Error('FailGettingRegistersAccess')));
        Sentry.captureException(error);
        return error;
    })
);

export const putRegistersAccess = (unitAccessId, body) => dispatch => (
    api.put(`unit-access/${unitAccessId}`, body, PUT_REGISTER_ACCESS, dispatch)
    .catch((error) => {
        dispatch(addError(new Error('FailPutingRegistersAccess')));
        Sentry.captureException(error);
        return error;
    })
);

export const exportRegistersKeys = (registerId, keyId) => dispatch => (
    api.get(`registers/${registerId}/keys/${keyId}/export-xlsx`, EXPORT_REGISTERS_KEYS, dispatch)
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    })
);

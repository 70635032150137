import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import minUnusedIndex from 'helpers/minUnusedIndex';

const eventElementTypes = ['bpmn:EndEvent'];

class EndEventElement extends React.Component {
    async componentDidMount() {
        this.loadEvent();
    }

    async componentDidUpdate(prevProps) {
        const { selectionId } = this.props;

        if (prevProps.selectionId !== selectionId) {
            this.loadEvent();
        }
    }

    getNextEventId = (element) => {
        const { modeler } = this.props;

        const ids = modeler.get('elementRegistry').getAll()
            .filter(({ type, id }) => eventElementTypes.includes(type) && id !== element.id)
            .map(this.getEventId)
            .map(String)
            .map(id => parseInt(id, 10));

        return minUnusedIndex(ids, 1);
    }

    isLocalId = id => eventElementTypes.filter((type) => {
        const suffix = type.split(':').pop();
        return id.indexOf(suffix) === 0;
    }).length > 0;

    loadEvent = async () => {
        const { element, onChange } = this.props;

        if (!this.isLocalId(element.businessObject.id)) {
            return;
        }

        const nextEventId = this.getNextEventId(element);
        if (!nextEventId) {
            return;
        }

        element.businessObject.id = ['end-event', nextEventId].join('-');
        element.businessObject.name = ['end-event', nextEventId].join('-');
        onChange(element.businessObject);
        // this.forceUpdate();
    }

    getEventId = ({ businessObject: { id } }) => parseInt(id.split('-').pop(), 10);

    render() {
        return null;
    }
}

EndEventElement.propTypes = {
    element: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    selectionId: PropTypes.string,
    modeler: PropTypes.object
};

EndEventElement.defaultProps = {
    selectionId: null,
    modeler: null
};

export default translate('WorkflowAdminPage')(EndEventElement);

import React, { Fragment } from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    MenuItem,
    ListItemIcon,
    ListItemText,
    Dialog
} from '@material-ui/core';
import Preloader from 'components/Preloader';
import Message from 'components/Snackbars/Message';
import CodeIcon from '@material-ui/icons/Code';
import stringToBlob from 'helpers/stringToBlob';
import downloadBase64Attach from 'helpers/downloadBase64Attach';
// import { exportRegistersKeys } from 'application/adminpanel/actions/registry';
import { addMessage } from 'actions/error';
import { genTSbyRegisterKey } from 'helpers/genTSbyRegisterKey';

class GenTSKeyMenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    genTS = async () => {
        const { actions, registerKey, onClose } = this.props;

        this.setState({ loading: true });

        const [name, src] = await genTSbyRegisterKey(registerKey);
        const blob = stringToBlob(src);
        // const blob = await importActions.exportRegistersKeys(registerKey.registerId, registerKey.id);

        this.setState({ loading: false });

        onClose();

        if (blob instanceof Error) {
            actions.addMessage(new Message('FailExportingRegisters', 'error'));
            return null;
        }

        return downloadBase64Attach({ fileName: name + '.ts' }, blob);
    };

    render = () => {
        const { t } = this.props;
        const { loading } = this.state;

        return (
            <Fragment>
                {loading &&
                        <Dialog open={true}>
                            <Preloader />
                        </Dialog>
                }
                <MenuItem onClick={this.genTS}>
                    <ListItemIcon>
                        <CodeIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('ExportRegister') + ' TypeScript'} />
                </MenuItem>
            </Fragment>
        );
    };
}

GenTSKeyMenuItem.propTypes = {
    registerId: PropTypes.string.isRequired,
    registerKey: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

GenTSKeyMenuItem.defaultProps = {
};

const mapDispatchToProps = dispatch => ({
    actions: {
        addMessage: bindActionCreators(addMessage, dispatch)
    }
});
const translated = translate('RegistryListAdminPage')(GenTSKeyMenuItem);
export default connect(null, mapDispatchToProps)(translated);

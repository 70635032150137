/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Children, isValidElement, cloneElement } from 'react';
import classNames from 'classnames';
import { withStyles, Tooltip } from '@material-ui/core';

import EJVError from 'components/JsonSchema/components/EJVError';

const styles = {
    cell: {
        // whiteSpace: 'normal !important',
        minWidth: 110,
        maxHeight: 44,
        '&.read-only': {
            whiteSpace: 'initial !important',
            paddingRight: 5,
            paddingLeft: 5
        },
        '& .value-viewer': {
            padding: '0 4px',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: 44,
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
        }
    },
    error: {
        background: '#fdb59c !important'
    }
};

const CustomTooltip = withStyles({
    tooltip: {
        color: '#c8221a',
        backgroundColor: '#f1b992',
        opacity: 0.7,
        fontWeight: 'bold',
        fontSize: 12
    }
})(Tooltip);

const SheetCell = ({
    classes,
    row,
    col,
    children,
    path,
    errors,
    className,
    items = {},
    onContextMenu,
    onDoubleClick,
    onMouseDown,
    onMouseOver
}) => {
    const cellRef = React.useRef();
    const propNames = Object.keys(items.properties || {});
    const error = errors.find(({ path: errorPath }) => errorPath === [path, row, propNames[col]].join('.'));

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { cellRef });
        }

        return child;
    });

    const sheetCell = (
        <td
            ref={cellRef}
            tabIndex="0"
            id={[path, row, propNames[col]].join('-')}
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseOver={onMouseOver}
            className={
                classNames(className, {
                    [classes.cell]: true,
                    [classes.error]: !!error
                })
            }
            style={
                {
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    wordBreak: 'break-word',
                    color: '#000',
                    outline: 'none'
                }
            }
        >
            {childrenWithProps}
        </td>
    );

    return (
        error ? (
            <CustomTooltip
                title={<EJVError error={error} />}
                placement="top"
            >
                {sheetCell}
            </CustomTooltip>
        ) : sheetCell
    );
};

export default withStyles(styles)(SheetCell);

import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import WorkflowListPage from 'application/adminpanel/modules/workflow/pages/WorkflowList';
import WorkflowPage from 'application/adminpanel/modules/workflow/pages/Workflow';
import NumberTemplateListPage from 'application/adminpanel/modules/workflow/pages/NumberTemplateList';
import WorkflowCategoryListPage from 'application/adminpanel/modules/workflow/pages/WorkflowCategoryList';
import WorkflowUsers  from "./pages/WorkflowUsers";

export default {
    routes: [{
        path: '/workflow/users',
        component: WorkflowUsers,
        title: 'AdminWorkflowList',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
    }, {
        path: '/workflow/categories',
        component: WorkflowCategoryListPage,
        title: 'WorkflowCategoryList',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
    }, {
        path: '/workflow/templates',
        component: NumberTemplateListPage,
        title: 'NumberTemplateList',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
    }, {
        path: '/workflow/:workflowId/:selectionId',
        component: WorkflowPage,
        title: 'AdminWorkflow',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
    }, {
        path: '/workflow/:workflowId',
        component: WorkflowPage,
        title: 'AdminWorkflow',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
    }, {
        path: '/workflow',
        component: WorkflowListPage,
        title: 'AdminWorkflowList',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
    }],
    navigation: [{
        id: 'Workflow',
        title: 'AdminWorkflowList',
        icon: <SettingsIcon />,
        path: '/workflow',
        children: [{
            id: 'Workflow',
            title: 'List',
            path: '/workflow'
        }, {
            id: 'WorkflowCategories',
            path: '/workflow/categories'
        }, {
            id: 'NumberTemplates',
            title: 'NumberTemplates',
            path: '/workflow/templates'
        }, {
            id: 'WorkflowUsers',
            title: 'WorkflowUsers',
            path: '/workflow/users'
        }],
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
    }]
};

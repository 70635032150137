export default (bytes, si) => {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KB(KiB)', 'MB(MiB)', 'GB(GiB)', 'TB(TiB)', 'PB(PiB)', 'EB(EiB)', 'ZB(ZiB)', 'YB(YiB)'];
    let u = -1;
    do {
        bytes /= thresh;
        u += 1;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
};

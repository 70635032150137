import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Dialog } from '@material-ui/core';
import Preloader from 'components/Preloader';
import Message from 'components/Snackbars/Message';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

class ImportTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    handleChange = async ({ target }) => {
        const { actions } = this.props;

        this.setState({ loading: true });

        const importResult = await actions.importTemplates(target.files[0]);

        this.setState({ loading: false });

        if (importResult instanceof Error) {
            if (importResult.message.indexOf('already exists') !== -1) {
                actions.addMessage(new Message('NumberTemplateAlreadyExists', 'error'));
            } else {
                actions.addMessage(new Message('InvalidFile', 'error'));
            }
            return;
        }

        actions.addMessage(new Message('ImportRegistersSuccess', 'success'));

        actions.load();
    };

    handleUploadClick = () => this.input && this.input.click();

    render = () => {
        const { t } = this.props;
        const { loading } = this.state;

        return (
            <>
                {
                    loading ? (
                        <Dialog open={true}>
                            <Preloader />
                        </Dialog>
                    ) : null
                }
                <input
                    ref={
                        ref => {
                            this.input = ref;
                        }
                    }
                    type="file"
                    accept=".bpmn, application/bpmn"
                    onChange={this.handleChange}
                    hidden={true}
                    multiple={false}
                />
                <Tooltip title={t('ImportTemplates')}>
                    <IconButton
                        id="import-units"
                        onClick={this.handleUploadClick}
                    >
                        <CloudUploadIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
}

ImportTemplate.propTypes = {
    actions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

ImportTemplate.defaultProps = {
};

export default translate('NumberTemplateListPage')(ImportTemplate);

/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import { loadTask } from 'application/manager/actions/task';
import {
    requestRegisterKeyRecordsFilter
} from 'application/manager/actions/registry';
import { ChangeEvent } from 'components/JsonSchema';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';
import RegisterChip from 'components/JsonSchema/elements/Register/components/Chip';
import TreeSelect from 'components/JsonSchema/elements/TreeSelect';

const styles = {
    containerWrapper: {
        marginBottom: 0,
        '& *': {
            marginBottom: 0
        }
    }
};

class RegisterSelect extends React.Component {
    optionInValue = ({ id }) => {
        const { value } = this.props;
        return (value || []).some(option => option.id === id);
    };

    handleChange = async (value) => {
        const { registerActions, onChange, taskId } = this.props;
        onChange && await onChange(new ChangeEvent(value, true, true));
        registerActions.loadTask(taskId);
    };

    onChange = (option) => {
        const { value } = this.props;

        if (!option) return;

        if (!this.optionInValue(option)) this.handleChange((value || []).concat(option));
    };

    getDisabled = () => {
        const {
            disabled,
            maxValues,
            value,
            rootDocument
        } = this.props;

        if ((maxValues && value) && maxValues <= value.length) {
            return true;
        }

        try {
            // eslint-disable-next-line no-eval
            return disabled && eval(disabled)(rootDocument.data);
        } catch (e) {
            return false;
        }
    };

    getFilters = () => {
        const {
            filters,
            rootDocument
        } = this.props;

        const dataToFilter = (filters || []).map(({ name, value, isValue }) => {
            const filerValue = isValue ? value : objectPath.get(rootDocument.data, value);

            return {
                name,
                value: filerValue
            };
        });
        const flatArray = (dataToFilter || []).reduce((acc, val) => acc.concat(val), []);
        return flatArray;
    };

    renderResults = () => {
        const { value, readOnly } = this.props;

        return (
            (value || []).map(({ name, label, id }) => (
                <RegisterChip
                    key={id}
                    label={label || name}
                    onDelete={!readOnly ? () => this.handleChange((value || []).filter(option => option.id !== id)) : null}
                />
            ))
        );
    };

    render() {
        const {
            hidden,
            required,
            classes,
            error,
            disabled,
            filters,
            maxValues,
            noMargin,
            readOnly,
            fieldToDisplay
        } = this.props;

        if (hidden) return null;

        return (
            <ElementContainer
                required={required}
                error={error}
                bottomSample={true}
                noMargin={noMargin}
            >
                {this.renderResults()}
                <TreeSelect
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                    isDisabled={(disabled || maxValues) && this.getDisabled()}
                    filters={filters && this.getFilters()}
                    customOnChange={this.onChange}
                    registerSelect={true}
                    className={classes.containerWrapper}
                    readOnly={readOnly}
                    fieldToDisplay={fieldToDisplay}
                />
            </ElementContainer>
        );
    }
}

RegisterSelect.propTypes = {
    registerActions: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func,
    keyId: PropTypes.number,
    originDocument: PropTypes.object,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    error: PropTypes.object,
    stepName: PropTypes.string.isRequired,
    path: PropTypes.array.isRequired,
    required: PropTypes.bool,
    taskId: PropTypes.string.isRequired,
    rootDocument: PropTypes.object.isRequired,
    disabled: PropTypes.string,
    filters: PropTypes.array,
    maxValues: PropTypes.number,
    readOnly: PropTypes.bool,
    fieldToDisplay: PropTypes.bool
};

RegisterSelect.defaultProps = {
    value: null,
    onChange: () => null,
    keyId: null,
    originDocument: {},
    hidden: false,
    error: null,
    required: false,
    description: '',
    disabled: false,
    filters: null,
    maxValues: null,
    readOnly: false,
    fieldToDisplay: null
};

const mapDispatchToProps = dispatch => ({
    registerActions: {
        loadTask: bindActionCreators(loadTask, dispatch),
        requestRegisterKeyRecordsFilter: bindActionCreators(requestRegisterKeyRecordsFilter, dispatch)
    }
});

const translated = translate('Elements')(RegisterSelect);
export default connect(null, mapDispatchToProps)(withStyles(styles)(translated));

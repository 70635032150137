import React from 'react';
import TimeLabel from 'components/Label/Time';
import { Chip } from '@material-ui/core';
import { getShortNameFromString } from 'helpers/getUserShortName';
import StringFilterHandler from 'components/DataTable/components/StringFilterHandler';
import SelectFilterHandler from 'components/DataTable/components/SelectFilterHandler';
import statuses from './statuses';
import withErrors from './withErrors';

const colors = {
    1: '#3a9ae6',
    2: '#60b52a',
    3: '#bf3229',
    null: '#848788'
};

export default ({ t }) => ({
    controls: {
        pagination: true,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: false
    },
    checkable: false,
    columns: [{
        id: 'number',
        align: 'left',
        sortable: false,
        name: t('WorkflowNumber')
    }, {
        id: 'lastStepLabel',
        align: 'left',
        sortable: false,
        name: t('WorkflowStatusLabel'),
        padding: 'none',
        render: (value, { lastStepLabel, workflowStatusId, isDraft }) => {
            if (isDraft) {
                return (
                    <Chip
                        style={{ cursor: 'inherit', backgroundColor: '#848788', color: 'white' }}
                        label={t('DraftStatus')}
                    />
                );
            }

            if (lastStepLabel) {
                return (
                    <Chip
                        style={{ cursor: 'inherit', backgroundColor: colors[workflowStatusId], color: 'white' }}
                        label={lastStepLabel}
                    />
                );
            }

            return null;
        }
    }, {
        id: 'workflowTemplate',
        align: 'left',
        sortable: false,
        name: t('WorkflowTemplateCategory'),
        render: ({ workflowTemplateCategory }) => workflowTemplateCategory && workflowTemplateCategory.name
    }, {
        id: 'workflowTemplate',
        align: 'left',
        sortable: false,
        name: t('WorkflowTemplate'),
        render: ({ name }, value) => <span style={{ color: (value && value.hasUnresolvedErrors) ? '#f44336' : '' }}>{name}</span>
    }, {
        id: 'userData',
        align: 'left',
        sortable: false,
        name: t('UserName'),
        render: ({ userName }) => getShortNameFromString(userName)
    }, {
        id: 'createdAt',
        width: 160,
        align: 'center',
        sortable: true,
        padding: 'checkbox',
        name: t('CreatedAt'),
        render: value => <TimeLabel date={value} />
    }],
    filterHandlers: {
        hasUnresolvedErrors: props => <SelectFilterHandler name={t('ShowingProcesses')} options={withErrors} {...props} />,
        number: props => <StringFilterHandler name={t('WorkflowNumber')} {...props} />,
        workflowTemplate: props => <StringFilterHandler name={t('WorkflowTemplate')} {...props} />,
        userData: props => <StringFilterHandler name={t('UserName')} {...props} />,
        workflowStatusId: props => <SelectFilterHandler name={t('WorkflowStatusLabel')} options={statuses} {...props} />,
    }
});

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import XLSX from 'xlsx';

import {
    Template,
    TemplatePlaceholder,
    Plugin,
    TemplateConnector
} from '@devexpress/dx-react-core';

import {
    Tooltip,
    IconButton,
    CircularProgress
} from '@material-ui/core';

import { requestRegisterKeyRecords } from 'application/manager/actions/registry';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import downloadBase64Attach from 'helpers/downloadBase64Attach';

import config from 'config.json';

const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    }
    return buf;
};

class ExportToExelButton extends React.Component {
    state = { busy: false };

    handleDownload = async () => {
        const { t, selectedKey, actions } = this.props;
        const { busy } = this.state;
        if (busy || !selectedKey) {
            return;
        }
        this.setState({ busy: true });
        const records = await actions.requestRegisterKeyRecords(selectedKey.id, { strict: false, limit: 30000 });
        const properties = Object.keys(selectedKey.schema.properties);

        const wb = XLSX.utils.book_new();

        wb.Props = {
            Title: selectedKey.description,
            Subject: t('Registry'),
            Author: config.application.name,
            CreatedDate: new Date()
        };

        wb.SheetNames.push(selectedKey.description.slice(0, 31));

        const header = properties.map(property => selectedKey.schema.properties[property].description);
        const data = records.map(record => properties.map(property => record.data[property]));

        wb.Sheets[selectedKey.description.slice(0, 31)] = XLSX.utils.aoa_to_sheet([header, ...data]);
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        this.setState({ busy: false });
        downloadBase64Attach({ fileName: selectedKey.description + '.xlsx' }, blob);
    }

    render() {
        const { t, selectedKey } = this.props;
        const { busy } = this.state;

        return (
            <Plugin name="ExportToExelButton">
                <Template name="toolbarContent">
                    <TemplatePlaceholder />
                    <TemplateConnector>
                        {() => (
                            <Tooltip title={t('ExportToExel')}>
                                <IconButton onClick={this.handleDownload} disabled={!selectedKey}>
                                    {busy ? <CircularProgress size={24} /> : <SaveAltIcon />}
                                </IconButton>
                            </Tooltip>
                        )}
                    </TemplateConnector>
                </Template>
            </Plugin>
        );
    }
}

ExportToExelButton.propTypes = {
    t: PropTypes.func.isRequired,
    selectedKey: PropTypes.object,
    actions: PropTypes.object.isRequired
};

ExportToExelButton.defaultProps = {
    selectedKey: null
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = dispatch => ({
    actions: {
        requestRegisterKeyRecords: bindActionCreators(requestRegisterKeyRecords, dispatch)
    }
});

const translated = translate('RegistryPage')(ExportToExelButton);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

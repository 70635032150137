import React from 'react';
import { translate } from 'react-translate';
import printJS from 'print-js';
import PropTypes from 'prop-types';
import {
    Tooltip,
    IconButton
} from '@material-ui/core';
import { humanDateTimeFormat } from 'helpers/humanDateFormat';
import KeyIcon from '@material-ui/icons/VpnKey';

class SignatureDetails extends React.Component {
    print = () => {
        const {
            item: {
                signature: {
                    signer: {
                        organizationName, commonName
                    },
                    issuer,
                    serial,
                    signTime
                },
                fileName,
                documentId
            }
        } = this.props;

        const printData = [
            {
                info: commonName
            },
            {
                info: organizationName || ''
            },
            {
                info: humanDateTimeFormat(signTime)
            },
            {
                info: issuer.commonName
            },
            {
                info: serial.toUpperCase()
            }, 
            {
                info: fileName
            }, 
            {
                info: documentId
            }
        ];

        printJS({
            printable: printData,
            properties: ['info'],
            type: 'json',
            gridHeaderStyle: 'opacity: 0;',
            gridStyle: 'border: none;',
            documentTitle: ''
        });
    };

    render = () => {
        const { t } = this.props;

        return (
            <>
                <Tooltip title={t('Signature')}>
                    <IconButton onClick={this.print}>
                        <KeyIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    }
}

SignatureDetails.propTypes = {
    t: PropTypes.func.isRequired,
    signature: PropTypes.object.isRequired
};

SignatureDetails.defaultProps = {};

export default translate('FileDataTable')(SignatureDetails);

import React from 'react';
import classNames from 'classnames';
import { translate } from 'react-translate';
import { withStyles, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import HighlightText from 'components/HighlightText';
import toCamelCase from 'helpers/toCamelCase';

const styles = {
    errorLabel: {
        color: '#f44336'
    },
    ids: {
        paddingLeft: 5
    },
    link: {
        textDecorationColor: '#f44336',
        marginRight: 4,
        whiteSpace: 'nowrap'
    },
    errorMessage: {
        wordBreak: 'break-all'
    }
};

const ErrorDetails = ({ t, classes, search, log: { details, details: { data: { queueMessage } } } }) => {
    const elementId = (queueMessage.eventTemplateId || queueMessage.taskTemplateId || queueMessage.gatewayTemplateId);
    const workflowId = String(elementId).slice(0, -3);

    const errorMessage = details.data && details.data.error;

    return (
        <>
            <ErrorOutlineIcon className={classes.errorLabel} />
            <a className={classes.link} rel="noopener noreferrer" target="_blank" href={`/workflow/${workflowId}/${details.serviceName}-${elementId}`}>
                <Typography variant="body2" className={classNames(classes.ids, classes.errorLabel)}>
                    <HighlightText
                        highlight={search}
                        text={t(toCamelCase(details.serviceName)) + ' ' + elementId + ' ' + (details.name || '')}
                    />
                </Typography>
            </a>
            <div className={classes.errorMessage}>
                {errorMessage}
            </div>
        </>
    );
};

const styled = withStyles(styles)(ErrorDetails);
export default translate('ProcessesListPage')(styled);

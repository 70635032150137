/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import Paper from '@material-ui/core/Paper';
import Pagination from 'components/Select/components/Pagination';
import { Toolbar, withStyles } from '@material-ui/core';

const styles = {
    toolbar: {
        padding: 0,
        margin: 0,
        minHeight: 'auto'
    },
    flexGrow: {
        flexGrow: 1
    }
};

const Menu = ({ classes, innerProps, selectProps: { id, classes: rootClasses, usePagination, pagination, onChangePage }, children }) => (
    <Paper
        id={`${id}Menu`}
        square={true}
        className={rootClasses.paper}
        {...innerProps}
    >
        {children}
        <Toolbar className={classes.toolbar}>
            {
                usePagination ? (
                    <Pagination {...pagination} onChangePage={onChangePage} />
                ) : null
            }
            <div className={classes.flexGrow} />
        </Toolbar>
    </Paper>
);

Menu.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
    children: PropTypes.node,
    onChangePage: PropTypes.func
};

Menu.defaultProps = {
    innerProps: {},
    children: '',
    onChangePage: () => null
};

const styled = withStyles(styles)(Menu);
export default translate('TreeListSelect')(styled);

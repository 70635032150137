import React from 'react';
import { translate } from 'react-translate';

import {
    Collapse,
    Typography,
    withStyles
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { withEditor } from 'components/JsonSchema/editor/JsonSchemaProvider';
import DraggableElement from './DraggableElement';

const styles = {
    group: {
        cursor: 'pointer',
        color: 'inherit',
        display: 'flex',
        minWidth: 320,
        padding: 10,
        alignItems: 'center'
    },
    divider: {
        flexGrow: 1,
        height: 1,
        background: '#aaaaaa'
    }
};

const GroupedElementList = ({ t, classes, group, elements, open }) => {
    const [showGroup, setShowGroup] = React.useState(true);

    const groupElements = Object.keys(elements)
        .filter(elementName => {
            const element = elements[elementName];
            return !element.hide && element.group === group;
        });

    return (
        <>
            {open ? (
                <Typography
                    className={classes.group}
                    onClick={() => setShowGroup(!showGroup)}
                >
                    {showGroup ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    <span style={{ margin: '0 10px' }}>{t(group)}</span>
                    <div className={classes.divider} />
                </Typography>
            ) : null}
            <Collapse in={showGroup || !open} timeout="auto" unmountOnExit>
                {groupElements.map(elementName => (
                    <DraggableElement
                        elementType={elements[elementName]}
                        open={open}
                        key={elementName}
                        name={elementName}
                    />
                ))}
            </Collapse>
        </>
    );
};

const styled = withStyles(styles)(GroupedElementList);
const translated = translate('JsonSchemaEditor')(styled);
export default withEditor(translated);

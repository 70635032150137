import React from 'react';
import { withVirtualization } from 'components/Virtualized';

const VirtualizedData = ({ rowRenderer, slicedData, startIndex, marginTop }) => (
    <>
        {/* <tr style={{ height: 22 - marginTop }} /> */}
        {slicedData.map((row, i) => rowRenderer(row, startIndex + i))}
    </>
);

export default withVirtualization(VirtualizedData);

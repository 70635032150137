import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    noBreak: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px',
            maxWidth: 100
        }
    },
    whiteSpace: {
        whiteSpace: 'nowrap'
    }
});

export default withStyles(styles)(({ classes, children, whiteSpace }) => (
    <div className={classNames(classes.noBreak, { [classes.whiteSpace]: whiteSpace })}>{children}</div>
));

import React from 'react';
import PropTypes from 'prop-types';

import { Chip, withStyles } from '@material-ui/core';

const styles = {
    root: {
        marginRight: 4,
        borderRadius: 3
    }
};

const FilterChip = ({ classes, ...rest }) => (
    <Chip
        {...rest}
        classes={classes}
    />
);

FilterChip.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FilterChip);

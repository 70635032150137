import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HealthCheckList from 'application/adminpanel/modules/healthCheck/pages/HealthCheckList';

export default {
    routes: [
        {
            path: '/healthcheck',
            component: HealthCheckList,
            title: 'HealthCheckListTitle',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.healthcheck.HealthCheckPage' }
        }
    ],
    navigation: [
        {
            id: 'HealthCheck',
            title: 'HealthCheckList',
            icon: <FiberManualRecordIcon />,
            path: '/healthcheck',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.healthcheck.HealthCheckPage' }
        }
    ]
};

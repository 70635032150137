import React from 'react';
import { translate } from 'react-translate';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { IconButton, Dialog, Tooltip } from '@material-ui/core';

import DataTable from 'components/DataTable';
import Preloader from 'components/Preloader';
import TimeLabel from 'components/Label/Time';

import edsService from 'services/eds';

const SignatureList = ({ t, details }) => {
    const [open, setOpen] = React.useState(false);
    const [signs, setSigns] = React.useState(null);
    const [selected, setSelected] = React.useState(null);

    if (!details.document || !details.document.signatures || !details.document.signatures.length) {
        return null;
    }

    const ResultData = () => {
        if (!signs) {
            return <Preloader />;
        }

        const data = selected
            ? Object.keys(selected)
                .map(key => ({ key, value: selected[key] }))
                .filter(({ value }) => !!value && typeof value !== 'boolean')
                .filter(({ value }) => typeof value !== 'object')
            : signs.map(({ ownerInfo, timeInfo }) => ({ ...ownerInfo, ...timeInfo }));

        return (
            <DataTable
                data={data}
                onRowClick={selected ? null : sign => setSelected(sign)}
                columns={selected
                    ? [{
                        id: 'key'
                    }, {
                        id: 'value',
                        render: value => <div style={{ wordBreak: 'break-all' }}>{value}</div>
                    }]
                    : [
                        {
                            id: 'subjCN'
                        }, {
                            id: 'signTimeStamp',
                            align: 'right',
                            render: date => <TimeLabel date={date} format="LLL" />
                        }
                    ]}
                controls={{
                    pagination: false,
                    toolbar: false,
                    search: false,
                    header: false,
                    refresh: false,
                    switchView: false
                }}
            />
        );
    };

    return (
        <>
            <Tooltip title={t('Signature')}>
                <IconButton
                    onClick={async () => {
                        setOpen(true);
                        if (signs) {
                            return;
                        }
                        const { document: { signatures } } = details;
                        const signer = edsService.getSigner();

                        const singDetails = [];
                        const verifyData = async (index) => {
                            if (!signatures[index]) {
                                return;
                            }

                            const [signature] = JSON.parse(signatures[index].signature);
                            try {
                                const signDetail = await signer.execute('VerifyDataInternal', signature);
                                singDetails.push(signDetail);
                            } catch (e) {
                                singDetails.push({ subjCN: e.message });
                            }

                            await verifyData(index + 1);
                        };

                        await verifyData(0);
                        setSigns(singDetails);
                    }}
                >
                    <VpnKeyIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="sm"
                onClose={() => (selected ? setSelected(null) : setOpen(false))}
            >
                <ResultData />
            </Dialog>
        </>
    )
};

export default translate('ProcessesListPage')(SignatureList);

import React from 'react';

import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    withStyles,
    TableHead
} from '@material-ui/core';

import getElementType from 'components/JsonSchema/editor/helpers/getElementType';

import { stringify, parse } from '../helpers/valueConverter';

import PropertyEditor from './PropertyEditor';

const hiddenProperties = ['type', 'control', 'properties'];

const styles = {
    root: {
        width: 320
    },
    divider: {
        margin: '0 4px',
        backgroundColor: '#757575'
    },
    cell: {
        width: '50%',
        borderBottom: 'none',
        borderRight: '#757575 1px solid',
        color: 'inherit',
        padding: '2px 6px',
        overflow: 'hidden',
        overflowWrap: 'anywhere',
        '&:last-child': {
            borderRight: 'none'
        },
        '& .ace_scrollbar-h': {
            display: 'none'
        }
    }
};

const PropertyTable = ({ classes, values, path, onChage }) => {
    const elementType = getElementType(values, path);
    const properties = Object.keys(elementType.defaultData)
        .filter(property => !hiddenProperties.includes(property));

    return (
        <Table size="small" className={classes.root}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.cell}>path</TableCell>
                    <TableCell className={classes.cell}>{path.join('.')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {properties.map((property) => {
                    const dataPath = path.length ? ['properties', path.join('.properties.'), property].join('.') : property;
                    return (
                        <TableRow key={property}>
                            <TableCell component="th" scope="row" className={classes.cell} width="50%">
                                {property}
                            </TableCell>
                            <TableCell align="right" className={classes.cell}>
                                <PropertyEditor
                                    value={stringify(values[property])}
                                    onChange={newValue => onChage(dataPath, parse(newValue))}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default withStyles(styles)(PropertyTable);

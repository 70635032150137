import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Tooltip,
    IconButton,
    withStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    TextField,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import Preloader from 'components/Preloader';
import { importRegistersKeysXLS } from 'application/adminpanel/actions/registry';
import EJVError from 'components/JsonSchema/components/EJVError';
import { addMessage } from 'actions/error';
import Message from 'components/Snackbars/Message';
import ExplicitIcon from '@material-ui/icons/Explicit';

const styles = {
    buttonPadding: {
        marginLeft: 10
    },
    xlsButton: {
        marginTop: 20,
        marginBottom: 20
    }
};

class ImportRegistersKeys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openImportDialog: false,
            registerId: null,
            keyId: null,
            unique: '',
            showErrorDialog: false,
            error: null,
            loading: false
        };
    }

    handleOpenImportDialog = () => this.setState({ openImportDialog: true });

    handleCloseImportDialog = () => this.setState({ openImportDialog: false });

    handleUploadClick = () => {
        const { t } = this.props;
        const { registerId, keyId } = this.state;

        if (registerId && keyId) {
            this.input && this.input.click();
        } else {
            this.setState({ error: new Error(t('RequiredField')) });
        }
    };

    handleChange = async ({ target }) => {
        const { actions, importActions } = this.props;
        const { registerId, keyId, unique } = this.state;
        this.handleCloseImportDialog();

        this.setState({ loading: true });

        const importResult = await importActions.importRegistersKeysXLS(target.files[0], registerId, keyId, unique);

        this.setState({ loading: false });

        if (importResult instanceof Error) {
            this.setState({ showErrorDialog: true, error: importResult.message });
            return;
        }
        importActions.addMessage(new Message('ImportRegistersSuccess', 'success'));
        this.setState({ registerId: null, keyId: null, unique: '' });
        actions.load();
    };

    handleFieldChange = name => ({ target: { value } }) => this.setState({ [name]: value });

    render = () => {
        const { t, classes } = this.props;
        const { openImportDialog, registerId, keyId, unique, showErrorDialog, error, loading } = this.state;
        const errorText = error ? <EJVError error={error} /> : null;

        return (
            <>
                {
                    loading ? (
                        <Dialog open={true}>
                            <Preloader />
                        </Dialog>
                    ) : null
                }
                <Tooltip className={classes.buttonPadding} title={t('ImportRegistersKeysXLS')}>
                    <IconButton onClick={this.handleOpenImportDialog} id="export-units">
                        <ExplicitIcon />
                    </IconButton>
                </Tooltip>
                <input
                    ref={(ref) => { this.input = ref; }}
                    type="file"
                    accept=".xls, .xlsx"
                    onChange={this.handleChange}
                    hidden={true}
                    multiple={false}
                />
                <Dialog
                    onClose={() => this.setState({ openImportDialog: false })}
                    open={openImportDialog}
                    fullWidth={true}
                    maxWidth={'xs'}
                >
                    <DialogTitle>{t('ImportRegistersKeysXLS')}</DialogTitle>
                    <DialogContent>
                        <FormControl
                            row={false}
                        >
                            <TextField
                                label={t('RegisterID')}
                                value={registerId || ''}
                                onChange={this.handleFieldChange('registerId')}
                                helperText={errorText}
                                error={!!error}
                            />
                            <TextField
                                label={t('KeyId')}
                                value={keyId || ''}
                                onChange={this.handleFieldChange('keyId')}
                                helperText={errorText}
                                error={!!error}
                            />
                            <TextField
                                label={t('Unique')}
                                value={unique || ''}
                                onChange={this.handleFieldChange('unique')}
                            />
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleUploadClick}
                            className={classes.xlsButton}
                        >
                            {t('Continue')}
                        </Button>
                    </DialogContent>
                </Dialog>

                {
                    showErrorDialog && error ? (
                        <Dialog
                            open={true}
                            onClose={() => this.setState({ showErrorDialog: false })}
                        >
                            <DialogTitle>{t('ErrorImportingKeys')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {error}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.setState({ showErrorDialog: false })}
                                    color="primary"
                                    autoFocus={true}
                                >
                                    {t('CloseErrorDialog')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null
                }
            </>
        );
    };
}

ImportRegistersKeys.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

ImportRegistersKeys.defaultProps = {
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    importActions: {
        importRegistersKeysXLS: bindActionCreators(importRegistersKeysXLS, dispatch),
        addMessage: bindActionCreators(addMessage, dispatch)
    }
});

const translated = translate('RegistryListAdminPage')(ImportRegistersKeys);
const styled = withStyles(styles)(translated);
export default connect(mapStateToProps, mapDispatchToProps)(styled);

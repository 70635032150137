import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { Typography, Button } from '@material-ui/core';

import Preloader from 'components/Preloader';

import evalate from 'helpers/evalate';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

import formElements from 'components/JsonSchema/elements';
// import emptyValues from './emptyValues';

class SchemaForm extends React.Component {
    state = { error: null };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        this.init();
    }

    init() {
        const { value, onChange, schema } = this.props;

        if (value && this.isHidden() && schema.cleanWhenHidden) {
            onChange(null);
        }
    }

    isHidden = () => {
        const { schema, schema: { hidden, checkHidden }, rootDocument, value, steps, activeStep, parentValue, pathIndex } = this.props;

        if (!hidden && !checkHidden) {
            return false;
        }

        if (typeof hidden === 'string') {
            const result = evalate(hidden, rootDocument.data, value, parentValue);

            if (result instanceof Error) {
                result.commit({ type: 'schema form isHidden', rootDocument, schema });
                return false;
            }

            return result === true;
        }

        if (checkHidden && typeof checkHidden === 'string') {
            const result = evalate(
                checkHidden,
                value,
                rootDocument.data[steps[activeStep]],
                rootDocument.data,
                parentValue,
                pathIndex?.index);

            if (result instanceof Error) {
                result.commit({ type: 'schema check isHidden error', schema, rootDocument, stepName: steps[activeStep] });
                return false;
            }

            return result === true;

        }

        return hidden || checkHidden;
    };

    getSample = () => {
        const { sample, rootDocument, value, steps, activeStep, getSample, parentValue } = this.props;

        if (getSample && typeof getSample === 'string') {
            return evalate(getSample, value, rootDocument.data[steps[activeStep]], rootDocument.data, parentValue);
        }

        return sample;
    };

    isReadonly = () => {
        const { checkReadonly, isDisabled, rootDocument, value, steps, activeStep, parentValue, pathIndex } = this.props;
        let { readOnly } = this.props;

        if (checkReadonly && typeof checkReadonly === 'string') {
            let isReadonly = evalate(
                checkReadonly,
                value,
                rootDocument.data[steps[activeStep]],
                rootDocument.data,
                parentValue,
                pathIndex?.index
            );

            if (isReadonly instanceof Error) {
                isReadonly.commit({ type: 'schema isReadonly', rootDocument });
                isReadonly = false;
            }

            readOnly = readOnly || (isReadonly === true);
        }

        return readOnly || isDisabled;
    }

    getRequired = () => {
        const { schema, schema: { allVisibleRequired }, value } = this.props;

        let required = schema.required || [];

        if (allVisibleRequired) {
            if (value && value.propertiesHasOptions) {
                required = required.concat(Object.keys(value.propertiesHasOptions).filter(option => value.propertiesHasOptions[option]));
            } else {
                required = required.concat(Object.keys(schema.properties));
            }
        }

        return required;
    }

    isRequired = () => {
        const { rootDocument, value, steps, activeStep, parentValue, pathIndex } = this.props;
        const { schema, schema: { checkRequired } } = this.props;
        let { required } = this.props;

        if (checkRequired && typeof checkRequired === 'string') {
            const result = evalate(checkRequired, value, rootDocument.data[steps[activeStep]] || rootDocument.data, rootDocument.data, parentValue, pathIndex?.index);

            if (result instanceof Error) {
                result.commit({ type: 'schema check isRequired error', schema, rootDocument, stepName: steps[activeStep] });
            } else {
                required = required || result === true;
            }
        }

        return required;
    }

    getFormElementName = () => {
        const { schema } = this.props;
        const { control, type } = schema || {};

        if (!control && !type) {
            return null;
        }

        const name = control || type + '.element';
        return name.split('.').map(capitalizeFirstLetter).join('');
    }

    render() {
        const { customControls } = this.props;
        const componentName = this.getFormElementName();
        const FormControl = { ...formElements, ...customControls }[componentName];

        if (!componentName) {
            return null;
        }

        if (!FormControl) {
            return <div>{`${componentName} не налаштований`}</div>;
        }

        const { t, schema, errors, path, value } = this.props;
        const { error } = this.state;

        if (schema.external && error) {
            return (
                <>
                    <Typography>{t('ExternalDataError')}</Typography>
                    <Button onClick={this.init}>{t('Retry')}</Button>
                </>
            );
        }

        if (schema.external && !value) {
            return <Preloader />;
        }

        const jsonSchema = {
            ...schema,
            required: this.getRequired()
        };

        return (
            <FormControl
                {...schema}
                {...this.props}
                template={this.props.template || { jsonSchema }}
                schema={jsonSchema}
                sample={this.getSample()}
                description={schema.description}
                error={errors.find(e => e.path === path.join('.'))}
                readOnly={this.isReadonly()}
                required={this.isRequired()}
                hidden={this.isHidden()}
            />
        );
    }
}

SchemaForm.propTypes = {
    schema: PropTypes.object,
    errors: PropTypes.array,
    path: PropTypes.array,
    readOnly: PropTypes.bool,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    rootDocument: PropTypes.object,
    onChange: PropTypes.func,
    customControls: PropTypes.object
};

SchemaForm.defaultProps = {
    schema: {},
    errors: [],
    path: [],
    readOnly: false,
    required: false,
    rootDocument: { data: {} },
    onChange: () => null,
    customControls: {}
};

export default translate('SchemaForm')(SchemaForm);

import { compile } from 'json-schema-to-typescript';
import prettier from 'prettier/standalone';
const plugins = [require('prettier/parser-typescript')];


export const genTSbySchema = async (schema, name) => {
    try {
        let src = await compile(schema, name, {
            format: false, 
            unknownAny: false,
            bannerComment: null
        });
        src = src.replaceAll('[k: string]: any', '');
        return prettier.format(src, { 
            parser: 'typescript', 
            bracketSpacing: false, 
            printWidth: 120, 
            semi: true, 
            singleQuote: false, 
            tabWidth: 4, 
            trailingComma: 'none', 
            useTabs: false,
            plugins 
        });
    } catch (err) {
        return `// ${err.toString()}`
    }
}
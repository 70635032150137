import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import {
    IconButton,
    Tooltip
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import FileViewerDialog from 'components/FileViewerDialog';
import { requestWorkflowProcessAttach } from 'application/adminpanel/actions/workflowProcess';

const DocumentPreview = ({ t, details, actions }) => {
    const [file, setFile] = React.useState(null);

    if (!details || !details.document) return null;

    const { fileId, fileName, fileType } = details.document;

    if (!fileId && !fileName && !fileType) return null;

    const getFile = async () => {
        const result = await actions.requestWorkflowProcessAttach(details.id, { link: fileId, id: fileId });
        setFile(result);
    };

    const getExtension = () => (fileType || '').split('/')[(fileType || '').split('/').length - 1] || '';

    return (
        <>
            <Tooltip title={t('PDF')}>
                <IconButton onClick={getFile}>
                    <PictureAsPdfIcon />
                </IconButton>
            </Tooltip>
            <FileViewerDialog
                file={file}
                fileName={fileName}
                open={!!file}
                extension={getExtension()}
                onClose={() => setFile(null)}
            />
        </>
    );
};

DocumentPreview.propTypes = {
    details: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired
};

const mapState = ({ workflowProcess: { attaches } }) => ({ attaches });

const mapDispatch = dispatch => ({
    actions: {
        requestWorkflowProcessAttach: bindActionCreators(requestWorkflowProcessAttach, dispatch)
    }
});

const translated = translate('ProcessesListPage')(DocumentPreview);
export default connect(mapState, mapDispatch)(translated);

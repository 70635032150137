import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import classNames from 'classnames';

import {
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    TableCell,
    TableRow,
    GridList,
    withStyles
} from '@material-ui/core';

// import Scrollbar from 'components/Scrollbar';
import DataTableHeader from 'components/DataTable/DataTableHeader';
import DataTableRow from 'components/DataTable/DataTableRow';
import DataTableCard from 'components/DataTable/DataTableCard';
import DataTableToolbar from 'components/DataTable/DataTableToolbar';
import CollapsedTableRows from 'components/DataTable/CollapsedTableRows';
import CollapsedTableCards from 'components/DataTable/CollapsedTableCards';

import Preloader from 'components/Preloader';
import arrayUnique from 'helpers/arrayUnique';

const styles = theme => ({
    fixedTable: {
        tableLayout: 'fixed'
    },
    TableCell: {
        [theme.breakpoints.down('xs')]: {
            padding: '10px 5px',
            fontSize: 13,
            lineHeight: '18px'
        }
    },
    empty: {
        marginTop: 15,
        textAlign: 'center',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px'
    },
    cardContainer: {
        padding: '8px 8px 0',
        margin: '0 !important'
    },
    tableBody: {
        overflowX: 'auto'
    }
});

class DataTable extends React.Component {
    state = { view: this.props.view, grouping: false };

    switchView = () => this.setState({ view: this.state.view === 'table' ? 'cell' : 'table' });

    toggleGrouping = () => {
        const { grouping } = this.state;
        this.setState({ grouping: !grouping });
    }

    createSortHandler = columnId => () => {
        const { sort, actions: { onColumnSortChange } } = this.props;
        const order = objectPath.get(sort, columnId) === 'desc' ? 'asc' : 'desc';
        onColumnSortChange && onColumnSortChange(columnId, order);
    };

    changeRowsPerPage = ({ target: { value } }) => {
        const { actions: { onChangeRowsPerPage } } = this.props;
        onChangeRowsPerPage && onChangeRowsPerPage(value);
    };

    goToPage = (e, page) => {
        const { actions: { onChangePage } } = this.props;
        onChangePage && onChangePage(page);
    };

    handleSelectRow = itemId => () => {
        const { rowsSelected, actions: { onRowsSelect } } = this.props;
        if (rowsSelected.includes(itemId)) {
            rowsSelected.splice(rowsSelected.indexOf(itemId), 1);
        } else {
            rowsSelected.push(itemId);
        }

        onRowsSelect && onRowsSelect([...rowsSelected]);
    };

    selectAllRows = () => {
        const { rowsSelected, data, actions: { onRowsSelect, isRowSelectable } } = this.props;

        if (rowsSelected.length) {
            onRowsSelect && onRowsSelect([]);
        } else {
            onRowsSelect && onRowsSelect(data.filter(isRowSelectable).map(({ id }) => id));
        }
    };

    handleClick = item => () => {
        const { onRowClick } = this.props;
        onRowClick && onRowClick(item);
    };

    renderPreloading = () => {
        const { columns, checkable } = this.props;
        return (
            <TableBody>
                <TableRow>
                    <TableCell colSpan={(columns || []).length + (checkable ? 1 : 0)}>
                        <Preloader />
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    };

    renderEmptyData = () => {
        const { t, columns, checkable, emptyDataText } = this.props;
        return (
            <TableBody>
                <TableRow>
                    <TableCell
                        align="center"
                        colSpan={(columns || []).length + (checkable ? 1 : 0)}
                        id="empty-data-cell"
                    >
                        {emptyDataText || t('EmptyData')}
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    };

    renderBody() {
        const { t, data, groupBy, columns, hiddenColumns, rowsSelected, hightlight, checkable, actions, onRowClick, cellStyle } = this.props;
        const { grouping } = this.state;

        const { isRowSelectable } = actions || {};

        if (!columns || !Array.isArray(columns)) {
            return (
                <TableRow>
                    <TableCell>define columns pls</TableCell>
                </TableRow>
            );
        }

        if (!data) {
            return this.renderPreloading();
        }

        if (Array.isArray(data) && data.length === 0) {
            return this.renderEmptyData();
        }

        const renderRow = (row, key) => (
            <DataTableRow
                key={key}
                rowIndex={key}
                item={row}
                cellStyle={cellStyle}
                hightlight={hightlight && row.id && hightlight.includes(row.id)}
                selected={rowsSelected && row.id && rowsSelected.includes(row.id)}
                selectable={isRowSelectable ? isRowSelectable(row) : true}
                checkable={checkable}
                columns={columns}
                hiddenColumns={hiddenColumns}
                onSelect={this.handleSelectRow(row.id)}
                onClick={onRowClick ? onRowClick.bind(this, row) : null}
            />
        );

        if (groupBy && grouping) {
            const groups = arrayUnique([].concat(...data.map(({ [groupBy]: group }) => group)));
            return (
                <TableBody>
                    {groups.map(group => (
                        <CollapsedTableRows
                            key={group}
                            title={group}
                            renderRow={renderRow}
                            colSpan={(columns || []).length + (checkable ? 1 : 0)}
                            data={data.filter(({ [groupBy]: filterData }) => {
                                return [].concat(filterData).includes(group);
                            })}
                        />
                    ))}
                    <CollapsedTableRows
                        title={t('WithoutGroup')}
                        renderRow={renderRow}
                        colSpan={(columns || []).length + (checkable ? 1 : 0)}
                        data={data.filter(({ [groupBy]: filterData }) => !filterData || !filterData.length)}
                    />
                </TableBody>
            );
        }

        return (
            <TableBody>
                {data.filter(Boolean).map(renderRow)}
            </TableBody>
        );
    }

    renderCards() {
        const {
            t,
            data,
            classes,
            actions,
            fileStorage,
            rowsSelected,
            columns,
            groupBy,
            components
        } = this.props;

        const { grouping } = this.state;

        if (!data || !data.length) {
            return null;
        }

        const CardComponent = components.DataTableCard || DataTableCard;

        const renderCard = (row, key) => (
            <CardComponent
                key={key}
                item={row}
                actions={actions}
                selected={rowsSelected && row.id && rowsSelected.includes(row.id)}
                selectable={true}
                checkable={true}
                fileStorage={fileStorage}
                columns={columns}
                onSelect={this.handleSelectRow(row.id)}
            />
        );

        if (groupBy && grouping) {
            const groups = arrayUnique([].concat(...data.map(({ [groupBy]: group }) => group)));
            return (
                <>
                    {groups.map(group => (
                        <CollapsedTableCards
                            key={group}
                            title={group}
                            renderCard={renderCard}
                            data={data.filter(({ [groupBy]: filterData }) => {
                                return [].concat(filterData).includes(group);
                            })}
                        />
                    ))}
                    <CollapsedTableCards
                        title={t('WithoutGroup')}
                        renderCard={renderCard}
                        data={data.filter(({ [groupBy]: filterData }) => !filterData || !filterData.length)}
                    />
                </>
            );
        }

        return (
            <GridList className={classes.cardContainer}>
                {data.map(renderCard)}
            </GridList>
        );
    }

    renderTableContent = () => {
        const { data } = this.props;
        const { view } = this.state;

        if (!data) {
            return this.renderPreloading();
        }

        if (Array.isArray(data) && data.length === 0) {
            return this.renderEmptyData();
        }

        if (view === 'table') {
            return this.renderBody();
        }

        return null;
    };

    render() {
        const { t, classes, groupBy, actions, count, page, rowsPerPage, dragEvents, controls, fixedTable } = this.props;
        const { view, grouping } = this.state;

        return (
            <div {...dragEvents}>
                {controls.toolbar ? (
                    <DataTableToolbar
                        {...this.props}
                        view={view}
                        switchView={this.switchView}
                        groupBy={groupBy}
                        grouping={grouping}
                        toggleGrouping={this.toggleGrouping}
                    />
                ) : null}
                <div className={classes.tableBody}>
                    <TableContainer>
                        <Table className={classNames({ [classes.fixedTable]: fixedTable })}>
                            {(view === 'table' && controls.header) ? (
                                <DataTableHeader createSortHandler={this.createSortHandler} {...this.props} />
                            ) : null}
                            {this.renderTableContent()}
                        </Table>
                    </TableContainer>
                    {view !== 'table' && this.renderCards()}
                    {controls.bottomPagination ? (
                        <TablePagination
                            id="table-pagination"
                            backIconButtonProps={{
                                id: 'table-pagination-back'
                            }}
                            nextIconButtonProps={{
                                id: 'table-pagination-next'
                            }}
                            className={classes.row}
                            classes={{ toolbar: classes.paginationToolbar }}
                            count={count}
                            onChangePage={(e, newPage) => actions.onChangePage && actions.onChangePage(newPage)}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={t('ROWS_PER_PAGE')}
                            labelDisplayedRows={({ from, to }) => t('DISPLAYED_ROWS', { from, to, total: count })}
                            rowsPerPageOptions={[10, 20, 50]}
                            onChangeRowsPerPage={({ target: { value } }) => actions.onChangeRowsPerPage && actions.onChangeRowsPerPage(value)}
                            page={page - 1}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

DataTable.propTypes = {
    classes: PropTypes.object.isRequired,
    components: PropTypes.object,
    controls: PropTypes.object,
    hightlight: PropTypes.array,
    rowsSelected: PropTypes.array,
    view: PropTypes.string,
    fixedTable: PropTypes.bool
};

DataTable.defaultProps = {
    view: 'table',
    hightlight: [],
    rowsSelected: [],
    components: {},
    fixedTable: false,
    controls: {
        pagination: false,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        switchView: true
    }
};

export { default as DataTableStated } from './DataTableStated';
export default translate('DataTable')(withStyles(styles)(DataTable));

import ProfileAppbar from './components/ProfileAppbar';
import UserProfile from './pages/UserProfile';

export default {
    appbar: [{
        component: ProfileAppbar
    }],
    routes: [{
        path: '/profile',
        component: UserProfile,
        title: 'EditUserHeader'
    }]
};

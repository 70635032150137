import React from 'react';

import {
    IconButton,
    TableRow,
    TableCell,
    withStyles
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const styles = {
    row: {
        cursor: 'pointer'
    }
};

const CollapsedTableRows = ({ classes, title, data, colSpan, renderRow }) => {
    const [open, setOpen] = React.useState(true);

    if (!data || !data.length) {
        return null;
    }

    return (
        <>
            <TableRow hover={true} className={classes.row} onClick={() => setOpen(!open)}>
                <TableCell colSpan={colSpan}>
                    <IconButton>
                        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                    {`${title}${open ? '' : ' (' + data.length + ')'}`}
                </TableCell>
            </TableRow>
            {open ? data.map(renderRow) : null}
        </>
    );
};

export default withStyles(styles)(CollapsedTableRows);

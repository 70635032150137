import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';

export default {
    type: 'Element',
    group: 'Basic',
    Icon: ListAltOutlinedIcon,
    defaultData: {
        control:'unit.select',
        description: 'Вибір юнітів',
        multiply: true
    }
};

import React from "react";
import LeftSidebarLayout from "../../../../../../layouts/LeftSidebar";
import {MenuItem, Select} from "@material-ui/core";
import { fromPairs, extend } from "lodash";
import { Link } from "react-router-dom";

const transformUnitsToMap = units => fromPairs(units.map(({ id, name }) => [id, name]));

const mapWithUnitsNames = (values, units) => values.map(id => [ id, units[id]]);

const PERMISSON_PERFORMERUSERS = 'performerUsers';
const PERMISSON_PERFORMERUNITS = 'performerUnits'
const PERMISSON_PERFORMERUSERSISWORKFLOWOWNER = 'performerUsersIsWorkflowOwner'
const PERMISSON_PERFORMERUSERSISRANDOMHEADFROMUNIT = 'performerUsersIsRandomHeadFromUnit';
const PERMISSON_PERFORMERUSERSISRANDOMMEMBERFROMUNIT = 'performerUsersIsRandomMemberFromUnit';
const PERMISSON_CALCPERFORMERUNITS = 'calcPerformerUnits';
const PERMISSON_PERFORMERUSERSISOPTIMALMEMBERFROMUNIT = 'performerUsersIsOptimalMemberFromUnit'
const PERMISSION_ONLYFORHEADS = 'onlyForHeads';

const textMap = {
    [PERMISSON_PERFORMERUSERS]: 'specific users',
    [PERMISSON_PERFORMERUNITS]: 'specific units',
    [PERMISSON_PERFORMERUSERSISWORKFLOWOWNER]: 'by creator of flow',
    [PERMISSON_PERFORMERUSERSISRANDOMHEADFROMUNIT]: 'random head from unit',
    [PERMISSON_PERFORMERUSERSISRANDOMMEMBERFROMUNIT]: 'random member from unit',
    [PERMISSON_PERFORMERUSERSISOPTIMALMEMBERFROMUNIT]: 'less loaded member unit',
    [PERMISSON_CALCPERFORMERUNITS]: 'dynamic',
    [PERMISSION_ONLYFORHEADS]: 'only for heads'
};

const transformWorkflowToTable = ({ taskTemplates }, units) => {
    return taskTemplates.map(({ id, name, jsonSchema: { setPermissions } }) => {
        return [id, name, extend(...setPermissions.map(({
                                                  performerUnits,
                                                  onlyForHeads,
                                                  performerUsersIsWorkflowOwner,
                                                  performerUsersIsRandomHeadFromUnit,
                                                  performerUsersIsRandomMemberFromUnit,
                                                  performerUsersIsOptimalMemberFromUnit,
                                                  calcPerformerUnits,
                                                  performerUsers
        }) => {
            const tasksWithUsers = [];

            if(performerUsers){
                tasksWithUsers.push([PERMISSON_PERFORMERUSERS, mapWithUnitsNames(performerUsers, units)]);
            }

            if(performerUnits){
                tasksWithUsers.push([PERMISSON_PERFORMERUNITS, mapWithUnitsNames(performerUnits, units)]);
            }

            if(performerUsersIsRandomHeadFromUnit) {
                tasksWithUsers.push([PERMISSON_PERFORMERUSERSISRANDOMHEADFROMUNIT, mapWithUnitsNames(performerUsersIsRandomHeadFromUnit, units)]);
            }

            if(performerUsersIsRandomMemberFromUnit) {
                tasksWithUsers.push([PERMISSON_PERFORMERUSERSISRANDOMMEMBERFROMUNIT, mapWithUnitsNames(performerUsersIsRandomMemberFromUnit, units)]);
            }

            if(performerUsersIsOptimalMemberFromUnit) {
                tasksWithUsers.push([PERMISSON_PERFORMERUSERSISOPTIMALMEMBERFROMUNIT, mapWithUnitsNames(performerUsersIsOptimalMemberFromUnit, units)]);
            }

            if(calcPerformerUnits) {
                tasksWithUsers.push([PERMISSON_CALCPERFORMERUNITS, []]);
            }

            if(onlyForHeads){
                tasksWithUsers.push([PERMISSION_ONLYFORHEADS, []]);
            }

            if(performerUsersIsWorkflowOwner){
                tasksWithUsers.push([PERMISSON_PERFORMERUSERSISWORKFLOWOWNER, []]);
            }

            return fromPairs(tasksWithUsers);
        }))]
    })
};

export class WorkflowUsers extends React.Component {

    state = {
        workflows: [],
        units: [],
        details: null,
        current: null
    };

    componentDidMount() {
        Promise.all([
            this.props.getUnits(),
            this.props.getProcesses()
        ]).then(([units, workflows]) => {
            this.setState({
                units: transformUnitsToMap(Array.from(units)),
                workflows: Array.from(workflows)
            });
        });
    }

    handleChangeCurrentWorkflow = ({ target: { value: id } }) => {
      this.props.getProcessDetails(id, false).then((details) => {
          this.setState({
              current: id,
              details: transformWorkflowToTable(details, this.state.units)
          });
      });
    };

    renderRole = (type, [id, role]) => {
        if([PERMISSON_PERFORMERUNITS, PERMISSON_PERFORMERUSERSISOPTIMALMEMBERFROMUNIT, PERMISSON_PERFORMERUSERSISRANDOMHEADFROMUNIT].includes(type)){
            return (
                <Link to={`/users/units/${id}`}>{role}</Link>
            );
        }

        return role;
    };

    render(){
        const {
            t,
            title,
            loading,
            location
        } = this.props;

        const {
            workflows,
            details,
            current
        } = this.state;

        return (
            <LeftSidebarLayout location={location} title={t(title)} loading={loading}>
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Select onChange={this.handleChangeCurrentWorkflow} style={{ width: '80%' }}>
                        {workflows.map(({ id, name }) => (
                            <MenuItem value={id}>{name}</MenuItem>
                        ))}
                    </Select>
                    <Link to={`/workflow/${current}`}>explore</Link>
                </div>
                {details && (
                    <table>
                        {details.map(([id, name, permissions]) => ([
                            <thead style={{ backgroundColor: 'grey', fontWeight: 'bold', color: 'white' }}>
                                <tr>
                                    <th colSpan={2}>{id} / {name}</th>
                                </tr>
                            </thead>,
                            <tbody>
                                {Object.entries(permissions).map(([restriction, roles])=> ([
                                    <tr>
                                        <td colSpan={1 + !roles.length } rowSpan={roles.length} width={roles.length && '30%'} style={{
                                            borderRightWidth: 3 * !!roles.length,
                                            borderRightColor: 'black',
                                            borderRightStyle: 'solid'}}>
                                            {textMap[restriction]}
                                        </td>
                                        <td>{roles.length ? this.renderRole(restriction, roles[0]) : ''}</td>
                                    </tr>,
                                    ...roles.splice(1).map((role => (
                                        <tr>
                                            <td>
                                                {this.renderRole(restriction, role)}
                                            </td>
                                        </tr>
                                    )))
                                    ]))}
                            </tbody>
                        ]))}
                    </table>
                )}
            </LeftSidebarLayout>
        )
    }
}

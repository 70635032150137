import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    Select,
    Input,
    MenuItem,
    InputLabel,
    FormControl,
    Button,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    CircularProgress,
    withStyles,
    DialogActions
} from '@material-ui/core';

import MaskedInput from 'react-text-mask';

import { history } from 'store';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';

import { importWorkflow, createWorkflow } from 'application/adminpanel/actions/workflow';

import emptyWorkflow from 'application/adminpanel/modules/workflow/variables/emptyWorkflow';

import { addMessage } from 'actions/error';
import Message from 'components/Snackbars/Message';
import ConfirmDialog from 'components/ConfirmDialog';

import padWithZeroes from 'helpers/padWithZeroes';

import config from 'config.json';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            showMask={false}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={[/\d/, /\d/, /\d/]}
        />
    );
}

const styles = {
    formControl: {
        marginBottom: 10
    }
};

class ImportWorkflow extends React.Component {
    state = {
        error: null,
        openNewWorkflowDialog: false,
        newWorkflow: {},
        busy: false,
        openConfirmDialog: false,
        target: {},
        customer: null
    };

    handleOpenConfirmDialog = () => this.setState({ openConfirmDialog: true });

    handleCloseConfirmDialog = () => this.setState({ openConfirmDialog: false });

    handleUploadClick = () => this.input && this.input.click();

    handleChangeNewWorkflow = field => ({ target: { value } }) => {
        const { newWorkflow } = this.state;
        this.setState({
            newWorkflow: {
                ...newWorkflow,
                [field]: value
            }
        });
    };

    handleChangeCusomer = ({ target: { value: customer } }) => this.setState({ customer });

    handleChange = async ({ target }) => {
        this.setState({ target });
        const { actions, importActions } = this.props;
        const importResult = await importActions.importWorkflow(target.files[0]);
        if (importResult instanceof Error) {
            importResult.message === 'Workflow already exists.' ? this.handleOpenConfirmDialog() : importActions.addMessage(new Message('InvalidFile', 'error', importResult.response.details));
            return;
        }
        importActions.addMessage(new Message('ImportSuccess', 'success'));
        actions.load();
    };

    handleChangeConfirm = async () => {
        const { actions, importActions } = this.props;
        const { target } = this.state;
        this.handleCloseConfirmDialog();
        const importResult = await importActions.importWorkflow(target.files[0], true);
        if (importResult instanceof Error) {
            importActions.addMessage(new Message('FailImportingWorkflow', 'error', importResult.response.details));
            return;
        }
        importActions.addMessage(new Message('ImportSuccess', 'success'));
        actions.load();
        this.setState({ target: {} });
    };

    generateWorkflowId = () => {
        const { newWorkflow, customer } = this.state;
        const { customers, application: { environment } } = config;
        const customerId = customer || customers[0].id;
        const envId = {
            development: 0,
            stage: 1,
            demo: 2,
            production: 9
        };

        const id = '' + customerId + (envId[environment] || 0) + padWithZeroes(parseInt(newWorkflow.id, 10), 3);

        return id;
    };

    handleCreateWorkflow = async () => {
        const { newWorkflow } = this.state;
        const { importActions } = this.props;

        this.setState({ busy: true });

        const workflow = await importActions.createWorkflow(emptyWorkflow({
            ...newWorkflow,
            id: this.generateWorkflowId()
        }));

        if (workflow instanceof Error) {
            this.setState({ busy: false, error: workflow });
            return;
        }
        this.setState({ busy: false }, () => {
            history.push(`/workflow/${workflow.id}`);
        });
    };

    render() {
        const { openNewWorkflowDialog, newWorkflow, busy, openConfirmDialog, customer, error } = this.state;
        const { t, classes } = this.props;
        const { customers } = config;

        return (
            <>
                <Tooltip title={t('CreateWorkflow')} onClick={() => this.setState({ openNewWorkflowDialog: true })}>
                    <IconButton>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('ImportWorkflow')} onClick={this.handleUploadClick}>
                    <IconButton>
                        <CloudUploadIcon />
                    </IconButton>
                </Tooltip>
                <input
                    ref={(ref) => {
                        this.input = ref;
                    }}
                    type="file"
                    accept=".bpmn, application/bpmn"
                    onChange={this.handleChange}
                    hidden={true}
                    multiple={false}
                />
                <ConfirmDialog
                    fullScreen={false}
                    open={openConfirmDialog}
                    title={t('OverwriteWorkflowConfirmation')}
                    description={t('OverwriteWorkflowConfirmationText')}
                    handleClose={this.handleCloseConfirmDialog}
                    handleConfirm={this.handleChangeConfirm}
                />
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    onClose={() => !busy && this.setState({ openNewWorkflowDialog: false })}
                    open={openNewWorkflowDialog}
                >
                    <DialogTitle>{t('NewWorkflow')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            label={t('WorkflowName')}
                            fullWidth={true}
                            onChange={this.handleChangeNewWorkflow('name')}
                            disabled={busy}
                            value={newWorkflow.name || ''}
                            className={classes.formControl}
                            inputProps={{ maxLength: 255 }}
                        />
                        {(customers && customers.length > 1) ? (
                            <FormControl className={classes.formControl} fullWidth={true}>
                                <InputLabel htmlFor="customer-select">{t('Customer')}</InputLabel>
                                <Select
                                    id="customer-select"
                                    value={customer || ''}
                                    onChange={this.handleChangeCusomer}
                                    inputProps={{
                                        id: 'customer-select'
                                    }}
                                >
                                    {customers.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : null}
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <InputLabel htmlFor="formatted-text-mask-input">{t('WorkflowId')}</InputLabel>
                            <Input
                                value={newWorkflow.id || ''}
                                onChange={this.handleChangeNewWorkflow('id')}
                                id="formatted-text-mask-input"
                                inputComponent={TextMaskCustom}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        {busy ? <CircularProgress size={32} /> : (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!newWorkflow.id || !newWorkflow.name || busy || (!customer && (customers || []).length > 1) || !Number.isInteger(parseInt(newWorkflow.id, 10))}
                                onClick={this.handleCreateWorkflow}
                            >
                                {t('Continue')}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                <ConfirmDialog
                    fullScreen={false}
                    open={!!error}
                    title={t('WorkflowErrorTitle')}
                    description={error && t(error.message)}
                    handleClose={() => this.setState({ error: null })}
                />
            </>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    importActions: {
        createWorkflow: bindActionCreators(createWorkflow, dispatch),
        importWorkflow: bindActionCreators(importWorkflow, dispatch),
        addMessage: bindActionCreators(addMessage, dispatch)
    }
});

const styled = withStyles(styles)(ImportWorkflow);
const translated = translate('WorkflowListAdminPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

import React from 'react';

import {
    IconButton,
    TableRow,
    TableCell,
    GridList,
    withStyles,
    Table,
    TableBody
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const styles = {
    row: {
        cursor: 'pointer'
    },
    cardContainer: {
        padding: '8px 8px 0',
        margin: '0 !important'
    }
};

const CollapsedTableCards = ({ classes, title, data, renderCard }) => {
    const [open, setOpen] = React.useState(true);

    if (!data || !data.length) {
        return null;
    }

    return (
        <>
            <Table>
                <TableBody>
                    <TableRow hover={true} className={classes.row} onClick={() => setOpen(!open)}>
                        <TableCell>
                            <IconButton>
                                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </IconButton>
                            {`${title}${open ? '' : ' (' + data.length + ')'}`}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {open ? (
                <>
                    <GridList className={classes.cardContainer}>
                        {data.map(renderCard)}
                    </GridList>
                </>
            ) : null}
        </>
    );
};

export default withStyles(styles)(CollapsedTableCards);

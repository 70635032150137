import React from 'react';
import { detect } from 'detect-browser';
import { withStyles } from '@material-ui/core';
import Scrollbar from 'components/Scrollbar';

const { name: browserName } = detect();

const { Provider, Consumer } = React.createContext();

const styles = {
    root: {
        display: 'flex',
        width: 'fit-content',
        maxWidth: '100%'
    },
    content: {
        maxWidth: 'calc(100% - 14px)'
    },
    scroll: {
        width: 14,
        overflow: 'auto',
        '& .ps__rail-y': {
            opacity: 1
        }
    }
};

class Virtualized extends React.Component {
    constructor(props) {
        super(props);

        this.state = { scrollTop: 0, rowHeights: [] };
    }

    componentDidMount() {
        this.contentRef.addEventListener('wheel', this.wheelFunction, { passive: false });
        window.addEventListener('mousemove', this.onWindowMouseMove);
    }

    componentWillUnmount() {
        this.contentRef.removeEventListener('wheel', this.wheelFunction);
        window.removeEventListener('mousemove', this.onWindowMouseMove);
    }

    onWindowMouseMove = (e) => {
        const { selecting, dataListRef } = this.props;

        if (!e) return;

        const { y: cursorY } = e;

        if (!dataListRef || !dataListRef.current) return;

        const { y, height } = dataListRef && dataListRef.current && dataListRef.current.getBoundingClientRect();

        // eslint-disable-next-line no-underscore-dangle
        const scrollContainer = this.scrollRef.scrollBarRef._container;

        if (selecting) {
            let deltaY = 0;

            if (y && cursorY && (cursorY < y)) {
                deltaY = cursorY - y;
            }

            if (y && cursorY && (cursorY > y + height)) {
                deltaY = cursorY - y - height;
            }

            scrollContainer.scrollTop += deltaY;
        }
    }

    componentDidUpdate() {
        const { dataListRef, data } = this.props;
        const { rowHeights } = this.state;
        const { topElementIndex } = this.getIndexes();
        if (dataListRef.current) {
            const list = [...dataListRef.current.getElementsByTagName('tr')];
            let hasNewElements = false;

            // eslint-disable-next-line no-plusplus
            for (let row = 0; row < list.length; row++) {
                if (
                    (rowHeights[row + topElementIndex] !== list[row].clientHeight)
                    && (row + topElementIndex < data.length)
                ) {
                    rowHeights[row + topElementIndex] = list[row].clientHeight;
                    hasNewElements = true;
                }
            }
            if (hasNewElements) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ rowHeights });
            }
        }
    }

    wheelFunction = (event) => {
        if (!this.scrollRef) {
            return;
        }
        // eslint-disable-next-line no-underscore-dangle
        const scrollContainer = this.scrollRef.scrollBarRef._container;
        const { scrollTop: st, offsetHeight, scrollHeight: sh } = scrollContainer;

        if (event.deltaY > 0 && sh - offsetHeight === st) {
            return;
        }

        if (event.deltaY < 0 && st === 0) {
            return;
        }

        event.preventDefault();
        event.stopImmediatePropagation();

        const wheelDelta = browserName === 'firefox' ? event.deltaY * 10 : event.deltaY;

        scrollContainer.scrollTop += wheelDelta;
        this.scrollRef.scrollBarRef.updateScroll();
    };

    getRowIndexAt = (top) => {
        const { rowHeights } = this.state;

        let height = 0;
        let index = 0;

        while (height < top) {
            height += rowHeights[index];
            // eslint-disable-next-line no-plusplus
            index++;
        }

        return index;
    }

    getIndexes = () => {
        const { height, rowHeight, headerRef, dataListRef, data } = this.props;
        const { scrollTop, rowHeights } = this.state;

        let averageRowHeight = rowHeight;

        if (rowHeights.length) {
            averageRowHeight = rowHeights.reduce((acc, h) => acc + h, 0) / rowHeights.length;
        }

        const headerHeight = (headerRef.current ? headerRef.current.offsetHeight : 0);

        const topElementIndex = this.getRowIndexAt(scrollTop);
        const bottomElementIndex = Math.max(
            this.getRowIndexAt(scrollTop + height - headerHeight),
            topElementIndex + 1
        );

        const showRowCount = bottomElementIndex - topElementIndex;

        const overSize = dataListRef.current ? dataListRef.current.offsetHeight - ((showRowCount - 1) * averageRowHeight) : 0;
        const scrollHeight = (data.length * averageRowHeight);

        return { topElementIndex, bottomElementIndex, headerHeight, overSize, scrollHeight, averageRowHeight };
    }

    render() {
        const { classes, height, children, data, virtualizeRef } = this.props;
        const { scrollTop } = this.state;

        const { topElementIndex, bottomElementIndex, headerHeight, scrollHeight, averageRowHeight } = this.getIndexes();
        const slicedData = data.slice(topElementIndex, bottomElementIndex);

        const providerValue = {
            contentHeight: height,
            scrollTop,
            scrollHeight,
            slicedData,
            headerHeight,
            startIndex: topElementIndex,
            averageRowHeight
        };

        if (virtualizeRef && typeof virtualizeRef === 'function') {
            virtualizeRef(providerValue);
        }

        return (
            <Provider
                value={providerValue}
            >
                <div
                    ref={r => {
                        this.contentRef = r;
                    }}
                    className={classes.root}
                    style={{ maxHeight: height }}
                >
                    <div className={classes.content}>
                        {children}
                    </div>
                    <div className={classes.scroll} style={{ marginTop: headerHeight }}>
                        <Scrollbar
                            ref={r => {
                                this.scrollRef = r;
                            }}
                            onScrollY={({ scrollTop: newScrollTop }) => this.setState({ scrollTop: newScrollTop })}
                            options={{ suppressScrollX: true, minScrollbarLength: 50 }}
                        >
                            <div style={{ height: scrollHeight }} />
                        </Scrollbar>
                    </div>
                </div>
            </Provider>
        );
    }
}

const withVirtualization = Component => props => (
    <Consumer>
        {context => (
            <Component {...props} {...context} />
        )}
    </Consumer>
);

export { withVirtualization };
export default withStyles(styles)(Virtualized);

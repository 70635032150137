import React from 'react';
import { translate } from 'react-translate';
import { Toolbar, Button, withStyles } from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = {
    root: {
        color: 'inherit',
        margin: '0 5px'
    },
    label: {
        justifyContent: 'flex-start'
    },
    icon: {
        marginRight: 8
    }
};

const CollapseButton = ({ t, classes, open, onClick }) => {
    const Icon = open ? ChevronLeftIcon : ChevronRightIcon;

    return (
        <Toolbar disableGutters={true}>
            <Button
                fullWidth={true}
                classes={classes}
                onClick={onClick}
            >
                <Icon className={open ? classes.icon : undefined} />
                {open ? t('CollapseElements') : null}
            </Button>
        </Toolbar>
    );
};

const styled = withStyles(styles)(CollapseButton);
export default translate('JsonSchemaEditor')(styled);

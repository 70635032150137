import React from 'react';
import PropTypes from 'prop-types';
import evalate from 'helpers/evalate';
import CheckboxLayout from 'components/JsonSchema/elements/CheckboxGroup/components/layout';
import ChangeEvent from '../../ChangeEvent';

class CheckboxGroup extends React.Component {
    componentDidMount() {
        const { value, items, onChange, required, defaultValue } = this.props;

        if (defaultValue && value === null) {
            onChange && onChange(defaultValue);
        } else if (required && !Array.isArray(value)) {
            onChange && onChange([]);
        }
        if (Array.isArray(value)) {
            value.forEach((key) => {
                if (this.isDisabled(items.find(({ id }) => id === key))) {
                    onChange && onChange(value.filter(checkboxKey => key !== checkboxKey));
                }
            });
        }
    }

    handleChange = keyId => () => {
        const { value, onChange } = this.props;
        const checkedKeys = value || [];

        const newValue = checkedKeys.includes(keyId)
            ? checkedKeys.filter(item => item !== keyId)
            : checkedKeys.concat(keyId);

        onChange && onChange(new ChangeEvent(newValue.length ? newValue : null, false, true));
    }

    isDisabled = ({ isDisabled }) => {
        const { rootDocument, value, steps, activeStep } = this.props;
        if (isDisabled && typeof isDisabled === 'string') {
            isDisabled = evalate(isDisabled, value, rootDocument.data[steps[activeStep]], rootDocument.data) === true;

            if (isDisabled instanceof Error) {
                isDisabled.commit({ type: 'checkbox group check disabled' });
                isDisabled = false;
            }
        }

        return isDisabled;
    }

    getSample = (key) => {
        const { rootDocument, value, steps, activeStep } = this.props;
        const { getSample, sample } = key;

        if (getSample && typeof getSample === 'string') {
            const result = evalate(getSample, value, rootDocument.data[steps[activeStep]], rootDocument.data);

            if (result instanceof Error) {
                result.commit({ type: 'Checkbox group sample' });
                return '';
            }

            return result;
        }

        return sample;
    };

    render() {
        const { value, hidden } = this.props;
        const checkedKeys = (value || []);

        if (hidden) return null;

        return (
            <CheckboxLayout
                {...this.props}
                {...this.state}
                handleChange={this.handleChange}
                isDisabled={this.isDisabled}
                getSample={this.getSample}
                checkedKeys={checkedKeys}
            />
        );
    }
}

CheckboxGroup.propTypes = {
    hidden: PropTypes.bool,
    value: PropTypes.array
};

CheckboxGroup.defaultProps = {
    hidden: false,
    value: null
};

export default CheckboxGroup;

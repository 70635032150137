import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import Handlebars from 'handlebars';

import { withStyles, Typography, Button } from '@material-ui/core';
import { Content } from 'layouts/LeftSidebar';

const styles = {
    root: {
        padding: '120px 0 0',
        textAlign: 'center'
    },
    title: {
        margin: '40px 0'
    },
    content: {
        margin: '0 auto 50px',
        maxWidth: 600
    },
    button: {
        display: 'inline-flex'
    }
};

const GreetingsPage = ({ t, classes, user, title, content, onDone }) => (
    <Content>
        <div className={classes.root}>
            <Typography variant="h5" className={classes.title}>
                {Handlebars.compile(title)({ user })}
            </Typography>
            <Typography variant="body1" className={classes.content}>
                {Handlebars.compile(content)({ user })}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={onDone}
                size="large"
                className={classes.button}
            >
                {t('Start')}
            </Button>
        </div>
    </Content>
);

GreetingsPage.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    title: PropTypes.string,
    content: PropTypes.string,
    onDone: PropTypes.func
};

GreetingsPage.defaultProps = {
    title: '',
    content: '',
    onDone: () => null
};

const mapStateToProps = ({ auth: { info } }) => ({ user: info });

const styled = withStyles(styles)(GreetingsPage);
const translated = translate('TaskPage')(styled);
export default connect(mapStateToProps)(translated);

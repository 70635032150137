import React from 'react';
import { Button } from '@material-ui/core';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import promiseChain from 'helpers/promiseChain';
import KeyFormModal from './KeyFormModal';

const CreateNewKey = ({ t, actions, registerId }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button
                variant="outlined"
                onClick={() => setOpen(true)}
            >
                {t('CreateNew')}
            </Button>
            <KeyFormModal
                open={open}
                registerId={registerId}
                onClose={() => setOpen(false)}
                onChange={data => promiseChain([actions.createKey, actions.load], data)}
            />
        </>
    );
};

CreateNewKey.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    registerId: PropTypes.string.isRequired
};

export default translate('RegistryListAdminPage')(CreateNewKey);

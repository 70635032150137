import React, { Fragment } from 'react';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import FieldName from 'components/JsonSchema/elements/Popup/components/FieldName';
import FieldValue from 'components/JsonSchema/elements/Popup/components/FieldValue';
import Wrapper from 'components/JsonSchema/elements/Popup/components/Wrapper';
import DialogWrapper from 'components/JsonSchema/elements/Popup/components/Dialog';
import FieldWithBackGround from 'components/JsonSchema/elements/Popup/components/FieldWithBackGround';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';

class Popup extends React.Component {
    constructor(props) {
        super(props);

        const { value, openEmpty } = props;
        const empty = Object.keys(value).length === 0;

        this.state = {
            open: empty && openEmpty,
            userInput: {},
            updatedValues: []
        };
    }

    copy = obj => JSON.parse(JSON.stringify(obj));

    pushUpdatedValues = (origin, elem) => {
        let isExists = false;

        const mapping = origin.map((item) => {
            const itemFiltered = item.filter((el, i) => i !== (item.length - 1));
            const elemFiltered = elem.filter((el, i) => i !== (elem.length - 1));

            if (itemFiltered.join('.') === elemFiltered.join('.')) {
                isExists = true;
                return elem;
            }
            return item;
        });

        !isExists && mapping.push(elem);

        return mapping;
    };

    handleClickOpen = () => {
        const { value, customValue } = this.props;

        this.setState({
            userInput: this.copy(customValue || value),
            open: true,
            clickOpen: true
        });
    };

    handleClose = () => {
        const { onChange, value, clearWhenEmpty, handleDeleteItem } = this.props;

        const empty = Object.keys(value).length === 0;

        onChange(empty ? null : value);

        this.setState({ 
            open: false,
            clickOpen: false
        });
        clearWhenEmpty && handleDeleteItem && empty && handleDeleteItem();
    };

    handleChange = (...args) => {
        const { userInput } = this.state;
        let { updatedValues } = this.state;

        updatedValues = this.pushUpdatedValues(updatedValues, args.map(el => el));

        const value = args.pop();
        const path = args.join('.');

        objectPath.set(
            userInput,
            path,
            typeof value === 'object' ? value.data || value : value
        );

        if (typeof value !== 'object' && !value.length) objectPath.del(userInput, path);

        this.setState({
            userInput,
            updatedValues
        });
    };

    renderFormGroup = (item, index) => {
        const {
            properties,
            value
        } = this.props;

        const controlsNames = properties[item].properties;

        if (!controlsNames) return null;

        return (
            <Fragment key={index}>
                {Object.keys(controlsNames).map((val, key) => (
                    <Fragment key={key}>
                        <FieldName>
                            {controlsNames[val].description}
                        </FieldName>
                        <FieldValue>
                            {controlsNames[val].options && value[item]
                                ? controlsNames[val].options
                                    .filter(({ id }) => typeof value[item][val] !== 'undefined' && Number(id) === Number(value[item][val]))[0].name
                                : value[item] && value[item][val]
                            }
                        </FieldValue>
                    </Fragment>
                ))}
            </Fragment>
        );
    };

    renderStringData = (item, index) => {
        const {
            properties,
            value,
            parentValue
        } = this.props;

        return (
            <Fragment key={index}>
                <FieldName>
                    {properties[item].description}
                </FieldName>
                <FieldValue>
                    {(parentValue && parentValue[item]) || (value && value[item]) || null}
                </FieldValue>
            </Fragment>
        );
    };

    renderSelectData = (item, index) => {
        const {
            value
        } = this.props;

        const isArray = Array.isArray(value[item]);

        return (
            <Fragment key={index}>
                {/* <FieldName style={{ marginBottom: 8, display: 'block' }}>
                    {properties[item].description}
                </FieldName> */}
                {value[item] && isArray && Object.keys(value[item]).map((option, key) => (
                    <FieldWithBackGround key={key}>
                        <FieldValue style={{ marginBottom: 0 }}>
                            {value[item][option] && value[item][option].label}
                        </FieldValue>
                    </FieldWithBackGround>
                ))}
                {value[item] && !isArray && (
                    <FieldValue>{value[item].label}</FieldValue>
                )}
            </Fragment>
        );
    };

    renderArrayData = (item, index) => {
        const {
            properties,
            value
        } = this.props;

        const { items } = properties[item];

        const compareValues = () => {
            const comparedString = [];

            if (!value[item]) return '';

            value[item].forEach((val) => {
                items.forEach(({ id, title }) => {
                    if (id === val) comparedString.push(title);
                });
            });

            return comparedString.join(', ');
        };

        return (
            <Fragment key={index}>
                <FieldName>
                    {properties[item].description}
                </FieldName>
                <FieldValue>{compareValues()}</FieldValue>
            </Fragment>
        );
    };

    renderTreeSelectData = (item, index) => {
        const {
            value,
            properties
        } = this.props;

        return (
            <Fragment key={index}>
                <FieldName style={{ marginBottom: 8 }}>
                    {properties[item].description}
                </FieldName>
                {value[item] && (
                    <FieldWithBackGround>
                        <FieldValue style={{ marginBottom: 0 }}>
                            {value[item].name}
                        </FieldValue>
                    </FieldWithBackGround>
                )}
            </Fragment>
        );
    };

    renderRadioButton = (item, index) => {
        const {
            properties,
            value
        } = this.props;

        return (
            <Fragment key={index}>
                <FieldName>
                    {properties[item].description}
                </FieldName>
                <FieldValue>
                    {value[item] && properties[item].items.filter(({ id }) => (value[item] === id))[0].title}
                </FieldValue>
            </Fragment>
        );
    };

    renderDataItem = (item, index) => {
        const { properties } = this.props;

        if (properties[item].control === 'form.group') {
            return this.renderFormGroup(item, index);
        }

        if (properties[item].control === 'related.selects') {
            return this.renderSelectData(item, index);
        }

        if (properties[item].control === 'tree.select') {
            return this.renderTreeSelectData(item, index);
        }

        if (properties[item].control === 'checkbox.group') {
            return this.renderArrayData(item, index);
        }

        if (properties[item].control === 'radio.group') {
            return this.renderRadioButton(item, index);
        }

        if (['string', 'number'].includes(properties[item].type)) {
            return this.renderStringData(item, index);
        }

        if (['register', 'register.select'].includes(properties[item].control)) {
            return this.renderSelectData(item, index);
        }

        return null;
    };

    checkErrors = () => {
        const { errors, error, name } = this.props;

        if (errors.length) {
            const filter = errors.filter(({ path }) => path.indexOf(name) !== -1);

            if (filter.length) {
                return {
                    keyword: 'pattern',
                    dataPath: `.${name}`,
                    path: name
                };
            }
        }

        return error;
    }

    render = () => {
        const { hidden, required, row, noMargin } = this.props;
        const { open, clickOpen } = this.state;

        if (hidden) return null;

        return (
            <ElementContainer
                required={required}
                error={this.checkErrors()}
                bottomSample={true}
                row={row}
                noMargin={noMargin}
            >
                <Wrapper
                    {...this.props}
                    handleClickOpen={this.handleClickOpen}
                    renderDataItem={this.renderDataItem}
                />
                {open ? (
                    <DialogWrapper
                        {...this.props}
                        clickOpen={clickOpen}
                        handleClose={this.handleClose}
                    />
                ) : null}
            </ElementContainer>
        );
    }
}

Popup.propTypes = {
    onChange: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    value: PropTypes.object,
    parentValue: PropTypes.object,
    description: PropTypes.string,
    dialogTitle: PropTypes.string,
    hidden: PropTypes.bool,
    errors: PropTypes.array,
    path: PropTypes.array,
    steps: PropTypes.array,
    taskId: PropTypes.string,
    rootDocument: PropTypes.object,
    activeStep: PropTypes.number,
    error: PropTypes.object,
    required: PropTypes.bool,
    originDocument: PropTypes.object,
    stepName: PropTypes.string,
    handleStoreCustom: PropTypes.func,
    validate: PropTypes.func,
    customValue: PropTypes.object,
    row: PropTypes.bool,
    name: PropTypes.string.isRequired,
    openEmpty: PropTypes.bool,
    clearWhenEmpty: PropTypes.bool,
    handleDeleteItem: PropTypes.func
};

Popup.defaultProps = {
    value: {},
    parentValue: null,
    customValue: null,
    description: null,
    dialogTitle: null,
    hidden: false,
    errors: {},
    readOnly: false,
    path: null,
    steps: [],
    taskId: null,
    rootDocument: null,
    activeStep: null,
    error: null,
    required: false,
    originDocument: {},
    stepName: '',
    handleStoreCustom: null,
    validate: null,
    row: false,
    openEmpty: false,
    clearWhenEmpty: false,
    handleDeleteItem: null
};

export default Popup;

import React from 'react';
import {
    Tooltip,
    IconButton
} from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import TimeLabel from 'components/Label/Time';
import DeleteWorkflow from '../components/DeleteWorkflow';

export default ({ t, exportWorkflow, actions }) => ({
    controls: {
        pagination: true,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: true
    },
    checkable: false,
    columns: [{
        id: 'id',
        align: 'left',
        sortable: true,
        name: t('WorkflowId')
    }, {
        id: 'name',
        align: 'left',
        sortable: true,
        name: t('WorkflowName')
    }, {
        id: 'workflowTemplateCategory.name',
        align: 'left',
        sortable: true,
        name: t('WorkflowCategory'),
        render: (value, { workflowTemplateCategory }) => workflowTemplateCategory ? workflowTemplateCategory.name : ''
    }, {
        id: 'createdAt',
        width: 160,
        align: 'center',
        sortable: true,
        padding: 'checkbox',
        name: t('CreatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'updatedAt',
        width: 160,
        align: 'center',
        sortable: true,
        padding: 'checkbox',
        name: t('UpdatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'export',
        width: 64,
        align: 'right',
        padding: 'checkbox',
        disableClick: true,
        render: (value, { id }) => (
            <Tooltip title={t('ExportWorkflow')}>
                <IconButton onClick={exportWorkflow(id)}>
                    <SaveAltIcon />
                </IconButton>
            </Tooltip>
        )
    }, {
        id: 'delete',
        width: 64,
        align: 'right',
        padding: 'checkbox',
        disableClick: true,
        render: (value, { id }) => <DeleteWorkflow workflowId={id} actions={actions} />
    }]
});

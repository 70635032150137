import React from 'react';
import diff from 'deep-diff';
import { translate } from 'react-translate';
import DataSheet from 'components/DataSheet';
import 'react-datasheet/lib/react-datasheet.css';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withStyles, IconButton, Menu, MenuItem } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

import FullScreenDialog from 'components/FullScreenDialog';
import { ElementGroupContainer } from 'components/JsonSchema';

import SheetLayout from './SheetLayout';
import SheetCell from './SheetCell';
import DataEditor from './DataEditor';
import ClearDataButton from './ClearDataButton';

// import parsePaste from './parsePaste';
import { input, output } from './dataMapping';
import valueRenderer from './valueRenderer';

const styles = {
    errored: {
        color: '#000'
    },
    grow: {
        flexGrow: 1
    }
};

const Spreadsheet = ({
    t,
    classes,
    sample,
    description,
    required,
    error,
    hidden,
    value,
    headers = [],
    items = {},
    onChange,
    name,
    originDocument,
    ...rest
}) => {
    const headerRef = React.useRef();
    const scrollRef = React.useRef();
    const dataListRef = React.useRef();

    const [menuPosition, setMenuPosition] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState(input(value, items));

    const cellError = rest.errors && rest.errors[0];
    const checkPath = cellError && cellError.path.split(".").shift() === name;

    React.useEffect(() => {
        const diffs = diff(data, input(value, items));
        if (diffs && diffs.length) {
            setData(input(value, items));
        }
    }, [value, data, items]);

    if (hidden) return null;

    const sheetRenderer = props => (
        <SheetLayout
            {...rest}
            {...props}
            items={items}
            name={name}
            headers={headers}
            headerRef={headerRef}
            scrollRef={scrollRef}
            dataListRef={dataListRef}
        />
    );

    const cellRenderer = props => (
        <SheetCell
            {...rest}
            {...props}
            items={items}
            name={name}
        />
    );

    const dataEditor = props => (
        <DataEditor
            {...rest}
            {...props}
            items={items}
            name={name}
            onCellChange={onChange}
        />
    );

    const spreadsheet = height => (
        <DataSheet
            height={height}
            bodyHeight={dataListRef.current && dataListRef.current.clientHeight}
            overflow="clip"
            headerRef={headerRef}
            scrollRef={scrollRef}
            dataListRef={dataListRef}
            dataEditor={dataEditor}
            cellRenderer={cellRenderer}
            valueRenderer={valueRenderer}
            sheetRenderer={sheetRenderer}
            data={[].concat(data, input([{}], items))}
            onContextMenu={
                (e, cell, i, j) => {
                    e.preventDefault();
                    if (i >= data.length) {
                        return;
                    }
                    setMenuPosition({
                        cell,
                        row: data[i],
                        i,
                        j,
                        mouseX: e.clientX - 2,
                        mouseY: e.clientY - 4
                    });
                }
            }
            onCellsChanged={(changes, additions) => output(onChange, value, items)(changes, additions)}
        />
    );

    return (
        <>
            <ElementGroupContainer
                description={description}
                sample={sample}
                className={classes.errored}
                error={cellError && checkPath ? new Error(t('TableError')) : error}
                required={required}
                fullWidth={true}
                actionButtons={
                    (
                        <>
                            <IconButton onClick={() => setOpen(true)} color="inherit">
                                <FullscreenIcon />
                            </IconButton>
                            {/* <div className={classes.grow} /> */}
                            {data && data.length ? <ClearDataButton onChange={onChange} /> : null}
                        </>
                    )
                }
                {...rest}
            >
                {spreadsheet(440)}
            </ElementGroupContainer>
            <FullScreenDialog
                open={open}
                title={description}
                disableEscapeKeyDown={true}
                onClose={() => setOpen(false)}
            >
                {spreadsheet('100%')}
            </FullScreenDialog>
            <Menu
                open={Boolean(menuPosition)}
                onClose={() => setMenuPosition(null)}
                anchorReference="anchorPosition"
                anchorPosition={
                    menuPosition
                        ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
                        : undefined
                }
            >
                <MenuItem
                    onClick={
                        () => {
                            setMenuPosition(null);

                            data.splice(menuPosition.i, 1);
                            value.splice(menuPosition.i, 1);
                            onChange(value);
                        }
                    }
                >
                    {t('DeleteRow')}
                </MenuItem>
                <CopyToClipboard
                    text={menuPosition && menuPosition.row.map(({ value: strValue }) => strValue).join('\t')}
                    onCopy={() => setMenuPosition(null)}
                >
                    <MenuItem>
                        {t('CopyRow')}
                    </MenuItem>
                </CopyToClipboard>
            </Menu>
        </>
    );
};

const styled = withStyles(styles)(Spreadsheet);
export default translate('Elements')(styled);

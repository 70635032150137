import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

import Preloader from 'components/Preloader';
import { SchemaForm, handleChangeAdapter } from 'components/JsonSchema';

import emptyGateway from 'application/adminpanel/modules/workflow/variables/emptyGateway';
import gatewayElementTypes from 'application/adminpanel/modules/workflow/variables/gatewayElementTypes';
import getGatewayTypeId from 'application/adminpanel/modules/workflow/pages/Workflow/helpers/getGatewayTypeId';

import {
    requestGateway,
    changeGatewayData,
    saveGatewayData,
    getGatewayTypes
} from 'application/adminpanel/actions/gateways';

import minUnusedIndex from 'helpers/minUnusedIndex';
import padWithZeroes from 'helpers/padWithZeroes';
import processList from 'services/processList';

import elementSchema from '../../defaultSchema.json';
import schema from './schema';

class GatewayElement extends React.Component {
    state = { error: null, showErrorDialog: false, validationErrors: [] };

    async componentDidMount() {
        this.loadGateway();
    }

    async componentDidUpdate(prevProps) {
        const { selectionId } = this.props;

        if (prevProps.selectionId !== selectionId) {
            this.loadGateway();
        }
    }

    getNextGatewayId = (element) => {
        const { workflow, modeler } = this.props;

        const ids = modeler.get('elementRegistry').getAll()
            .filter(({ type, id }) => gatewayElementTypes.includes(type) && id !== element.businessObject.id)
            .filter(({ businessObject: { id } }) => !this.isLocalId(id))
            .map(this.getGatewayId)
            .filter(Number.isInteger)
            .map(String)
            .map(id => id.replace(workflow.id, ''))
            .map(id => parseInt(id, 10));

        return workflow.id + padWithZeroes(minUnusedIndex(ids, 1), 3);
    }

    isLocalId = id => gatewayElementTypes.some((type) => {
        const suffix = type.split(':').pop();
        return id.indexOf(suffix) === 0;
    });

    loadGateway = async () => {
        const { t, element, workflow, actions, actualGatewayList, onChange, gatewayTypes: types } = this.props;
        const gatewayTypes = types || await processList.hasOrSet('getGatewayTypes', actions.getGatewayTypes);

        const gatewayId = this.getGatewayId(element);
        if (element.id.slice(element.id.length - 3) === 'end') {
            return;
        }

        if (this.isLocalId(element.businessObject.id)) {
            const nextGatewayId = this.getNextGatewayId(element);
            element.businessObject.id = ['gateway', nextGatewayId].join('-');
            element.businessObject.name = element.businessObject.name || t('NewGateway');
            onChange(element.businessObject);
            // this.forceUpdate();
            return;
        }

        if (!actualGatewayList[gatewayId] && !processList.has('requestGateway', gatewayId)) {
            const gateway = await processList.set('requestGateway', actions.requestGateway, gatewayId);
            if (gateway instanceof Error) {
                const gatewayTypeId = getGatewayTypeId(element, gatewayTypes);
                await actions.saveGatewayData(emptyGateway(gatewayId, { t, gatewayTypeId, workflow }));
            }
        }
    }

    getGatewayId = ({ businessObject: { id } }) => parseInt(id.split('-').pop(), 10);

    handleChange = (gateway) => {
        const { actions, element } = this.props;
        // const { outgoing } = element;

        // const validationErrors = validateData(gateway, schema({ outgoing }));
        // this.setState({ validationErrors });

        actions.changeGatewayData(this.getGatewayId(element), gateway);
        this.handleChangeBusiness();
    }

    handleChangeBusiness = (businessObject) => {
        const { onChange } = this.props;
        onChange && onChange(businessObject);
        // this.forceUpdate();
    }

    renderForm() {
        const { validationErrors } = this.state;
        const { actualGatewayList, element } = this.props;
        const { outgoing } = element;

        if (element.id.slice(element.id.length - 3) === 'end') {
            return null;
        }

        const gatewayId = this.getGatewayId(element);
        const gateway = actualGatewayList[gatewayId];

        if (!gateway) {
            return <Preloader />;
        }

        return (
            <SchemaForm
                schema={schema({ outgoing })}
                value={gateway}
                errors={validationErrors}
                onChange={handleChangeAdapter(gateway, this.handleChange)}
            />
        );
    }

    render() {
        const { t, element } = this.props;
        const { error, showErrorDialog } = this.state;

        return (
            <div>
                <SchemaForm
                    schema={elementSchema}
                    value={element.businessObject}
                    onChange={handleChangeAdapter(element.businessObject, this.handleChangeBusiness)}
                />
                {this.renderForm()}
                {(showErrorDialog && error)
                    ? (
                        <Dialog
                            open={true}
                            onClose={() => this.setState({ showErrorDialog: false })}
                        >
                            <DialogTitle>{t('ErrorWhileSavingGateway')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {error.message}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.setState({ showErrorDialog: false })}
                                    color="primary"
                                    autoFocus={true}
                                >
                                    {t('CloseErrorDialog')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null}
            </div>
        );
    }
}

GatewayElement.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    actualGatewayList: PropTypes.object,
    element: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    gatewayTypes: PropTypes.array,
    workflow: PropTypes.object,
    selectionId: PropTypes.string,
    modeler: PropTypes.object
};

GatewayElement.defaultProps = {
    actualGatewayList: {},
    gatewayTypes: null,
    workflow: {},
    selectionId: null,
    modeler: null
};

const mapStateToProps = ({
    gateways: { actual, types }
}) => ({
    actualGatewayList: actual,
    gatewayTypes: types
});

const mapDispatchToProps = dispatch => ({
    actions: {
        requestGateway: bindActionCreators(requestGateway, dispatch),
        saveGatewayData: bindActionCreators(saveGatewayData, dispatch),
        changeGatewayData: bindActionCreators(changeGatewayData, dispatch),
        getGatewayTypes: bindActionCreators(getGatewayTypes, dispatch)
    }
});

const translated = translate('WorkflowAdminPage')(GatewayElement);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/uk';
import { Tooltip, withStyles } from '@material-ui/core';

moment.locale('uk');

const styles = theme => ({
    text: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    }
});

const Time = ({ classes, format, date }) => (
    date ? (
        <Tooltip title={moment(date).fromNow()}>
            <span className={classes.text}>{moment(date).format(format)}</span>
        </Tooltip>
    ) : null);

Time.propTypes = {
    date: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    format: PropTypes.string
};

Time.defaultProps = {
    format: 'DD.MM.YYYY HH:mm'
};

export default withStyles(styles)(Time);

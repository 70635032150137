import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import ProcessesListPage from 'application/adminpanel/modules/processes/pages/ProcessesList';
import ProcessesPage from 'application/adminpanel/modules/processes/pages/Processes';

export default {
    routes: [{
        path: '/process/:processId',
        component: ProcessesPage,
        title: 'ProcessesPageTitle',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.process.Process' }
    }, {
        path: '/process',
        component: ProcessesListPage,
        title: 'ProcessesListPageTitle',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.process.Process' }
    }],
    navigation: [{
        id: 'Process',
        title: 'ProcessList',
        icon: <WarningIcon />,
        path: '/process',
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.process.Process' }
    }]
};

export default ({ t }) => ({
    type: 'object',
    properties: {
        name: {
            type: 'string',
            description: t('Name'),
            useTrim: true,
            maxLength: 255,
            checkValid: [
                {
                    isValid: '(propertyData) => !!(propertyData && propertyData.length)',
                    errorText: 'Обов\'язкове поле'
                }
            ]
        },
        description: {
            type: 'string',
            description: t('Description'),
            useTrim: true,
            maxLength: 255,
            checkValid: [
                {
                    isValid: '(propertyData) => !!(propertyData && propertyData.length)',
                    errorText: 'Обов\'язкове поле'
                }
            ]
        },
        schema: {
            control: 'code.editor',
            description: t('JsonSchema'),
            mode: 'json',
            validate: true,
            asJsonObject: true
        },
        parentId: {
            control: 'key.select',
            description: t('ParentId')
        },
        meta: {
            type: 'object',
            properties: {
                isTest: {
                    control: 'toggle',
                    onText: t('IsTest')
                }
            }
        },
        toString: {
            control: 'code.editor',
            description: t('ToString'),
            mode: 'javascript',
            validate: true
        },
        toSearchString: {
            control: 'code.editor',
            description: t('ToSearchString'),
            mode: 'javascript',
            validate: true
        }
    },
    required: [
        'name',
        'description',
        'schema',
        'toString',
        'toSearchString'
    ]
});

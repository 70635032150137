export default {
    locale: 'en',
    App: {
        ApplicationTypeNotDefined: 'Конфігурація сервісу містить помилки',
        'ApplicationTypeNotDefined subtitle': 'Зверніться до адміністрації, щоб налагодити роботу сервісу.',
        NoPermission: 'Ви не маєте повноважень для перегляду цієї сторінки',
        NoPermissionIp: 'Ви не маєте повноважень для перегляду цієї сторінки',
        'NoPermissionIp subtitle': 'Ваша IP адреса відсутня в списку дозволених',
        NoPermissionTitle: 'Немає доступу',
        'User without needed role.': 'Обліковий запис не має прав доступу в цей розділ',
        SwitchUser: 'Використайте інший обліковий запис',
        ConnectionFailed: "Немає з'єднання з сервером",
        'ConnectionFailed subtitle': "З'єднання з сервісом розірвано. Перевірте підключення до інтернету",
        'Failed to fetch': 'Сервіс недоступний',
        'Failed to fetch subtitle': 'Ми проводимо технічні роботи, це займе до 10 хвилин',
        'User without needed role. subtitle': 'Зверніться до адміністратора, або',
        PageNotFound: 'Сторінка не знайдена',
        'Access denied.': 'Обліковий запис не має прав доступу',
        'Access denied. subtitle': 'Зверніться до адміністратора, або',
        NoUnitFound: 'Увага',
        'NoUnitFound subtitle': 'Шановний користувач! Для відкриття доступу до меню кабінету зверніться, будь-ласка, до свого керівника.'
    },
    Navigator: {
        dashboard: 'Головна',
        Tasks: 'Задачі',
        Documents: 'Документи',
        AddNewTask: 'Замовити послугу',
        AddNewProject: 'Подати проєкт',
        Inbox: 'Отримані документи',
        InboxTasks: 'Мої задачі',
        UnitInboxTasks: 'Задачі відділу',
        All: 'Всі',
        ClosedTasks: 'Архів моїх задач',
        UnitClosedTasks: 'Архів задач відділу',
        Registry: 'Реєстр',
        Deleted: 'Видалені',
        Trash: 'Кошик',
        Workflow: 'Мої послуги',
        MyWorkflow: 'Замовлені послуги',
        WorkflowList: 'Список процесів',
        MyProjects: 'Мої проєкти',
        SubmittedProjects: 'Подані проєкти',
        Drafts: 'Чернетки',
        Logout: 'Вийти',
        MyProfile: 'Мій профіль',
        Messages: 'Повідомлення',
        WorkflowCategories: 'Категорії',
        MessagesInbox: 'Вхідні повідомлення',
        AdminWorkflowList: 'Процеси',
        MadeBy: 'Розроблено в',
        UnitList: 'Підрозділи',
        DebugMode: 'Режим розробника',
        ProcessList: 'Логи',
        NumberTemplates: 'Шаблони номерів',
        Users: 'Користувачі',
        List: 'Список',
        HealthCheckList: 'Сервіси',
        ToCabinet: 'До кабінету',
        ToAdminPanel: 'До Адмін-панелі',
        UserProcessList: 'Логи дій користувача',
        WorkflowUsers: 'Виконавці'
    },
    TaskListPage: {
        Header: 'Задачі',
        StatusOpened: 'Відкриті',
        StatusClosed: 'Закриті',
        StatusAll: 'Всі',
        TaskName: 'Задача',
        TaskCreatingTime: 'Дата створення',
        CreatedBy: 'Ким створено',
        UpdatedBy: 'Ким модифіковано',
        createdAt: 'Дата надходження',
        deadline: 'Кінцева дата виконання',
        RefreshPage: 'Оновити',
        EmptyListTitle: 'Вам не надійшло жодної задачі',
        EmptyListDescription: 'Тут будуть відображатись задачі, які ви отримали',
        InboxTasksTitle: 'Поточні задачі',
        UnitInboxTasksTitle: 'Поточні задачі мого відділу',
        ClosedTasksTitle: 'Закриті задачі',
        UnitClosedTasksTitle: 'Закриті задачі мого відділу',
        TrashTitle: 'Кошик',
        DeleteTask: 'Видалити',
        DeleteTaskConfirmation: 'Дійсно видалити заяви?',
        DeleteTaskConfirmationText: 'Заяви буде переміщено в кошик. Їх можна буде відновити в будь-який час.',
        DeleteTaskPermanent: 'Видалити назавжди',
        DeleteTaskPermanentConfirmation: 'Дійсно видалити заяви?',
        DeleteTaskPermanentConfirmationText: 'Заяви буде видалено остаточно, відновити їх вже не буде можливим.',
        RecoverTask: 'Відновити заяву',
        ActiveStatus: 'Активна',
        FinalStatus: 'Закрита',
        DeletedStatus: 'Видалена',
        Performer: 'Виконавець',
        PerformerUnits: 'Підрозділ',
        Deadline: 'Строк виконання',
        DocumentNumber: 'Номер заяви',
        Applicant: 'Заявник',
        ApplicantType: 'Тип Заявника',
        Individual: 'Фізична особа',
        LegalEntity: 'Юридична особа',
        Status: 'Статус',
        DueDate: 'кінцевий термін виконання',
        UserName: 'Заявник {{userName}}'
    },
    TaskPage: {
        You: 'Ви',
        TaskAssign: 'Виконавці',
        Nobody: 'Не назначено',
        Search: 'Пошук',
        Signers: 'Документ буде відправлено після того, як його підпишуть:',
        PrevStepBtn: 'Назад',
        NextStepBtn: 'Продовжити',
        CreatePDFBtn: 'Сформувати документ',
        Sign: 'Підписання',
        SignBtn: 'Підписати та надіслати',
        RejectSignBtn: 'Відмовитись від підписання',
        FinishBtn: 'Готово',
        BackToEdit: 'Повернутись до редагування',
        SuccessCommitMessageHeader: 'Вітаємо!',
        SuccessCommitMessageText: 'Ваша заява була успішно відправлена',
        SigningDialogTitle: 'Будь ласка, підпишіть документ',
        PDFDocumentDialogTitle: 'Попередній перегляд документа',
        CloseDialog: 'Закрити',
        Loading: 'Завантаження...',
        SaveBtn: 'Зберегти',
        PrintBtn: 'Надрукувати',
        DeleteTaskConfirmation: 'Дійсно видалити заяву?',
        DeleteTaskConfirmationText: 'Заява буде видалено остаточно, відновити її вже не буде можливим.',
        RemoveDraft: 'Видалити чернетку',
        "Sign certificate PEM not match user's PEM.": 'Ви не можете підписати документ цим ключем. Використовуйте ключ, за допомогою якого ви авторизувались в кабінеті',
        "P7S sign certificate PEM not match user's PEM.": 'Ви не можете підписати документ цим ключем. Використовуйте ключ, за допомогою якого ви авторизувались в кабінеті',
        GoToNextTask: 'Перейти до наступної задачі',
        ErrorMessageHeader: 'Помилка',
        ErrorLoadingTaks: 'Неможливо завантажити задачу',
        'User doesn\'t have any access to task.': 'Немає доступу для перегляду цієї задачі',
        ErrorLoadingTemplate: 'Неможливо завантажити шаблон задачі',
        ErrorCommitDocument: 'Виникла помилка при надсиланні документа',
        DueDate: 'Кінцева дата виконання',
        Cancel: 'Скасувати',
        ErrorDialogTitle: 'От халепа!',
        BackToList: 'Повернутись до списку',
        'Document has already committed.': 'Документ вже було відправлено.',
        '404 File not found': 'Помилка при роботі з документом',
        MinDateMessage: 'Прострочено',
        InvalidDateMessage: 'Невірний формат дати',
        Preview: 'Попередній перегляд',
        'Validation error.': 'Документ не пройшов валідацію',
        'Workflow template not found.': 'Невідомий процес',
        'Task template not found.': 'Не знайдено шаблон документа',
        'Signed content not match needed.': 'Документ не відповідає підпису',
        'Invalid entryTaskTemplateId.': 'Послуга вам недоступна',
        InvalidTemplate: 'Невалідна схема',
        UnsupportedFormat: 'Формат не підтримується',
        "Ipn from sign certificate not match user's ipn.": 'ІПН електронно-цифрового підпису не збігається з ІПН заявника',
        RejectSigningDialogTitle: 'Відмова підписання',
        RejectSignReason: 'Причина відмови',
        RejectSign: 'Надіслати',
        Start: 'Почати',
        ImportData: 'Імпортувати з файлу XLSX',
        'Signed name not match needed.': 'ПІБ, вказане в електронному ключі, не збігається з ПІБ заявника',
        RequiredField: "Обов'язкове поле",
        'User doesn’t have any access to task.': 'Ви не маєте доступу до цієї заяви',
        'User names are not equal.': 'ПІБ, вказане у вашому електронному ключі, не збігається з ПІБ у списку підписантів. Зверніться, будь ласка, до ініціатора підписання для виправлення помилки.',
        "Can't update - document contains signatures.": 'Вiдбулась помилка при оновленнi даних, виправити яку неможливо, оскільки документ вже підписаний',
        DeleteSignatures: 'Відкликати',
        MaxSizeError: 'Ви намагаєтесь підписати завеликий файл. Максимальний розмір файлів для підписання 5МБ.',
        CheckInputValues: 'Перевірте введені дані',
        ErrorValidatingDocument: 'Виникла помилка при валідації документу. Для виправлення введенних даних оновіть сторінку',
        'Signer can not sign task before performer.': 'Підписати заяву буде можливо після підпису заявника.',
        'Sign not available.': 'Підписання не доступне, Ви не маєте права підпису',
        'Commit not available.': 'Відправка не доступна',
        'User is not in signerUsers list.': 'Користувач відсутній у списку підписантів'
    },
    CreateTaskDialog: {
        SelectTemplate: 'Виберіть послугу',
        SearchTemplate: 'Пошук послуги',
        SearchTemplateHelpText: 'Почніть вводити назву послуги',
        SelectProject: 'Виберіть проєкт',
        SearchProject: 'Пошук проєкта',
        SearchProjectHelpText: 'Почніть вводити назву проєкта',
        Close: 'Закрити'
    },
    Errors: {
        FailStoreWorkflow: 'Виникла помилка при збереженні процесу',
        FailDeletingRows: 'Виникла помилка при видаленні записів',
        FailFetchingRows: 'Виникла помилка при завантаженні записів',
        FailFetchingTask: 'Виникла помилка при завантаженні документа',
        FailUpdatingDocument: 'Виникла помилка при збереженні документа',
        FailCommitDocument: 'Виникла помилка при надсиланні документа',
        FailCreatingTask: 'Виникла помилка при створенні заяви',
        FailRecoveringRows: 'Виникла помилка при відновленні замовлення',
        FailLoadingRegisters: 'Виникла помилка при завантаженні реєстрів',
        FailStoringRecord: 'Виникла помилка при збереженні реєстра',
        FailCreatingRecord: 'Виникла помилка при створенні реєстра',
        FailDeletingRecord: 'Виникла помилка при видаленні реєстра',
        FailLoadingDocumentWorkflowFiles: 'Виникла помилка завантаження документів',
        FailLoadingFile: 'Виникла помилка при завантаженні файлу',
        FailDeletingFile: 'Виникла помилка при видаленні файлу',
        FailDeletingTask: 'Виникла помилка при видаленні задачі',
        FailGeneratingDocument: 'Помилка генерування документа',
        FailLoadingWorkflowTemplates: 'Виникла помилка завантаження шаблонів послуг',
        FailGettingDocument: 'Виникла помилка при завантаженні файлу',
        FailSigningDocument: 'Виникла помилка при підписанні документу',
        FailGettingAttachments: 'Виникла помилка при завантаженні всіх документів',
        FailSettingsTaskSigners: 'Виникла помилка призначення підписантів',
        FailGettingUnreadMessageCount: 'Виникла помилка при завантаженні непрочитанних повідомлень',
        FailGettingUnreadInboxCount: 'Виникла помилка при завантаженні непрочитанних отриманих документів',
        FailLoadingWorkflowStatuses: 'Виникла помилка при завантаженні статусів послуг',
        FailLoadingRegisterKeyRecords: 'Виникла помилка при завантаженні ключів реєстра',
        FailLoadingDocumentAsicContainer: 'Виникла помилка при завантаженні ASIC контейнера',
        FailLoadingDocumentAttachPreview: 'Виникла помилка при завантаженні превью',
        FailLoadingDocumentTemplate: 'Виникла помилка при завантаженні шаблону',
        FailGettingUnreadTaskCount: 'Виникла помилка при завантаженні непрочитанних задач',
        FailGettingUnreadUnitTaskCount: 'Виникла помилка при завантаженні непрочитанних задач відділу',
        FailMarkTaskRead: 'Виникла помилка при позначенні задачі як прочитаної',
        FailExportingWorkflow: 'Цей процес не можна експортувати, бо він містить помилку',
        FailImportingWorkflow: 'Виникла помилка при імпортуванні послуги',
        FailSavingUnit: 'Виникла помилка при збереженні підрозділу',
        FailCalculating: 'Виникла поммилка при підрахунку',
        FailFetchingUnit: 'Виникла помилка при завантаженні підрозділу',
        FailCreatingUnit: 'Виникла помилка при створенні підрозділу',
        ImportSuccess: 'Імпорт послуги виконано успішно',
        ImportUnitsSuccess: 'Імпорт підрозділів виконано успішно',
        FailExportingUnits: 'Виникла помилка при експортуванні підрозділів',
        FailImportingUnits: 'Виникла помилка при імпортуванні підрозділів',
        FailCreatingWorkflow: 'Виникла помилка при створенні процесу',
        FailSavingGateway: 'Виникла помилка при збереженні шлюзу',
        FailSavingEvent: 'Виникла помилка при збереженні події',
        FailSavingTask: 'Виникла помилка при збереженні задачі',
        FailLoadingWorkflow: 'Виникла помилка при завантаженні процесу',
        InvalidFile: 'Файл, який Ви намагаєтесь імпорувати, невалідний',
        FixAllErrors: 'Спочатку виправте всі помилки',
        FailDeletingWorkflowCategory: 'Виникла помилка при видаленні категорії',
        ImportRegistersSuccess: 'Імпорт реєстрів виконано успішно',
        FailImportingRegisters: 'Виникла помилка при імпортуванні реєстрів',
        FailExportingRegisters: 'Виникла помилка при експортуванні реєстрів',
        FailExportingTemplates: 'Виникла помилка при експортуванні шаблонів',
        FailDeletingWorkflow: 'Даний процес видалити неможливо, тому що він використовується у інших процесах',
        FailRequestExternalData: 'Виникла помилка при завантаженні даних',
        RegistersAccessNotAllowed: 'У вас відсутній доступ на перегляд записів реєстру',
        FailGettingPaymentInfo: 'Виникла помилка при отриманні даних про оплату',
        FailGettingPaymentStatus: 'Виникла помилка при отриманні статусу оплати',
        SuccessPaymentStatus: 'Оплата пройшла успішно',
        PendingPaymentStatus: 'Транзакція ще обробляється. Спробуйте перевірити статус оплати трохи пізніше, натиснувши "Оновити статус"',
        FailConfirmingSmsCode: 'Не вдалось підтвердити код',
        PaymentCheckCode: 'Оплата не пройшла. Перевірте код з смс',
        FailGettingRegistersKeys: 'Виникла помилка при отриманні ключів реєстру',
        ErrorSavingWorkflow: 'Виникла помилка під час збереження процесу',
        FailToUpdateWorkflowProcessTask: 'Виникла помилка під час збереження задачі',
        WorkflowOldVersionError: 'Ваша версія процесу застаріла. Оновіть сторінку.',
        FailSearchingUsers: 'Виникла помилка при пошуку користувачів',
        FailGettingWorkflowVersions: 'Виникла помилка при отриманні версій процесу',
        FailCreatingWorkflowVersion: 'Виникла помилка при створенні версії процесу',
        CreatingVersionSuccess: 'Нова версія процесу успішно створена',
        FailRestoringWorkflowVersion: 'Виникла помилка при відновленні версії процесу',
        RestoringVersionSuccess: 'Версію процесу успішно відновлено',
        NumberTemplateAlreadyExists: 'Такий шаблон вже існує',
        FailSettingRegistersAccess: 'Виникла помилка під час створення прав доступу',
        FailGettingRegistersAccess: 'Виникла помилка під час отримання прав доступу',
        FailPutingRegistersAccess: 'Виникла помилка під час оновлення прав доступу',
        FailRestartingWorkflowProcess: 'Виникла помилка під час перезапуску процесу',
        FailRestartingWorkflowProcessFromPoint: 'Виникла помилка під час перезапуску процесу з даної точки',
        FailCheckingExternalReader: 'Помилка при перевірці даних в реєстрі',
        FailReadOnlySavingWorkflow: 'Коригування процесу відключено на даному середовищі',
        'update or delete on table "units" violates foreign key constraint "unit_access_unit_id_fkey" on table "unit_access"': 'Видалення неможливе, поки підрозділу надано доступи до реєстрів',
        "Task has paid payment! Can not delete, let's commit it!": 'Ви не можете видалити чернетку, тому що вона містить інформацію про сплату',
        "Error: Can't find recipient banking details.": 'Неможливо знайти банківські реквізити одержувача',
        "Error: Can't define pay type for this phone number.": 'Неможливо визначити тип оплати для цього номеру телефону',
        "Error: User phone is not defined. Can't get payment data.": 'Телефон користувача не визначений. Неможливо отримати дані про оплату',
        FailToCheckAsNotErrorWorkflowProcess: 'Виникла помилка під час позначення процесу як "Без помилки"'
    },
    Elements: {
        UploadAnotherFile: 'Завантажити інший файл',
        DeleteRow: 'Видалити рядок',
        CopyRow: 'Копіювати рядок',
        ClearFiles: 'Очистити',
        ClearData: 'Очистити дані',
        ClearDataPrompt: 'Ви дійсно хочете очистити всі дані таблиці?',
        Error: 'Помилка',
        SHOW_SAMPLE_DIALOG: 'Як заповнювати',
        SAMPLE_EXPAND: 'Як заповнювати',
        CLOSE: 'Закрити',
        Close: 'Закрити',
        Cancel: 'Скасувати',
        Accept: 'Так',
        Yes: 'Так',
        No: 'Ні',
        DropFiles: 'Перетягніть сюди файли або {{link}}',
        SelectFiles: 'завантажте їх зі свого носія',
        MaxFileSizeLimit: 'Максимальний розмір одного файлу: {{size}}',
        MinFileSizeLimit: 'Мінімальний розмір одного файлу: {{size}}',
        FileTypeLimit: 'Допустимі формати: {{types}}',
        FileName: 'Документ',
        FileDate: 'Дата',
        IsGenerated: 'Згенерований документ',
        AddArrayItem: 'Додати',
        DownloadAllAttachments: 'Завантажити всі документи',
        NotRequired: ' (не обов`язково)',
        AddItem: 'Додати запис',
        DeleteItem: 'Видалити запис',
        NoOptions: 'Не знайдено',
        EditMode: 'Редагувати {{mode}}',
        ValidationErrors: 'Спочатку виправте всі помилки',
        Apply: 'Зрозуміло',
        Search: 'Пошук...',
        AddUsers: 'Додати користувачів',
        FinishEditing: 'Закінчити редагування',
        EmptySelectedUsers: 'Список користувачів пустий',
        EmptySearchResults: 'Відсутні варіанти',
        SearchResults: 'Результати пошуку:',
        SearchByName: 'по прізвищу',
        SearchByIPN: 'по ІПН',
        SearchByID: 'по ID',
        Clear: 'Очистити',
        SelectRecord: 'Оберіть зі списку',
        EmptyData: 'Немає даних',
        FROM: 'з',
        MakePayment: 'Сплатити',
        Paid: 'Сплачено',
        SYMBOLS_COUNT: 'Введено символів: {{num}} {{max}} ',
        Currency: 'грн',
        PhoneConfirnmation: 'Ми надіслали вам смс-повідомлення з кодом на вказаний телефон <b>{{contact}}</b>. Введіть його, щоб підтвердити, що це саме ваш номер',
        PhoneConfirnmationSuccess: 'Ви успішно підтвердили телефон',
        EmailConfirnmation: 'Ми надіслали вам лист з кодом на вказану електронну адресу <b>{{contact}}</b>. Введіть його, щоб підтвердити, що це саме ваша електронна адреса',
        EmailConfirnmationSuccess: 'Ви успішно підтвердили електронну адресу',
        FailToValidateCode: 'Виникла помилка при перевірці коду',
        SendAnotherCode: 'Надіслати ще раз',
        SendAnotherCodeCounDown: 'Надіслати ще раз зможете через {{counter}}',
        PhoneIsAlreadyExists: 'Цей номер телефону ({{contact}}) вже використовується в системі.',
        EmailIsAlreadyExists: 'Ця електронна адреса ({{contact}}) вже використовується в системі.',
        FailToSendVerifyCode: 'Виникла помилка при відправці. Спробуйте пізніше.',
        Edit: 'Редагувати',
        Phone: 'Телефон',
        SmsCode: 'Код із СМС-повідомлення',
        EmailCode: 'Код із листа',
        Resend: 'Надіслати ще раз',
        GetCode: 'Отримати код',
        SendCode: 'Відправити код',
        RefreshPaymentStatus: 'Оновити статус',
        Loading: 'Завантаження...',
        Open: 'Відкрити',
        PhoneValidationMessage: 'Введіть номер телефону у вигляді 380XXXXXXXXX',
        CodeValidationMessage: 'Введіть код із смс',
        QRError: 'Сервіс оплати тимчасово не доступний, спробуйте через деякий час',
        Save: 'Зберегти',
        CodeEditorAlertTitle: 'Впевнені що хочете закрити це вікно?',
        CodeEditorAlertText: 'Всі зміни буде збережено',
        UploadFiles: 'Завантажити',
        OpenEditor: 'Відкрити редактор',
        OpenTableEditor: 'Відкрити таблицю',
        TableError: 'У таблиці допущено помилки.',
        ResolveErrors: 'Увага',
        ResolveErrorsPrompt: 'Перш ніж покинути редактор, виправте помилки в схемі.'
    },
    SignForm: {
        sign: 'Підпис',
        stamp: 'Печатка',
        Timeout: 'Вийшов час виконання функції. Спробуйте пізніше',
        ACSKServer: 'Кваліфікований надавач електронних довірчих послуг',
        ACSKServerHint: 'Виберіть центр сертифікацій, який видав вам електронний підпис',
        SelectServer: 'Виберіть центр сертифікацій, який видав вам електронний підпис',
        Key: 'Файл ключа',
        KeyHint: "Виберіть файл особистого ключа на своєму комп'ютері",
        SelectedKey: 'Ключ',
        SelectedKeyHint: 'Оберіть ключ, печатку або підпис',
        SelectKey: "Виберіть файл особистого ключа на своєму комп'ютері",
        Password: 'Пароль',
        PasswordHint: 'Введіть пароль від особистого ключа',
        FillPassword: 'Введіть пароль від особистого ключа',
        Sign: 'Підписати',
        SigningDataError: 'Помилка',
        Cancel: 'Скасувати',
        CloseDialog: 'Закрити',
        FileKeySignMethod: 'Файловий ключ',
        HardwareKeySignMethod: 'Апаратний ключ',
        HardwareKeySignMethodNotSupportedMobile: 'Апаратний ключ не підтримується в мобільній версії',
        HardwareKeySignMethodNotSupportedBrowser: 'Апаратний ключ не підтримується вашим браузером',
        SelectKmType: 'Тип пристрою',
        SelectKmDevice: 'Пристрій',
        TypeHint: 'Виберіть тип пристрою',
        DeviceHint: 'Виберіть пристрій',
        SelectType: 'Виберіть тип пристрою',
        SelectDevice: 'Виберіть пристрій',
        ProxySettings: 'Проксі',
        ProxySettingsTitle: 'Налаштування проксі сервера',
        ProxySettingsEnable: 'Використовувати проксі сервер',
        ProxySettingsAddress: 'Адреса проксі',
        ProxySettingsPort: 'Порт',
        ProxySettingsAnonymous: 'Анонімно',
        ProxySettingsUser: 'Користувач',
        ProxySettingsPassword: 'Пароль',
        CantDetectACSK: 'Нажаль, ми не змогли автоматично визначити центр сертифікацї вашого ключа',
        ACSKAutoDetect: 'Визначити автоматично',
        DropFiles: 'Перетягніть сюди файл ключа або {{link}}',
        UploadFiles: 'оберіть його на своєму носієві',
        DropFilesLimits: 'Підтримуються формати: .jks, .pfx, .pk8, .zs2, .dat',
        DropedFile: 'Файл ключа:',
        DropNewFile: 'Завантажити інший файл',
        Refresh: 'Оновити',
        PROXY_SETTINGS_ENABLE: 'Використовувати проксі сервіс',
        PROXY_SETTING: 'Налаштування проксі сервісу',
        PROXY_SETTINGS_ADDRESS: 'Хост',
        PROXY_SETTINGS_PORT: 'Порт',
        PROXY_SETTINGS_ANONYMOUS: 'Анонімно',
        PROXY_SETTINGS_USER: 'Користувач',
        PROXY_SETTINGS_PASSWORD: 'Пароль',
        FileSizeLimit: 'Файл має завеликий обсяг',
        NoItems: 'Ключ не знайдено'
    },
    DataTable: {
        DISPLAYED_ROWS: 'з {{from}} по {{to}} із {{total}}',
        ROWS_PER_PAGE: 'Показувати на сторінці',
        SelectedRows: 'Рядків обрано',
        Delete: 'Перемістити до кошику',
        RecoverItem: 'Відновити видалені',
        RemovePermanent: 'Видалити з кошика',
        Reload: 'Оновити',
        Search: 'Пошук...',
        Select: 'Вибрати',
        SwitchView: 'Переключити відображення',
        Show: 'Показати:',
        EmptyData: 'Немає даних',
        AddItem: 'Додати запис',
        CustomizateColumns: 'Колонки',
        WithoutGroup: 'Без категорії',
        Close: 'Закрити',
        ToggleGrouping: 'Групування'
    },
    DataList: {
        Back: 'Попередня сторінка',
        Forward: 'Наступна сторінка'
    },
    RegistryPage: {
        HasUnsavedData: 'Увага!',
        HasUnsavedDataPrompt: 'Запис містить незбережені дані, ви дійсно хочете закрити вікно?',
        title: 'Редактор реєстрів',
        SelectKey: 'Виберіть реєстр...',
        SelectRegistryKeyLabel: 'Ключ реєстра',
        SearchFieldLabel: 'Пошук',
        RowsPerPage: 'Записів на сторінці',
        Name: 'Назва',
        CreatedBy: 'Ким створено',
        CreatedAt: 'Дата створення',
        UpdatedBy: 'Ким модифіковано',
        Delete: 'Видалити',
        Close: 'Закрити',
        Save: 'Зберегти',
        DeleteRecordConfirmation: 'Дійсно видалити запис?',
        DeleteRecordConfirmationText: 'Запис буде видалено остаточно, відновити вже не буде можливим.',
        AddNewRow: 'Новий запис',
        NoData: 'Немає записів',
        Actions: 'Дії',
        Edit: 'Редагувати',
        ExportToExel: 'Експортувати в Excel',
        Pagination: '{from} - {to}',
        ChooseColumns: 'Обрати колонки',
        Records: 'Записи',
        History: 'Історія змін',
        Operation: 'Операція',
        create: 'Створення',
        update: 'Зміна',
        delete: 'Видалення',
        Versions: 'Перегляд версій',
        'User doesn\'t have any access to register record.': 'Ваш обліковий запис не має прав доступу до цього розділу'
    },
    MimeType: {
        OR: ' або ',
        'image/*': 'зображення',
        'audio/': 'аудіо',
        'video/': 'відео',
        'audio/*': 'аудіо',
        'video/*': 'відео',
        'image/gif': 'GIF зображення',
        'image/jpg': 'JPG зображення',
        'application/pdf': 'PDF документ',
        'application/msword': 'документ Word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'документ Word',
        'application/vnd.oasis.opendocument.text': 'документ Word',
        'application/rtf': 'документ Word',
        'application/vnd.ms-excel': 'документ Excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'документ Excel'
    },
    EJV: {
        CheckValid: 'Перевірте поле',
        SchemaNotFound: 'Не знайдена схема "{{ref}"',
        ShouldIncludeLessThan: [
            'Повинно мати не більше одного елемента',
            'Повинно мати не більше {{n}} елементів'
        ],
        ShouldIncludeMoreThan: [
            'Повинно мати не менше одного елемента',
            'Повинно мати не менше {{n}} елементів'
        ],
        ShouldNotInclude: 'Не повинно мати додаткові поля',
        NotEquilsTo: 'Не відповідає заданим параметрам',
        HaveNoDepencies: 'Відсутні залежності',
        MustBe: 'Повинно бути {{cond}}',
        InvalidSchema: 'схема недійсна',
        MustHaveFormat: 'Виправте введені дані',
        FormatExclusiveMaximum: 'formatExclusiveMaximum має бути boolean',
        FormatExclusiveMinimum: 'formatExclusiveMinimum має бути boolean',
        MustEquilsToSchema: 'Повинно відповідати схемі "{{keyword}}"',
        ShouldBeLessThan: [
            'Повинно бути не довше одного символа',
            'Повинно бути не довше {{n}} символів'
        ],
        ShouldBeMoreThan: [
            'Повинно бути не менше одного символа',
            'Повинно бути не менше {{n}} символів'
        ],
        ShouldHaveLessPropertiesThan: [
            'Повинно мати не менше одного поля',
            'Повинно мати не менше {{n}} полів'
        ],
        ShouldHaveMorePropertiesThan: [
            'Повинно мати не більше одного поля',
            'Повинно мати не більше {{n}} полів'
        ],
        MustBeMultipleOf: 'має бути кратным {{multipleOf}}',
        ShouldNotBe: 'Повинно не відповідати схемі в "not"',
        ShouldBeOneOf: 'Повинно відповідати в точності одній схемі в "oneOf"',
        ShouldPatternRequired: 'Повинно мати поле, відповідно прикладу "{{missingPattern}}"',
        InvalidPropertyName: "ім'я поля \"{{propertyName}}\" не відповідає схемі",
        ReqiredField: "Обов'язкове поле",
        ShouldHaveUniqueElements: 'не Повинно мати повторних елементів ({{j}}) та {{i}} однакові)',
        AllFieldsRequired: 'Необхідно заповнити усі поля',
        ContactConfirmationRequired: 'Необхідно ввести правильний код підтверждення'
    },
    WorkflowListPage: {
        InboxTitle: 'Замовлені послуги',
        ProjectTitle: 'Подані проєкти',
        Drafts: 'Чернетки',
        WorkflowNumber: 'Номер заяви',
        WorkflowName: 'Послуга',
        ProjectName: 'Проєкт',
        LastStepLabel: 'Статус',
        CreatedAt: 'Дата створення',
        OrderedAt: 'Дата замовлення',
        UpdatedAt: 'Дата оновлення',
        SubmittedAt: 'Дата подання',
        DeleteWorkflow: 'Видалити',
        EmptyListTitle: 'Ви не замовили жодної послуги',
        EmptyListDescription: 'Тут будуть відображатись послуги, які ви замовили, та хід їх розгляду',
        EmptyProjectListTitle: 'Ви не подали жодного проєкту',
        EmptyProjectListDescription: 'Тут будуть відображатись проєкти, які ви подали та хід їх розгляду',
        EmptyTrashListTitle: 'Кошик порожній',
        EmptyTrashListDescription: 'Тут будуть відображатись видалені послуги',
        EmptyDraftsListTitle: 'Список чернеток порожній',
        EmptyDraftsListDescription: 'Тут будуть відображатись чернетки',
        DeleteWorkflowConfirmation: 'Дійсно видалити послуги?',
        DeleteWorkflowConfirmationText: 'Послуги буде переміщено в кошик. Їх можна буде відновити в будь-який час.',
        DeleteProjectConfirmation: 'Дійсно видалити проєкти?',
        DeleteProjectConfirmationText: 'Проєкти буде переміщено в кошик. Їх можна буде відновити в будь-який час.',
        DraftStatus: 'Чернетка',
        NoStatus: 'Статус не задано',
        Doing: 'У роботі',
        Done: 'Надано',
        Rejected: 'Відмовлено',
        All: 'Усі',
        TrashPage: 'Кошик'
    },
    TrashListPage: {
        TrashPage: 'Кошик',
        RestoreTrash: 'Відновити',
        DeleteTrash: 'Видалити остаточно',
        DeleteTrashConfirmation: 'Дійсно видалити послуги?',
        DeleteTrashConfirmationText: 'Послуги будуть видалені остаточно, відновити їх вже буде неможливо.',
        DeleteProjectTrashConfirmation: 'Дійсно видалити проєкти?',
        DeleteProjectTrashConfirmationText: 'Проєкти будуть видалені остаточно, відновити їх вже буде неможливо.',
        RestoreTrashConfirmation: 'Дійсно відновити послуги?',
        RestoreTrashConfirmationText: 'Послуги буде відновлено. Їх можна буде знову видалити.',
        RestoreProjectTrashConfirmation: 'Дійсно відновити проєкти?',
        RestoreProjectTrashConfirmationText: 'Проєкти буде відновлено. Їх можна буде знову видалити.',
        EmptyTrashListTitle: 'Кошик порожній',
        EmptyTrashListDescription: 'Тут будуть відображатись видалені послуги',
        WorkflowName: 'Послуга'
    },
    WorkflowPage: {
        Loading: 'Завантаження...',
        CreatedAt: 'Замовлено {{time}}',
        SubmittedAt: 'Подано {{time}}',
        FileName: 'Документ',
        FileDate: 'Дата',
        IsGenerated: 'Згенерований документ',
        PDFDocumentDialogTitle: 'Попередній перегляд документа',
        CloseDialog: 'Закрити',
        ShowPreview: 'Попередній перегляд',
        DownloadFile: 'Завантажити',
        DeleteFile: 'Видалити',
        DeleteRecordConfirmation: 'Дійсно видалити файл?',
        DeleteRecordConfirmationText: 'Файли будуть видалені остаточно, відновити їх вже буде неможливо.',
        DownloadAllAttachments: 'Завантажити всі документи',
        TaskCreated: 'Створено задачу № {{id}}',
        IncomingMessage: 'Вхідне повідомлення:'
    },
    MessageListPage: {
        InboxTitle: 'Вхідні повідомлення',
        MessageTitle: 'Повідомлення',
        createdAt: 'Отримано',
        Delete: 'Видалити',
        DeletePermanent: 'Видалити',
        Recover: 'Відновити видалені',
        EmptyListTitle: 'Вам не надійшло жодного повідомлення',
        EmptyListDescription: '',
        DeletePermanentConfirmation: 'Дійсно видалити повідомлення?',
        DeletePermanentConfirmationText: 'Повідомлення будуть видалені остаточно, відновити їх вже буде неможливо.',
        MarkAllAsRead: 'Позначити як прочитане'
    },
    MessagePage: {
        CreatedAt: 'Надіслано {{time}}',
        Loading: 'Завантаження...'
    },
    UserProfile: {
        EditUserHeader: 'Профіль користувача',
        EditUserHeaderHint: 'Редагувати профіль',
        SaveButton: 'Зберегти',
        LastNameInputLabel: 'Прізвище',
        FirstNameInputLabel: "Ім'я",
        MiddleNameInputLabel: 'По-батькові',
        EmailInputLabel: 'Email',
        PhoneInputLabel: 'Телефон',
        BirthdayInputLabel: 'Дата народження',
        RegistrationInputLabel: 'Дата державної реєстрації',
        BirthdayInputError: 'Введіть дату у форматі 01.01.2000',
        IpnInputLabel: 'РНОКПП (раніше ІПН) або серія та номер паспорта',
        ProfileSaved: 'Профіль збережено',
        Saved: 'Зміни збережено',
        TwoFactorAuthEnable: 'Увімкнути двоетапну перевірку при вході',
        TwoFactorAuthDisable: 'Вимкнути двоетапну перевірку при вході',
        TwoFactorAuthTitle: 'Увімкнення двоетапної перевірки',
        TwoFactorAuthText: 'Якщо ви ввімкнете двоетапну перевірку ви додатково отримуватимете смс з унікальним кодом на свій телефон при кожному вході в систему.',
        ConfirmText: 'Для увімкнення вам буде надіслано смс з кодом на номер:',
        EmailError: 'Некоректна адреса Email',
        NotChangeEmailError: 'Ви ввели ту саму адресу',
        DuplicateEmailError: 'Ця електронна адреса  вже зареєстрована за іншим аккаунтом',
        EmailDialogTitle: 'Зміна адреси Email',
        PhoneDialogTitle: 'Зміна номеру телефону',
        EmailDialogText: 'Введіть нову адресу. На неї вам буде відправлено лист з кодом підтвердження:',
        PhoneDialogText: 'Введіть новий номер. На нього вам буде відправлено смс з кодом підтвердження:',
        PhoneAlreadyExists: 'Цей телефонний номер вже використовується',
        TextWaitForCode: 'Будь ласка, зачекайте на лист та введіть код у поле нижче:',
        TextWaitForSMSCode: 'Будь ласка, зачекайте на SMS та введіть код у поле нижче:',
        SendMail: 'Надіслати лист',
        ValidationFalse: 'Код не пройшов перевірку',
        CodeInputLabel: 'Код підтвердження',
        VerifyCode: 'Підтвердити',
        ResendCode: 'Надіслати лист ще раз',
        ResendSMS: 'Надіслати СМС ще раз',
        SendCode: 'Надіслати лист',
        SendSMS: 'Надіслати СМС',
        CompanyNameInputLabel: 'Юридична особа',
        EDRPOUInputLabel: 'код ЄДРПОУ',
        Upload: 'Завантажити відсканований документ',
        IndividualEntrepreneur: 'Фізична особа–підприємець',
        PhoneValidationNeeded: 'Ви не підтвердили ваш телефон. {{actions}}',
        ValidatePhone: 'Підтвердити',
        ValidatePhoneTitle: 'Підтвердження номера телефону.',
        ValidatePhoneMessage: "На ваш телефон {{phone}} вислано код. Для підтвердження введіть його у поле нижче і натисніть кнопку 'Підтвердити'",
        Cancel: 'Відміна',
        Validate: 'Підтвердити',
        ValidatePhoneSuccess: 'Перевірка номеру успішна.',
        Close: 'Закрити',
        Address: 'Адреса',
        LegalAddress: 'Адреса фактичного місцезнаходження',
        RequiredFields: "* - заповнювати обов'язково",
        AddressHelper: 'Введіть область, район, місто (село, селище), вул (бульв. провул, узвіз тощо), буд, кв, поштовий індекс.',
        PassportSeries: 'Серія',
        PassportNumber: 'Номер',
        PassportIssueDate: 'Дата видачі',
        PassportIssuedBy: 'Ким виданий'
    },
    DatePicker: {
        Label: 'Дата',
        FormatError: 'Введіть дату у форматі 01.01.2000',
        MaxDateError: 'Дата повинна бути не пізніше {{date}}',
        MinDateError: 'Дата повинна бути не раніше {{date}}',
        FromDate: 'З дати',
        ToDate: 'По дату',
        CorrectDate: 'За дату',
        Cancel: 'Закрити'
    },
    InboxFilesListPage: {
        InboxFilesTitle: 'Отримані документи',
        EmptyListTitle: 'Вам не надійшло жодного документа',
        EmptyListDescription: 'Тут будуть відображатись документи, отримані в процесі користування сервісом',
        InboxFilesNumber: 'Номер',
        InboxFilesName: 'Документ',
        CreatedAt: 'Дата отримання',
        MarkAllAsRead: 'Позначити як прочитане'
    },
    Labels: {
        DueDate: 'На виконання {{date}}',
        Expired: 'Прострочено на {{date}}'
    },
    InboxFilesPage: {
        Loading: 'Завантаження...',
        FileName: 'Документ',
        FileDate: 'Дата',
        DownloadAsic: 'Завантажити документи з підписом',
        SaveBtn: 'Зберегти',
        PrintBtn: 'Надрукувати',
        FileLoadingError: 'Завантажений файл не знайдено'
    },
    FileDataTable: {
        FileName: 'Документ',
        FileDate: 'Дата',
        Download: 'Завантажити обрані',
        ArchiveName: 'Документи.zip',
        Preview: 'Попередній перегляд',
        Signature: 'Підписання',
        DownloadFile: 'Завантажити',
        Originals: 'Оригінали файлів',
        Asics: 'ASiC-S контейнери',
        DeleteFile: 'Видалити файл',
        DeleteFilePrompt: 'Ви дійсно бажаєте видалити файл?'
    },
    SignerListComponent: {
        EmptySignerList: 'Список підписантів пустий'
    },
    SmartMessage: {
        CloneWorkflow: 'Створити нову задачу'
    },
    Settings: {
        SettingsTitle: 'Імпорт/Експорт послуги',
        ImportFromFile: 'Імпортувати з файлу',
        Upload: 'Завантажити',
        Import: 'Імпорт',
        Export: 'Експорт'
    },
    PageTitles: {
        InboxTitle: 'Замовлені послуги',
        Users: 'Користувачі',
        AdminWorkflowList: 'Виконавці задач'
    },
    MessagesNotify: {
        AllMessages: 'Всі повідомлення'
    },
    TreeListSelect: {
        Select: 'Не обрано',
        Loading: 'Завантаження...',
        Search: 'Пошук...',
        Close: 'Закрити'
    },
    WorkflowListAdminPage: {
        AdminWorkflowList: 'Список процесів',
        WorkflowName: 'Назва процесу',
        WorkflowId: 'Номер',
        ImportWorkflow: 'Імпортувати процес',
        ExportWorkflow: 'Експортувати процес',
        CreateWorkflow: 'Створити процес',
        FillNewId: 'Введіть ID нового процесу',
        OverwriteWorkflowConfirmation: 'Процеси з таким ID вже існують',
        OverwriteWorkflowConfirmationText: 'Ви дійсно бажаєте виконати перезапис існуючих процесів?',
        NewWorkflow: 'Створення процесу',
        Continue: 'Продовжити',
        Customer: 'Замовник',
        CreatedAt: 'Дата створення',
        UpdatedAt: 'Дата оновлення',
        WorkflowCategory: 'Категорія',
        WorkflowErrorTitle: 'Виникла помилка',
        'Workflow already exists.': 'Процес з таким ID вже існує',
        '403 forbidden': 'Створення процесу відключено на даному середовищі',
        '403 forbidden_deleting': 'Видалення процесу відключено на даному середовищі',
        DeleteWorkflow: 'Видалити процес',
        DeleteWorkflowDialogTitle: 'Ви дійсно бажаєте видалити процес?',
        DeleteWorkflowDialogDescription: 'Видалення буде остаточним, відновити процес можливо лише з експортованих на жорсткий диск пристрою.',
        ErrorWhileDeletingWorkflow: 'Помилка при видаленні процесу',
        WorkflowDeletingErrorMessage: 'Даний процес видалити неможливо, тому що він використовується у інших процесах',
        CloseErrorDialog: 'Закрити'
    },
    WorkflowAdminPage: {
        AdminWorkflow: 'Редактор процесу',
        Save: 'Зберегти',
        ErrorWhileSavingTask: 'Виникла помилка при збереженні. Перевірте всі дані.',
        ErrorWhileSavingEvent: 'Виникла помилка при збереженні. Перевірте всі дані.',
        ErrorWhileSavingGateway: 'Виникла помилка при збереженні. Перевірте всі дані.',
        CloseErrorDialog: 'Закрити',
        SaveChanges: 'Зберегти',
        WorkflowCategory: 'Категорія',
        WorkflowIsActive: 'Активний',
        WithoutCategory: 'Без категорії',
        UnsavedDataWillBeLostPrompt: 'Процес не був збережений. Якщо ви перезавантажите сторінку, дані будуть втрачені.',
        Settings: 'Налаштування',
        Close: 'Закрити',
        WorkflowName: 'Назва процесу',
        WorkflowDescription: 'Опис',
        WorkflowEntryTask: 'Вхідні задачі',
        WorkflowInfo: 'Посилання',
        WorkflowTimeLine: 'Хронологія',
        WorkflowInfoName: 'Назва',
        WorkflowInfoLink: 'Посилання',
        WorkflowTimelineLabel: 'Позначка',
        WorkflowTimelineDescription: 'Опис',
        WorkflowTaskTemplate: 'Шаблон заяви',
        WorkflowEntryTaskFunction: 'Функція',
        HideInCreateTaskDialog: 'Приховати в списку задач',
        Deadline: 'Строк виконання',
        DeadlineSample: 'Наприклад: 15wd або 15d',
        WorkflowStatuses: 'Статуси',
        WorkflowStatus: 'Статус',
        WorkflowEvent: 'Подія',
        NewEvent: 'Нова подія',
        NewGateway: 'Новий шлюз',
        NewTask: 'Нова задача',
        Saved: 'Зміни збережено',
        SavedWithError: 'Не всі зміни було збережено',
        Accesses: 'Доступи',
        InboxesAccess: 'Отримані документи',
        WorkflowFilesAccess: 'Файли процесу',
        DocumentJsonSchema: 'Схема користувацької форми',
        DocumentHtmlSchema: 'Шаблон друкованої форми',
        TaskJsonSchema: 'Налаштування задачі',
        TaskHtmlSchema: 'HTML шаблон задачі',
        NumberTemplate: 'Шаблон нумерації',
        CreateWorkflowVersion: 'Зберегти в нову версію процесу',
        CurrentVersion: 'Поточна версія: {{version}}',
        RestoreWorkflowVersion: 'Відновити версію процесу',
        Version: 'Повернутись до версії',
        Restore: 'Відновити',
        SignRequired: 'Підписання документа',
        IsP7sSign: 'Підпис в форматі p7s',
        TaskTitle: 'Заголовок задачі',
        StepOrders: 'Порядок кроків',
        ForceRedirect: 'Переадресовувати до наступної задачі',
        ShowAttach: 'Показувати Завантаження',
        FinalScreen: 'Фінальний екран',
        FinalScreenTitle: 'Заголовок',
        FinalScreenSubtitle: 'Підзаголовок',
        Schema: 'Схема',
        AskEventTypeTitle: 'Виберіть тип:',
        AskEventTypeDescr: 'За замовчуванням - "notification"',
        CreateParallelGatewayEnding: 'Закрити шлюз'
    },
    DebugTools: {
        AuthTools: 'Авторизація',
        TaskDataTools: 'Дані заяви',
        CheckValidFunction: 'Перевірка функції checkValid',
        CheckHiddenFunction: 'Перевірка функції isHidden',
        Token: 'Токен',
        SelectPage: 'Виберіть крок',
        SelectElement: 'Виберіть елемент',
        CheckFunction: 'Перевірити функцію',
        Function: 'Функція',
        Result: 'Результат',
        WorkflowLogs: 'Логи',
        CreatedAt: 'Дата',
        EDSFormTest: 'Перевірка форми підпису',
        EDSSignVerify: 'Перевірка підпису',
        PopupDebugTools: 'Дані попап',
        PopupCheckValidTools: 'CheckValid на попап',
        Curator: 'Увійти від імені...'
    },
    UnitsListPage: {
        UnitList: 'Список підрозділів',
        UnitName: 'Назва',
        HeadCount: 'Голови',
        CreateNewUnit: 'Створити підрозділ',
        ImportUnits: 'Імпортувати підрозділи',
        ExportUnits: 'Експортувати підрозділи',
        OverwriteUnitsConfirmation: 'Підрозділи з таким ID вже існують',
        OverwriteUnitsConfirmationText: 'Ви дійсно бажаєте виконати перезапис існуючих підрозділів?',
        CreatedAt: 'Дата створення',
        UpdatedAt: 'Дата оновлення',
        DeleteUnitsDialogTitle: 'Ви дійсно бажаєте видалити вибрані підрозділи?',
        DeleteUnitsDialogDescription: 'Видалення буде остаточним, відновити підрозділи можливо лише з експортованих на жорсткий диск пристрою.',
        DeleteUnits: 'Видалити підрозділи'
    },
    UnitPage: {
        Unit: 'Підрозділ',
        Save: 'Зберегти',
        Loading: 'Завантаження...',
        NewUnit: 'Створення нового підрозділу',
        UnitErrorTitle: 'Виникла помилка',
        'Unit already exists.': 'Підрозділ з такою назвою вже існує'
    },
    WorkflowCategoryListPage: {
        CreateCategory: 'Створити',
        WorkflowCategoryList: 'Категорії процесів',
        ErrorWhileDeletingCategory: 'Помилка при видаленні категорії',
        CategoryDeletingErrorMessage: 'Дану категорію видалити неможливо, тому що вона використовується у інших процесах',
        CloseErrorDialog: 'Закрити',
        Name: 'Назва',
        ChangeCategoryNameTitle: 'Зміна назви',
        Save: 'Зберегти',
        EditCategory: 'Редагувати',
        DeleteCategory: 'Видалити',
        RequiredField: 'Назва не може бути порожньою'
    },
    RegistryListAdminPage: {
        RegisterList: 'Список реєстрів',
        RegisterId: 'Номер',
        RegisterName: 'Назва',
        RegisterDescription: 'Опис',
        CreatedBy: 'Ким створено',
        UpdatedBy: 'Ким модифіковано',
        CreatedAt: 'Дата створення',
        UpdatedAt: 'Дата оновлення',
        ExportRegister: 'Експортувати',
        EditRegister: 'Редагувати',
        ImportRegisters: 'Імпортувати реєстри',
        OverwriteRegisterConfirmation: 'Реєстри з таким ID вже існують',
        OverwriteRegisterConfirmationText: 'Ви дійсно бажаєте виконати перезапис існуючих реєстрів?',
        HowToExport: 'Як експортувати?',
        HowToImport: 'Як імпортувати?',
        Continue: 'Продовжити',
        WithData: 'З даними',
        WithoutData: 'Без даних',
        RewriteSchema: 'Оновити схему ключів',
        ClearRecords: 'Видалити старі записи',
        AddData: 'Додати нові записи',
        FailExportingRegisters: 'Виникла помилка при експортуванні реєстрів',
        ErrorWhileExportingRegister: 'Помилка експортування реєстру',
        RegisterExportErrorMessage: 'Цей реєстр занадто великий для експорту з даними.',
        CloseErrorDialog: 'Закрити',
        CloseKeysDialog: 'Закрити',
        RegistryKeys: 'Ключі реєстру',
        ImportRegistersKeysXLS: 'Імпорт даних в реєстр з XLSX',
        RegisterID: 'ID реєстру',
        KeyId: 'ID ключа реєстру',
        Unique: 'Унікальна колонка в таблиці XLS (не обов`язково)',
        ErrorImportingKeys: 'Помилка при імпорті ключів з XLS',
        RequiredField: "Обов'язкове поле",
        NewRegister: 'Новий реєстр',
        Name: 'Назва',
        Description: 'Опис',
        ParentId: 'Батьківський реєстр',
        IsTest: 'Тестовий реєстр',
        Cancel: 'Скасувати',
        Save: 'Зберегти',
        CreateNew: 'Cтворити новий',
        DeleteRegister: 'Видалити',
        DeletePrompt: 'Ви дійсно бажаєте видалити реєстр?',
        DeletePropmtDescription: 'Ця дія безповоротна. Якщо ви видалите реєстр, його відновити буде неможливо.',
        DeleteError: 'Помилка видалення реєстру',
        'Can not delete.': 'Реєстр містить заповнені дані.',
        'Can not create.': 'Неможливо створити реєстр, можливо, назва вже використовується.',
        'Can not update.': 'Неможливо змінити реєстр, можливо, назва вже використовується.',
        UseStream: 'Використовувати потік',
        RegistryId: 'ID реєстру',
        RegistryKeyId: 'ID ключа реєстру'
    },
    KeyListAdminPage: {
        KeyList: 'Список ключів',
        KeyId: 'Номер',
        KeyName: 'Назва',
        KeyDescription: 'Опис',
        CreatedBy: 'Створено ким',
        CreatedAt: 'Створено коли',
        UpdatedBy: 'Оновлено ким',
        UpdatedAt: 'Оновлено коли',
        NewKey: 'Новий ключ',
        EditKey: 'Редагувати ключ',
        Name: 'Назва',
        Description: 'Опис',
        ParentId: 'Батьківський ключ',
        IsTest: 'Тестовий ключ',
        Cancel: 'Скасувати',
        Save: 'Зберегти',
        JsonSchema: 'Схема',
        ToString: 'Функція ToString',
        ToSearchString: 'Функція ToSearchString',
        DeleteKey: 'Видалити',
        DeletePrompt: 'Ви дійсно бажаєте видалити ключ реєстра?',
        DeletePropmtDescription: 'Ця дія безповоротна. Якщо ви видалите ключ, його відновити буде неможливо.',
        DeleteError: 'Помилка видалення ключа',
        'Can not delete.': 'Ключ містить заповнені дані.',
        Access: 'Доступ',
        EditKeyAccess: 'Редагувати права доступу до ключа',
        ReguiredFiled: 'Обов\'язкове поле',
        KeyNameExists: 'В цьому реєстрі ключ з такою назвою вже існує'
    },
    WorkflowLogs: {
        CreatedAt: 'Дата',
        WorkflowLogType: 'Тип',
        WorkflowLogDetails: 'Деталі',
        WorkflowLogWarnings: 'Помилки',
        Task: 'Задача',
        WorkflowIncomingMessage: 'Вхідне повідомлення',
        WorkflowOutgoingMessage: 'Вихідне повідомлення',
        Gateway: 'Шлюз'
    },
    ProcessesListPage: {
        ProcessesListPageTitle: 'Логи процесів',
        WorkflowNumber: 'Номер процесу',
        WorkflowTemplateCategory: 'Категорія',
        WorkflowTemplate: 'Назва процесу',
        UserName: 'Користувач',
        Loading: 'Завантаження...',
        ProcessListPageTitle: 'Логи для {{number}} {{name}}',
        ProcessTitle: 'Логи процесу',
        CreatedAt: 'Дата створення',
        UpdatedAt: 'Дата оновлення',
        WorkflowLogType: 'Тип',
        WorkflowLogDetails: 'Деталі',
        WorkflowLogWarnings: 'Помилки',
        Task: 'Задача',
        WorkflowIncomingMessage: 'Вхідне повідомлення',
        WorkflowOutgoingMessage: 'Вихідне повідомлення',
        Gateway: 'Шлюз',
        Event: 'Подія',
        BackButton: 'До списку',
        Error: 'Помилка',
        RestartProcessButton: 'Перезапуск процессу після помилки',
        Save: 'Зберегти',
        Cancel: 'Скасувати',
        FinishedTask: 'Оброблена задача',
        IsFinalDocument: 'Завершений документ',
        DoneTask: 'Виконана задача',
        DocumentData: 'Дані документа',
        DocumentSettings: 'Налаштування',
        WorkflowStatusLabel: 'Статус',
        RestartProcessFromPoint: 'Перезапуск процесу з цього місця',
        RestartProcessConfirmation: 'Ви дійсно бажаєте перезапустити процес з поточного місця?',
        RestartProcess: 'Перезапустити',
        Restart: 'Перезапущено',
        FailRestartingWorkflowProcessFromPoint: 'Виникла помилка під час перезапуску процесу з даної точки',
        ShowingProcesses: 'З помилками/без помилок',
        HasError: 'Невирішена помилка',
        Doing: 'В процесі',
        Done: 'Виконано',
        Rejected: 'Відхилено',
        NoStatus: 'Статус не задано',
        IsFinal: 'Завершено',
        DraftStatus: 'Чернетка',
        DraftStatusLabel: 'Фільтрація чернеток',
        Actions: 'Дії',
        Signature: 'Інформація про підпис',
        AttachmentList: 'Долучені файли',
        PDF: 'Згенерований документ',
        ShowMessages: 'Показати проміжні кроки',
        ShowMessagesTooltip: 'оберіть для перезапуску процесу з певного місця',
        SearchLabel: 'Пошук',
        ActionsWithProcess: 'Дії з процесом',
        CheckAsNotError: 'Позначити як "Без помилки"',
        CheckAsNotErrorTitle: 'Ви дійсно бажаєте позначити процес як "Без помилки"?',
        CheckAsNotErrorDescription: 'Ця дія безповоротна. Якщо ви позначите процес як "Без помилки", його відновити буде неможливо.',
    },
    UserProcessesListPage: {
        UserProcessesListPageTitle: 'Логи дій користувача',
        CreatedAt: 'Дата та час дії',
        UserName: 'ПІБ користувача',
        UserId: 'ID користувача',
        DocumentId: 'Ідентифікатор документу',
        Type: 'Дія',
        Ip: 'IP-адреса',
        Party: 'Партія',
        PartyID: 'ID партії',
        RegionPartyID: 'ID регіонального офісу'
    },
    SelectFilterHandler: {
        doing: 'В процесі',
        done: 'Виконано',
        rejected: 'Відхилено',
        withErrors: 'Тільки з помилками',
        withoutErrors: 'Без помилок',
        isDraft: 'Чернетка'
    },
    SelectUserDialog: {
        Search: 'Пошук...',
        AddUsers: 'Додати користувачів',
        FinishEditing: 'Закінчити редагування',
        EmptySelectedUsers: 'Список користувачів пустий',
        EmptySearchResults: 'Відсутні варіанти',
        SearchResults: 'Результати пошуку:',
        SearchByName: 'по імені',
        SearchByIPN: 'по ІПН',
        SearchByID: 'по ID'
    },
    AdminTools: {
        SelectDebugUser: 'Увійти від імені...'
    },
    NumberTemplateListPage: {
        TemplateId: 'Номер шаблону',
        CreateNew: 'Створити',
        DeleteTemplate: 'Видалити',
        EditTemplate: 'Редагувати',
        EditTemplateData: 'Редагувати шаблон',
        NewTemplate: 'Новий шаблон',
        NumberTemplateList: 'Шаблони номерів',
        NumberTemplateName: 'Назва шаблону',
        CreatedAt: 'Дата створення',
        UpdatedAt: 'Дата оновлення',
        CreateNewTemplate: 'Новий шаблон',
        DeleteSelectedTemplates: 'Видалити',
        DeleteTemplateDialogTitle: 'Увага!',
        DeleteTemplateDialogDescription: 'Ви дійсно хочете видалити вибраний шаблон?',
        ImportTemplates: 'Імпортувати',
        DeleteTemplates: 'Видалити шаблони номерів',
        DeleteTemplatesDialogDescription: 'Ви дійсно хочете видалити вибрані шаблони?'
    },
    NumberTemplatePage: {
        Loading: 'Завантаження...',
        Back: 'Повернутись до списку',
        Save: 'Зберегти зміни'
    },
    UserListPage: {
        DeleteUser: 'Видалення користувача',
        DeleteUserPropmt: 'Ви дійсно бажаєте видалити користувача?',
        Users: 'Користувачі',
        UserList: 'Список користувачів',
        Name: 'П.І.Б',
        IPN: 'ІПН',
        RNOKPP: 'РНОКПП',
        Phone: 'Контактний телефон',
        Email: 'Електронна адреса',
        CreatedAt: 'Дата створення',
        UserId: 'ID користувача',
        Administrator: 'Адміністратор',
        BlockUser: 'Заблокувати',
        Delete: 'Видалити',
        AddUnitUser: 'Додати користувача',
        UnblockUser: 'Розблокувати',
        SelectUserUnitsDialog: 'Виберіть підрозділи',
        UserUnits: 'Підрозділи...',
        UnitMember: 'Входить до підрозділів',
        UnitHead: 'Голова підрозділів',
        Cancel: 'Cкасувати',
        Unactive: 'Неактивний',
        WrongUserName: 'Помилка в ПІБ',
        Save: 'Зберегти',
        OK: 'ОК',
        OnlyAdministrator: 'Тільки адміністратори',
        SetAdmin: 'Зробити адміном',
        UnsetAdmin: 'Позбавити адмінських прав',
        UserCertInfo: 'Інформація сертифікату',
        PemNotFound: 'Сертифікат користувача не знайдено',
        DialogTitle: 'Увага!',
        ErrorAddingUser: 'Помилка додавання користувача',
        SetAdminTitle: 'Надати права адміністратора?',
        UnsetSetAdminTitle: 'Позбавити прав адміністратора?',
        'Requested member already in unit by ID.': 'Користувач вже присутній.',
        'Requested member with wrong name.': 'У користувача з таким РНОКПП вказане інше ПІБ в сертифікаті електронного підпису, перевірте введені дані'
    },
    HealthCheckListPage: {
        HealthCheckListPage: 'Статуси сервісів',
        HealthCheckListTitle: 'Сервіси',
        status: 'Статус',
        name: 'Назва',
        details: 'Деталі',
        version: 'Версія',
        message: 'Повідомлення',
        processPid: 'processPid',
        authResponse: 'auth',
        notifyResponse: 'notify',
        edsServiceResponse: 'eds',
        registerServiceResponse: 'registerService',
        fileStorageServiceResponse: 'fileStorage',
        correctNotifyConnection: 'correctNotify',
        correctEdsConnection: 'correctEds',
        smsNotifierResponse: 'smsNotifier',
        emailNotifierResponse: 'emailNotifier',
        registerResponse: 'register',
        persistLinkResponse: 'persistLink',
        taskServiceResponse: 'taskService',
        previewServiceResponse: 'previewService',
        oauthResponse: 'oauth',
        correctAuthServiceConnection: 'correctAuthService',
        correctPeristLinkConnection: 'correctPeristLink',
        authMeta: 'authMeta',
        notifyMeta: 'notifyMeta',
        edsMeta: 'edsMeta',
        managerServiceResponse: 'managerServiceResponse',
        customer: 'customer',
        environment: 'environment',
        smsNotifierMeta: 'smsNotifierMeta',
        emailNotifierMeta: 'emailNotifierMeta',
        registerMeta: 'registerMeta',
        pLinkResponse: 'pLinkResponse',
        pLinkMeta: 'pLinkMeta',
        filestorageResponse: 'filestorageResponse',
        filestorageMeta: 'filestorageMeta',
        trembitaResponse: 'trembitaResponse',
        digestNotifierResponse: 'digestNotifierResponse',
        authServiceResponse: 'authServiceResponse',
        registerServiceMeta: 'registerServiceMeta',
        taskServiceMeta: 'taskServiceMeta',
        previewServiceMeta: 'previewServiceMeta',
        oauthServiceMeta: 'oauthServiceMeta'
    },
    JsonSchemaEditor: {
        CollapseElements: 'Контроли',
        VisualEditor: 'Схема',
        CodeEditor: 'Код',
        FormPreview: 'Перегляд',
        Step: 'Крок',
        ObjectElement: 'Контейнер',
        StringElement: 'Текстове поле',
        Components: 'Компоненти',
        NewElementId: 'Введіть ключ нового елемента',
        Cancel: 'Скасувати',
        Save: 'Зберегти',
        Properties: 'Властивості',
        Containers: 'Контейнери',
        TextFields: 'Текстові поля',
        Email: 'Електронна адреса',
        ElementBlocks: 'Блоки',
        Name: 'Ім\'я',
        LastName: 'Прізвище',
        BirthDate: 'Дата народження',
        Pib: 'П.І.Б.',
        Ipn: 'ІПН',
        Phone: 'Телефон',
        Passport: 'Паспорт',
        Edrpoy: 'ЄДРПОУ',
        Select: 'Випадаючий список',
        SelectFiles: 'Вибір файлів',
        Toggle: 'Перемикач',
        Textarea: 'Текстовий редактор',
        Date: 'Дата',
        CheckboxGroup: 'Прапорці',
        DynamicCheckboxGroup: 'Динаміні прапорці',
        RadioGroup: 'Радіо-кнопки',
        DynamicRadioGroup: 'Динаміні радіо-кнопки',
        Register: 'Список з реєстру',
        RegisterRelated: 'Пов\'язаний список з реєстру',
        TextBlock: 'Довільний текст',
        TreeSelect: 'Деревовидний список',
        RelatedSelects: 'Пов\'язані списки',
        PreviewDocument: 'Перегляд файлів',
        FormGroup: 'Група елементів',
        Popup: 'Розкриваюче вікно',
        Edrpou: 'ЄДРПОУ',
        Unzr: 'УНЗР',
        Iban: 'ІБАН',
        Atu: 'Адреса',
        Citizen: 'Громадянин',
        PassportIssurer: 'Ким видано',
        PassportIssueDate: 'Дата видачі',
        Basic: 'Головні',
        ExistedElementId: 'Увага',
        ExistedElementWarning: 'Елемент з таким ключем вже існує, замінити його?',
        Change: 'Замінити',
        Payment: 'Оплата',
        RegisterSelect: 'Деревовидний реєстр',
        CalculateButton: 'Кнопка розрахувати',
        RegistrySearch: 'Реєстр з пошуком',
        Table: 'Таблиця',
        RegisterTable: 'Таблиці даних з реєстру',
        Spreadsheet: 'Електронна таблиця',
        Modal: 'Модальне вікно',
        ArrayElement: 'Масив',
        CustomDataSelect: 'Випадаючий список (кастом)',
        UnitSelect: 'Випадаючий список юнітів',
        SignerList: 'Список підписантів'
    }
};

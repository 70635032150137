import React from 'react';

import {
    Tooltip,
    IconButton
} from '@material-ui/core';

import DeleteImage from '@material-ui/icons/Delete';

export default ({ t, deleteItem, readOnly }) => (
    <Tooltip title={t('DeleteItem')}>
        <IconButton onClick={deleteItem} disabled={readOnly}>
            <DeleteImage />
        </IconButton>
    </Tooltip>
);

import React from 'react';
import Scrollbar from 'components/Scrollbar';

import { withStyles } from '@material-ui/core';

import useStickyState from 'helpers/useStickyState';

import SheetHeader from './SheetHeader';

const styles = {
    scroll: {
        '& .ps__rail-x': {
            opacity: 1
        },
        overflowY: 'hidden !important',
        overflowX: 'hidden !important'
    }
};

const SheetLayout = ({ children, className, classes, width, stepName, path, scrollRef, dataListRef, selecting, ...rest }) => {
    const [scrollLeft, setScrollLeft] = useStickyState(0, [stepName].concat(path, 'spreadsheet').join('-'));

    React.useEffect(() => {
        if (scrollRef.current) {
            // eslint-disable-next-line no-underscore-dangle
            scrollRef.current.scrollBarRef._container.scrollLeft = scrollLeft;
        }
    }, [scrollRef, scrollLeft]);

    React.useEffect(() => {
        const updateScroll = (e) => {

            if (!e) return;

            const { x: cursorX } = e;
            // const { x, width: containerWidth } = dataListRef.current.getBoundingClientRect();

            const scrollContainer = scrollRef && scrollRef.current && scrollRef.current.scrollBarRef && scrollRef.current.scrollBarRef._container;

            if (!scrollContainer) return;

            const { x, width: containerWidth } = scrollContainer && scrollContainer.getBoundingClientRect();

            if (selecting) {
                let deltaX = 0;

                if (x && cursorX && (cursorX < x)) {
                    deltaX = cursorX - x;
                }

                // console.log('cursorX', cursorX, x, dataListRef, scrollContainer);
                if (x && cursorX && (cursorX > x + containerWidth)) {
                    deltaX = cursorX - x - containerWidth;
                }

                scrollContainer.scrollLeft += deltaX;
            }
        };

        window.addEventListener('mousemove', updateScroll);
        return () => window.removeEventListener('mousemove', updateScroll);
    });

    return (
        <Scrollbar
            ref={scrollRef}
            options={{ suppressScrollY: true }}
            className={classes.scroll}
            onScrollX={({ scrollLeft: left }) => setScrollLeft(left)}
            onScrollY={(container) => console.log('container', container)}
        >
            <table
                className={className}
                style={
                    {
                        marginBottom: 20
                    }
                }
            >
                <SheetHeader {...rest} />
                <tbody ref={dataListRef}>
                    {children}
                </tbody>
            </table>
        </Scrollbar>
    );
};

export default withStyles(styles)(SheetLayout);

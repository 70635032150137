import profileModule from 'modules/profile';
import homeModule from 'application/adminpanel/modules/home';
import settingsModule from 'application/adminpanel/modules/settings';
import workflowModule from 'application/adminpanel/modules/workflow';
import usersModule from 'application/adminpanel/modules/users';
import registryModule from 'application/adminpanel/modules/registry';
import processesModule from 'application/adminpanel/modules/processes';
import healthCheckModule from 'application/adminpanel/modules/healthCheck';

import testModule from 'modules/test';

const modules = [
    testModule,
    profileModule,
    settingsModule,
    workflowModule,
    usersModule,
    registryModule,
    processesModule,
    healthCheckModule,
    homeModule
];

export default modules;

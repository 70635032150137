import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

import Preloader from 'components/Preloader';
import { SchemaForm, handleChangeAdapter } from 'components/JsonSchema';

import emptyEvent from 'application/adminpanel/modules/workflow/variables/emptyEvent';
import eventElementTypes from 'application/adminpanel/modules/workflow/variables/eventElementTypes';
import { requestEvent, changeEventData, saveEventData, getEventTypes } from 'application/adminpanel/actions/events';

import minUnusedIndex from 'helpers/minUnusedIndex';
import padWithZeroes from 'helpers/padWithZeroes';
import processList from 'services/processList';

import elementSchema from '../../defaultSchema.json';
import schema from './schema';

class EventElement extends React.Component {
    state = { error: null, showErrorDialog: false, validationErrors: [] };

    async componentDidMount() {
        this.loadEvent();
    }

    async componentDidUpdate(prevProps) {
        const { selectionId } = this.props;

        if (prevProps.selectionId !== selectionId) {
            this.loadEvent();
        }
    }

    getNextEventId = (element) => {
        const { workflow, modeler } = this.props;

        const ids = modeler.get('elementRegistry').getAll()
            .filter(({ type, id }) => eventElementTypes.includes(type) && id !== element.businessObject.id)
            .filter(({ businessObject: { id } }) => !this.isLocalId(id))
            .map(this.getEventId)
            .map(String)
            .map(id => id.replace(workflow.id, ''))
            .map(id => parseInt(id, 10));

        return workflow.id + padWithZeroes(minUnusedIndex(ids, 1), 3);
    }

    isLocalId = id => eventElementTypes.some((type) => {
        const suffix = type.split(':').pop();
        return id.indexOf(suffix) === 0;
    });

    loadEvent = async () => {
        const { t, element, workflow, actions, actualEventList, onChange, eventTypes: types } = this.props;
        const eventTypes = types || await processList.hasOrSet('getEventTypes', actions.getEventTypes);

        const eventId = this.getEventId(element);

        if (this.isLocalId(element.businessObject.id)) {
            const nextEventId = this.getNextEventId(element);
            element.businessObject.id = ['event', nextEventId].join('-');
            element.businessObject.name = element.businessObject.name || t('NewEvent');
            onChange(element.businessObject);
            // this.forceUpdate();
            return;
        }

        if (!actualEventList[eventId] && !processList.has('requestEvent', eventId)) {
            const event = await processList.set('requestEvent', actions.requestEvent, eventId);
            if (event instanceof Error) {
                await actions.saveEventData(emptyEvent(eventId, { t, eventTypes, workflow }));
            }
        }
    }

    getEventId = ({ businessObject: { id } }) => parseInt(id.split('-').pop(), 10);

    handleChange = async (event) => {
        const { actions, element } = this.props;

        // const validationErrors = validateData(event, schema({ eventTypes }));
        // this.setState({ validationErrors });

        await actions.changeEventData(this.getEventId(element), event);
        this.handleChangeBusiness();
    }

    handleChangeBusiness = (businessObject) => {
        const { onChange } = this.props;
        onChange && onChange(businessObject);
        // this.forceUpdate();
    }

    renderForm() {
        const { validationErrors } = this.state;
        const { actualEventList, element, eventTypes } = this.props;
        const eventId = this.getEventId(element);

        const event = actualEventList[eventId];

        if (!event) {
            return <Preloader />;
        }

        return (
            <SchemaForm
                schema={schema({ eventTypes })}
                value={event}
                errors={validationErrors}
                onChange={handleChangeAdapter(event, this.handleChange)}
            />
        );
    }

    render() {
        const { t, element } = this.props;
        const { error, showErrorDialog } = this.state;

        return (
            <div>
                <SchemaForm
                    schema={elementSchema}
                    value={element.businessObject}
                    onChange={handleChangeAdapter(element.businessObject, this.handleChangeBusiness)}
                />
                {this.renderForm()}
                {(showErrorDialog && error)
                    ? (
                        <Dialog
                            open={true}
                            onClose={() => this.setState({ showErrorDialog: false })}
                        >
                            <DialogTitle>{t('ErrorWhileSavingEvent')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {error.message}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.setState({ showErrorDialog: false })}
                                    color="primary"
                                    autoFocus={true}
                                >
                                    {t('CloseErrorDialog')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null}
            </div>
        );
    }
}

EventElement.propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    actualEventList: PropTypes.object,
    element: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    eventTypes: PropTypes.array,
    workflow: PropTypes.object,
    selectionId: PropTypes.string,
    modeler: PropTypes.object
};

EventElement.defaultProps = {
    actualEventList: {},
    eventTypes: null,
    workflow: {},
    selectionId: null,
    modeler: null
};

const mapStateToProps = ({
    events: { actual, types }
}) => ({
    actualEventList: actual,
    eventTypes: types
});

const mapDispatchToProps = dispatch => ({
    actions: {
        requestEvent: bindActionCreators(requestEvent, dispatch),
        saveEventData: bindActionCreators(saveEventData, dispatch),
        changeEventData: bindActionCreators(changeEventData, dispatch),
        getEventTypes: bindActionCreators(getEventTypes, dispatch)
    }
});

const translated = translate('WorkflowAdminPage')(EventElement);
export default connect(mapStateToProps, mapDispatchToProps)(translated);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import DataTable from 'components/DataTable';

class DataTableStated extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            page: 1,
            rowsPerPage: 10
        };
    }

    getFilteredData = () => {
        const { data } = this.props;
        return data; // filters will be here
    };

    getData = () => {
        const { isIncreasing } = this.props;
        const { page, rowsPerPage } = this.state;
        const firstIndex = (page - 1) * rowsPerPage;
        const lastIndex = page * rowsPerPage;

        const data = this.getFilteredData();

        isIncreasing && page !== 1 && this.setState({ page: 1 });

        if ((data || []).length <= firstIndex) {
            const start = firstIndex - rowsPerPage;
            return data.slice(start < 0 ? 0 : start, lastIndex - rowsPerPage);
        }

        return data.slice(firstIndex, lastIndex);
    };

    onChangePage = page => {
        this.setState({ page: page + 1 });
        const { reserIsIncreasing } = this.props;
        reserIsIncreasing && reserIsIncreasing();
    };

    onChangeRowsPerPage = rowsPerPage => this.setState({ rowsPerPage });

    render = () => {
        const { controls, actions, ...dataTableProps } = this.props;
        const { page, rowsPerPage } = this.state;

        const data = this.getFilteredData();

        return (
            <DataTable
                {...dataTableProps}
                actions={
                    {
                        ...actions,
                        onChangePage: this.onChangePage,
                        onChangeRowsPerPage: this.onChangeRowsPerPage
                    }
                }
                data={this.getData()}
                page={page}
                count={data.length}
                rowsPerPage={rowsPerPage}
                controls={
                    {
                        ...controls,
                        bottomPagination: data.length > rowsPerPage
                    }
                }
            />
        );
    };
}

DataTableStated.propTypes = {
    controls: PropTypes.object,
    actions: PropTypes.object,
    data: PropTypes.array,
    reserIsIncreasing: PropTypes.func,
    isIncreasing: PropTypes.bool
};

DataTableStated.defaultProps = {
    actions: {},
    data: [],
    reserIsIncreasing: null,
    isIncreasing: false,
    controls: {
        pagination: false,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        switchView: true
    }
};

export default translate('DataTableStated')(DataTableStated);

import React from 'react';

import TimeLabel from 'components/Label/Time';
import LogDetails from '../components/LogDetails';
import JsonExpand from '../components/JsonExpand';
import TaskDetails from '../components/TaskDetails';
import SignatureList from '../components/SignatureList';
import AttachmentList from '../components/AttachmentList';
import DocumentPreview from '../components/DocumentPreview';

export default ({ t, processId, editable, search }) => ({
    controls: {
        pagination: false,
        toolbar: false,
        search: false,
        header: true,
        refresh: false,
        customizateColumns: false
    },
    checkable: false,
    columns: [{
        id: 'createdAt',
        width: 160,
        sortable: false,
        name: t('CreatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'updatedAt',
        width: 160,
        sortable: false,
        name: t('UpdatedAt'),
        render: value => <TimeLabel date={value} />
    }, {
        id: 'type',
        width: 600,
        sortable: false,
        name: t('WorkflowLogType'),
        padding: 'none',
        render: (value, log) => <LogDetails processId={processId} log={log} search={search} />
    }, {
        id: 'details',
        sortable: false,
        name: t('Actions'),
        padding: 'none',
        render: (value, log) => (
            <>
                <JsonExpand value={value} />
                {log.type === 'task' && editable ? <TaskDetails processId={processId} log={log} /> : null}
                <SignatureList {...log} />
                <AttachmentList {...log} />
                <DocumentPreview {...log} />
            </>
        )
    }]
});

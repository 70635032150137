import React from 'react';
import { translate } from 'react-translate';
import { DragPreviewImage, useDrag } from 'react-dnd';

import {
    Toolbar,
    Tooltip,
    Button,
    withStyles
} from '@material-ui/core';

import previewImage from './dragImage';

const styles = {
    root: {
        color: 'inherit',
        margin: '0 5px'
    },
    label: {
        justifyContent: 'flex-start'
    },
    icon: {
        marginRight: 8
    }
};

const DraggableElement = ({ t, classes, name, elementType, open }) => {
    const [, drag, preview] = useDrag({
        item: {
            ...elementType,
            defaultData: {
                ...elementType.defaultData,
                description: t(name)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const button = React.useRef(null);

    return (
        <>
            <DragPreviewImage connect={preview} src={previewImage} />
            <Toolbar disableGutters={true} ref={button}>
                <Button ref={drag} classes={classes} fullWidth={true}>
                    <Tooltip title={t(name)}>
                        <elementType.Icon className={open ? classes.icon : undefined} />
                    </Tooltip>
                    {open ? t(name) : null}
                </Button>
            </Toolbar>
        </>
    );

};

const styled = withStyles(styles)(DraggableElement);
export default translate('JsonSchemaEditor')(styled);

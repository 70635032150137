import React from 'react';
import objectPath from 'object-path';

import Scrollbar from 'components/Scrollbar';
import { withEditor } from 'components/JsonSchema/editor/JsonSchemaProvider';
import getElementType from 'components/JsonSchema/editor/helpers/getElementType';

import SchemaItem from './components/SchemaItem';

const VisualEditor = ({ rootValue, editPath }) => (
    <Scrollbar>
        <SchemaItem
            path={editPath}
            value={objectPath.get(rootValue, editPath)}
            elementType={getElementType(null, editPath)}
        />
    </Scrollbar>
);

export default withEditor(VisualEditor);
